import * as React from "react";
import { CanvasMeta } from "../Canvas";
import { intl } from "../../../GlobalHelperReact";
import { useParticleContext } from "../../../api/ParticleContext";
import { MainWorkerPipe } from "../../../MainWorkerPipe";
import { WebAppTelemetryFactory } from "../../../../app/services/WebAppTelemetry.service";
import { generateCanvasCalendarIntl } from "@/legacy/GlobalHelperFluentUI";

export function getMoreButtonOptions(props: { lcmx: any; worker: MainWorkerPipe; meta: CanvasMeta }) {
    const { lcmx, meta, worker } = props;

    const LCMD = useParticleContext();
    const moreMenuProps = React.useMemo(() => {
        const config_exports = Array.isArray(worker.config.exports?.cmd)
            ? worker.config.exports.cmd.reduce((ret, cmd) => {
                  ret.push(...cmd(LCMD));
                  return ret;
              }, [])
            : null;

        const moreMenuProps = [
            {
                key: "pdf",
                onClick: onPdfExport,
            },
            {
                key: "mpp",
                onClick: () => {
                    onExport("mpp.xml", { auto: true });
                },
                options: [
                    {
                        key: "mpp_xml",
                        onClick: () => onExport("mpp.xml", {}),
                    },
                    {
                        key: "mpp_xml_auto",
                        onClick: () => {
                            onExport("mpp.xml", { auto: true });
                        },
                    },
                ],
            },
            {
                key: "lcmd.export.procore",
                onClick: () => onExport("procore", { meta: meta }),
            },
            {
                key: "lcmd.export.beta.csv",
                onClick: () =>
                    onExport("csv", {
                        cards: true,
                    }),
                data: true,
            },
            {
                key: "lcmd.export.csv",
                onClick: () => onExport("csv", {}),
                data: true,
            },
            {
                key: "lcmd.export.changes.csv",
                onClick: () => onExport("changes.csv", {}),
                data: false,
            },
            {
                key: "lcmd.export.processes.csv",
                onClick: () => onExport("processes.csv", {}),
                data: false,
            },
            {
                key: "reasonCode",
                onClick: onReasonCodeExcelExport,
            },
            {
                key: "msproject",
                onClick: () => onExport("msproject", {}),
                data: false,
            },
            {
                key: "lcmdx",
                onClick: onLCMDExtension,
            },
        ];
        function onReasonCodeExcelExport() {
            const columnHeaderTranslations = new Map<string, string>();
            columnHeaderTranslations.set("reasonCodeId", intl.get(`export.reasonCode.xlsx.columns.reasonCodeId`));
            columnHeaderTranslations.set("reasonCode", intl.get(`export.reasonCode.xlsx.columns.reasonCode`));
            columnHeaderTranslations.set("processId", intl.get(`export.reasonCode.xlsx.columns.processId`));
            columnHeaderTranslations.set("processName", intl.get(`export.reasonCode.xlsx.columns.processName`));
            columnHeaderTranslations.set("trade", intl.get(`export.reasonCode.xlsx.columns.trade`));

            worker.postMessage([
                "exportReasonCode",
                {
                    target: "csv",
                    opt: { translations: columnHeaderTranslations },
                },
            ]);
        }

        function onPdfExport() {
            const dialogId = "dialog.export.pdf";
            worker.dispatchMessage([
                "toggle",
                dialogId,
                {
                    dialogId,
                    doExport: (opt, onDone?: (success: boolean) => void) => {
                        worker.postMessage([
                            "export",
                            {
                                target: "pdf",
                                opt: opt || {},
                                calendarIntl: generateCanvasCalendarIntl(),
                                cb: onDone
                                    ? worker.registerCallback((data) => {
                                          onDone(data);
                                      })
                                    : undefined,
                            },
                        ]);
                        console.log("DO EXPORT...");
                    },
                },
            ]);
        }

        function onExport(target: string, opt?: any) {
            if ("procore" === target) {
                opt = { ...(opt || {}), ...(opt.meta?.procore || {}) };
            } else if ("csv" === target) {
                const columnHeaders = [
                    "id",
                    "processName",
                    "startDate",
                    "endDate",
                    "status",
                    "duration",
                    "trade",
                    "tradeBackgroundColor",
                    "predecessors",
                    "successor",
                    "taktZoneLevel",
                    "taktZones",
                    "taktZonesId",
                    "cwStart",
                    "cwEnd",
                    "extensions",
                    "date",
                    "processId",
                    "task",
                    "description",
                    "tradeDescription",
                    "workforce",
                    "statusChanged",
                ];

                const columnHeaderTranslations = new Map<string, string>();
                columnHeaders.forEach((x) => columnHeaderTranslations.set(x, intl.get(`export.xlsx.columns.${x}`)));
                opt = { ...(opt || {}), translations: columnHeaderTranslations };

                if (opt.cards === true) {
                    WebAppTelemetryFactory.trackEvent("export-xlsx-cards");
                } else {
                    WebAppTelemetryFactory.trackEvent("export-xlsx-processes");
                }
            }

            worker.postMessage([
                "export",
                {
                    target: target,
                    opt: opt,
                },
            ]);
        }

        function onLCMDExtension() {
            worker.dispatchMessage(["framework", "uploadExtension"]);
        }

        return [
            ...moreMenuProps
                .filter((item) => {
                    let ret = false !== item.data;
                    if (!ret && Array.isArray(lcmx?.menus)) {
                        ret = ret || lcmx.menus.findIndex((menu) => menu.cmd === item.key) >= 0;
                    }
                    if (ret && "lcmd.export.procore" === item.key) {
                        ret = Boolean(meta?.procore);
                    }

                    if (["lcmd.export.csv", "lcmd.export.beta.csv"].includes(item.key)) {
                        ret = isT3XlsxExportAllowedForUser();
                    }

                    return ret;
                })
                .map((item) => {
                    const newItem = {
                        ...item,
                        disabled: "lcmdx" === item?.key && "admin" !== meta?.role,
                        text: intl.get(["canvas.cmd", item.key.replace(/\./g, "_"), "text"].join(".")),
                        title: intl.get(["canvas.cmd", item.key.replace(/\./g, "_"), "title"].join(".")),
                    };

                    if (item.options) {
                        newItem.options = item.options.map((option) => ({
                            ...option,
                            text: intl.get(["canvas.cmd", option.key.replace(/\./g, "_"), "text"].join(".")),
                            title: intl.get(["canvas.cmd", option.key.replace(/\./g, "_"), "title"].join(".")),
                        }));
                    }

                    return newItem;
                }),
            ...(config_exports || []),
        ];
    }, [LCMD, lcmx, meta?.role]);

    function isT3XlsxExportAllowedForUser(): boolean {
        const t3ProjectIds = ["47a141ba-53df-402e-9f80-db489c63f504", "c2649094-f2a8-4975-a1e6-f2f6da5ccc86"];
        const t3XlsxExportAllowedForUserIds = [
            "3f8968b8-6a09-41b0-869c-a91290716b08", // max.Jochim@dreso.com
            "7c72c39f-d21a-45f3-bc06-cb75ff61a55f", // marco.tsaklakidis@dreso.com
            "ff5b6bf6-ceca-4aca-8d80-5183a1473dfd", // petra.drescher@dreso.com
            "61198f2c-8f66-437e-ab8e-30c1f3c84806", // wolfgang.joos@dreso.com
            "ffa18e66-cc15-445b-9692-62d5c1f36a1f", // lina.estupinan@dreso.com
            "4b115339-b461-497a-845f-5121c0a90d62", // era.sylejmani@dreso.com
            "deedff1c-57ba-4e01-beba-7e2530c196eb", // lisa-aline.sommer@dreso.com
            "b9449d5b-3d0c-4852-a7e7-f43dcfb6c1a7", // valeska-marie.pogoda@dreso.com
            "8cd4895c-c251-4319-893d-6a6ab864a3f6", // sahana.pavilu@dreso.com
            "d3dee875-5eb7-46e2-9b83-d1ef46d22d4b", // inez.horn@dreso.com
        ];

        return Boolean(
            t3ProjectIds.includes(LCMD.worker.nav.project)
                ? t3XlsxExportAllowedForUserIds.includes(LCMD.worker.warmup_result.sub)
                : true,
        );
    }

    return moreMenuProps;
}
