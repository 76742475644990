import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { ToDoEditView } from "../../ToDo/EditView";
import { ItemState, ItemType, StoreActionItem, ToDoProps } from "../../ToDo/interface";
import { DigitalPlanningBoardSelectionEvent, intl, LCMDContextAuthUser } from "lcmd2framework";
import { createStoreItem, generateTodosFromRawDate, getEmptyToDo } from "../../ToDo/ActionItem.factory";
import { getToDoState } from "../../Utils";
import { useLCMD } from "../../../app/LCMContext";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { getCurrentLanguage } from "../../utils/date/locale";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { ToDoCheckbox } from "../ToDoCheckbox/ToDoCheckboxV2";
import { Pencil, Plus, Trash2 } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";

export function ActionsView() {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editableActionItem, setEditableActionItem] = useState<ToDoProps>(getEmptyToDo());
    const [currentUser, setCurrentUser] = useState<LCMDContextAuthUser>(null);
    const [processId, setProcessId] = useState<number[]>([]);
    const [actionItems, setActionItems] = useState<ToDoProps[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [today, setToday] = useState<number>(0);
    const sidebarContext = useContext(SidebarContext);
    const LCMD = useLCMD();

    sidebarContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setProcessId(ev.pid);
        },
        [setProcessId],
    );

    LCMD.useProcessDetailsEffect(
        processId,
        false,
        (error, data) => {
            if (!data) {
                return;
            }

            setToday(data.todos.today.value);
            setActionItems(generateTodosFromRawDate(data.todos).filter((todo) => !!todo.actionId));
            if (!data.pid?.indeterminate && !Array.isArray(data.pid?.value)) {
                setEditableActionItem({
                    ...editableActionItem,
                    process: { pid: data.pid?.value, name: data.name?.value, areaPath: data.areaPath },
                });
            }
        },
        [],
        sidebarContext.ppTS,
    );

    useEffect(() => {
        LCMD.getAuthUser((error, user) => {
            if (error) {
                console.error(error);
            } else {
                setCurrentUser(user);
            }
        });
    }, []);

    function close() {
        setEditableActionItem({
            ...getEmptyToDo(),
            process: {
                pid: editableActionItem.process.pid,
                name: editableActionItem.process.name,
                areaPath: editableActionItem.process.areaPath,
            },
        });
        setShowModal(false);
    }

    function showModalView() {
        if (editableActionItem?.process?.pid) {
            setEditableActionItem({ ...editableActionItem, raisedBy: [currentUser.sub] });
            setShowModal(true);
        }
    }

    function editActionItem(ai: ToDoProps) {
        WebAppTelemetryFactory.trackEvent("action-item-edit-button-clicked");
        setEditableActionItem({ ...ai });
        setShowModal(true);
    }

    function checkboxChanged(checked: boolean, actionItem: ToDoProps) {
        WebAppTelemetryFactory.trackEvent("action-item-checkbox-clicked", {
            checked: checked,
        });
        const obj: StoreActionItem = createStoreItem(actionItem);
        if (actionItem.actionId) {
            LCMD.setOrCreateTodoItemState(
                { pid: actionItem.process.pid, id: actionItem.actionId },
                { value: checked ? ItemState.DONE : ItemState.OPEN },
                obj,
                (error) => {
                    if (error) {
                        console.error(error);
                    }
                },
            );
        } else if (actionItem.stability) {
            console.log("Can this happen? HANDLE ME?!");
        }
    }

    function renderCheckboxes(): JSX.Element[] {
        let tmpActionItems = [...actionItems];
        if (searchText.length > 0) {
            tmpActionItems = tmpActionItems.filter((ai) => {
                return ai.issue.toLowerCase().indexOf(searchText.toLowerCase()) >= 0;
            });
        }
        return tmpActionItems.map((ai) => (
            <ToDoCheckbox
                key={ai.id}
                menuProps={[
                    {
                        key: "editMessage",
                        text: intl.get("ToDo.edit"),
                        icon: <Pencil size={16} color="#666666" />,
                        onClick: () => {
                            editActionItem(ai);
                        },
                    },
                    {
                        key: "deleteEvent",
                        text: intl.get("ToDo.delete"),
                        icon: <Trash2 size={16} color="#666666" />,
                        onClick: () => {
                            LCMD.setOrCreateTodoItemState(
                                { pid: ai.process.pid, id: ai.actionId },
                                {
                                    value: null,
                                },
                                null,
                                (error) => {
                                    if (error) {
                                        console.error(error);
                                    }
                                },
                            );
                        },
                    },
                ]}
                headline={ai.issue}
                subtext={
                    new Date(ai.deadline).toUTCFormatString(getCurrentLanguage(), {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                    }) + (ai.leadDays ? " · " + ai.leadDays + intl.get("StabilityView.WorkingDaysShort") : "")
                }
                state={getToDoState(ai.status === ItemState.DONE, ai.deadline, today)}
                onCheckBoxChange={(checked) => {
                    checkboxChanged(checked, ai);
                }}
            />
        ));
    }

    return (
        <>
            <div>
                <div className="border-b border-solid border-gray-300">
                    <div className="px-5 pb-2 pt-0">
                        <p className="text-lg font-semibold not-italic leading-6 tracking-tight">
                            {intl.get("ActionsView.ActionsLabelText")}
                        </p>
                    </div>
                </div>
                <div className="h-full overflow-y-hidden">
                    <SearchBar
                        className="w-100 my-2 px-2"
                        value={searchText}
                        onSearch={(value) => setSearchText(value || "")}
                        onChange={(event, newValue) => setSearchText(newValue)}
                        placeholder={intl.get("StabilityView.SearchFieldPlaceholderText")}
                    />
                    <ScrollArea
                        style={{
                            width: "100%",
                            height: "calc(100% - 200px)",
                            position: "absolute",
                        }}
                        className="pl-2"
                    >
                        {renderCheckboxes()}
                    </ScrollArea>
                </div>
            </div>
            <div className="absolute bottom-0 flex h-16 w-full justify-end border-t border-solid border-gray-300 px-2 py-3">
                <Button
                    variant="outline"
                    className="border-[#8a8886]"
                    onClick={showModalView}
                    disabled={!editableActionItem?.process?.pid}
                >
                    <Plus size={16} className="mr-2" />
                    {intl.get("ActionsView.NewActionItemText")}
                </Button>
            </div>

            {showModal && (
                <ToDoEditView
                    isOpen={showModal}
                    todos={[editableActionItem]}
                    createItemType={!editableActionItem.actionId && ItemType.ACTION_ITEM}
                    onCloseEditView={close}
                />
            )}
        </>
    );
}
