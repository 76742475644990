import * as React from "react";
import { DataModelFilterSavedAs, DateToMonday, DigitalPlanningBoardTradeDetails, FrameworkError } from "lcmd2framework";
import { Stack, IStackTokens, Separator } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";

import { FilterComponent } from "./FilterComponent";
import { DatesFilterComponent } from "./DatesFilterComponent/DatesFilterComponent";
import { TradesFilterComponent } from "./TradesFilterComponent/TradesFilterComponent";
import { SelectionDatesConst } from "../DateScreen";
import { useLCMD } from "../../../../../app/LCMContext";
import { DeleteFilterButton } from "../../../../common/deleteFilter/DeleteFilterButton";
import { copyAndSort } from "@/components/Utils";

const initialScreenStackTokens: IStackTokens = {
    padding: 26,
    childrenGap: 5,
};

function toLCMDFilter(tzFilter, tradesFilter, dateFilter) {
    const _taktZones = Object.getOwnPropertyNames(tzFilter);
    const _trades = Object.getOwnPropertyNames(tradesFilter);
    return {
        tz: _taktZones.length > 0 ? _taktZones.map((tid) => Number.parseInt(tid)) : undefined,
        trade: _trades.length > 0 ? _trades.map((id) => Number.parseInt(id)) : undefined,
        d: dateFilter,
    };
}

export function MainScreen({
    dialog,
    route: {
        params: {
            //        LCMD,
            //        tz,
            //        trades,
            //        initialTaktZones,
            //        initialTrades,
            //        initialDateFilter,
            editFilterEntityId,
            onClose,
            filterOptions,
        },
    },
}) {
    const LCMD = useLCMD();

    const [info, setInfo] = React.useState({});
    const [tz, setTZ] = React.useState([]);
    const [trades, setTrades] = React.useState([]);
    const [tzFilter, setTZFilter] = React.useState({});
    const [tradesFilter, setTradesFilter] = React.useState({});
    const [dateFilter, setDateFilter] = React.useState(null);
    const [editFilterEntity, setEditFilterEntity] = React.useState(undefined);

    React.useLayoutEffect(() => {
        if (filterOptions?.modeDailyBoard) {
            if (!dateFilter) {
                // 6 weeks filter by defailt in daily board
                const date = DateToMonday(new Date(Date.now()), true);
                setDateFilter([date.getTime(), SelectionDatesConst.FOUR_WEEKS]);
            }
        }
    }, [dateFilter, filterOptions]);

    const onClearFilter = React.useCallback(() => {
        setTZFilter({});
        setTradesFilter({});
        setDateFilter(null);
        setEditFilterEntity(undefined);
    }, [setTZFilter, setTradesFilter, setDateFilter, setEditFilterEntity]);

    React.useEffect(() => {
        return LCMD.loadFilterData((data) => {
            const wbs = data?.wbs || [];
            const trades: DigitalPlanningBoardTradeDetails[] = data?.trades || [];
            const sortedTrades = copyAndSort(trades, "label");

            setInfo((info) => ({
                ...info,
                startDate: data.startDate ? new Date(data.startDate) : null,
                endDate: data.startDate ? new Date(data.endDate) : null,
            }));

            const filterEntity = data?.filterCollection.find((filter) => filter._hid === editFilterEntityId);

            const filter: DataModelFilterSavedAs = filterEntity ? filterEntity._filter : data?.filter;

            const initialTaktZones = (filter?.tz || []).reduce((ret, tid) => {
                const tz = wbs.find((tz) => tz.tid === tid);
                if (tz) {
                    ret[tid] = tz;
                } else {
                    // deleted???
                }
                return ret;
            }, {});
            const initialTrades = (filter?.trade || filterOptions?.trades?.trades || []).reduce((ret, trid) => {
                const trade = sortedTrades.find((trade) => trade.id === trid);
                if (trade) {
                    ret[trid] = trade;
                } else {
                    // deleted ???
                }
                return ret;
            }, {});
            const initialDateFilter = Array.isArray(filter?.d) && filter.d.length >= 2 ? filter.d : null;
            setTZ(wbs);
            setTrades(sortedTrades);
            setTZFilter(initialTaktZones);
            setTradesFilter(initialTrades);
            setDateFilter(initialDateFilter);
            setEditFilterEntity(filterEntity);
            if ("function" === typeof filterOptions?.trades?.selected) {
                dialog.replaceScreen("trades", {
                    LCMD,
                    trades,
                    title: filterOptions?.trades?.title,
                    commandBarItems: filterOptions?.trades?.commandBarItems,
                    initiallySelected: initialTrades,
                    onSelected: (selected?) => {
                        filterOptions.trades.selected(selected);
                    },
                    multiselect: filterOptions?.trades?.multiselect || false,
                });
            } else if ("function" === typeof filterOptions?.taktSelection?.selected) {
                dialog.replaceScreen("tz", {
                    LCMD,
                    tz: wbs,
                    initiallySelected: filterOptions?.taktSelection.tz,
                    onSelected: (selected?) => {
                        filterOptions?.taktSelection.selected(selected);
                    },
                });
            }
        });
    }, [
        LCMD,
        editFilterEntityId,
        setTZ,
        setTrades,
        setTZFilter,
        setTradesFilter,
        setDateFilter,
        setInfo,
        filterOptions?.trades,
        filterOptions?.taktSelection,
    ]);

    const tzFilterItems = Object.getOwnPropertyNames(tzFilter)
        .map((tid) => {
            const tz = tzFilter[tid];
            return {
                key: tid,
                name: tz.n || "",
            };
        })
        .sort((item1, item2) => item1.name.localeCompare(item2.name));
    const tradesFilterItems = Object.getOwnPropertyNames(tradesFilter)
        .map((trid) => {
            const trade = tradesFilter[trid];

            return {
                key: trid,
                name: trade.name || trade.label || "",
                color: trade.color,
            };
        })
        .sort((item1, item2) => item1.name.localeCompare(item2.name));

    React.useLayoutEffect(() => {
        dialog.setOptions({
            title: intl.get("filter.main.title"),
            onOK: () => {
                const nextFilter = {
                    ...toLCMDFilter(tzFilter, tradesFilter, dateFilter),
                    saveAs: editFilterEntity && {
                        hid: editFilterEntity._hid,
                        pos: editFilterEntity._pos,
                        name: editFilterEntity.name,
                    },
                };
                LCMD.setFilter(nextFilter, filterOptions);
                onClose();
            },
            onClose: onClose,
            onCancel: onClose,
            commandBarItems: [
                {
                    key: "clear",
                    commandBarButtonAs: () => (
                        <Stack verticalAlign={"center"}>
                            <DeleteFilterButton onClick={onClearFilter}></DeleteFilterButton>
                        </Stack>
                    ),
                },
            ],
            overflowItems: [
                {
                    key: "save",
                    icon: intl.get("filter.main.save.icon"),
                    name: intl.get("filter.main.save.name"),
                    title: intl.get("filter.main.save.title"),
                    disabled: 0 === tzFilterItems.length && 0 === tradesFilterItems.length && !dateFilter,
                    onClick: () => {
                        // EDIT:
                        if (editFilterEntity) {
                            const nextFilter = {
                                ...toLCMDFilter(tzFilter, tradesFilter, dateFilter),
                                saveAs: editFilterEntity && {
                                    hid: editFilterEntity._hid,
                                    pos: editFilterEntity._pos,
                                    name: editFilterEntity.name,
                                },
                            };

                            LCMD.setFilter(nextFilter, filterOptions);
                            onClose();

                            return;
                        }

                        dialog.pushScreen("save", {
                            LCMD,
                            onSave: (name: string, cb: (error: FrameworkError) => void) => {
                                LCMD.setFilter(
                                    {
                                        ...toLCMDFilter(tzFilter, tradesFilter, dateFilter),
                                        saveAs: { name },
                                    },
                                    filterOptions,
                                    (error?) => {
                                        if (error) {
                                            cb(error);
                                        } else {
                                            onClose();
                                        }
                                    },
                                );
                            },
                            onCancel: () => dialog.popScreen(),
                        });
                    },
                },
            ],
        });
    }, [LCMD, dialog, filterOptions, editFilterEntity, tzFilter, tradesFilter, dateFilter, onClose]);

    const onChangeTimeframeClick = React.useCallback(() => {
        dialog.pushScreen("date", {
            LCMD,
            info,
            initialDateFilter: dateFilter,
            onSelected: (selected?) => {
                if (Array.isArray(selected)) {
                    setDateFilter(2 === selected.length ? selected.slice() : null);
                }
                dialog.popScreen();
            },
            filterOptions,
        });
    }, [dialog, LCMD, dateFilter, setDateFilter, filterOptions, info]);

    const onClearTimeframe = React.useCallback(() => {
        setDateFilter([]);
    }, [setDateFilter]);

    return (
        <Stack tokens={initialScreenStackTokens}>
            <FilterComponent
                prefix={"filter.main.tz"}
                items={tzFilterItems}
                onClick={() => {
                    dialog.pushScreen("tz", {
                        LCMD,
                        tz,
                        initiallySelected: tzFilter,
                        onSelected: (selected?) => {
                            if (selected) {
                                setTZFilter(selected);
                            }
                            dialog.popScreen();
                        },
                    });
                }}
                onRemoveItem={(item) => {
                    const _helper = Object.getOwnPropertyNames(tzFilter).reduce((ret, tid) => {
                        if (item.key !== tid) {
                            ret[tid] = tzFilter[tid];
                        }
                        return ret;
                    }, {});
                    setTZFilter(_helper);
                }}
                userflowId="pp-filter-bereiche"
            />
            <Separator />
            <TradesFilterComponent
                items={tradesFilterItems}
                onClick={() => {
                    dialog.pushScreen("trades", {
                        LCMD,
                        trades,
                        initiallySelected: tradesFilter,
                        onSelected: (selected?) => {
                            if (selected) {
                                setTradesFilter(selected);
                            }
                            dialog.popScreen();
                        },
                        multiselect: true,
                    });
                }}
                onRemoveItem={(item) => {
                    const _helper = Object.getOwnPropertyNames(tradesFilter).reduce((ret, trid) => {
                        if (item.key !== trid) {
                            ret[trid] = tradesFilter[trid];
                        }
                        return ret;
                    }, {});
                    setTradesFilter(_helper);
                }}
                userflowId="pp-filter-gewerke"
            />
            <Separator />

            <DatesFilterComponent
                dates={dateFilter}
                onChangeClick={onChangeTimeframeClick}
                onClearClick={onClearTimeframe}
                userflowId="pp-filter-zeitfenster"
            />
        </Stack>
    );
}
