import * as React from "react";
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { DigitalPlanningBoardTradeDetails, DigitalPlanningBoardTradesEvent, intl } from "lcmd2framework";
import { copyAndSort } from "../../Utils";
import { useLCMD } from "../../../app/LCMContext";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { TradeListItem } from "@/components/common/trade/TradeListItem";
import { SearchBar } from "@/components/common/SearchBar/SearchBar";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { ArrowUpDown } from "lucide-react";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { useVirtual } from "react-virtual";
import { usePresentingMode } from "@/app/store/canvasStore";

export function TradesView() {
    const LCMD = useLCMD();

    const propsContext = useContext(SidebarContext);

    const [allTrades, setAllTrades] = useState<DigitalPlanningBoardTradeDetails[]>([]);
    const [visibleTrades, setVisibleTrades] = useState<DigitalPlanningBoardTradeDetails[]>([]);
    const [isSortedDescending, setSortedDescending] = useState<boolean>(false);
    const [newTrade, setNewTrade] = useState<DigitalPlanningBoardTradeDetails>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const parentRef = useRef(null);
    const presentingMode = usePresentingMode();

    const rowVirtualizer = useVirtual({
        size: visibleTrades?.length,
        parentRef,
        overscan: 15,
        estimateSize: useCallback(() => 60, [presentingMode]),
    });

    useEffect(() => {
        const filteredTrades = filterTradesBySearchText(allTrades, searchValue);
        setVisibleTrades(copyAndSort(filteredTrades, "label", isSortedDescending));
    }, [isSortedDescending, searchValue, allTrades]);

    useLayoutEffect(() => {
        if (!newTrade) {
            return;
        }
        const newTradeHTML = document.getElementById("tradesTeaserDiv-" + newTrade.id);
        if (newTradeHTML) {
            newTradeHTML.scrollIntoView(false);
        }
        setNewTrade(null);
    }, [visibleTrades]);

    propsContext.sidebarCtx.useTradesEffect(
        (ev: DigitalPlanningBoardTradesEvent) => {
            setAllTrades((oldTrades) => {
                if (oldTrades && oldTrades.length > 0) {
                    const difference = ev[propsContext.view.projectId].filter((searchedObj) => {
                        return (
                            oldTrades.filter((comparedObj) => {
                                return searchedObj.id == comparedObj.id;
                            }).length == 0
                        );
                    });
                    if (difference.length > 0) {
                        setNewTrade(difference[0]);
                        setSearchValue("");
                    }
                }
                return ev[propsContext.view.projectId];
            });
        },
        [propsContext.view.projectId],
    );

    function toggleSort() {
        const newSort = !isSortedDescending;
        const isAscending = newSort === true ? "false" : "true";
        WebAppTelemetryFactory.trackEvent("trade-sorting-button", {
            is_ascending: isAscending,
        });

        setSortedDescending(newSort);
    }

    function filterTradesBySearchText(trades: DigitalPlanningBoardTradeDetails[], searchText: string) {
        if (searchText != undefined && searchText.length > 0) {
            return trades.filter((trade) => {
                if (!trade.name) {
                    console.log("Trade name bug: ", { ...trade });
                }
                if (!trade.label) {
                    console.log("Trade label bug: ", { ...trade });
                }
                return trade.label?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;
            });
        } else {
            return [...trades];
        }
    }

    function handleSearchInput(event?: React.ChangeEvent<HTMLInputElement>, newSearchValue?: string): void {
        if (typeof newSearchValue != "string" || newSearchValue == "") {
            setSearchValue("");
            return;
        }
        setSearchValue(newSearchValue);
    }

    function onTradeEdit(trade: DigitalPlanningBoardTradeDetails) {
        propsContext.sidebarCtx.setTradeData(trade);
    }

    function onTradeDelete(trade: DigitalPlanningBoardTradeDetails) {
        LCMD.showDialog("fw.trade.delete", { ...trade, dialog: "dialog.trade.delete" });
    }

    return (
        <div className="flex h-full flex-col overflow-hidden p-3">
            <div className="mt-1 text-lg font-semibold leading-7 text-[#1B2126]">
                {intl.get("Sidebar.TradesView.SelectTrade")}
            </div>
            <div className="my-4 flex items-center">
                <SearchBar
                    onChange={handleSearchInput}
                    onSearch={() => {}}
                    placeholder={intl.get("Sidebar.TradesView.SearchPlaceholder")}
                    value={searchValue}
                    className="mr-4"
                />
                <IconButton icon={ArrowUpDown} onClick={toggleSort} className="mr-6" />
            </div>
            <div ref={parentRef} style={{ height: "calc(100% - 42px)" }} className="overflow-y-auto overflow-x-hidden">
                {!rowVirtualizer.virtualItems.length ? (
                    <div className="flex justify-center text-gray-500">No item found</div>
                ) : (
                    <div style={{ height: rowVirtualizer.totalSize + "px" }} className="relative w-full gap-2.5">
                        {rowVirtualizer.virtualItems.map((virtualRow) => {
                            const trade = visibleTrades[virtualRow.index];
                            return (
                                <div
                                    key={`${virtualRow.index}-${trade.id}-${trade.label}`}
                                    ref={virtualRow.measureRef}
                                    id={"tradesTeaserDiv-" + trade.id}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: `${virtualRow.size}px`,
                                        transform: `translateY(${virtualRow.start}px)`,
                                    }}
                                >
                                    <TradeListItem
                                        tradeId={trade.id}
                                        createDays={5}
                                        title={trade.label}
                                        iconName={trade.icon}
                                        backgroundColor={trade.color}
                                        onTradeEdit={() => {
                                            onTradeEdit(trade);
                                        }}
                                        onTradeDelete={() => {
                                            onTradeDelete(trade);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
