import {
    assert,
    applyPatch,
    EpochDaystoEpochMS,
    EpochMStoEpochDays,
    jsonToError,
    HelperDateToUTCEpochMS,
    FrameworkHttpError,
    LCMDContextCardStatus,
} from "../../model/GlobalHelper";
import { initServices, SERVICES } from "../../model/services";
import { authLogin, authVerify, getLog, getProject, getSub } from "../../model/ApiHelper";
import { RBAC } from "../../model/DataModel";
import { MainWorkerPipe } from "../MainWorkerPipe";
import { OnErrorProps, setOnErrorHandler, showScreenOfDeath } from "../ScreenOfDeath";
import { initWorkerLink } from "../../app/worker/workerLink";
export { LCMDContextCardStatus } from "../../model/GlobalHelper";

let _attachmentsId = 0; // last attachmentsId used;
export function _uploadAttachment(
    worker: MainWorkerPipe,
    card: { tid: number; aid: number; i: number } | {},
    files: FileList | File[],
    userKey?: string | number,
    cb?: number,
) {
    let _files = [];
    if (!Array.isArray(files)) {
        for (let i = 0; i < files.length; i++) {
            _files.push(files[i]);
        }
    } else {
        _files = files;
    }
    if (_files.length > 0) {
        if (undefined !== userKey && worker.dispatchMessage) {
            const status = _files.map((file, i) => ({
                key: 1 + _attachmentsId + i,
                userKey: userKey,
                fileName: _files[i].name,
                contentType: _files[i].type,
            }));
            console.log("DISPATCH UPLOAD STATUS");
            worker.dispatchMessage(["framework", "uploadStatus", status]);
        }
        setTimeout(() => {
            // give the UI time to process uploadStatus
            for (let i = 0; i < _files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const buffer = e.target.result as ArrayBuffer;
                    const attachment = {
                        card: card,
                        key: ++_attachmentsId,
                        userKey: userKey,
                        buffer: buffer,
                        fileName: _files[i].name,
                        contentType: _files[i].type,
                        cb: cb,
                    };
                    worker.postMessage(["attachment", "upload", attachment], [buffer]);
                };
                reader.readAsArrayBuffer(_files[i]);
            }
        }, 2 * 1000);
    }
}

export function _deleteAttachment(
    worker: MainWorkerPipe,
    card: { tid: number; aid: number; i: number } | {},
    blobIds: string[],
) {
    worker.postMessage([
        "attachment",
        "delete",
        {
            card,
            blobIds,
        },
    ]);
}

export namespace LCM {
    export type DailyBoardAPITaskId = number;
    export type DailyBoardAPICardId = number;
    export type DailyBoardAPITradeId = number;
    export type DailyBoardAPIEpochDays = number;

    /**
     * @deprecated
     */
    export type DailyBoardAPICardStatus = LCMDContextCardStatus;

    // see https://lcmexecute.atlassian.net/browse/LCM2-194
    export type DailyBoardAPIUserRoles = "owner" | "admin" | "padmin" | "pmanager" | "user" | "mobile" | null;

    export type DailyBoardAPICard = {
        //** parent task this card belongs to */
        p: DailyBoardAPITaskId;
        //** if id>0 an unique id, id<0 uniue together with parent task id */
        id: DailyBoardAPICardId;
        //** name */
        n: string;
        //** description */
        m?: string;
        /** date in Epoch Days */
        d: DailyBoardAPIEpochDays;
        /** y posistion of the card */
        y: number;
        /** associated trade, array if more than one */
        t?: DailyBoardAPITradeId | (DailyBoardAPITradeId[] | null);
        /** status */
        s: DailyBoardAPICardStatus;
        /** process name */
        p_name?: string;
        /** process start (Epoch MS), if p_start is set and p_end is not set, then the process is planned in sub-day units (h, m) */
        p_start?: number;
        /** process end (Epoch MS) */
        p_end?: number;
        /** attachements  */
        atm?: {
            url: string;
        }[];
    };

    export type DailyBoardAPTrade = {
        /** color in RGB */
        color: number;
        /** unique id */
        id: DailyBoardAPITradeId;
        /** trade name */
        name: string;
    };

    export type DailyBoardAPICardUpdate = {
        /** update name */
        n?: string;
        /** update coMment / description */
        m?: string;
        /** update date, in Epoch Days */
        d?: DailyBoardAPIEpochDays | Date | "+1d" | "-1d" | "clone+1d";
        /** update y coordinate */
        y?: number;
        /** status */
        s?: DailyBoardAPICardStatus;
        /** associated trade, array if more than one */
        t?: DailyBoardAPITradeId;
    };

    export type DailyBoardAPICardInsertion = {
        //** parent task this card belongs to */
        p: DailyBoardAPITaskId;
        /** intial date, in Epoch Days */
        d: DailyBoardAPIEpochDays | Date;
    };

    export type DailyBoardAPICardCreation = {
        /** taskzone the task should be inserted to */
        tz: DailyBoardAPITaktZone;
        /** if the taktzone has several stripes, the stripe */
        s: number;
        /** intial date, in Epoch Days */
        d: DailyBoardAPIEpochDays | Date;
        /** y coordinate */
        y?: number;
        /** position relative to stripe */
        i?: number;
        /** name */
        n?: string;
        /** trade  */
        t?: DailyBoardAPITradeId;
    };

    export type DailyBoardAPICardInsertionBatch = {
        card: DailyBoardAPICardInsertion;
        props: DailyBoardAPICardUpdate;
    }[];

    export type DailyBoardAPICardRemoveBatch = {
        card: DailyBoardAPICard;
    }[];

    export type DailyBoardAPICardCreationBatch = {
        pos: DailyBoardAPICardCreation;
        props: DailyBoardAPICardUpdate;
    }[];

    export type DailyBoardAPITaktZoneStripe = {
        t: DailyBoardAPITaskId;
        i: number;
        j: number;
    };

    export type DailyBoardAPITaktZone = {
        /** name */
        name: string;
        /** image url */
        image?: string;
        /** max y of all tasks */
        y_max: number;
        /** cards of this takt zone */
        cards: DailyBoardAPICard[];
        /** stripes */
        s: DailyBoardAPITaktZoneStripe | DailyBoardAPITaktZoneStripe[];
        /** y_end */
        y_end: number;
        /** cells */
        cells: { vSpan: number; hSpan: number; l: number; name: string }[];
    };

    export type DailyBoardAPIOnInitCallbackData = {
        synced: boolean;
        syncTS: number;
        trades: DailyBoardAPTrade[];
        taktZones: DailyBoardAPITaktZone[];
        start: number;
        end: number;
        filter: boolean;
        role: DailyBoardAPIUserRoles;
        rbac: RBAC;
        subTrades?: number[];
        projectId: string;
        sub: string;
        ppStart: number;
        init: boolean;
    };

    export type DailyBoardAPIOnUpdateCallbackData = {
        synced: boolean;
        syncTS: number;
        taktZones: DailyBoardAPITaktZone[];
        start: number;
        end: number;
        filter: boolean;
        ppStart: number;
        init: boolean;
    };

    export type DailyBoardAPIOnCoreEventCallbackData = {
        serviceAPI: boolean;
        data: any;
        isOpen: boolean;
    };

    export type DailyBoardAPICallbacks = {
        config?: {
            _canary?: boolean | "dev";
        };
        onInit: (initialData: DailyBoardAPIOnInitCallbackData | null, error?: Error) => void;
        onUpdate: (data: DailyBoardAPIOnUpdateCallbackData) => void;
        onCoreEventAPI: (data: DailyBoardAPIOnUpdateCallbackData) => void;
        onReconnect?: (doReconnect: () => void) => void;
        onCrash?: (props: OnErrorProps) => JSX.Element;
        onError?: (e: Error) => boolean;
        onUpload?: (error: Error, status: { userCtx: string }[] | null) => void;
    };

    export type DailyBoadLoginResponse = {
        auth_token: string;
        details?: {
            projects?: string[];
        };
    };

    export type DailyBoardProjectDetails = {
        details?: {
            name?: string;
            message?: string;
        };
        pid: string;
        sid: string;
        role: DailyBoardAPIUserRoles;
    };

    export class DailyBoardAPI {
        public worker: Worker = null;
        private callbacks: DailyBoardAPICallbacks = null;
        private taktZones: any = [];
        private trades: any = [];
        private role: DailyBoardAPIUserRoles;
        private rbac: RBAC;
        private subTrades: number[] | null = null;
        auth = {
            auth_token: null,
            params: {
                sub: null,
            },
        };
        nav = {
            session: {
                pid: null,
            },
        };

        private _patchTaktzones(this: DailyBoardAPI, patch: any) {
            /*
           const _fixDate=(c:{d:number, y?:number})=>{
                assert(undefined===c.y);
                const _d=EpochMStoEpochDays(c.d);
                c.y=c.d-EpochDaystoEpochMS(_d);
                c.d=_d;
           }
           */
            this.taktZones = applyPatch(this.taktZones, patch, (ins: any) => {
                if (ins.i_patch > 0) {
                    const i_patch = ins.i_patch - 1;
                    delete ins.i_patch;
                    ins.cards = applyPatch(this.taktZones[i_patch].cards, ins.cards);
                } else {
                    ins.cards = applyPatch([], ins.cards);
                }
                //ins.y_max=ins.cards.reduce((ret, card)=>Math.max(ret, card.y+1), 0);
            });

            //postprocess
            const MIN_STRIPE_HEIGHT = 1;
            const tz = this.taktZones;
            const tzn = tz.length;
            for (let i = 0, yStart = 0; i < tzn; i++) {
                tz[i].y_max = Math.max(tz[i].y_max, MIN_STRIPE_HEIGHT);
                const yEnd = yStart + tz[i].y_max;
                if (tz[i].y_end !== yEnd) {
                    tz[i] = { ...tz[i], y_end: yEnd };
                }
                yStart = yEnd;
            }
            // postprocess y_end; for Dario
            const l = [];
            for (let i = 0; i < tzn; i++) {
                tz[i].cells.forEach((c, j, cells) => {
                    const end = tz[i + c.vSpan - 1];
                    if (c.name === null) {
                        if (cells[j - 1] && cells[j - 1].hSpan > 1) {
                            for (let k = 0; k < cells[j - 1].hSpan; k++) {
                                l[j + k - 1] = end.y_end;
                            }
                        }
                        return;
                    }
                    c.l = l[j] ? end.y_end - l[j] : end.y_end;
                    l[j] = end.y_end;
                });
            }
        }

        private __showReconnectDialogDiv: HTMLDivElement | null | false = null;
        private __doReconnect = function (this: DailyBoardAPI) {
            if (this.__showReconnectDialogDiv) {
                this.__showReconnectDialogDiv.parentElement.removeChild(this.__showReconnectDialogDiv);
            }
            this.__showReconnectDialogDiv = null;
            this.worker.postMessage(["reconnect", {}]);
        }.bind(this);
        private _showReconnectDialog(noReconnect) {
            if (this.callbacks.onReconnect) {
                if (false !== this.__showReconnectDialogDiv) {
                    this.callbacks.onReconnect(this.__doReconnect);
                }
            } else if (!this.__showReconnectDialogDiv) {
                this.__showReconnectDialogDiv = document.createElement("div");
                this.__showReconnectDialogDiv.style.position = "fixed";
                this.__showReconnectDialogDiv.style.left = "50%";
                this.__showReconnectDialogDiv.style.top = "50%";
                this.__showReconnectDialogDiv.style.width = "300px";
                this.__showReconnectDialogDiv.style.height = "60px";
                this.__showReconnectDialogDiv.style.lineHeight = "60px";
                this.__showReconnectDialogDiv.style.marginLeft = "-150px";
                this.__showReconnectDialogDiv.style.marginTop = "-30px";
                this.__showReconnectDialogDiv.style.backgroundColor = "red";
                this.__showReconnectDialogDiv.style.padding = "5px";
                this.__showReconnectDialogDiv.style.textAlign = "center";
                this.__showReconnectDialogDiv.style.color = "white";
                this.__showReconnectDialogDiv.style.borderRadius = "20px";
                this.__showReconnectDialogDiv.style.fontFamily = "sans-serif";
                this.__showReconnectDialogDiv.style.fontSize = "18px";
                this.__showReconnectDialogDiv.style.userSelect = "none";
                this.__showReconnectDialogDiv.style.cursor = "pointer";
                if (!noReconnect) {
                    this.__showReconnectDialogDiv.innerHTML = "Connection Error! <u>Reconnect now...</u>";
                    this.__showReconnectDialogDiv.onclick = this.__doReconnect;
                } else {
                    this.__showReconnectDialogDiv.innerHTML = "Connection Error!";
                }
                document.documentElement.appendChild(this.__showReconnectDialogDiv);
            }
        }

        private __showErrorDialogDiv: HTMLDivElement | null | false = null;
        private _showErrorDialog(msg: string) {
            if (!this.__showErrorDialogDiv) {
                this.__showErrorDialogDiv = document.createElement("div");
                this.__showErrorDialogDiv.style.position = "fixed";
                this.__showErrorDialogDiv.style.left = "50%";
                this.__showErrorDialogDiv.style.top = "50%";
                this.__showErrorDialogDiv.style.width = "300px";
                this.__showErrorDialogDiv.style.height = "60px";
                this.__showErrorDialogDiv.style.lineHeight = "60px";
                this.__showErrorDialogDiv.style.marginLeft = "-150px";
                this.__showErrorDialogDiv.style.marginTop = "-30px";
                this.__showErrorDialogDiv.style.backgroundColor = "red";
                this.__showErrorDialogDiv.style.padding = "5px";
                this.__showErrorDialogDiv.style.textAlign = "center";
                this.__showErrorDialogDiv.style.color = "white";
                this.__showErrorDialogDiv.style.borderRadius = "20px";
                this.__showErrorDialogDiv.style.fontFamily = "sans-serif";
                this.__showErrorDialogDiv.style.fontSize = "18px";
                this.__showErrorDialogDiv.style.userSelect = "none";
                this.__showErrorDialogDiv.style.cursor = "pointer";
                switch (msg) {
                    case "lcmd.error.db.noactive":
                        msg = "No Weekly Lookahead is active.";
                        break;
                }
                this.__showErrorDialogDiv.innerHTML = msg;
            }
            document.documentElement.appendChild(this.__showErrorDialogDiv);
        }

        private __showNotificationDialogDiv: HTMLDivElement | null | false = null;
        private _showNotificationDialog(msg: string, show: boolean) {
            if (msg || show) {
                if (!this.__showNotificationDialogDiv) {
                    this.__showNotificationDialogDiv = document.createElement("div");
                    this.__showNotificationDialogDiv.style.position = "fixed";
                    this.__showNotificationDialogDiv.style.left = "50%";
                    this.__showNotificationDialogDiv.style.top = "50%";
                    this.__showNotificationDialogDiv.style.width = "300px";
                    this.__showNotificationDialogDiv.style.height = "60px";
                    this.__showNotificationDialogDiv.style.lineHeight = "60px";
                    this.__showNotificationDialogDiv.style.marginLeft = "-150px";
                    this.__showNotificationDialogDiv.style.marginTop = "-30px";
                    this.__showNotificationDialogDiv.style.backgroundColor = "darkgray";
                    this.__showNotificationDialogDiv.style.padding = "5px";
                    this.__showNotificationDialogDiv.style.textAlign = "center";
                    this.__showNotificationDialogDiv.style.color = "white";
                    this.__showNotificationDialogDiv.style.borderRadius = "20px";
                    this.__showNotificationDialogDiv.style.fontFamily = "sans-serif";
                    this.__showNotificationDialogDiv.style.fontSize = "18px";
                    this.__showNotificationDialogDiv.style.userSelect = "none";
                    this.__showNotificationDialogDiv.style.cursor = "pointer";
                    document.documentElement.appendChild(this.__showNotificationDialogDiv);
                }
                if (msg) {
                    this.__showNotificationDialogDiv.innerHTML = "<u>" + msg + "</u>";
                    this.__showNotificationDialogDiv.onclick = function () {
                        this.__showNotificationDialogDiv.remove();
                        this.__showNotificationDialogDiv = null;
                    }.bind(this);
                } else {
                    this.__showNotificationDialogDiv.innerHTML = "Uploading...";
                }
            } else {
                if (this.__showNotificationDialogDiv) {
                    this.__showNotificationDialogDiv.remove();
                    this.__showNotificationDialogDiv = null;
                }
            }
        }

        private __readonlyValidationError = false;

        private handelProcessesDatesNegativeEffect = function (eventName: string, data: any) {
            switch (eventName) {
                case "processes::durationChanged::negativeEffect":
                case "processes::durationChanged::positiveEffect":
                case "processes::startDateChanged::negativeEffect":
                    this.callbacks.onCoreEventAPI({
                        coreEventName: eventName,
                        eventData: data,
                    });
                    break;
            }
        };

        private onMessage = function (this: DailyBoardAPI, event: MessageEvent) {
            if (Array.isArray(event.data) && event.data.length >= 2) {
                const msg = event.data[1];
                switch (event.data[0]) {
                    case "cb":
                        {
                            const cb = this.cbs[msg];
                            if (cb) {
                                delete this.cbs[msg];
                                cb(event.data[2]);
                            }
                        }
                        break;
                    case "init":
                        {
                            if (msg?.error) {
                                const e = jsonToError(msg?.error);
                                if (this.callbacks.onError && this.callbacks.onError(e)) {
                                    // error was handled by callback...
                                } else {
                                    // show error
                                    this._showErrorDialog(e.message);
                                }
                                this.callbacks.onInit(null, e);
                            } else {
                                this._patchTaktzones(msg.taktZones);
                                this.trades = msg.trades;
                                this.role = msg.role;
                                this.rbac = msg.rbac;
                                this.subTrades = Array.isArray(msg.subTrades) ? msg.subTrades : null;
                                this.auth.auth_token = msg.auth_token;
                                this.auth.params.sub = msg.sub;
                                this.nav.session.pid = msg.projectId;
                                this.callbacks.onInit({
                                    init: true,
                                    synced: msg.sync.syncCommited.ofs === msg.sync.commited,
                                    syncTS: msg.sync.syncCommited.ofs,
                                    trades: this.trades,
                                    taktZones: this.taktZones,
                                    start: msg.start,
                                    end: msg.end,
                                    filter: msg.filter,
                                    role: this.role,
                                    rbac: msg.rbac,
                                    subTrades: this.subTrades,
                                    projectId: msg.projectId,
                                    sub: msg.sub,
                                    ppStart: msg.ppStart,
                                });
                            }
                        }
                        break;
                    case "patch":
                        {
                            this._patchTaktzones(msg.taktZones);
                            this.callbacks.onUpdate({
                                init: false,
                                synced: msg.sync.syncCommited.ofs === msg.sync.commited,
                                syncTS: msg.sync.syncCommited.ofs,
                                taktZones: this.taktZones,
                                filter: msg.filter,
                                start: msg.start,
                                end: msg.end,
                                ppStart: msg.ppStart,
                            });
                        }
                        break;
                    case "connection":
                        {
                            const c = event.data[1];
                            this._showReconnectDialog(c?.noReconnect);
                        }
                        break;
                    case "error":
                        {
                            const e = event.data[1];
                            showScreenOfDeath(e, { message: "(empty)" });
                        }
                        break;
                    case "framework":
                        {
                            switch (event.data[1]) {
                                case "uploadStatus":
                                    {
                                        console.log("uploadStatus");
                                        console.log(event.data[2]);
                                        const helper = event.data[2].filter((s) => "api" === s.userKey);
                                        console.log(helper);
                                        if (this.callbacks?.onUpload) {
                                            this.callbacks.onUpload(null, helper.length > 0 ? helper : null);
                                        } else {
                                            this._showNotificationDialog(null, helper.length > 0);
                                        }
                                        //setStatus(helper.length>0?helper:null);
                                    }
                                    break;
                                case "uploadFailed":
                                    {
                                        console.log("uploadFailed");
                                        if ("api" === event.data[2].userKey) {
                                            assert(!Array.isArray(event.data[2]));
                                            const e = jsonToError(event.data[2].error);
                                            if (this.callbacks?.onUpload) {
                                                this.callbacks.onUpload(e, null);
                                            } else {
                                                this._showNotificationDialog(e.message, true);
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                        break;
                    case "readonly":
                        {
                            // readonly violation
                            if (!this.__readonlyValidationError) {
                                this.__readonlyValidationError = true;
                                this._showNotificationDialog("Project is Read-Only", true);
                            }
                        }
                        break;
                    case "core::event":
                        this.handelProcessesDatesNegativeEffect(event.data[2], event.data[1]);
                        break;
                }
                {
                    // report to handlers...
                    let n = this.handlers.length;
                    for (let i = 0; i < n; ) {
                        if ("unregister" === this.handlers[i](event.data as any)) {
                            this.handlers.splice(i, 1);
                            n--;
                        } else {
                            i++;
                        }
                    }
                }
            }
        }.bind(this);

        enableSync(enabled?: boolean) {
            this.worker.postMessage([
                "sync",
                {
                    sync: false !== enabled,
                },
            ]);
        }

        private generateCardProps(
            card: {
                p: DailyBoardAPITaskId;
                id: number | null;
                d: number;
                y?: number;
                n?: string;
                t?: number | number[];
            },
            props: DailyBoardAPICardUpdate,
        ) {
            let props_d = undefined;
            const card_y = "number" === typeof props.y ? props.y : card.y || 0;
            if (props?.d && "number" === typeof props.d) {
                props_d = EpochDaystoEpochMS(props.d, card_y);
            } else if (props?.d && props.d instanceof Date) {
                props_d = EpochDaystoEpochMS(EpochMStoEpochDays(HelperDateToUTCEpochMS(props.d)), card_y);
            } else if ("string" === typeof props.d) {
                props_d = props.d;
            } else if ("number" === typeof props.y) {
                props_d = EpochDaystoEpochMS(card.d as number, card_y);
            } else if (null === card.id || card.id < 0) {
                props_d = EpochDaystoEpochMS(card.d as number, card_y);
            }
            const _props = Object.assign({}, props, {
                p: card.p,
                id: card.id,
                d: props_d,
                n: card.id < 0 ? props.n || card.n : props.n,
                t_: card.t,
            });
            return _props;
        }

        private dateToEpochDays(d: Date) {
            /* dealing with timezones: we convert every date to GMT by dropping the timezone and then convert it to days */
            const _d = d.toString();
            const __d = _d.toString().substring(0, 25) + "GMT";
            const ___d = EpochMStoEpochDays(Date.parse(__d));
            return ___d;
        }

        spliceCards(
            pos: {
                p: DailyBoardAPITaskId;
                d: DailyBoardAPIEpochDays | Date;
                y?: number;
            },
            operation: {
                remove?: DailyBoardAPICardRemoveBatch;
                insert?: DailyBoardAPICardInsertionBatch;
                move?: number;
                moveTo?: DailyBoardAPIEpochDays | Date;
            },
        ) {
            if (this.worker) {
                const dummy = this.generateCardProps(
                    {
                        p: pos.p,
                        d: pos.d instanceof Date ? this.dateToEpochDays(pos.d) : pos.d,
                        y: pos.y || 0,
                        id: null,
                    },
                    {},
                );
                const moveTo =
                    operation?.moveTo instanceof Date
                        ? this.dateToEpochDays(operation.moveTo)
                        : "number" === typeof operation?.moveTo
                        ? operation?.moveTo
                        : undefined;
                this.worker.postMessage([
                    "spliceCards",
                    {
                        p: dummy.p,
                        d: dummy.d,
                    },
                    Object.assign(operation, {
                        moveTo: moveTo,
                    }),
                ]);
            }
        }

        updateCard(card: DailyBoardAPICard, props: DailyBoardAPICardUpdate) {
            if (this.worker) {
                this.updateCards([
                    {
                        card: card,
                        props: props,
                    },
                ]);
            }
        }

        updateCards(
            batch: {
                card: DailyBoardAPICard;
                props: DailyBoardAPICardUpdate;
            }[],
        ) {
            this.worker.postMessage([
                "updateCards",
                batch.map((b) => {
                    return this.generateCardProps(b.card, b.props);
                }),
            ]);
        }

        moveCard(card: DailyBoardAPICard, props: DailyBoardAPICardUpdate) {
            if (this.worker) {
                this.worker.postMessage(["moveCard", this.generateCardProps(card, props)]);
            }
        }

        removeCard(card: DailyBoardAPICard) {
            if (this.worker) {
                this.removeCards([
                    {
                        card: card,
                    },
                ]);
            }
        }

        removeCards(batch: DailyBoardAPICardRemoveBatch) {
            if (this.worker) {
                this.worker.postMessage(["removeCards", batch]);
            }
        }

        insertCard(card: DailyBoardAPICardInsertion, props: DailyBoardAPICardUpdate) {
            this.insertCards([
                {
                    card: card,
                    props: props,
                },
            ]);
        }

        insertCards(batch: DailyBoardAPICardInsertionBatch) {
            if (this.worker) {
                this.worker.postMessage([
                    "insertCards",
                    batch.map((item) => {
                        return this.generateCardProps(
                            {
                                p: item.card.p,
                                id: null,
                                d:
                                    item.card.d instanceof Date
                                        ? EpochMStoEpochDays(item.card.d.getTime())
                                        : item.card.d,
                            },
                            item.props || {},
                        );
                    }),
                ]);
            }
        }

        createCard(pos: DailyBoardAPICardCreation, props: DailyBoardAPICardUpdate) {
            this.createCards([
                {
                    pos: pos,
                    props: props,
                },
            ]);
        }

        createCards(batch: DailyBoardAPICardCreationBatch) {
            if (this.worker) {
                this.worker.postMessage([
                    "createCards",
                    batch.map((item) => {
                        return {
                            pos: {
                                s: Array.isArray(item.pos.tz.s)
                                    ? item.pos.tz.s[Math.max(0, item.pos.s || 0)]
                                    : item.pos.tz.s,
                                n: item.pos.n,
                                t: item.pos.t,
                            },
                            card: this.generateCardProps(
                                {
                                    p: 0,
                                    d:
                                        item.pos.d instanceof Date
                                            ? EpochMStoEpochDays(item.pos.d.getTime())
                                            : item.pos.d,
                                    y: item.pos.y || 0,
                                    id: null,
                                },
                                item.props || {},
                            ),
                        };
                    }),
                ]);
            }
        }

        getTrade(tradeId: DailyBoardAPITradeId): DailyBoardAPTrade | null {
            return this.trades[tradeId] || null;
        }

        undo() {
            this.worker.postMessage(["undo", {}]);
        }

        redo() {
            this.worker.postMessage(["redo", {}]);
        }

        sync(cb?: () => void) {
            this.worker.postMessage([
                "sync",
                {
                    cb: cb ? this.registerCallback(cb) : undefined,
                },
            ]);
        }

        uploadAttachments(card: { id: string }, files: FileList | File[], userKey?: string | number) {
            const id = (card?.id || "").split("_");
            if (4 === id.length && "C" === id[0]) {
                const tid = Number.parseInt(id[1], 16);
                const aid = Number.parseInt(id[2], 16);
                const i = Number.parseInt(id[3], 16);
                _uploadAttachment(this.worker as any, { tid, aid, i }, files, userKey);
            }
        }

        deleteAttachments(card: { id: string }, blobIds: string[]) {
            const id = (card?.id || "").split("_");
            if (4 === id.length && "C" === id[0]) {
                const tid = Number.parseInt(id[1], 16);
                const aid = Number.parseInt(id[2], 16);
                const i = Number.parseInt(id[3], 16);
                _deleteAttachment(this.worker as any, { tid, aid, i }, blobIds);
            }
        }

        private static _initServices(ctx: { location?: string }, config: any) {
            const location = {
                FORCE_CANARY: config._canary,
                FORCE_PROD: false, // probably never used, check if can be removed
            } as any;
            console.log("_initService");
            console.log({ ctx, config });
            if (ctx.location && ("?" === ctx.location[0] || "#" === ctx.location[0])) {
                ctx.location
                    .substr(1)
                    .split("&")
                    .reduce((ret, e) => {
                        const _e = e.split("=").map((n) => decodeURIComponent(n));
                        if (2 === _e.length) {
                            ret[_e[0]] = _e[1];
                        } else if (1 === _e.length) {
                            ret[_e[0]] = true;
                        } else {
                            // unhandled...
                        }
                        return ret;
                    }, location);
            }
            console.log({ location });
            initServices(location);
            return location;
        }

        public shutdown() {
            if (this) {
                this.worker = null;
                this.callbacks = null;
            }
        }

        //static initialize

        static init(
            ctx: {
                /** Pass the window.location.search
                 *  Can be used to initialize the API via a URL.
                 *  Basic format: http://127.0.0.1:3000/index.html?sid=7881a943-568e-57f1-aed7-9b07d2bae81a&auth_token=[JWT]
                 */
                sid?: string;
                auth_token?: string;
                location?: string;
                filter?: {
                    start?: number;
                    end?: number;
                };
                filter2?: any;
                sync?: boolean;
            },
            callbacks: DailyBoardAPICallbacks,
        ) {
            setOnErrorHandler(callbacks?.onCrash || null);
            const location = DailyBoardAPI._initServices(ctx, callbacks.config);
            //let baseUrl=location.baseUrl||ctx.baseUrl||"/";
            //while (baseUrl.endsWith("/")) baseUrl=baseUrl.substring(0, baseUrl.length-1);
            //baseUrl=baseUrl+"/";
            /*

            let serviceUrl=location.serviceUrl||ctx.serviceUrl||"/";
            while (serviceUrl.endsWith("/")) serviceUrl=serviceUrl.substring(0, serviceUrl.length-1);
*/
            if (location?.api_ver) {
                console.debug(
                    JSON.stringify({
                        api_ver: location.api_ver,
                        API_VERSION: SERVICES.API_VERSION,
                    }),
                );
                const api_ver = Number.parseInt(location.api_ver, 10);
                if (api_ver !== SERVICES.API_VERSION) {
                    const div = document.createElement("div");
                    div.style.position = "fixed";
                    div.style.left = "50%";
                    div.style.top = "50%";
                    div.style.width = "300px";
                    div.style.height = "60px";
                    div.style.lineHeight = "60px";
                    div.style.marginLeft = "-150px";
                    div.style.marginTop = "-30px";
                    div.style.backgroundColor = "red";
                    div.style.padding = "5px";
                    div.style.textAlign = "center";
                    div.style.color = "white";
                    div.style.borderRadius = "20px";
                    div.style.fontFamily = "sans-serif";
                    div.style.fontSize = "18px";
                    div.style.userSelect = "none";
                    div.style.cursor = "pointer";
                    div.innerHTML = "API Version " + api_ver + " needed (got " + SERVICES.API_VERSION + ")";
                    document.documentElement.appendChild(div);
                    return null;
                }
            }
            if (!ctx.filter2 && location?.filter2) {
                try {
                    const filter2 = JSON.parse(location.filter2);
                    ctx.filter2 = filter2;
                } catch (e) {}
            }

            const ret = new DailyBoardAPI();
            ret.callbacks = callbacks;
            ret.worker = new Worker(new URL("../../dailyboard-app/worker/worker.ts", import.meta.url), {
                type: "module",
            });
            initWorkerLink(ret.worker);

            ret.worker.addEventListener("message", ret.onMessage);
            ret.worker.postMessage([
                "init",
                Object.assign(ctx, {
                    SERVICES: SERVICES,
                    sid: location.sid || ctx.sid,
                    filter: location.filter || ctx.filter,
                    filter2: ctx.filter2,
                    role_hack: location.role || null,
                    auth_token: location.auth_token || ctx.auth_token,
                    sync: false !== ctx.sync,
                }),
            ]);
            return ret;
        }

        public static fetchProjectDetails(
            auth_token: string,
            pid: string,
            cb: (error: Error, result: DailyBoardProjectDetails) => void,
        ) {
            getProject(auth_token, pid, (project_error, project_result) => {
                if (project_error) {
                    cb(project_error, null);
                } else {
                    const log = project_result?.project?.log || [];
                    if (log.length > 0) {
                        getLog(project_result.project_token, pid, log[log.length - 1], (log_error, log_result) => {
                            if (log_error) {
                                cb(log_error, null);
                            } else {
                                let master = null;
                                try {
                                    master = JSON.parse(atob((log_result?.master || "").split(".")[1]));
                                } catch (e) {
                                    master = null;
                                }
                                const ret = {
                                    details: {
                                        name: project_result.project?.name || null,
                                    },
                                    pid: log_result.pid,
                                    sid: master?.sid || null,
                                    role: project_result.role,
                                };
                                cb(null, ret);
                            }
                        });
                    } else {
                        cb(new FrameworkHttpError(500), null);
                    }
                }
            });
        }

        public static login(
            ctx: { location?: string; auth_token?: string },
            email: string,
            password: string | null,
            cb: (
                error: Error,
                verifyPin: (
                    pin: string,
                    new_password: string | null,
                    cb: (error: Error, resp: DailyBoadLoginResponse) => void,
                ) => void,
                resp: DailyBoadLoginResponse,
            ) => void,
            config: {
                _canary?: boolean | "dev";
            },
        ) {
            const location = DailyBoardAPI._initServices(ctx, config);
            if (ctx?.auth_token) {
                let _auth_token = null;
                try {
                    _auth_token = JSON.parse(atob(ctx.auth_token.split(".")[1]));
                } catch (e) {
                    _auth_token = null;
                }
                getSub(ctx.auth_token, _auth_token?.sub, (error, result) => {
                    if (error) {
                        cb(error, null, null);
                    } else {
                        cb(null, null, {
                            auth_token: ctx.auth_token,
                            details: result.result,
                        });
                    }
                });
            } else {
                authLogin(
                    email,
                    password,
                    (error, result) => {
                        if (error) {
                            cb(error, null, null);
                        } else {
                            if (result.auth_token) {
                                cb(null, null, result);
                            } else {
                                cb(
                                    null,
                                    (pin: string, new_password: string | null, cb: (error: Error, resp) => void) => {
                                        authVerify(pin, result.pin_secret, new_password, (pin_error, pin_result) => {
                                            if (pin_error) {
                                                cb(pin_error, null);
                                            } else {
                                                cb(null, pin_result);
                                            }
                                        });
                                    },
                                    null,
                                );
                            }
                        }
                    },
                    null,
                    { req: "00000000-0000-0000-0000-000000000000" },
                    false,
                    undefined,
                    undefined,
                );
            }
        }

        private handlers: any[] = [];
        public registerHandler(handler: any) {
            this.handlers.push(handler);
        }
        public unregisterHandler(handler: any) {
            const ret = this.handlers.indexOf(handler);
            if (ret >= 0) {
                this.handlers.splice(ret, 1);
            }
        }
        private cbsId = 0;
        private cbs: { [x: number]: (data) => void } = {};
        public registerCallback(cb: (data: any) => void): number {
            this.cbsId++;
            this.cbs[this.cbsId] = cb;
            return this.cbsId;
        }

        public postMessage(msg: any, transfer?: Transferable[]) {
            if (this?.worker) {
                this.worker.postMessage(msg, transfer);
            }
        }

        isAllowed(feature: string, param: string) {
            let ret = this.role ? true : false;
            const canSetDone = this.role === "admin" || this.rbac?.rules?.a_decl?.s?.values[2] === true;
            switch (feature) {
                case "lcmd.mobile.view":
                    if ("all" === param) {
                        ret = ret && !Array.isArray(this.subTrades);
                    } else {
                        ret = false; // handle me
                    }
                    break;
                case "lcmd.op.task.status":
                    if ("done" === param) {
                        ret = (ret && !Array.isArray(this.subTrades)) || canSetDone;
                    } else {
                        // ok
                    }
            }
            return ret;
        }
    }
}

export default LCM;
