import * as React from "react";
import { DialogType, IconButton, IContextualMenuProps, TooltipHost } from "@fluentui/react";
import { intl, FilesOverlayProps, FilesOverlayProps_Project, WorkerSession } from "lcmd2framework";
import { LCMDContextType, useLCMD } from "../app/LCMContext";
import { useCallback, useState } from "react";
import { LastOpenedProjectsService } from "../app/services/LastOpenedProjects.service";
import { ScrollArea } from "./ui/scroll-area";
import { SearchBar } from "./common/SearchBar/SearchBar";
import { Folder, PlusCircle, UploadCloud } from "lucide-react";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";
import { Separator } from "./ui/separator";
import { Skeleton } from "./ui/skeleton";
import { LCMDTooltip } from "./common/Tooltip/LCMDTooltip";
import { Icons } from "./RegisteredIcons";
import DigitalPlanningBoard from "@/legacy/components/DigitalPlanningBoard";
import { MainWorkerPipe } from "@/legacy/MainWorkerPipe";
import { getLog, getProject } from "@/model/ApiHelper";

type LCMDFilesOverlayProps = FilesOverlayProps & {
    onSearch: (searchString: string) => void;
    value: string;
};

export function openProject(worker: MainWorkerPipe, session: WorkerSession, dailyBoard: boolean) {
    worker.dispatchMessage(["framework", "files", false]);
    DigitalPlanningBoard.openProject(worker, session, dailyBoard);
}

function FilesOverlayHeader(this: null, props: LCMDFilesOverlayProps) {
    const LCMD = useLCMD();

    const onUploadDone = useCallback(
        (details) => {
            LCMD.showDialog("dialog.project.upload", null);
            props.cbs.onUploadDone(details);

            details &&
                details.project_token &&
                getLog(details.project_token, details.pid, 0, (error, log) => {
                    const session: WorkerSession = {
                        pid: details.pid,
                        pid_ts: 0,
                        project_token: details.project_token,
                        resource: log.file,
                        master_token: undefined,
                        sandbox: Date.now() as any,
                    };
                    openProject(LCMD.worker, session, false);
                });
        },
        [LCMD, props.cbs],
    );

    return (
        <div className="border-box flex max-w-full items-center gap-2 p-4">
            <SearchBar
                onChange={(event, newValue) => {
                    props.onSearch(newValue);
                }}
                onSearch={(newValue) => {
                    props.onSearch(newValue);
                }}
                placeholder={intl.get("projects.title")}
                value={props.value}
                className="w-full"
            />
            <div className="flex">
                <LCMDTooltip key="uploadScheduleTooltip" asChild text={intl.get("projects.uploadSchedule")}>
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-10 w-10"
                        data-userpilot-id="filesOverlay-uploadProject"
                    >
                        <UploadCloud
                            size={16}
                            onClick={() => {
                                LCMD.showDialog("dialog.project.upload", {
                                    view: "upload",
                                    onDone: onUploadDone,
                                    auth_token: LCMD.worker.auth?.auth_token || null,
                                    auth_warmup: LCMD.worker.warmup_result?.req
                                        ? (LCMD.worker.warmup_result as { req: string })
                                        : null,
                                });
                            }}
                        />
                    </Button>
                </LCMDTooltip>
                <LCMDTooltip key="addProjectTooltip" asChild text={intl.get("projects.newProject")}>
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-10 w-10"
                        data-userpilot-id="filesOverlay-addProject"
                    >
                        <PlusCircle
                            size={16}
                            onClick={() => {
                                LCMD.showDialog("dialog.project.upload", {
                                    view: "details",
                                    onDone: onUploadDone,
                                    auth_token: LCMD.worker.auth?.auth_token || null,
                                    auth_warmup: LCMD.worker.warmup_result?.req
                                        ? (LCMD.worker.warmup_result as { req: string })
                                        : null,
                                });
                            }}
                        />
                    </Button>
                </LCMDTooltip>
            </div>
        </div>
    );
}

function onFilesOverlayCell(this: FilesOverlayProps, LCMD: LCMDContextType, item: FilesOverlayProps_Project) {
    const iconStyle = { color: "#565C60" };

    const unlinkProject = () => {
        LCMD.showDialog("fw.alert", {
            dialogContentProps: {
                type: DialogType.normal,
                title: intl.get("overview.cmd.unlink.alert.title"),
                subText: intl.get("overview.cmd.unlink.alert.subText"),
            },
            onOK: () => {
                LCMD.unlinkProject(item.pid, this.worker.auth?.auth_result.sub, (error, result) => {
                    if (result && this.worker.nav.project === item.pid) {
                        location.reload();
                    } else {
                        this.cbs.onUploadDone({});
                    }
                });
                LCMD.showDialog("fw.alert", null);
            },
            onCancel: () => {
                LCMD.showDialog("fw.alert", null);
            },
        });
    };

    const menuMoreProps: IContextualMenuProps = {
        items: [
            {
                key: "more.delete",
                text: intl.get("overview.cmd.unlink.alert.title"),
                iconProps: { iconName: Icons.Lcmd_Delete, style: iconStyle },
                onClick: unlinkProject,
            },
        ],
    };
    return (
        <TooltipHost content={item?.project?.project?.name || intl.get("projects.unnamedProject")} key={item.pid}>
            <div
                key={item.pid}
                data-is-focusable={true}
                data-userpilot-id={this.projects.slice(-1)[0].pid === item.pid ? "filesOverlay-newAddedProject" : null}
                className={cn(
                    "flex cursor-pointer items-center rounded-lg p-2",
                    item.pid === this.worker.nav.project
                        ? "bg-zinc-800 text-white"
                        : "text-zinc-400 transition ease-in-out hover:bg-gray-100 hover:text-zinc-800",
                )}
            >
                {"number" === typeof item?.pid_ts ? (
                    <>
                        <div onClick={this.cbs.makeCellClickCB(item)} className="flex flex-1 gap-2">
                            <Folder size={20} />
                            <p
                                style={{ wordBreak: "break-word" }}
                                className={cn(
                                    "flex-1",
                                    item.pid === this.worker.nav.project ? "text-white" : "text-zinc-800",
                                )}
                            >
                                {(item as FilesOverlayProps_Project).project?.project?.name ||
                                    intl.get("projects.unnamedProject")}
                            </p>
                        </div>
                        <div className="flex-2 self-start">
                            <IconButton
                                styles={{ menuIcon: { display: "none" } }}
                                iconProps={{ iconName: Icons.Lcmd_More, style: iconStyle }}
                                menuProps={menuMoreProps}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Skeleton className="h-5 w-5" />
                        <Skeleton className="h-4 w-[250px]" />
                    </>
                )}
            </div>
        </TooltipHost>
    );
}

export function FilesOverlay(this: null, props: FilesOverlayProps) {
    const LCMD = useLCMD();
    const [searchString, setSearchString] = useState("");
    const onRenderCell = useCallback(onFilesOverlayCell.bind(props, LCMD), [props]);

    const sortedProjects = [...props.projects].sort((a, b) =>
        a.project?.project?.name?.toLowerCase().localeCompare(b.project?.project?.name?.toLowerCase()),
    );
    const lastOpenedProjects = sortedProjects.length > 3 ? get3LastOpenedProjects(sortedProjects) : [];

    const filterProjects = (projects) =>
        projects.filter((project) => {
            return (
                searchString == "" ||
                (project as FilesOverlayProps_Project).project?.project?.name
                    ?.toLowerCase()
                    .includes(searchString.toLowerCase())
            );
        });

    const filteredLastOpenedProjects = filterProjects(lastOpenedProjects);
    const filteredSortedProjects = filterProjects(sortedProjects);

    function get3LastOpenedProjects(sortedProjects) {
        const lastOpenedProjectIds = LastOpenedProjectsService.getLastOpenedProjectIds();
        const lastOpenedProjects = [];

        for (const projectId of lastOpenedProjectIds) {
            const project = sortedProjects.find((x) => x.pid === projectId);
            if (project) {
                lastOpenedProjects.push(project);
                sortedProjects.splice(sortedProjects.indexOf(project), 1);
            }

            if (lastOpenedProjects.length >= 3) {
                break;
            }
        }

        return lastOpenedProjects;
    }

    function handleSearch(searchString: string) {
        setSearchString(searchString);
    }

    return (
        <div className="overflow-hidden" data-userflow-id="projektliste">
            <FilesOverlayHeader onSearch={handleSearch} value={searchString} {...props} />
            <ScrollArea
                className="absolute inset-0 h-[calc(100vh-160px)] p-4"
                data-userpilot-id="filesOverlay-projectPanel"
            >
                {lastOpenedProjects.length > 0 && (
                    <div className="pb-4">
                        <div className="mb-4 text-left text-sm font-semibold leading-5 tracking-normal text-zinc-950">
                            {intl.get("projects.recentlySeen")}
                        </div>
                        <div className="flex flex-col gap-1 pb-3">
                            {filteredLastOpenedProjects?.map((project) => onRenderCell(project))}
                        </div>
                        <Separator orientation="horizontal" className="my-4" />
                    </div>
                )}
                <div className="mb-4 text-left text-sm font-semibold leading-5 tracking-normal text-zinc-950">
                    {intl.get("projects.all")}
                </div>
                <div className="flex flex-col gap-1">
                    {filteredSortedProjects?.map((project) => onRenderCell(project))}
                </div>
            </ScrollArea>
        </div>
    );
}
