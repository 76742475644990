import * as React from "react";
import { mergeStyleSets } from "@fluentui/react";
import {
    CanvasHostBase,
    CanvasHostBasePointerEvent,
    CanvasHostBaseProps,
    CanvasHostHitResult,
    CanvasHostRenderer,
    CanvasHostRendererCtx,
    CanvasHostRendererDragInfo,
    CanvasHostRendererDropInfo,
    CanvasHostRenderNode,
    CanvasHostStripeRenderNode,
    CanvasStripeData,
    CanvasTaskData,
    ICanvasHostRenderer,
    intl,
    needsWhiteColor,
} from "lcmd2framework";
import { MainWorkerMessageHandler } from "../legacy/MainWorkerPipe";
import { LCMDContextDependencyType } from "../app/LCMDContextTypes";
import { useCanvasTooltipStore } from "../app/store/tooltipStore";
import { CopyToaster } from "@/app/components/UtilsContainer";
import { useCanvasStore } from "@/app/store/canvasStore";
import { useConflictStore } from "@/app/store/conflictsStore";
import { tradeImagesMap } from "@/components/Sidebar/TradeImages";
import { generateCanvasCalendarIntl } from "@/legacy/GlobalHelperFluentUI";
import { weekStart } from "@/utils/DateUtils";
import posthog from "posthog-js";

const MIN_WIDTH = 10;
const MIN_TASK_WIDTH = 4;
const MIN_HEIGHT = 10;

const MIN_LINE_SCALE = 0.25;
const GRID_WIDTH = 2;
const GRID_COLOR = "#f1f3f3";

const DETAILS_FONT = "400 6pt Roboto";
const CANVAS_BUBBLE_FONT = "400 10pt Roboto";
const CANVAS_CONFLICT_FONT = "600 11px Roboto";

const classNames = mergeStyleSets({
    overlayHack: {
        //transition: "left 0 ease",
        position: "absolute",
        left: 0,
        top: 0,
        width: 1,
        height: 0,
        backgroundColor: "red",
    },
    overlayHackBubble: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "auto",
        height: "auto",
        font: "400 120x Roboto",
        textAlign: "left",
        backgroundColor: "red",
        color: "white",
        whiteSpace: "pre",
    },
});

type CanvasProjectHostProps = CanvasHostBaseProps & {
    onTaktzoneClicked: (taktzone: number[]) => void;
};

export class CanvasProjectHost extends CanvasHostBase<CanvasProjectHostProps> implements ICanvasHostRenderer {
    private hoverTimeoutIndex = null;
    private hoveredPid = null;
    private intl = null;

    private weekDaysShort;

    constructor(props: CanvasProjectHostProps) {
        super(props, {
            zOrderStripes: false,
            xGroupTasks: false,
            eventHitTest: false,
        });
        this._renderer.setRenderer(this);
        this.intl = generateCanvasCalendarIntl();
        const shortdays = this.intl.shortDays2;

        this.weekDaysShort = [
            shortdays?.sun,
            shortdays?.mon,
            shortdays?.tue,
            shortdays?.wed,
            shortdays?.thu,
            shortdays?.fri,
            shortdays?.sat,
        ];
        // this._overlayHack=this.renderOverlayHack.bind(this);
    }

    public renderOverlay(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {
        if (rc.header) {
            const scale = rc.view.scale;
            const y = rc.A.titleHeight + rc.A.subtitleHeight;
            ctx.fillStyle = rc.C.gpa.canvasColor;
            ctx.fillRect(0, y, rc.view.x2 - rc.view.x1, rc.C.colHeaderHeight);
            const width = rc.grid.view.cols * rc.C.colPx;
            const h = rc.view.y2 - rc.view.y1;
            const header = rc.header;
            const a = header.weeks;
            const n_a = Array.isArray(a) ? a.length : 0;
            const b = header.month;
            const n_b = Array.isArray(b) ? b.length : 0;

            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            ctx.save();

            ctx.scale(scale, 1);
            ctx.translate(-rc.view.x1, 0);
            const S_MIN_WIDTH = MIN_WIDTH / scale;
            const S_MIN_HEIGHT = MIN_HEIGHT / scale;

            const NV_X1 = rc.view.x1;
            const NV_X2 = NV_X1 + (rc.view.x2 - rc.view.x1) / scale;
            for (let i_b = 0; i_b < n_b && b[i_b].left <= width; i_b++) {
                const x1 = b[i_b].left;
                const x2 = Math.min(i_b + 1 < n_b ? b[i_b + 1].left : width, width);
                const l = x1;
                const t = y;
                const w = x2 - x1;
                const h = rc.C.colHeaderHeight1;
                const nv = l + w < NV_X1 || l > NV_X2;
                if (w > S_MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                    const p = 2;
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        [0, 0, b[i_b].text],
                        l + p,
                        t + p,
                        w - p - p,
                        h - p - p,
                        1,
                        false,
                        false,
                        0,
                        0,
                        false,
                    );
                    ctx.strokeRect(l, t, w, h);
                }
            }

            ctx.fillStyle = "#FFFFFF";
            for (let i_a = 0; i_a < n_a && a[i_a].left <= width; i_a++) {
                const x1 = a[i_a].left;
                const x2 = Math.min(i_a + 1 < n_a ? a[i_a + 1].left : width, width);
                const l = x1;
                const t = y + rc.C.colHeaderHeight1;
                const w = x2 - x1;
                const h = rc.C.colHeaderHeight2;
                const nv = l + w < NV_X1 || l > NV_X2;
                if (false !== a[i_a].wd && w > S_MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                    ctx.fillRect(l, t, w, h);
                    ctx.strokeRect(l, t, w, h);
                }
            }
            ctx.strokeRect(0, y, width, rc.C.colHeaderHeight);
            ctx.restore();
            ctx.fillStyle = "black";
            const view_w = rc.view.x2 - rc.view.x1;
            for (let i_b = 0; i_b < n_b && b[i_b].left <= width; i_b++) {
                const x1 = b[i_b].left;
                const x2 = Math.min(i_b + 1 < n_b ? b[i_b + 1].left : width, width);
                const l = (x1 - rc.view.x1) * scale;
                const t = y;
                const w = (x2 - x1) * scale;
                const h = rc.C.colHeaderHeight1;
                const m = b[i_b].m;
                const nv = l + w < 0 || l > view_w;
                if (w > MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                    const p = 2;
                    if (m) {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            [0, 0, [b[i_b].text, b[i_b].m, b[i_b].y].join(" ")],
                            l + p,
                            t + p,
                            w - p - p,
                            h - p - p,
                            1,
                            false,
                            true,
                            0,
                            0,
                            false,
                        );
                    } else {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            [0, 0, b[i_b].text],
                            l + p,
                            t + p,
                            w - p - p,
                            h - p - p,
                            1,
                            false,
                            true,
                            0,
                            0,
                            false,
                        );
                    }
                }
            }
            for (let i_a = 0; i_a < n_a && a[i_a].left <= width; i_a++) {
                const d = this.weekDaysShort[a[i_a].d];
                const x1 = a[i_a].left;
                const x2 = Math.min(i_a + 1 < n_a ? a[i_a + 1].left : width, width);
                const l = (x1 - rc.view.x1) * scale;
                const t = y + rc.C.colHeaderHeight1;
                const w = (x2 - x1) * scale;
                const h = rc.C.colHeaderHeight2 / (d ? 2 : 1);
                const nv = l + w < 0 || l > view_w;
                if (w > MIN_WIDTH && h > MIN_HEIGHT && !nv) {
                    const p = 2;
                    if (d) {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            [0, 0, d],
                            l + p,
                            t + p,
                            w - p - p,
                            h - p - p,
                            1,
                            false,
                            true,
                            0,
                            0,
                            false,
                        );
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            [0, 0, a[i_a].text],
                            l + p,
                            t + h + p,
                            w - p - p,
                            h - p - p,
                            1,
                            false,
                            true,
                            0,
                            0,
                            false,
                        );
                    } else {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            [0, 0, a[i_a].text],
                            l + p,
                            t + p,
                            w - p - p,
                            h - p - p,
                            1,
                            false,
                            true,
                            0,
                            0,
                            false,
                        );
                    }
                }
            }
        }

        if (rc.sidebar) {
            // sidebar
            const scale = rc.view.scale;
            const info = rc.sidebarL;
            const n_info = info.length;
            let l_max = 0;
            const S_MIN_HEIGHT = MIN_HEIGHT / scale;
            while (l_max < n_info && (!info[l_max] || info[l_max].m >= S_MIN_HEIGHT)) l_max++;
            const max_l = l_max * rc.A.sidebarColWidth;
            const view_h = rc.view.y2 - rc.view.y1;
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            const sidebar = rc.sidebar;
            const n_sidebar = sidebar.length;
            const width = (rc.l_max - rc.l_min) * rc.A.sidebarColWidth + rc.A.sidebarColExtra + rc.C.sidebarColImage;
            const height = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
            ctx.fillStyle = rc.C.gpa.canvasColor;
            ctx.fillRect(0, 0, width, rc.view.y2 - rc.view.y1);
            for (let i_sidebar = 0; i_sidebar < n_sidebar; i_sidebar++) {
                const item = sidebar[i_sidebar];
                const l = item.left;
                if (l <= max_l) {
                    const t = (item.top - rc.view.y1) * scale;
                    const leaf = l >= max_l;
                    const w = leaf ? width - l : item.width;
                    const h = item.height * scale;
                    const nv = t + h < 0 || t > view_h;
                    if (!nv) {
                        ctx.fillStyle = item.color;
                        ctx.fillRect(l, t, w, h);
                        ctx.fillStyle = rc.C.gpa.task.label.black;
                        const p = 2;
                        const renderSidebarColImage = l + w === width;
                        const sidebarColImage = renderSidebarColImage ? rc.C.sidebarColImage : 0;
                        const { totalW, totalH } = CanvasHostRenderer.renderSegments(
                            ctx,
                            item.segs,
                            l + p,
                            t + p,
                            w - sidebarColImage - p - p,
                            h - p - p,
                            1,
                            !leaf && item.rotated,
                            true,
                            0,
                            0,
                            false,
                            rc.view,
                        );
                        if (renderSidebarColImage) {
                            const image_s = Math.min(sidebarColImage, sidebarColImage * rc.view.scale);
                            if (item.stripe._gpa) {
                                ctx.save();
                                const t_x1 = l + p;
                                const t_y1 = t + p;
                                const t_w = w - sidebarColImage - p - p;
                                const t_h = h - p - p;
                                const i_x1 = l + w - image_s - p;
                                const i_y1 = t + p;
                                const i_w = image_s - p - p;
                                const i_h = image_s - p - p;

                                const r_x1 = t_x1 + totalW + p;
                                const r_y1 = t;
                                const r_x2 = i_x1;
                                const r_h = h;

                                const b_x1 = t_x1;
                                const b_y1 = t_y1 + totalH;
                                const b_x2 = i_x1;
                                const b_y2 = t + h - p;

                                const name = item.stripe._gpa.name || "";
                                ctx.font = CANVAS_BUBBLE_FONT;
                                const m = ctx.measureText(name);
                                const _p = 4;
                                let y1;
                                let x2;
                                if (m.width + _p + _p <= r_x2 - r_x1) {
                                    x2 = r_x2;
                                    y1 = r_y1;
                                } else {
                                    x2 = b_x2;
                                    y1 = b_y1;
                                }
                                const x1 = x2 - (m.width + _p + _p);
                                const y2 = y1 + m.actualBoundingBoxAscent + m.actualBoundingBoxDescent + _p + _p;

                                ctx.fillStyle = "#EEDFF7";
                                ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
                                ctx.fillStyle = "#520980";
                                ctx.fillText(name, x1 + _p, y2 - m.actualBoundingBoxDescent - _p);
                                ctx.restore();
                            }
                            if (sidebarColImage) {
                                ctx.save();
                                ctx.strokeStyle = item.stripe._image ? "red" : "gray";
                                const image = item.stripe._image
                                    ? this.getImage(item.stripe._image, sidebarColImage, sidebarColImage)
                                    : null;
                                if (image) {
                                    const __w = image.width as number;
                                    const __h = image.height as number;
                                    const s = Math.min(image_s / __w, image_s / __h);
                                    const _w = Math.floor(image.width as number) * s;
                                    const _h = Math.floor(image.height as number) * s;
                                    ctx.drawImage(image, l + w - _w - p, t + p, _w, _h);
                                } else {
                                    if ("number" === typeof item.stripe._gpa?.color) {
                                        ctx.fillStyle = "#" + item.stripe._gpa.color.toString(16).padStart(6, "0");
                                        ctx.fillRect(l + w - image_s - p, t + p, image_s - p - p, image_s - p - p);
                                    }
                                    ctx.strokeRect(l + w - image_s - p, t + p, image_s - p - p, image_s - p - p);
                                }
                                ctx.restore();
                            }
                        }
                        ctx.strokeRect(l, t, w, h);
                    }
                }
            }
            ctx.strokeRect(0, (0 - rc.view.y1) * scale, width, height * scale);

            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, rc.A.titleHeight + rc.A.subtitleHeight, width, rc.C.colHeaderHeight);
            ctx.strokeRect(0, rc.A.titleHeight + rc.A.subtitleHeight, width, rc.C.colHeaderHeight);
        }

        this._restoreSessionSplitView();
    }

    public renderHell(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHeaven(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, isVisible: boolean) {
        const h = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
        if (!isVisible) {
            return;
        }
        this.renderStripeGrid(rc, ctx, h);
    }

    private renderStripeGrid(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, h: number) {
        const w = rc.grid.view.cols * rc.C.colPx;
        const S_MIN_HEIGHT = MIN_HEIGHT / rc.view.scale;
        if (h >= S_MIN_HEIGHT) {
            const header = rc.header;
            const a = header.weeks;
            const n_a = Array.isArray(a) ? a.length : 0;
            ctx.fillStyle = "#f5f5f5";
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            let isNonWorkDay = false;
            for (let i_a = 0; i_a < n_a && a[i_a].left <= w; i_a++) {
                ctx.beginPath();
                if (false === a[i_a].wd && a[i_a].left + a[i_a].width < w) {
                    ctx.fillRect(a[i_a].left, 0, a[i_a].width, h);

                    if (isNonWorkDay) {
                        ctx.strokeStyle = "white";
                        ctx.moveTo(a[i_a].left, 0);
                        ctx.lineTo(a[i_a].left, h);
                        ctx.stroke();
                        ctx.strokeStyle = GRID_COLOR;
                    } else {
                        ctx.moveTo(a[i_a].left, 0);
                        ctx.lineTo(a[i_a].left, h);
                        ctx.stroke();
                        isNonWorkDay = true;
                    }
                    continue;
                }

                // @todo: not correct for countries where the week starts at Sunday
                if (a[i_a].d === weekStart) {
                    ctx.lineWidth = GRID_WIDTH * 3;
                    ctx.moveTo(a[i_a].left, 0);
                    ctx.lineTo(a[i_a].left, h);
                    ctx.stroke();
                    ctx.lineWidth = GRID_WIDTH;
                } else {
                    ctx.moveTo(a[i_a].left, 0);
                    ctx.lineTo(a[i_a].left, h);
                    ctx.stroke();
                }

                isNonWorkDay = false;
            }
        }

        for (let i = 0; i < Math.floor(h / rc.C.rowPx); i++) {
            ctx.moveTo(rc.header.weeks[0].left, i * rc.C.rowPx);
            ctx.lineTo(w, i * rc.C.rowPx);
        }
        ctx.stroke();
    }

    private renderTodayLine(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, h) {
        if (undefined !== rc.today) {
            const x = rc.today;
            ctx.strokeStyle = "black";
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(x * rc.C.colPx + 1, 0);
            ctx.lineTo(x * rc.C.colPx + 1, h);
            ctx.stroke();
        }
    }

    public renderEnd(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {
        const h = (rc.stripes.length > 0 ? rc.stripes[rc.stripes.length - 1].e : 0) * rc.C.rowPx;
        this.renderTodayLine(rc, ctx, h);
    }

    public renderStripeHell(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {
        if (0 !== i_stripe % 2) {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        }
    }

    public renderStripeBegin(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {
        const S_MIN_HEIGHT = MIN_HEIGHT;
        if (stripe.__h >= S_MIN_HEIGHT) {
            ctx.strokeStyle = GRID_COLOR;
            ctx.lineWidth = GRID_WIDTH;
            ctx.beginPath();
            if (0 === stripe.y) {
                ctx.moveTo(stripe.__x, stripe.__y);
                ctx.lineTo(stripe.__w, stripe.__y);
            }
            ctx.moveTo(stripe.__x, stripe.__y + stripe.__h);
            ctx.lineTo(stripe.__w, stripe.__y + stripe.__h);
            ctx.stroke();
        }
    }

    public renderStripeEnd(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {}

    zoom(
        event: { deltaY: number; clientX: number; clientY: number; currentTarget: HTMLCanvasElement },
        pinchZoom?: boolean,
    ) {
        const { width, height } = this.state.reactState;
        const delta = event.deltaY < 0 ? 1 : -1;
        const step = pinchZoom ? 0.05 : Math.abs(event.deltaY) > 50 ? 0.2 : 0.05;
        const s = this.state.reactState.scale;
        const __s = s + delta * (s - s * step) * step;
        const _s = Math.max(
            Math.min(__s, 10),
            (height > width ? (window.innerHeight - 146) / height : (window.innerWidth - 471) / width) / 3,
        );

        if (_s > 0) {
            this.setState(
                {
                    ...this.state,
                    reactState: {
                        ...this.state.reactState,
                        scale: _s,
                        left: this.state.reactState.left - (event.clientX * (_s - s)) / s / _s,
                        top: this.state.reactState.top - (event.clientY * (_s - s)) / s / _s,
                    },
                },
                this._invalidateCanvasWithEvent.bind(this, event),
            );
        }
    }

    private shrinkText(ctx: CanvasRenderingContext2D, text: string, width: number) {
        let low = 0;
        let high = text.length;

        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            const textWidth = ctx.measureText(text.slice(0, mid).trim()).width;

            if (Math.round(textWidth) === width) {
                return mid;
            } else if (textWidth > width) {
                high = mid - 1;
            } else {
                low = mid + 1;
            }
        }

        return high;
    }

    private _renderDepOffset(ctx: CanvasRenderingContext2D, conflictNumber: string, x, y): void {
        ctx.font = CANVAS_CONFLICT_FONT;

        const p = 7;
        const r = 15;
        const borderWidth = 2;
        const finalX = x + p + r + borderWidth;
        const finalY = y + p + r + borderWidth;
        const finalConflictNumber = conflictNumber.length > 2 ? "99+" : conflictNumber;
        const textWidth = ctx.measureText(finalConflictNumber).width;

        ctx.save();
        ctx.fillStyle = "#DC2626";
        ctx.lineWidth = borderWidth;
        ctx.strokeStyle = "white";

        ctx.beginPath();
        ctx.arc(finalX, finalY, r, 0, Math.PI * 2);
        ctx.fill();
        ctx.beginPath();
        ctx.arc(finalX, finalY, r + 1, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fillStyle = "white";
        ctx.fillText(finalConflictNumber, finalX - textWidth / 2, finalY + 4);
        ctx.restore();
    }

    private _renderTaskStatusBar(
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        x: number,
        y: number,
        w: number,
        h: number,
        isDarkBg: boolean,
    ): void {
        const { p } = task;
        const lineWidth = 4;
        const paddingX = 12;
        const paddingY = 4;
        const lineY = y + h - paddingY - lineWidth / 2;
        const startX = x + paddingX;
        const lineLength = w - paddingX - paddingX;

        ctx.lineCap = "round";
        ctx.lineWidth = lineWidth;

        ctx.strokeStyle = isDarkBg ? "rgba(255, 255, 255, 0.16)" : "rgba(9, 9, 11, 0.16)";
        ctx.beginPath();
        ctx.moveTo(startX, lineY);
        ctx.lineTo(startX + lineLength, lineY);
        ctx.stroke();

        if (p > 0) {
            ctx.strokeStyle = isDarkBg ? "rgba(255, 255, 255, 0.65)" : "rgba(9, 9, 11, 0.65)";
            ctx.beginPath();
            ctx.moveTo(startX, lineY);
            ctx.lineTo(startX + lineLength * (p / 100), lineY);
            ctx.stroke();
        }
    }

    public renderTask(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
        drawOutline: boolean,
    ) {
        const isSelected = this._renderer.isTaskSelected(task);
        // if 1 then the process is not selected and should stay the same in width. Otherwise will be a factor for width scaling
        const scaleWidthFactor = isSelected ? this._renderer.getBBox()?.sx : 1;
        const transformXValue = isSelected ? this._renderer.getBBox()?.tx : 0;
        const S_MIN_WIDTH = MIN_TASK_WIDTH;
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = rc.C.label === "week" ? 4 : 2; // "week = daily view" and "month = weekly view" :(
        let l = task.__x + dx + p;
        const t = task.__y + dy + p;
        let w = task.__w - p - p;
        const h = task.__h - p - p;
        const { showConflictMode, dependencyChain, showDependencies } = useCanvasStore.getState();
        const isRoundedCorner = rc.view.scale >= this._renderer.roundedCornersThreshold;
        const isTextHidden = rc.view.scale < this._renderer.textVisibilityThreshold;
        const handle = this._pointer.drag?.handle;
        const isMilestone = task.right === task.left;

        const nv = l + w < rc.view.x1 || l > rc.view.x2 || t + h < rc.view.y1 || t > rc.view.y2;
        if (nv) {
            // abort render if process not visible
            return;
        }
        if (handle === 6 && !isMilestone) {
            l += transformXValue;
            w -= transformXValue;
        }
        if (handle === 5 && !isMilestone) {
            // l /= scaleWidthFactor;
            w = task.__w * scaleWidthFactor - p - p;
        }
        if (w >= S_MIN_WIDTH) {
            const isGray = true === rc.M.sel && 1 !== task.m && task.v === "conflict";
            const color = !isGray ? (task.v && undefined !== task.c ? task.c : task.color) : 0xc1c5c7;
            const _w = needsWhiteColor(color);
            ctx.fillStyle = "#" + color.toString(16).padStart(6, "0");
            if (isGray) {
                ctx.save();
            }
            if (showConflictMode && dependencyChain.indexOf(task.id) === -1) {
                ctx.globalAlpha = 0.3;
            }

            if (task.isVirtual) {
                ctx.globalAlpha = 0.6;
            }

            if (isMilestone) {
                if (isRoundedCorner) {
                    ctx.beginPath();
                    ctx.moveTo(l + 8, t + h / 2 - 8);
                    ctx.lineTo(l + w / 2 - 8, t + 8);
                    ctx.arcTo(l + w / 2, t, l + w / 2 + 8, t + 8, 8);
                    ctx.lineTo(l + w - 8, t + h / 2 - 8);
                    ctx.arcTo(l + w, t + h / 2, l + w - 8, t + h / 2 + 8, 8);
                    ctx.lineTo(l + w / 2 + 8, t + h - 8);
                    ctx.arcTo(l + w / 2, t + h, l + w / 2 - 8, t + h - 8, 8);
                    ctx.lineTo(l + 8, t + h / 2 + 8);
                    ctx.arcTo(l, t + h / 2, l + 8, t + h / 2 - 8, 8);
                    ctx.closePath();
                    ctx.fill();

                    // draw read frame when process has a conflict
                    if (task.dep >= 0) {
                        ctx.lineWidth = 2;
                        ctx.beginPath();
                        ctx.strokeStyle = "#D13501";
                        ctx.moveTo(l + 4, t + h / 2 - 8);
                        ctx.lineTo(l + w / 2 - 8, t + 4);
                        ctx.arcTo(l + w / 2, t - 4, l + w / 2 + 8, t + 4, 10);
                        ctx.lineTo(l + w - 4, t + h / 2 - 8);
                        ctx.arcTo(l + w + 4, t + h / 2, l + w - 8, t + h / 2 + 12, 10);
                        ctx.lineTo(l + w / 2 + 12, t + h - 8);
                        ctx.arcTo(l + w / 2, t + h + 4, l + w / 2 - 12, t + h - 8, 10);
                        ctx.lineTo(l + 4, t + h / 2 + 8);
                        ctx.arcTo(l - 4, t + h / 2, l + 8, t + h / 2 - 12, 10);
                        ctx.closePath();
                        ctx.stroke();
                    }
                } else {
                    ctx.beginPath();
                    ctx.moveTo(l, t + h / 2);
                    ctx.lineTo(l + w / 2, t);
                    ctx.lineTo(l + w, t + h / 2);
                    ctx.lineTo(l + w / 2, t + h);
                    ctx.closePath();
                    ctx.fill();
                }

                // render selection for task milestone
                if (isSelected) {
                    ctx.beginPath();
                    ctx.strokeStyle = "#000000";
                    ctx.moveTo(l + 8, t + h / 2 - 8);
                    ctx.lineTo(l + w / 2 - 8, t + 8);
                    ctx.arcTo(l + w / 2, t, l + w / 2 + 8, t + 8, 8);
                    ctx.lineTo(l + w - 8, t + h / 2 - 8);
                    ctx.arcTo(l + w, t + h / 2, l + w - 8, t + h / 2 + 8, 8);
                    ctx.lineTo(l + w / 2 + 8, t + h - 8);
                    ctx.arcTo(l + w / 2, t + h, l + w / 2 - 8, t + h - 8, 8);
                    ctx.lineTo(l + 8, t + h / 2 + 8);
                    ctx.arcTo(l, t + h / 2, l + 8, t + h / 2 - 8, 8);
                    ctx.closePath();
                    ctx.stroke();
                }

                if (!isTextHidden) {
                    ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
                    CanvasHostRenderer.renderSegments(
                        ctx,
                        task.segs,
                        l,
                        t,
                        w,
                        h,
                        1,
                        false,
                        false,
                        1,
                        1,
                        isMilestone && (_w ? rc.C.gpa.task.label.black : rc.C.gpa.task.label.white),
                        null,
                        task.name,
                        this.state.fs > 0 ? -this.state.fs : this.state.fs, // make sure this number is always negative, because milestones need always text calculation
                    );
                }
            } else {
                if (isRoundedCorner) {
                    ctx.beginPath();
                    ctx.roundRect(l, t, w, h, 8);
                    ctx.fill();
                } else {
                    ctx.fillRect(l, t, w, h);
                }

                if (task.dep >= 0) {
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "#D13501";

                    ctx.beginPath();
                    ctx.roundRect(l - 2, t - 2, w + 4, h + 4, 10);
                    ctx.stroke();
                }

                // render selection for task
                if (isSelected) {
                    ctx.beginPath();
                    ctx.roundRect(l + 1, t + 1, w - 2, h - 2, 7);
                    ctx.strokeStyle = "#000000";
                    ctx.stroke();
                }
                // console.log("BBOX: ", this._renderer.getBBox()?.canvas);

                if (this.state.const.showStatusBar && !isTextHidden) {
                    this._renderTaskStatusBar(ctx, task, l, t, w, h, _w);
                }

                // render text old UI with text adaption
                ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
                if (this.state.fs < 0 && w >= 48 && !isTextHidden) {
                    if (!handle || !isSelected) {
                        CanvasHostRenderer.renderSegments(
                            ctx,
                            task.segs,
                            l,
                            t,
                            w,
                            h,
                            1,
                            false,
                            false,
                            1,
                            1,
                            isMilestone && (_w ? rc.C.gpa.task.label.black : rc.C.gpa.task.label.white),
                            null,
                            task.name,
                            this.state.fs,
                        );

                        // render status, conflict, stabillity indicator in the views
                        if ((!isMilestone && task.v && task.l) || (showConflictMode && typeof task.dep === "number")) {
                            const p = 2;
                            const text = task.l ?? "+" + Math.abs(task.dep).toString();
                            ctx.save();
                            ctx.font = DETAILS_FONT;
                            const m = ctx.measureText(text);
                            ctx.fillText(text, l + w - m.width - p, t + h - m.actualBoundingBoxDescent - p);
                            ctx.restore();
                        }
                    }
                } else {
                    // check if the process is to short, so no rendering of icon or text
                    if (w >= 48) {
                        if (!isTextHidden) {
                            ctx.save();
                            ctx.font = "600 14px Inter";
                            ctx.fillText(
                                task.name.slice(0, this.shrinkText(ctx, task.name, w - 44)).trim(),
                                l + 44,
                                t + 34,
                            );

                            ctx.fillStyle = _w ? rc.C.gpa.task.trade.light : rc.C.gpa.task.trade.dark;
                            ctx.font = "10px Inter";
                            ctx.fillText(
                                (task?.trade || "").slice(0, this.shrinkText(ctx, task.trade || "", w - 44)).trim(),
                                l + 44,
                                t + 19,
                            );
                            ctx.restore();
                        }

                        if (rc.view.scale >= this._renderer.iconVisibilityThreshold) {
                            this.renderIconAndStatusInfo(ctx, task.tradeIcon, l, t, task.l);
                            if (task.atm && !(showConflictMode && typeof task.dep === "number")) {
                                this.renderAttachment(ctx, l, t);
                            }
                            if (showConflictMode && typeof task.dep === "number") {
                                this._renderDepOffset(ctx, Math.abs(task.dep).toString(), l, t);
                            } else {
                                //disable rendering red dot for the moment
                                // this.renderRedDot(rc, ctx, task);

                                if (task.hasComments) {
                                    this.newNotificationDot(rc, ctx, task);
                                }
                            }
                        }
                    }
                }
            }

            if (showDependencies && this._renderer.hit.hitTask?.id === task.id) {
                ctx.fillStyle = "rgba(0, 0, 0, 0.33)";
                if (this._renderer.hit.hitTaskLeft === true) {
                    // is left
                    ctx.fillRect(l, t, w / 4, h);
                    this.drawPlusSing(ctx, l + w * 0.125, t + h / 2);
                } else if (this._renderer.hit.hitTaskLeft === false) {
                    // is right
                    ctx.fillRect(l + w * 0.75, t, w / 4, h);
                    this.drawPlusSing(ctx, l + w * 0.875, t + h / 2);
                }
            }

            if (isGray) {
                ctx.restore();
            }
            // else if (true === rc.M.sel) {
            // disable dotted lines
            // if (-1 === task.__z) {
            //     // not selected...
            //     const p = 1;
            //     const l = task.__x + dx + p;
            //     const t = task.__y + dy + p;
            //     const w = task.__w - p - p;
            //     const h = task.__h - p - p;
            //     ctx.save();
            //     ctx.strokeStyle = "#000000";
            //     ctx.lineWidth = 1;
            //     ctx.setLineDash([3, 4]);
            //     ctx.strokeRect(l, t, w, h);
            //     ctx.restore();
            // }
            // }
            //set alpha back to normal, so in any case alpha returns back
            ctx.globalAlpha = 1.0;
        }
    }

    private renderIconAndStatusInfo(
        ctx: CanvasRenderingContext2D,
        icon: string,
        l: number,
        t: number,
        label?: string,
    ): void {
        const svgIcon = tradeImagesMap.get(icon || this._renderer.C.tradeDefaultIcon);
        if (!svgIcon && !label) {
            console.error("Could not render iconIndex:", { icon: this._renderer.C.tradeDefaultIcon });
        }

        if (!this.svgImageCache.has(svgIcon.title) && !label) {
            const img = new Image();
            img.src = svgIcon.img;
            this.svgImageCache.set(svgIcon.title, img);
        }

        ctx.beginPath();
        ctx.arc(l + 24, t + 24, 13, 0, Math.PI * 2);
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        ctx.fill();
        // ctx.save();
        // ctx.strokeStyle = "rgba(0, 0, 0, 0.4)";
        // ctx.lineWidth = 2;
        // ctx.beginPath();
        // ctx.arc(l + 24, t + 24, 13, 0, Math.PI * 2);
        // ctx.stroke();
        // ctx.restore();
        if (!label) {
            ctx.drawImage(this.svgImageCache.get(svgIcon?.title), l + 16.51, t + 16, 16, 16);
        } else {
            ctx.save();
            ctx.fillStyle = "#ffffff";
            ctx.font = "8px Inter";
            ctx.textAlign = "center";
            ctx.fillText(label, l + 24, t + 27);
            ctx.restore();
        }
    }

    private renderAttachment(ctx: CanvasRenderingContext2D, l: number, t: number): void {
        const svgIcon = tradeImagesMap.get("attachment");
        if (!svgIcon) {
            console.error("Could not render iconIndex:", { icon: this._renderer.C.tradeDefaultIcon });
        }

        if (!this.svgImageCache.has(svgIcon.title)) {
            const img = new Image();
            img.src = svgIcon.img;
            this.svgImageCache.set(svgIcon.title, img);
        }

        ctx.beginPath();
        ctx.arc(l + 33, t + 33, 6, 0, Math.PI * 2);
        ctx.fillStyle = "black";
        ctx.fill();
        ctx.drawImage(this.svgImageCache.get(svgIcon?.title), l + 29, t + 29, 8, 8);
    }

    private renderRedDot(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;

        ctx.beginPath();
        ctx.arc(l + 35.75, t + 35.75, 3.5, 0, Math.PI * 2);
        ctx.save();
        ctx.fillStyle = "#D13501";
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = "white";
        ctx.fill();
        ctx.stroke();
        ctx.restore();
    }

    private newNotificationDot(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;
        ctx.beginPath();
        ctx.arc(l + 17, t + 17, 3.5, 0, Math.PI * 2);
        ctx.save();
        ctx.fillStyle = "#4F3AB1";
        ctx.lineWidth = 1.5;
        ctx.strokeStyle = "white";
        ctx.fill();
        ctx.stroke();
        ctx.restore();
    }

    private renderDepOffset(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;

        ctx.beginPath();
        ctx.fillStyle = "#ffffff";
        ctx.fillText(Math.abs(task.dep).toString(), l + 35.75, t + 35.75);
    }

    public selChanged() {
        const sel = this._renderer.getSel();
        const tids = sel.reduce((ret, sel) => {
            if (CanvasHostBase.TASK_TARGET === sel.target) {
                ret.push(sel.id);
            }
            return ret;
        }, []);
        const canvasState = useCanvasStore.getState();
        useCanvasTooltipStore.getState().setInvisible();
        canvasState.setSelectedProcessIds(tids);

        this.props.worker.postMessage([
            "meta",
            "sel",
            {
                ts: ++this._metaTS,
                mode: this.props.mode,
                details: this.props.viewConst?.details || null,
                tids,
                cb: this.props.worker.registerCallback((data) => {
                    if (
                        sel[0]?.dep >= 0 ||
                        (typeof sel.at(0)?.dep === "undefined" && data?.numberOfConflictingProcesses)
                    ) {
                        useConflictStore.getState().actions.setConflictCount(data?.numberOfConflictingProcesses);
                    } else {
                        useConflictStore.getState().actions.setConflictCount(0);
                    }

                    if (canvasState.showConflictMode === false) {
                        canvasState.setDependencyChain(data?.chain || []);
                    }
                }),
            },
        ]);
    }

    protected override onDragStart(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult) {
        clearTimeout(this.hoverTimeoutIndex);
        useCanvasTooltipStore.getState().setInvisible();
        const sel = this._renderer.getSel();
        this.props.worker.postMessage([
            "async",
            "drag",
            {
                ts: ++this._asyncTS,
                mode: this.props.mode,
                tids: sel.reduce((ret, sel) => {
                    if (CanvasHostBase.TASK_TARGET === sel.target) {
                        ret.push(sel.id);
                    }
                    return ret;
                }, []),
            },
        ]);
    }

    protected override onDragEnd(rc: CanvasHostRendererCtx, dragInfo: CanvasHostRendererDragInfo | null) {
        const sel = this._renderer.getSel();
        let d_sel;
        if (
            "number" === typeof dragInfo?.task?.id &&
            (d_sel = sel.findIndex((task) => task.id === dragInfo.task.id)) >= 0
        ) {
            const task = dragInfo.task;
            const tx = dragInfo?.tx || 0;
            const e0 = task.stripe > 0 ? rc.stripes[task.stripe - 1].e : 0;
            const e1 = rc.stripes[task.stripe].e;
            const y = Math.max(Math.min(Math.round(task.top / rc.C.rowPx) - e0, e1 - e0), 0);
            const x = Math.round((task.left + tx) / rc.C.colPx);
            const sx = dragInfo?.sx || 1;
            this.props.worker.postMessage([
                "move",
                {
                    taskId: task.id,
                    x: x,
                    sx: sx,
                    y: y,
                    multisel: sel.filter((t) => t.id !== task.id).map((t) => t.id),
                },
            ]);
            const canvasState = useCanvasStore.getState();
            this.props.worker.postMessage([
                "meta",
                "sel",
                {
                    ts: ++this._metaTS,
                    mode: this.props.mode,
                    details: this.props.viewConst?.details || null,
                    tids: [task.id],
                    cb: this.props.worker.registerCallback((data) => {
                        if (!canvasState.showConflictMode) {
                            canvasState.setDependencyChain(data?.chain || []);
                            useConflictStore
                                .getState()
                                .actions.setConflictCount(data?.numberOfConflictingProcesses ?? 0);
                        }
                    }),
                },
            ]);
        }
    }

    protected onDropEnd(
        rc: CanvasHostRendererCtx,
        pointer: CanvasHostBasePointerEvent,
        dropInfo: CanvasHostRendererDropInfo | null,
        draggedRef: HTMLElement,
    ) {
        if (rc.hit.hitStripe && dropInfo.trade) {
            const dropDays = draggedRef?.dataset?.dropDays;
            const days = "string" === typeof dropDays ? Number.parseInt(dropDays, 10) : 0;
            this.props.worker.postMessage([
                "add",
                {
                    trade: dropInfo.trade.id,
                    stripe: -1, //@TODO
                    stripet: rc.hit.hitStripe.t,
                    stripei: rc.hit.hitStripe.i,
                    stripej: rc.hit.hitStripe.j,
                    x1: rc.hit.hitStripeGridX,
                    x2: rc.hit.hitStripeGridX + days,
                    y: rc.hit.hitStripeGridY,
                    text: dropInfo.trade.name,
                },
            ]);
        } else if (rc.hit.hitStripe && dropInfo.lib) {
            this.props.worker.postMessage([
                "wb",
                "pastepp",
                {
                    lib: dropInfo.lib.id,
                    stripet: rc.hit.hitStripe.t,
                    stripei: rc.hit.hitStripe.i,
                    stripej: rc.hit.hitStripe.j,
                    x1: rc.hit.hitStripeGridX,
                    y: rc.hit.hitStripeGridY,
                },
            ]);
        } else if (rc.hit.hitSidebar && dropInfo.lib) {
            this.props.worker.postMessage([
                "wb",
                "setPPGpa",
                {
                    lib: dropInfo.lib.id,
                    stripet: rc.hit.hitSidebar.stripe.t,
                    stripei: rc.hit.hitSidebar.stripe.i,
                    stripej: rc.hit.hitSidebar.stripe.j,
                },
            ]);
        }
    }

    public override onClick(mouse: { x: number; y: number }) {
        useCanvasTooltipStore.getState().setInvisible();
        if (this._renderer.hit.hitSidebarImage && this.props.meta?.session?.sandbox) {
            this.props.worker.dispatchMessage([
                "toggle",
                "dialog.taktzone.details",
                {
                    projectId: this.props.meta.session.sandbox,
                    tid: this._renderer.hit.hitSidebar.stripe.t,
                },
            ]);
        } else {
            const taktzones = this._renderer?.hit?.hitSidebar?.stripe?._p;
            if (!taktzones) {
                //Taktzones can be null or undefined
                return;
            }

            const taktzonesExceptRoot = taktzones.filter((taktzone) => taktzone > 0);
            if (taktzonesExceptRoot.length > 0) {
                this.props.onTaktzoneClicked(taktzonesExceptRoot);
            }
        }

        //@ts-ignore
        const taktzones = this._renderer?.hit?.hitSidebar?.stripe?._p;
        if (taktzones === undefined) {
            return;
        }

        const taktzonesExceptRoot = taktzones.filter((taktzone) => taktzone !== 0);
        if (taktzonesExceptRoot.length > 0) {
            if (window.top != window.self) {
                console.log("taktzones", taktzonesExceptRoot);
                const messageObj = {
                    action: "taktzoneSelected",
                    //@ts-ignore
                    taktzones: taktzonesExceptRoot,
                };
                parent.postMessage(messageObj, "*");
            }
        }
    }

    public onAddDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType) {
        const canvasState = useCanvasStore.getState();
        if (canvasState.showConflictMode === true && canvasState.dependencyChain.indexOf(srcPid) === -1) {
            console.warn(
                "adding dependency aborted. In conflict mode only active chain is allowed to create dependencies",
            );
            return;
        }
        // src and dst are wrong way around!!!!!
        const props: { src: number; dst: number; type: number; lag: [number, number]; whiteboard?: boolean } = {
            dst: srcPid,
            src: tgtPid,
            lag: [0, 3],
            type: dependencyType,
        };
        this.props.worker.postMessage(["dependency", "add", props]);

        // todo: separate sel logic from depChain calcualtion in Worker, because here is only the chain calculation required! could lead to side effects
        if (canvasState.dependencyChain.length > 0 && canvasState.showConflictMode === true) {
            this.props.worker.postMessage([
                "meta",
                "sel",
                {
                    ts: ++this._metaTS,
                    mode: this.props.mode,
                    details: this.props.viewConst?.details || null,
                    tids: [srcPid],
                    cb: this.props.worker.registerCallback((data) => {
                        canvasState.setDependencyChain(data?.chain || []);
                        useConflictStore.getState().actions.setConflictCount(data?.numberOfConflictingProcesses);
                    }),
                },
            ]);
        }
    }

    protected override onTextEdited(target: number, id: number, value: string) {
        if (CanvasHostBase.TASK_TARGET === target) {
            this.props.worker.postMessage([
                "rename",
                {
                    taskId: id,
                    text: value,
                },
            ]);
        }
    }

    protected override onCopyCut(cmd: "copy" | "cut") {
        const sel = this._renderer.getSel();
        const selectedProcessData = [];
        if (sel.length > 0) {
            const ids = sel.map((t) => t.id);
            //get in-depth data of each copied task
            sel.forEach((item) => {
                this.props.worker.postMessage([
                    "details",
                    {
                        taskId: item.id,
                        cb: this.props.worker.registerCallback((data) => {
                            selectedProcessData.push(data);
                        }),
                    },
                ]);
            });
            // Send the message to the worker to perform the copy/cut operation
            this.props.worker.postMessage([cmd, ids]);
            // process data recieved from worker
            const clipboardContentHandler: MainWorkerMessageHandler = (msg) => {
                if (msg[0] === "clipboardContent") {
                    const clipboardContent = msg[1];
                    this.writeToClipboard(JSON.stringify({ clipboardContent, selectedProcessData, cmd }));
                    this.props.worker.unregisterHandler(clipboardContentHandler);
                }
            };
            //register the handler to process the clipboard content once it's received
            this.props.worker.registerHandler(clipboardContentHandler);
        }
    }

    protected async writeToClipboard(clipboardContent: string) {
        //Clipboard API copy function
        try {
            await navigator.clipboard.writeText(clipboardContent);
            console.log("Content copied to clipboard successfully!");
        } catch (err) {
            console.error("Failed to copy content to clipboard:", err);
        }
    }

    protected override async onPaste(ev: CanvasHostBasePointerEvent) {
        this._renderer.hitTest(ev);
        if (this._renderer.hit.hitStripe) {
            const pointerGridX = Math.max(0, Math.floor(this._renderer.hit.hitStripeLeft / this._renderer.C.colPx));
            const pointerGridY = Math.max(0, Math.floor(this._renderer.hit.hitStripeTop / this._renderer.C.rowPx));

            try {
                let clipboardContent;
                let copiedTrades;
                let sourceProjectId;
                let copiedTaskIds;
                let selectedProcessData;
                const clipboardData = await navigator.clipboard.readText();
                let data;

                try {
                    data = JSON.parse(clipboardData);
                    clipboardContent = data.clipboardContent;
                    selectedProcessData = data.selectedProcessData;
                    copiedTrades = data.selectedProcessData
                        .flatMap((item) => (item.trades ? item.trades.value : []))
                        .filter((obj, index, self) => index === self.findIndex((t) => t.name === obj.name));
                    sourceProjectId = data.selectedProcessData[0].projectId;
                    copiedTaskIds = data.selectedProcessData
                        .map((item) => ({
                            id: item.pid.value,
                            name: item.name.value,
                            startDate: item.start.value,
                            predecessors: item.predecessors,
                            successors: item.successors,
                        }))
                        .sort((a, b) => {
                            if (a.startDate === b.startDate) {
                                return a.id - b.id;
                            }
                            return a.startDate - b.startDate;
                        });
                } catch (error) {
                    console.error("Failed to JSON parse clipboard content:", error);
                    return;
                }
                this.props.worker.postMessage([
                    "pastev2",
                    {
                        x: pointerGridX,
                        y: pointerGridY,
                        p: {
                            pid: this._renderer.hit.hitStripe.t,
                            i_pid: this._renderer.hit.hitStripe.i,
                        },

                        clipboardContent: clipboardContent,
                        copiedTrades: copiedTrades,
                        sourceProjectId: sourceProjectId,
                        copiedTaskIds: copiedTaskIds,
                        action: data.cmd,
                    },
                ]);

                //TODO: Make this unnecessary
                const clipboardContentHandler: MainWorkerMessageHandler = (msg) => {
                    if (msg[0] === "clipboardContent") {
                        const clipboardContent = msg[1];
                        //When copy => copy; when cut, keep copy in Clipboard => copy
                        this.writeToClipboard(JSON.stringify({ clipboardContent, selectedProcessData, cmd: "copy" }));
                        this.props.worker.unregisterHandler(clipboardContentHandler);
                    }
                };

                //register the handler to process the clipboard content once it's received
                this.props.worker.registerHandler(clipboardContentHandler);
            } catch (error) {
                console.error("Failed to internally paste clipboard content:", error);
                // if (this.props.showToast) {
                CopyToaster(
                    {
                        title: intl.get("ClipboardToast.title"),
                        text: intl.getHTML("ClipboardToast.description"),
                        // link: "https://lcmdigital.zendesk.com/hc/de/articles/12420163695132"
                    },
                    {
                        closeButton: true,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        theme: "light",
                    },
                );
                // }
            }
        }
    }

    protected override onDeleteKey() {
        const sel = this._renderer.getSel();
        if (sel.length > 0) {
            this.props.worker.postMessage([
                "deleteTasks",
                {
                    tasks: sel.map((t) => t.id),
                },
            ]);
            return;
        }

        const depSelection = this._renderer.getDependencySel();
        if (depSelection[0] >= 1 && depSelection[1] >= 1) {
            this.props.worker.postMessage([
                "details",
                {
                    dep: {
                        srcPid: depSelection[1], // targetTaskId,
                        dstPid: depSelection[0], // sourceTaskId,
                        state: null,
                    },
                },
            ]);
            return;
        }
    }

    public override onHoverProcess(
        task: CanvasTaskData & CanvasHostRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
        scale: number,
        dynamicFontSize: boolean,
    ) {
        if (this.hoveredPid !== task.id) {
            //clear timeout if previous exists
            clearTimeout(this.hoverTimeoutIndex);

            this.hoverTimeoutIndex = setTimeout(() => {
                this.hoveredPid = task.id;
                const { setVisible } = useCanvasTooltipStore.getState();
                setVisible(points.mouse.x, points.task.y, task);
            }, 700);
        } else {
            const { setVisible } = useCanvasTooltipStore.getState();
            setVisible(points.mouse.x, points.task.y, task);
        }
    }

    public override onLeaveProcess() {
        clearTimeout(this.hoverTimeoutIndex);
        this.hoverTimeoutIndex = null;
        this.hoveredPid = null;

        useCanvasTooltipStore.getState().setInvisible();
    }

    private renderOverlayHack(this: CanvasHostBase, props: { const: any; reactState: any; overlayHack: any }) {
        const CONST = this.getCONST();
        const headerHeight = CONST.titleHeight + CONST.subtitleHeight;
        let x = undefined;
        let d = null;
        if (props.overlayHack.hover) {
            x = props.overlayHack.hover.x;
            d = props.overlayHack.hover.d;
        }
        return (
            <div
                className={classNames.overlayHack}
                style={{
                    visibility: undefined === x ? "hidden" : null,
                    left: (props.reactState.left + (x || 0)) * props.reactState.scale,
                    top: headerHeight,
                    height: props.const.colHeaderHeight,
                }}
            >
                {d ? <div className={classNames.overlayHackBubble}>{d}</div> : null}
            </div>
        );
    }
}
