import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { Text, IconButton } from "@fluentui/react";
import { DigitalPlanningBoardSelectionEvent, intl } from "lcmd2framework";
import { SidebarContext } from "../../../app/components/SidebarHost";
import { X } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { tradeTabs } from "../TradePanels/TradePanels";

type ExtensionMenuItem = {
    key: string;
    text: string;
    iconProps: { iconName: string };
    onClick?: () => void;
};

type ExtensionMenuProp = {
    iconProps?: { iconName: string };
    menuItems?: ExtensionMenuItem[];
};

type PanelHeaderStats = {
    extensionMenuProp?: ExtensionMenuProp;
    headerTitle?: string;
    customHeader?: ReactNode;
    isTradesView?: boolean;
    onClose?: () => void;
    tradesTab?: string;
    setTradesTab?: Dispatch<SetStateAction<string>>;
};

export function PanelHeader({
    extensionMenuProp,
    headerTitle,
    customHeader,
    isTradesView = false,
    onClose,
    tradesTab,
    setTradesTab,
}: PanelHeaderStats) {
    const propsContext = useContext(SidebarContext);

    const [extensionMenu] = useState<ExtensionMenuProp>(extensionMenuProp || null);
    const [selectedProcesses, setSelectedProcesses] = React.useState<DigitalPlanningBoardSelectionEvent>(null);

    propsContext.sidebarCtx.useSelectionEffect(
        (ev: DigitalPlanningBoardSelectionEvent) => {
            setSelectedProcesses(ev);
        },
        [setSelectedProcesses],
    );

    /* is this still needed?
    LCMD.useProcessDetailsEffect(selectedProcesses?.projectId, selectedProcesses?.pid, (error, data) => {
        console.log(data);
        if (!data) {
            return;
        }
    }, [], propsContext.ppTS);
    */

    const processIds = selectedProcesses
        ? typeof selectedProcesses?.pid === "number"
            ? `#${selectedProcesses.pid}`
            : selectedProcesses.pid
                  .slice(0, Math.min(selectedProcesses.pid.length, 20))
                  .map((value) => `#${value}`)
                  .join(", ")
        : "";
    const processIdsTitle = selectedProcesses
        ? typeof selectedProcesses?.pid === "number"
            ? `#${selectedProcesses.pid}`
            : selectedProcesses.pid.map((value) => `#${value}`).join(", ")
        : "";

    const headerContent = () => {
        if (isTradesView) {
            return (
                <Tabs value={tradesTab} defaultValue={tradeTabs.trades} onValueChange={(value) => setTradesTab(value)}>
                    <TabsList>
                        <TabsTrigger value={tradeTabs.trades}>{intl.get("Sidebar.TradesView.HeaderTitle")}</TabsTrigger>
                        <TabsTrigger value={tradeTabs.newTrade} data-userflow-id="pp-sidebar-neues-gewerk">
                            {intl.get("TradesView.NewTradeTabText")}
                        </TabsTrigger>
                    </TabsList>
                </Tabs>
            );
        }
        if (headerTitle) {
            return (
                <Text
                    styles={{
                        root: {
                            fontSize: 14,
                            width: "80%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            userSelect: "text",
                        },
                    }}
                    title={processIdsTitle}
                >
                    {headerTitle}
                </Text>
            );
        }
        if (customHeader) {
            return customHeader;
        }
        return (
            <Text
                styles={{
                    root: {
                        fontSize: 14,
                        width: "80%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        userSelect: "text",
                    },
                }}
                title={processIdsTitle}
            >
                {processIds}
            </Text>
        );
    };

    return (
        <div className="mx-4 mt-5 flex w-[350px] flex-row content-center items-center justify-between">
            {headerContent()}
            {extensionMenu && (
                <IconButton
                    styles={{
                        root: {
                            color: "background: rgb(86, 92, 96)",
                        },
                    }}
                    menuProps={{
                        items: extensionMenu.menuItems,
                        styles: {
                            root: {
                                color: "background: rgb(86, 92, 96)",
                            },
                        },
                    }}
                    iconProps={{
                        iconName: extensionMenu.iconProps?.iconName || "MoreVertical",
                    }}
                    onMenuClick={() => {}}
                    data-selection-disabled={true}
                    data-is-focusable={false}
                    split={false}
                    onRenderMenuIcon={() => null}
                />
            )}
            {onClose && (
                <Button size={"icon"} variant={"ghost"} className={"h-4 w-4"} onClick={onClose}>
                    <X color="#A1A1AA" className="h-4 w-4" />
                </Button>
            )}
        </div>
    );
}
