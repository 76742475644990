import * as React from "react";
import { CanvasCommonProps } from "../Canvas";
import type { CanvasStripeData } from "../../../../model/DataModel";
import {
    CalendarGrid,
    CANVAS_FONT,
    CanvasCalendarHeader,
    CanvasTaskData,
    CanvasViewConst,
    CanvasViewMeta,
    DataOperationTarget,
    VCONST0,
} from "../../../../model/DataModel";
import { CONST } from "../../../settings";
import {
    Dropdown,
    getTheme,
    IconButton,
    IDropdownOption,
    IMessageBarStyles,
    mergeStyleSets,
    MessageBar,
    MessageBarButton,
    MessageBarType,
} from "@fluentui/react";
import { intl } from "../../../GlobalHelperReact";
import ResizeObserver from "resize-observer-polyfill";
import { assert, EpochDaystoEpochMS, gfu, gfu2 } from "../../../../model/GlobalHelper";
import * as detectIt from "detect-it";
import {
    CanvasHostHitResult,
    CanvasHostRenderer,
    CanvasHostRendererCtx,
    CanvasHostRendererDragInfo,
    CanvasHostRendererDropInfo,
    CanvasHostRenderNode,
} from "./CanvasHostRenderer";
import { CanvasChartCallbacks, getParticleContext } from "../../../api/ParticleContext";
import { MainWorkerMessage, MainWorkerPipe } from "../../../MainWorkerPipe";
import { CanvasSizeHelper } from "./CanvasSizeHelper";
import { isPointNearLine } from "./CanvasUtils";
// FIXME: if i import it -> application wont start
// import { LCMDContextDependencyType } from "../../../../app/LCMContext";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { CanvasStore, useCanvasStore } from "@/app/store/canvasStore";
import { useUserJourneyStore } from "@/app/store/userJourneyStore";

const contentTypeMap: { [key: string]: string } = {
    RESOURCE_CHART: "canvas.kappa",
    MILESTONES: "canvas.milestones",
};

export enum LCMDContextDependencyType {
    INVALID, // INVALID/DELETED
    EE, //"EE",
    EA, //"EA",
    AE, //"AE",
    AA, //"AA"
}

export type LCMDWBSContextType = {
    id: number;
    data: any[];
    ts: number; // pending operation timestamp
    readonly: boolean;
    stripes: any[] | null;
} | null;

export type LCMDProcessViewData = any;

export type ClientPoint = {
    clientX: number;
    clientY: number;
};

export function _handleProcessViewMsg(
    this: {
        onInit?: (msg: any) => void;
        onUpdate?: (resp: { data: any[]; readonly: boolean }) => void;
    },
    LCMD: { wbs: LCMDWBSContextType },
    msg: MainWorkerMessage,
) {
    switch (msg[0]) {
        case "processview":
            {
                switch (msg[1]) {
                    case "init":
                        {
                            assert(null === LCMD.wbs); // already initialized? why?
                            LCMD.wbs = {
                                id: msg[2].id,
                                data: msg[2].patch,
                                ts: 0,
                                readonly: msg[2].readonly,
                                stripes: msg[2].stripes,
                            };
                            if (Array.isArray(LCMD.wbs.stripes)) {
                            } else if (LCMD.wbs.data.length > 0) {
                                LCMD.wbs.data.push({
                                    tid: -1,
                                    l: 0, // LCMD.wbs.data[LCMD.wbs.data.length-1].l,
                                    n: null,
                                });
                            }
                            if (this?.onInit) {
                                this.onInit(msg[2]);
                            }
                            if (this?.onUpdate) {
                                this.onUpdate(LCMD.wbs as any);
                            }
                        }
                        break;
                    case "update":
                        {
                            if (LCMD.wbs?.id === msg[2].id) {
                                assert(LCMD.wbs.ts >= msg[2].ts);
                                if (true || LCMD.wbs.ts === msg[2].ts) {
                                    //@TODO deal with out-of-bound patches..
                                    const data = msg[2].patch.map((i) => {
                                        if ("number" === typeof i) {
                                            // unchanged...
                                            return LCMD.wbs.data[i];
                                        } else {
                                            return i;
                                        }
                                    });
                                    if (Array.isArray(LCMD.wbs.stripes)) {
                                        if (Array.isArray(msg[2].stripes)) {
                                            //@TODO patch!
                                            LCMD.wbs.stripes = msg[2].stripes;
                                        }
                                    } else if (data.length > 0) {
                                        data.push({
                                            tid: -1,
                                            l: 0, // data[data.length-1].l,
                                            n: null,
                                        });
                                    }
                                    LCMD.wbs.data = data;
                                    if (this?.onUpdate) {
                                        this.onUpdate(LCMD.wbs as any);
                                    }
                                } else {
                                    // out-of-bound
                                }
                            } else {
                                // out-of-bound
                            }
                        }
                        break;
                }
            }
            break;
    }
}

const theme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
    host: {
        position: "absolute",
        boxSizing: "border-box",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "gray",
        touchAction: "none",
        //        backgroundColor: "pink",
        //        border: "4px solid red"
    },
    canvas: {
        position: "absolute",
        boxSizing: "border-box",
        left: 0,
        top: 0,
        backgroundColor: "#82878A",
        pointerEvents: "none",
        fontKerning: "none",
        textRendering: "geometricPrecision",
        font: "400 12px Roboto",
        //zIndex:100
    },
    overlay: {
        position: "absolute",
        left: 0,
        top: 0,
        width: 0,
        height: 0,
        //width: this.state.reactState.width,
        //height: this.state.reactState.height,
        //backgroundColor: "green",
        //opacity: 0.5
        transformOrigin: "0 0 0",
        pointerEvents: "none",
        userSelect: "none",
    },
    textarea: {
        position: "absolute",
        left: 0,
        top: 0,
        width: 100,
        height: 100,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 9,
        paddingRight: 9,
        border: "0px",
        margin: 0,
        //backgroundColor: "pink",
        color: "black",
        backgroundColor: "white",
        visibility: "hidden",
        zIndex: 1000,
        textRendering: "geometricPrecision",
        overflow: "hidden",
        resize: "none",
        outline: "none",
        "&:focus": {
            outline: "none",
        },
        textAlign: "left",
        lineHeight: "100%",
        whiteSpace: "pre",
        pointerEvents: "auto",
        //display: "inline-block",
        //verticalAlign: "middle"
        //textTransform: "none",
        //textSizeAdjust: "100%"
    },
    stupidBox: {
        position: "absolute",
        left: 0,
        top: CONST.titleHeight + CONST.subtitleHeight,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        alignContent: "left",
        alignItems: "center",
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: "white",
    },
    stupidBoxItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "space-between",
        flexGrow: 1,
    },
    footer: {
        position: "absolute",
        boxSizing: "border-box",
        left: 0,
        right: 0,
        bottom: 0,
        //backgroundColor: "#88000088",
        //zIndex:100
    },
    footerComponent: {
        position: "absolute",
        boxSizing: "border-box",
        left: 0,
        bottom: 0,
        backgroundColor: "white",
        overflow: "overlay",
    },
    legend: {
        position: "absolute",
        boxSizing: "border-box",
        //backgroundColor: "pink",
        left: 0,
        right: 0,
        height: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "end",
        //pointerEvents: "none",
        //userSelect: "none"
    },
    hRule: {
        position: "absolute",
        boxSizing: "border-box",
        left: 0,
        right: 0,
        height: 10,
        bottom: 0,
        borderBottom: "1px solid #E1E4E6",
        //display: "flex",
        //flexDirection: "row" ,
        //justifyContent: "center",
        //alignItems: "end",
        //pointerEvents: "none",
        userSelect: "none",
        selectors: {
            "&:hover": {
                backgroundColor: "#E1E4E6",
                cursor: "row-resize",
            },
        },
    },
});

const alertStyle: IMessageBarStyles = {
    root: {
        position: "absolute",
        left: 0,
        right: 0,
        top: CONST.titleHeight /*+CONST.subtitleHeight*/,
        zIndex: 1000,
    },
};

type CanvasHostBaseTarget = {
    ref: HTMLDivElement;
    target: DataOperationTarget;
    id?: number;
    item?: any;
};

type CanvasHostBaseDragState = {
    sel: CanvasHostBaseTarget;
    hit: CanvasHostHitResult;
    overlay: boolean;
    clientX: number | null;
    clientY: number | null;
    left: number | null;
    top: number | null;
    width: number | null;
    height: number | null;
    handle: number; // 0=none, 1=top/left, 2=top/right, 3=bottom/left, 4=bottom/right
};

type CanvasHostBaseFooter = {
    name: string;
    chartInstance: CanvasChartCallbacks;
    overlayState: any;
};

export type CanvasHostBasePointerEvent = {
    x: number;
    y: number;
    screenX: number;
    screenY: number;
    offsetX: number;
    offsetY: number;
    scale: number;
};

export type CanvasHostBaseProps = CanvasCommonProps & {
    mode: "canvas" | "wb" | "tz";
    viewConst: CanvasViewConst;
    insetTop: number;
    active: string;
    meta: any;
    loaded: boolean;
};

export type CanvasHostBaseReactState = {
    stripes: CanvasStripeData[];
    l_min: number;
    l_max: number;
    tasks: CanvasTaskData[];
    header: CanvasCalendarHeader;
    messages: [];
    scale: number;
    left: number;
    top: number;
    width: number;
    height: number;
};

type CanvasHostBaseState = {
    const: CanvasViewConst;
    meta: CanvasViewMeta;
    grid: CalendarGrid;
    canvas?: {
        attr: {
            width: number;
            height: number;
        };
        style: {
            width: number;
            height: number;
        };
        scale: number;
    };
    canvasState: {
        drag: CanvasHostBaseDragState;
        marquee: null | { x: number; y: number; w: number; h: number };
        pointer: { [session: string]: { x: number; y: number; userName: string } };
    };
    reactState: CanvasHostBaseReactState;
    footer: CanvasHostBaseFooter;
    footerH: number;
    gpaDirty: boolean;
    connectionState: {
        error?: any;
        rt?: any;
    };
    overlayHack: any;
    // TODO: remove after new design implementation
    fs: number;
    loaded: boolean;
};

/**
 * @public
 */
export abstract class CanvasHostBase<T extends CanvasHostBaseProps = CanvasHostBaseProps> extends React.Component<
    T,
    CanvasHostBaseState
> {
    public static TASK_TARGET = DataOperationTarget.TASKS;
    public static STRIPE_TARGET = DataOperationTarget.MAX;
    public svgImageCache = new Map<string, HTMLImageElement>();
    _canvasStoreSubscription = null;
    /** @internal */
    state = {
        const: null,
        meta: null,
        grid: null,
        canvas: null as {
            attr: {
                width: number;
                height: number;
            };
            style: {
                width: number;
                height: number;
            };
            scale: number;
        },
        canvasState: {
            drag: null as CanvasHostBaseDragState,
            marquee: null,
            pointer: {},
        },
        reactState: {
            stripes: [] as CanvasStripeData[],
            l_min: 0,
            l_max: 0,
            tasks: [] as CanvasTaskData[],
            header: null as CanvasCalendarHeader,
            messages: [] as any,
            //scale: 2,
            //left: 100,
            //top: 100,
            //width: 9216,
            //height: 6237,
            scale: 0,
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        },
        footer: null,
        footerH: 400 / (window?.devicePixelRatio || 1),
        gpaDirty: false,
        connectionState: null,
        overlayHack: {},
        // TODO: remove after new design implementation
        fs: 12,
        loaded: false,
    };
    /** @internal */
    _div: HTMLDivElement = null;
    /** @internal */
    _hRule: HTMLDivElement = null;
    /** @internal */
    _ftrDiv: HTMLDivElement = null;
    /** @internal */
    _ftrComp: HTMLDivElement = null;
    /** @internal */
    _canvas: HTMLCanvasElement = null;
    /** @internal */
    _renderPending = false;
    /** @internal */
    _overlay: HTMLDivElement = null;
    /** @internal */
    _debug: HTMLDivElement = null;
    /** @internal */
    _textarea: HTMLTextAreaElement = null;
    /** @internal */
    _invalidateCanvas = function (this: CanvasHostBase) {
        this._setPendingView(this.state.const, this.state.grid);
        if (!this._renderPending) {
            this._renderPending = true;
            requestAnimationFrame(this._onRenderCanvas);
        }
    }.bind(this);
    /** @internal */
    _onResize = function (this: CanvasHostBase, a: any) {
        if (a.length > 0) {
            assert(1 === a.length);
            const s = window.devicePixelRatio;
            const _width = Math.floor(a[0].contentRect.width || 0);
            const _height = Math.floor(a[0].contentRect.height || 0);
            const width = _width * s;
            const height = _height * s;
            if (this.state.footer) {
                this.state.footer.canvasState.chart.width = width; // HACK update width as side-effect
            }
            this.setState(
                {
                    canvas: {
                        attr: {
                            width,
                            height,
                        },
                        style: {
                            width: _width,
                            height: _height,
                        },
                        scale: s,
                    },
                },
                this.onRenderCanvas,
            );
        }
    }.bind(this);
    /** @internal */
    _resizeObserver: ResizeObserver = new ResizeObserver(this._onResize);
    /** @internal */
    _onDivRef = function (this: CanvasHostBase, r: HTMLDivElement) {
        this._div = r;
        if (r) {
            this._resizeObserver.observe(r);
            const contentRect = r.getBoundingClientRect();
            this._onResize([{ contentRect }]);
            //console.log("FOCUS");
            this._div.focus();
        } else {
            this._resizeObserver.disconnect();
        }
    }.bind(this);
    /** @internal */
    _onFtrDivRef = function (this: CanvasHostBase, r: HTMLDivElement) {
        this._ftrDiv = r;
    }.bind(this);
    /** @internal */
    _onFtrCompRef = function (this: CanvasHostBase, r: HTMLDivElement) {
        this._ftrComp = r;
    }.bind(this);
    /** @internal */
    _onCanvasRef = function (this: CanvasHostBase, r: HTMLCanvasElement) {
        this._canvas = r;
        if (this._canvas) {
            this.onRenderCanvas();
        }
    }.bind(this);
    /** @internal */
    _onDebugRef = function (this: CanvasHostBase, r: HTMLDivElement) {
        this._debug = r;
    }.bind(this);
    /** @internal */
    _onTextareaRef = function (this: CanvasHostBase, r: HTMLTextAreaElement) {
        this._textarea = r;
    }.bind(this);
    /** @internal */
    _onTextareaFocus = function (this: CanvasHostBase, ev: FocusEvent) {
        console.log("FOCUS");
    }.bind(this);
    /** @internal */
    _onInputKeyDown = function (ev) {
        if (13 === ev.keyCode) {
            this._div.focus();
            ev.preventDefault();
        } else if ((ev.ctrlKey || ev.metaKey) && ("z" === ev.key || "y" === ev.key)) {
            // undo, redo
            this._cancelTextArea();
            ev.preventDefault();
        } else if ("Escape" === ev.key) {
            this._cancelTextArea();
            ev.preventDefault();
        }
    }.bind(this);
    /** @internal */
    _onInputCopy = function (ev) {
        const start = this._textarea.selectionStart || 0;
        const end = this._textarea.selectionEnd || 0;
        if (start < end) {
            const text = (this._textarea.value || "").substring(start, end).split("\n").join("");
            ev.clipboardData.setData("text/plain", text);
        }
        ev.preventDefault();
    }.bind(this);
    /** @internal */
    _onInputPaste = function (ev) {
        const data: string = ev.clipboardData.getData("text/plain");
        if ("string" === typeof data) {
            const text = data.replace(/\n|\r/g, " ").trim();
            //this._textarea.setRangeText(text);
            //this._textarea.setSelectionRange(ofs, ofs);
            document.execCommand("insertText", false /*no UI*/, text);
            //this._onTextareaChange();
        }
        ev.preventDefault();
    }.bind(this);
    /** @internal */
    _onTextareaBlur = function (this: CanvasHostBase) {
        if ("visible" === this._textarea.style.visibility) {
            const target_1 = Number.parseInt(this._textarea.dataset.selTarget, 10);
            const id = Number.parseInt(this._textarea.dataset.selId, 10);
            if (target_1 > 0) {
                const value = (this._textarea.value || "").trimStart().trimEnd();
                if ("string" === typeof this._textarea.dataset.text && this._textarea.dataset.text !== value) {
                    if (DataOperationTarget.TASKS === target_1 - 1) {
                        this.onTextEdited(target_1 - 1, id, value);
                    }
                }
            }
            this._cancelTextArea(false);
        }
    }.bind(this);
    /** @internal */
    _onTextareaChange = function (this: CanvasHostBase) {
        const value = this._textarea.value || "";
        this._postSZMessage(value);
    }.bind(this);
    protected _renderer: CanvasHostRenderer = null;
    /** @internal */
    protected wbs: LCMDWBSContextType | null = null;
    /** @beta */
    protected grid5: number = 5;
    /** @beta */
    protected _asyncTS = 0;
    /** @beta */
    protected _metaTS = 0;
    protected _overlayHack: (props: { const: any; reactState: any; overlayHack: any }) => JSX.Element = undefined;
    /** @internal */
    private _pointerPath = new Path2D(
        "M6.63462 4.22991C5.98263 3.69275 5 4.15683 5 5.0019V19.0007C5 19.9268 6.14953 20.3554 6.75512 19.655L10.2766 15.5826C10.5614 15.2533 10.9751 15.0641 11.4102 15.0641L16.9986 15.0641C17.9363 15.0641 18.3577 13.8885 17.6339 13.2921L6.63462 4.22991Z",
    );
    /** @internal */
    private _wbsCtx: any = null;
    /** @internal */
    private ts0 = performance.timeOrigin;
    private _pendingView = {
        state: 0, // 0==pending, 1==send, 2==send+pending
        grid_ts: 0,
        col0: null,
        col1: null,
        scale: null,
        options: undefined,
    };
    /** @internal */
    private _fontsDirty = true;
    /** @internal */
    // private _onRenderCanvasCalls = 0;
    // private _onRestoreSessionReady() {
    //     this._onRenderCanvasCalls++;
    //     console.log('_onRenderCanvasCalls', this._onRenderCanvasCalls)

    //     // if (this._onRenderCanvasCalls === 4) {
    //     //     this._restoreSessionState();
    //     //     this.onRenderCanvas = this._onRender;
    //     // }
    // }

    // private _renderCanvasWithRestoredSession(ts?: number) {
    //     this._onRestoreSessionReady();
    //     this._onRender(ts);
    // }

    // onRenderCanvas = this._renderCanvasWithRestoredSession;

    onRenderCanvas = function (this: CanvasHostBase, ts?: number) {
        if (undefined === ts) {
            ts = Date.now();
        }
        assert("number" === typeof ts);
        // console.log('loaded', this.state.loaded, this.props.loaded)
        if (this._canvas && !this._fontsDirty) {
            const ctx = this._canvas.getContext("2d", { alpha: false });
            const C = this.state.const;
            if (ctx) {
                ctx.save();
                ctx.scale(this.state.canvas.scale, this.state.canvas.scale);
                const rs = this.state.reactState;
                const w = this.state.canvas.style.width;
                const h = this.state.canvas.style.height;
                ctx.fillStyle = "#82878A";
                ctx.fillRect(0, 0, w, h);
                if (C) {
                    ctx.fillStyle = C.gpa.canvasColor;
                    const xs = rs.left * rs.scale;
                    const ys = rs.top * rs.scale;
                    const ws = rs.width * rs.scale;
                    const hs = rs.height * rs.scale;
                    ctx.fillRect(xs, ys, ws, hs);
                }
                ctx.save();
                ctx.scale(rs.scale, rs.scale);
                ctx.translate(rs.left, rs.top);
                //ctx.translate(rs.left*rs.scale, rs.top*rs.scale);

                this._renderer.render(ctx, rs.scale, -rs.left, -rs.top, w / rs.scale, h / rs.scale);

                const drag = this.state.canvasState.drag;
                if ((drag?.sel as CanvasHostBaseTarget)?.ref) {
                    // drag and drop an outside html element/ref into the canvas
                    const x = drag.clientX / rs.scale - rs.left - (drag.left || 0);
                    const y = drag.clientY / rs.scale - rs.top - (drag.top || 0);
                    const w = drag.width || 1;
                    const h = drag.height || 1;
                    ctx.strokeStyle = C.gpa.selected.primary.style;
                    ctx.lineWidth = C.gpa.selected.primary.width;
                    ctx.strokeRect(x, y, w, h);
                } /*else if (drag) {
                    ctx.fillStyle="red";
                    ctx.fillRect(drag.left-2, drag.top-2, 4, 4);
                }*/
                const marquee = this.state.canvasState.marquee;
                if (marquee) {
                    ctx.fillStyle = "#00000044";
                    ctx.fillRect(marquee.x, marquee.y, marquee.w, marquee.h);
                }
                ctx.restore();
                this._renderer.renderOverlay(ctx, rs.scale, -rs.left, -rs.top, w, h);
                if (this.state.footer?.chartInstance && this.state.footer?.data) {
                    const y = this.state.canvas.style.height - this.state.footer.canvasState.chart.height;
                    ctx.save();
                    ctx.fillStyle = C.gpa.canvasColor;
                    ctx.fillRect(0, y, this.state.canvas.style.width, this.state.canvas.style.height);
                    ctx.beginPath();
                    ctx.rect(0, y, this.state.canvas.style.width, this.state.canvas.style.height);
                    ctx.clip();
                    //const cols0=rs.left*rs.scale;
                    const data = this.state.footer.data;
                    const _rs = this._getFooterReactState();
                    //ctx.translate((rs.left+(data.view.col0*C.colPx))*rs.scale, y);
                    ctx.translate(_rs.left, y);
                    this.state.footer.canvasState.legend =
                        this.state.canvas.style.width - CONST.newSidebarWidth - _rs.left;
                    this.state.footer.chartInstance.onRenderCanvas(this.state.footer.canvasState, _rs);
                    ctx.restore();

                    /*ctx.save();
                    ctx.translate(this.state.canvas.width-CONST.newSidebarWidth, y);
                    ctx.strokeStyle="red";
                    ctx.lineWidth=5;
                    ctx.strokeRect(0, 0, -100, this.state.footer.canvasState.chart.height);
                    ctx.restore();*/
                }
                {
                    const pointer = this.state.canvasState.pointer;
                    const sessions = Object.getOwnPropertyNames(pointer);
                    if (sessions.length > 0) {
                        for (let i = 0; i < sessions.length; i++) {
                            const p = pointer[sessions[i]];
                            if (p.ts + 10 * 1000 >= ts && null !== p.x && null !== p.y) {
                                ctx.save();
                                ctx.fillStyle = "red";
                                ctx.translate(rs.left * rs.scale, rs.top * rs.scale);
                                ctx.translate(p.x * rs.scale - 6, p.y * rs.scale - 6);
                                ctx.fill(this._pointerPath);
                                ctx.fillRect(20, 20, 57, 20);
                                const f = C.fonts[C.gpa.pointerFont];
                                ctx.font = f.font;
                                ctx.fillStyle = "white";
                                ctx.fillText(p.n, 20 + 2, 20 + f.ascent + 4, 50);
                                ctx.restore();
                                //ctx.fillRect(p.x, p.y, 10, 10);
                            }
                        }
                    }
                }
                ctx.restore();
            }
        }
    }.bind(this);
    /** @internal */
    _onRenderCanvas = function (this: CanvasHostBase, ts: number) {
        if (this._fontsDirty) {
            if (document?.fonts) {
                const font = CANVAS_FONT.font.replace("{sz}", (12).toString());
                document.fonts
                    .load(font)
                    .then(() => {
                        // loaded
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        this._renderPending = false;
                        this._fontsDirty = false;
                        this._invalidateCanvas();
                    });
            } else {
                this._fontsDirty = false;
            }
        } else {
            this.onRenderCanvas(this.ts0 + ts);
            this._renderPending = false;
        }
    }.bind(this);
    /** @internal */
    private _onWheel = function (this: CanvasHostBase, e: WheelEvent) {
        if (e.ctrlKey || e.metaKey) {
            e.stopPropagation();
            e.preventDefault();
            return false;
        }
    }.bind(this);
    //private _previousWheelEvent=null;
    /** @internal */
    private onWheel = function (this: CanvasHostBase, event: React.WheelEvent<HTMLCanvasElement>) {
        let handled = false;
        const isCanvasTarget = event?.target === this._div;
        const isFooterTarget = event?.target === this._ftrDiv;
        if (event.isDefaultPrevented) {
            // handled...
        } else if (event.ctrlKey || event.metaKey) {
            this.zoom(event);
            handled = true;
        } else if ((isCanvasTarget || isFooterTarget) && event.shiftKey) {
            //console.log("xshift "+event.deltaY);
            const s = this.state.reactState.scale;
            const delta = event.deltaX || event.deltaY;
            this.setState(
                {
                    ...this.state,
                    reactState: { ...this.state.reactState, left: this.state.reactState.left - delta / s },
                },
                this._invalidateCanvasWithEvent.bind(this, event),
            );
            handled = true;
        } else if (isCanvasTarget || isFooterTarget) {
            //console.log("yshift "+event.deltaY);
            const s = this.state.reactState.scale;
            this.setState(
                {
                    ...this.state,
                    reactState: {
                        ...this.state.reactState,
                        left: this.state.reactState.left - event.deltaX / s,
                        top: this.state.reactState.top - event.deltaY / s,
                    },
                },
                this._invalidateCanvasWithEvent.bind(this, event),
            );
            handled = true;
        }
        if (handled) {
            console.log("onWheel handled");
            this._savePositionSessionState();
            event.stopPropagation();
            event.preventDefault();
        }
        return handled;
    }.bind(this);
    private onClipBoardPaste = function (this: CanvasHostBase, event: ClipboardEvent) {
        //console.log(JSON.stringify(event.key));
        if ((!event.defaultPrevented && !document.activeElement) || document.activeElement === this._div) {
            this.props.worker.dispatchMessage(["clip", "paste", event]);
        }
    }.bind(this);
    private _timerId: any = null;
    private _timerCtx = {
        ts: Date.now(),
        rt: {
            mode: null as string,
            n: null as string,
            x: null as number,
            y: null as number,
            s: null as number,
            bbox: null,
        },
    };
    public _pointer = {
        last: null as CanvasHostBasePointerEvent,
        rt: { x: null as number, y: null as number, s: null as number, mode: null as string, bbox: null },
        down: 0, // ev.buttons
        drag: null as CanvasHostBaseDragState,
        clientX: null,
        clientY: null,
        left: null,
        top: null,
        clear: false, // clear selection on mouseup
        marquee: false,
        forceMarquee: false,
        hRule: null,
    };
    private onKeyDown = function (this: CanvasHostBase, event: KeyboardEvent) {
        //console.log(JSON.stringify((event.target as HTMLElement).outerHTML));
        const isCanvasTarget = event?.target === this._div;
        if (!event.defaultPrevented && (!document.activeElement || document.activeElement === this._div)) {
            if (
                this.props.worker.config?.onKeyDown &&
                this.props.worker.config?.onKeyDown(getParticleContext(), event)
            ) {
                // handled
            } else
                switch (event.key) {
                    case "x": // no break;
                    case "c":
                        if (event.ctrlKey || event.metaKey) {
                            this.onCopyCut("x" === event.key ? "cut" : "copy");
                            event.preventDefault();
                        }
                        break;
                    case "v":
                        if (event.ctrlKey || event.metaKey) {
                            if (!this.state.const.readonly && isCanvasTarget && null !== this._pointer.last) {
                                this.onPaste(this._pointer.last);
                            }
                            event.preventDefault();
                        }
                        break;
                    case "g":
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.dispatchMessage(["goto", "today"]);
                            event.preventDefault();
                        }
                        break;
                    case "z":
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.postMessage(["undo", {}]);
                            event.preventDefault();
                        }
                        break;
                    case "y":
                        if (event.ctrlKey || event.metaKey) {
                            this.props.worker.postMessage(["redo", {}]);
                            event.preventDefault();
                        }
                        break;
                    case "Backspace":
                    case "Delete":
                        if (!this.state.const.readonly) {
                            this.onDeleteKey();
                        }
                        event.preventDefault();
                        break;
                    case "E":
                        if (event.ctrlKey && event.shiftKey && event.metaKey) {
                            this.props.worker.postMessage([
                                "export",
                                {
                                    target: "raw",
                                    mode: this.props.mode,
                                },
                            ]);
                            event.preventDefault();
                        }
                        break;
                    case "C":
                        if (event.ctrlKey && event.shiftKey && event.metaKey) {
                            throw new Error("crash test");
                        }
                        break;
                }
            if (!event.defaultPrevented && !event.ctrlKey && !event.metaKey) {
                if (1 === event.key.length) {
                    this._showTextArea();
                }
            }
        }
    }.bind(this);
    private onDblClick = function (this: CanvasHostBase, event: MouseEvent) {
        const isCanvasTarget = event?.target === this._div;
        if ((!event.defaultPrevented && !document.activeElement) || document.activeElement === this._div) {
            if (isCanvasTarget && null !== this._pointer.last) {
                this._showTextArea();
            }
        }
    }.bind(this);
    private _timerCB = function () {
        const ts = Date.now();
        const x = this._pointer.last?.x || null;
        const y = this._pointer.last?.y || null;
        const s = this._pointer.last?.scale || null;
        const bbox = this._renderer.getBBox();
        if (bbox) {
        }
        if (
            x !== this._pointer.rt.x ||
            y !== this._pointer.rt.y ||
            s !== this._pointer.rt.s ||
            bbox !== this._pointer.rt.bbox
        ) {
            this._pointer.rt.x = x;
            this._pointer.rt.y = y;
            this._pointer.rt.s = s;
            this._pointer.rt.bbox = bbox;
            if (
                (x !== this._timerCtx.rt.x ||
                    y !== this._timerCtx.rt.y ||
                    s !== this._timerCtx.rt.s ||
                    bbox !== this._timerCtx.rt.bbox) &&
                ("wb" === this._timerCtx.rt.mode || this._timerCtx.rt.ts + 5000 < ts)
            ) {
                this._timerCtx.rt.x = x;
                this._timerCtx.rt.y = y;
                this._timerCtx.rt.s = s;
                this._timerCtx.rt.bbox = bbox;
                this._timerCtx.rt.ts = ts;
                this.props.worker.postMessage(["rt", this._timerCtx.rt]);
            } else {
                this.props.worker.postMessage(["rt", this._pointer.rt]);
            }
        }
    }.bind(this);
    // TODO: maybe i can send message inside of restoreSession
    /** @internal */
    onWorkerMsg = function (this: CanvasHostBase, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "rt":
                {
                    if (this.props.mode === msg[1]?.mode) {
                        this.state.canvasState.pointer[msg[1].session] = msg[1];
                        this._invalidateCanvas();
                    }
                }
                break;
            case "canvas":
                if (msg[0] === this.props.mode) {
                    const canvas = msg[1];
                    const _kappa = this.state.footer && canvas?.stats && canvas.stats[this.state.footer.name];
                    if (null === _kappa?.view) {
                        // fix this, so we do not have to check for null values over and over again...
                        _kappa.view = {
                            cols: 0,
                            col0: 0,
                            col1: 0,
                        };
                    }
                    let kappa = _kappa || this.state.footer?.data;
                    if (kappa) {
                        if (2 === this._pendingView.state) {
                            this._postView();
                            //assert(0===this.pending.state || 1===this.pending.state);
                        } else {
                            this._pendingView.state = 0;
                        }
                    }
                    if (canvas.gridPatch) {
                        // invalidate on grid change
                        this._pendingView.state = 0;
                        this._pendingView.grid_ts = 0;
                        this._pendingView.col0 = null;
                        this._pendingView.col1 = null;
                        this._pendingView.scale = null;
                        this._setPendingView(canvas.viewConst || this.state.const, canvas.grid);
                        kappa = null; // invalidate date
                        /*
                    this.setState({
                        data: null,
                        kappa: null // same as data for backward kompatibility
                    });
                    */
                    }
                    const C = gfu2(canvas.viewConst, this.state.const);
                    const M = gfu2(canvas.viewMeta, this.state.meta);
                    const grid = canvas.grid || this.state.grid;
                    const stripes = canvas.stripes || this.state.reactState.stripes;
                    const l_min = gfu(canvas?.l_min, this.state.reactState.l_min, 0);
                    const l_max = gfu(canvas?.l_max, this.state.reactState.l_max, 0);
                    const tasks = canvas.tasks || this.state.reactState.tasks;
                    const header = canvas.header || this.state.reactState.header;
                    const gpaDirty = canvas.gpaDirty || this.state.gpaDirty;
                    const connectionState = canvas.errorState;

                    if (
                        C !== this.state.const ||
                        M !== this.state.meta ||
                        grid !== this.state.grid ||
                        stripes !== this.state.reactState.stripes ||
                        l_min !== this.state.reactState.l_min ||
                        l_max !== this.state.reactState.l_max ||
                        tasks !== this.state.reactState.tasks ||
                        header !== this.state.reactState.header ||
                        kappa !== this.state.footer?.data ||
                        gpaDirty !== this.state.gpaDirty ||
                        connectionState !== this.state.connectionState ||
                        // TODO: remove after new design implementation
                        canvas.fs !== this.state.fs
                    ) {
                        const width = (grid?.view?.cols || 0) * C.colPx;
                        const height = (stripes.length > 0 ? stripes[stripes.length - 1].e : 0) * C.rowPx;
                        this.setState((state) => {
                            const ret = {
                                const: C,
                                meta: M,
                                grid,
                                reactState: {
                                    ...state.reactState,
                                    stripes,
                                    l_min,
                                    l_max,
                                    tasks,
                                    width,
                                    height,
                                    header,
                                },
                                // TODO: remove after new design implementation
                                fs: canvas.fs,
                                footer: state.footer && kappa ? { ...state.footer, data: kappa } : state.footer,
                                gpaDirty: gpaDirty,
                                connectionState,
                            };
                            if (canvas.event) {
                                if (ret.reactState.stripes !== state.reactState.stripes) {
                                    canvas.event.stripes = ret.reactState.stripes;
                                    canvas.event.stripes0 = state.reactState.stripes;
                                }
                                this.onViewChangeEvent(ret, canvas);
                            }
                            this._renderer.updateIfNeeded(
                                ret.const,
                                ret.meta,
                                ret.grid,
                                ret.reactState.stripes,
                                l_min,
                                l_max,
                                ret.reactState.tasks,
                                ret.reactState.messages,
                                ret.reactState.header,
                            );
                            return ret;
                        }, this._invalidateCanvas);
                    }
                }
                break;
            case "wb":
                if (msg[0] === this.props.mode) {
                    const wb = msg[1];
                    //console.log("WB");
                    //console.log(wb);
                    const C = gfu2(wb.viewConst, this.state.const);
                    const M = gfu2(wb.viewMeta, this.state.meta);
                    const grid = wb.grid || this.state.grid;
                    const stripes = wb.stripes || this.state.reactState.stripes;
                    const tasks = wb.tasks || this.state.reactState.tasks;
                    const width = 9216; //@TODO
                    const height = 6237; //@TODO
                    const connectionState = wb.errorState;
                    if (
                        C !== this.state.const ||
                        M !== this.state.meta ||
                        grid !== this.state.grid ||
                        stripes !== this.state.reactState.stripes ||
                        tasks !== this.state.reactState.tasks ||
                        width !== this.state.reactState.width ||
                        height !== this.state.reactState.height ||
                        connectionState != this.state.connectionState
                    ) {
                        this.grid5 = C.grid;
                        this.setState((state) => {
                            const ret = {
                                const: C,
                                meta: M,
                                grid,
                                reactState: { ...state.reactState, stripes, tasks, width, height },
                                connectionState,
                            };
                            this._renderer.updateIfNeeded(
                                ret.const,
                                ret.meta,
                                ret.grid,
                                ret.reactState.stripes,
                                0,
                                0,
                                ret.reactState.tasks,
                                ret.reactState.messages,
                                null,
                            );
                            return ret;
                        }, this._invalidateCanvas);
                    }
                }
            case "processview":
                if ("tz" === this.props.mode) {
                    _handleProcessViewMsg.call(this._wbsCtx, this, msg);
                }
                break;
            case "async":
                {
                    switch (msg[1]) {
                        case "drag":
                            if (msg[2].mode === this.props.mode && msg[2].ts === this._asyncTS) {
                                const tids = msg[2].tids;
                                const n_tids = tids.length;
                                for (let i_tid = 0; i_tid < n_tids; i_tid++) {
                                    this._renderer.select(DataOperationTarget.TASKS, tids[i_tid], i_tid + 1 < n_tids);
                                }
                            }
                            break;
                        case "overlayHack":
                            {
                                const overlayState = msg[2];
                                const overlayHack = this.state.overlayHack;
                                this.setState({
                                    overlayHack: { ...overlayHack, ...overlayState },
                                });
                            }
                            break;
                    }
                }
                break;
            case "toggle":
                {
                    switch (msg[1]) {
                        case "footer":
                            {
                                this._setFooter(msg[2]?.name || null);
                                // msg[2]?.name || null
                            }
                            break;
                        case "forceShiftKey":
                            {
                                const forceShiftKey = msg[2] as boolean;
                                this._pointer.forceMarquee = forceShiftKey;
                            }
                            break;
                        case "zoom":
                            {
                                const ftrRect = this._ftrComp ? this._ftrComp.getBoundingClientRect() : null;
                                const ftrW = ftrRect
                                    ? ftrRect.width
                                    : CanvasSizeHelper.getHeaderWidth(
                                          this.state.const,
                                          this.state.reactState.l_min,
                                          this.state.reactState.l_max,
                                      );
                                const ftrH = ftrRect ? ftrRect.height : 0;
                                const hOfs =
                                    this.state.const.colHeaderHeight + CONST.titleHeight + CONST.subtitleHeight;
                                const w = this.state.canvas.style.width - ftrW - CONST.newSidebarWidth;
                                const h = this.state.canvas.style.height - ftrH - hOfs;
                                const clientX = ftrW + w / 2;
                                const clientY = hOfs + h / 2;
                                if ("number" === typeof msg[2]) {
                                    this.zoom({
                                        deltaY: -msg[2],
                                        clientX: clientX,
                                        clientY: clientY,
                                        currentTarget: this._canvas,
                                    });
                                }
                            }
                            break;
                    }
                }
                break;
            case "goto":
                {
                    if ("today" === msg[1]) {
                        const today = this.state.const.today;
                        if (today) {
                            const C = this.state.const;
                            if (this.state.grid && this.state.grid.grid.length > 0) {
                                const ftrRect = this._ftrComp ? this._ftrComp.getBoundingClientRect() : null;
                                const ftrW = ftrRect
                                    ? ftrRect.width
                                    : CanvasSizeHelper.getHeaderWidth(
                                          this.state.const,
                                          this.state.reactState.l_min,
                                          this.state.reactState.l_max,
                                      );
                                const ftrH = ftrRect ? ftrRect.height : 0;
                                const hOfs =
                                    this.state.const.colHeaderHeight + CONST.titleHeight + CONST.subtitleHeight;
                                const x1 = -this.state.reactState.left + ftrW / this.state.reactState.scale;
                                const y1 = -this.state.reactState.top + hOfs / this.state.reactState.scale;
                                const x2 =
                                    x1 +
                                    (this.state.canvas.style.width - ftrW - CONST.newSidebarWidth) /
                                        this.state.reactState.scale;
                                const y2 =
                                    y1 + (this.state.canvas.style.height - ftrH - hOfs) / this.state.reactState.scale;
                                const ty1 = 0;
                                const ty2 = this.state.reactState.height;
                                let left = this.state.reactState.left;
                                let top = this.state.reactState.top;
                                let col;
                                if (
                                    this.state.grid.grid[0].d0 <= today &&
                                    today < this.state.grid.grid[this.state.grid.grid.length - 1].d1 &&
                                    (col = this.state.grid.dateToGrid(today)) * C.colPx < this.state.reactState.width
                                ) {
                                    const tx = col * C.colPx;
                                    /*
                            const p=10;
                            if (tx-(p)<x1) {
                                left=-(tx-(p))+ftrW/this.state.reactState.scale;
                            } else if (tx+p>x2) {
                                left=-(tx+p-(x2-x1))+ftrW/this.state.reactState.scale;
                            }
                            */
                                    left = -tx + ftrW / this.state.reactState.scale + (x2 - x1) / 2;
                                } else {
                                    const tx1 = 0;
                                    const tx2 = this.state.reactState.width;
                                    if (tx2 < x1) {
                                        left = -(tx2 - (x2 - x1)) + ftrW / this.state.reactState.scale;
                                    } else if (tx1 > x2) {
                                        left = -tx1 + ftrW / this.state.reactState.scale;
                                    }
                                }
                                //const tx=Math.max(0, Math.min(this.state.reactState.width-(this.state.canvas.width-ftrW-CONST.newSidebarWidth)/this.state.reactState.scale, col*C.colPx));
                                if (ty2 < y1) {
                                    top = -(ty2 - (y2 - y1)) + hOfs / this.state.reactState.scale;
                                } else if (ty1 > y2) {
                                    top = -ty1 + hOfs / this.state.reactState.scale;
                                }
                                this.setState(
                                    { ...this.state, reactState: { ...this.state.reactState, left: left, top: top } },
                                    this._invalidateCanvas,
                                );
                            }
                        }
                    } else if (msg[1]?.pid) {
                        this.goToProcess(msg[1]?.pid, msg[1]?.scale);
                    }
                }
                break;
            case "sz":
                {
                    const sz = msg[1];
                    if (
                        (this._textarea.dataset.loading === "true" || "visible" === this._textarea.style.visibility) &&
                        (sz.text0 || "") === this._textarea.value
                    ) {
                        delete this._textarea.dataset.loading;
                        this._textarea.style.visibility = "visible";
                        if (this._textarea.value !== sz.lines) {
                            // adjust caret
                            const calc_lines = (text) =>
                                text.split("\n").reduce((ret, l) => {
                                    if (ret.length > 0) {
                                        ret.push(ret[ret.length - 1] + l.length);
                                    } else {
                                        ret.push(l.length);
                                    }
                                    return ret;
                                }, []);
                            const value_lines = calc_lines(this._textarea.value);
                            const sz_lines = calc_lines(sz.lines);
                            const delta = [];
                            for (let i = 0, j = 0; i < value_lines.length || j < sz_lines.length; ) {
                                if (i < value_lines.length && j < sz_lines.length) {
                                    if (value_lines[i] === sz_lines[j]) {
                                        i++;
                                        j++;
                                    } else if (value_lines[i] < sz_lines[j]) {
                                        delta.push(-value_lines[i++]);
                                    } else {
                                        delta.push(sz_lines[j++]);
                                    }
                                } else if (i < value_lines.length) {
                                    delta.push(-value_lines[i++]);
                                } else {
                                    delta.push(sz_lines[j++]);
                                }
                            }
                            const ofs = delta.reduce((ofs, delta) => {
                                if (Math.abs(delta) < this._textarea.selectionEnd) {
                                    if (delta < 0) {
                                        ofs--;
                                    } else if (delta > 0) {
                                        ofs++;
                                    } else {
                                        //WTF?
                                    }
                                }
                                return ofs;
                            }, this._textarea.selectionEnd);

                            this._textarea.value = sz.lines;
                            this._textarea.setSelectionRange(ofs, ofs);
                        }
                        if (
                            Number.parseInt(this._textarea.style.width, 10) === sz.w &&
                            Number.parseInt(this._textarea.style.height, 10) === sz.h
                        ) {
                            const font = CANVAS_FONT.font.replace("{sz}", sz._fs.toString());
                            //console.log(font);
                            this._textarea.style.paddingTop = ((sz.h - sz._h) / 2).toString(10) + "px";
                            this._textarea.style.lineHeight = sz.lh.toString(10) + "px";
                            this._textarea.style.font = font;
                        }
                        if (null === sz.text0) {
                            this._textarea.select();
                            //this._textarea.setSelectionRange(0, this._textarea.value.length);
                            //this._textarea.style.visibility="visible";
                            //this._textarea.focus();
                            console.log(
                                JSON.stringify({
                                    value: this._textarea.value,
                                    selectionStart: this._textarea.selectionStart,
                                    selectionEnd: this._textarea.selectionEnd,
                                    focus: document.activeElement.outerHTML,
                                }),
                            );
                        }
                    }
                }
                break;
            case "framework":
                {
                    switch (msg[1]) {
                        case "loaded":
                            if (
                                msg[2].storageName ===
                                ("wb" === this.props.mode
                                    ? this.props.active
                                    : this.props.worker.canvas.meta.session.sandbox)
                            ) {
                                this.setState(
                                    (state) => {
                                        const footerH = gfu2(msg[2].footerH, state.footerH);
                                        const ret = {
                                            ...state,
                                            reactState: { ...state.reactState, scale: 1 },
                                            footerH,
                                        };
                                        if (msg[2].view) {
                                            ret.reactState.left = msg[2].view.left;
                                            ret.reactState.top = msg[2].view.top;
                                            ret.reactState.scale = msg[2].view.scale;
                                        } else {
                                            this._zoomToAll(ret, ret.reactState.tasks);
                                        }
                                        return ret;
                                    },
                                    () => {
                                        if (msg[2].footer?.name) {
                                            this._setFooter(msg[2].footer.name, msg[2].footer.options);
                                        } else {
                                            this._setFooter(null);
                                        }
                                        this._invalidateCanvas();
                                    },
                                );
                            }
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }.bind(this);
    private _mouseCursorHandle: number = 0;

    private defineDependencyType(hitSrcTaskLeft: boolean, hitDstTaskLeft: boolean): LCMDContextDependencyType {
        if (hitSrcTaskLeft && hitDstTaskLeft) {
            return LCMDContextDependencyType.AA;
        }
        if (!hitSrcTaskLeft && hitDstTaskLeft) {
            return LCMDContextDependencyType.EA;
        }
        if (!hitSrcTaskLeft && !hitDstTaskLeft) {
            return LCMDContextDependencyType.EE;
        }
        if (hitSrcTaskLeft && !hitDstTaskLeft) {
            return LCMDContextDependencyType.AE;
        }
    }

    private _pivotTouchPoint: ClientPoint = null;
    private _prevTouchDistance: number = null;

    private _getDistanceBetweenPoints(point1: ClientPoint, point2: ClientPoint) {
        const differenceX = point2.clientX - point1.clientX;
        const differenceY = point2.clientY - point1.clientY;

        return Math.sqrt(differenceX * differenceX + differenceY * differenceY);
    }

    onTouchStart = function (this: CanvasHostBase, e: TouchEvent) {
        const { target, touches } = e;
        const isCanvas = target === this._div;

        if (!isCanvas || touches.length < 2) {
            return;
        }

        e.preventDefault();

        this._pivotTouchPoint = {
            clientX: (touches[0].clientX + touches[1].clientX) / 2,
            clientY: (touches[0].clientY + touches[1].clientY) / 2,
        };

        this._prevTouchDistance = this._getDistanceBetweenPoints(touches[0], touches[1]);
    }.bind(this);

    onTouchMove = function (this: CanvasHostBase, e: TouchEvent) {
        const { target, touches } = e;
        const isCanvas = target === this._div;

        if (!isCanvas || touches.length < 2) {
            return;
        }

        e.preventDefault();

        const currenTouchDistance = this._getDistanceBetweenPoints(touches[0], touches[1]);
        const distanceDifference = this._prevTouchDistance - currenTouchDistance;

        this.zoom(
            {
                deltaY: distanceDifference,
                currentTarget: target as HTMLCanvasElement,
                ...this._pivotTouchPoint,
            },
            true,
        );

        this._prevTouchDistance = currenTouchDistance;
    }.bind(this);

    onTouchEnd = function (this: CanvasHostBase, e: TouchEvent) {
        if (this._pivotTouchPoint) {
            e.preventDefault();
        }

        this._pivotTouchPoint = null;
        this._prevTouchDistance = null;
    }.bind(this);

    private onPointerDown = function (this: CanvasHostBase, ev: PointerEvent) {
        this._cancelPointerIfNeeded();
        this._pointer.last = null;
        const target = ev.target;
        if (target === this._div) {
            const C = this.state.const;
            if (C) {
                if (1 === ev.buttons) {
                    const mouse = {
                        x: ev.clientX / this.state.reactState.scale - this.state.reactState.left,
                        y: ev.clientY / this.state.reactState.scale - this.state.reactState.top,
                        screenX: ev.clientX,
                        screenY: ev.clientY,
                        offsetX: this.state.reactState.left,
                        offsetY: this.state.reactState.top,
                        scale: this.state.reactState.scale,
                    };
                    let clear = true;
                    let handled = false;
                    let handle;
                    if (
                        null !== (handle = this._renderer.isBBox(mouse, this.state.reactState.scale)) &&
                        !ev.ctrlKey &&
                        !ev.metaKey &&
                        !ev.shiftKey
                    ) {
                        this._renderer.hitTest(mouse, { handle });
                        const sel = this._renderer.getSel();
                        if (
                            this._renderer.hit.hitTask ||
                            (this._renderer.hit.hitStripe && this._renderer.hit.hitStripeHeader) ||
                            handle > 0
                        ) {
                            // if multiple processes are selected and a click happens on a single process
                            // then the pointer is set to true, so it will trigger the updateSelection on pointerUp
                            if (this._renderer.hit.hitTask && sel.length > 1) {
                                this._pointer.clear = true;
                            }

                            if (
                                this._renderer.hit.hitSrcTaskId === this._renderer.hit?.hitTask?.id &&
                                this._renderer.hit.hitTaskLeft !== null &&
                                sel.length <= 1
                            ) {
                                this._renderer.clearSelect();
                                this._updateSelection(ev);
                                this._renderer.hit.hitSrcTaskLeft = this._renderer.hit.hitTaskLeft;
                                this._pointer.clear = false;
                                this._invalidateCanvas();
                            } else if (this._renderer.hit.hitTaskLeft !== null && sel.length <= 1) {
                                this.addDependency(
                                    this._renderer.hit.hitSrcTaskId,
                                    this._renderer.hit.hitTask.id,
                                    this.defineDependencyType(
                                        this._renderer.hit.hitSrcTaskLeft,
                                        this._renderer.hit.hitTaskLeft,
                                    ),
                                );

                                this._renderer.clearSelect();
                            } else if (
                                this._renderer.hit.hitTask &&
                                this._renderer.hit.hitTask.id !== this._renderer.hit.hitSrcTaskId &&
                                sel.length <= 1
                            ) {
                                this._updateSelection(ev);
                            }

                            this.__setDragTarget({} as any, { ...this._renderer.hit }, ev, handle);
                            handled = true;
                        } else {
                            clear = false;
                        }
                    } else if (!ev.shiftKey && !this._pointer.forceMarquee) {
                        const sel = this._renderer.getSel();
                        this._renderer.hitTest(mouse, { ignoreSrcLeft: sel.length > 0 });
                        if (
                            (useCanvasStore.getState().showDependencies || this.props.mode === "wb") &&
                            this._renderer.hit?.hitTask &&
                            this._renderer.hit.hitTaskLeft !== null &&
                            this._renderer.hit.hitSrcTaskId !== null &&
                            this._renderer.hit.hitSrcTaskLeft !== null &&
                            this._renderer.hit.hitSrcTaskId !== this._renderer.hit.hitTask.id
                        ) {
                            this.addDependency(
                                this._renderer.hit.hitSrcTaskId,
                                this._renderer.hit.hitTask.id,
                                this.defineDependencyType(
                                    this._renderer.hit.hitSrcTaskLeft,
                                    this._renderer.hit.hitTaskLeft,
                                ),
                            );

                            this._renderer.clearSelect();
                            handled = true;
                        } else if (this._updateSelection(ev)) {
                            if (this._renderer.hit.hitStripe && !this._renderer.hit.hitTask) {
                                this._renderer.hit.hitSrcTaskId = null;
                                this._renderer.hit.hitSrcTaskLeft = null;
                            } else if (
                                this._renderer.hit.hitTask?.id &&
                                this._renderer.hit.hitSrcTaskId !== this._renderer.hit.hitTask.id &&
                                this._renderer.hit.hitTaskLeft !== null
                            ) {
                                this._renderer.hit.hitSrcTaskId = this._renderer.hit.hitTask.id;
                                this._renderer.hit.hitSrcTaskLeft = this._renderer.hit.hitTaskLeft;
                            } else if (
                                this._renderer.hit.hitTask.id === this._renderer.hit.hitSrcTaskId &&
                                this._renderer.hit.hitTaskLeft !== null
                            ) {
                                this._renderer.hit.hitSrcTaskLeft = this._renderer.hit.hitTaskLeft;
                            }

                            this.__setDragTarget({} as any, { ...this._renderer.hit }, ev);
                            handled = true;
                        }
                    }
                    if (!handled) {
                        this._pointer.down = ev.buttons;
                        this._pointer.clientX = ev.clientX;
                        this._pointer.clientY = ev.clientY;
                        this._pointer.left = this.state.reactState.left;
                        this._pointer.top = this.state.reactState.top;
                        this._pointer.clear = clear;
                        this._pointer.marquee = ev.shiftKey || this._pointer.forceMarquee;
                        if (this._pointer.marquee) {
                            this._renderer.clearSelect();
                            this._invalidateCanvas();
                        }
                    }

                    if (this._pointer.clear) {
                        this._renderer.hit.hitSrcTaskId = null;
                        this._renderer.hit.hitSrcTaskLeft = null;
                    }
                } else {
                    //@TODO pinch...
                }
            } /* if C, not ready yet otherwise */
        }
        if (target === this._hRule) {
            this._pointer.down = ev.buttons;
            this._pointer.clientX = ev.screenX;
            this._pointer.clientY = ev.screenY;
            this._pointer.hRule = target;
        } else {
            const id = (ev.target as HTMLDivElement)?.dataset?.dropId || (ev.target as HTMLDivElement)?.id || "";
            if (id.length >= 2 && "_" === id[0]) {
                if ("A" === id[1]) {
                    // trade template
                    const tradeId_1 = Number.parseInt(id.substring(2), 16);
                    if (tradeId_1 > 0) {
                        this.__setDragTarget(
                            {
                                ref: ev.target as HTMLDivElement,
                                target: DataOperationTarget.TRADE,
                                id: tradeId_1 - 1,
                            },
                            null,
                            ev,
                        );
                    }
                } else if ("L" === id[1]) {
                    // lib item
                    const libId_1 = Number.parseInt(id.substring(2), 16);
                    if (libId_1 > 0) {
                        this.__setDragTarget(
                            {
                                ref: ev.target as HTMLDivElement,
                                target: DataOperationTarget.MAX, //@TODO
                                id: libId_1 - 1,
                            },
                            null,
                            ev,
                        );
                    }
                } else if ("C" === id[1]) {
                    // clip item
                    const imageUrl = (ev.target as HTMLDivElement).dataset.image;
                    const image = new Image();
                    image.src = imageUrl;
                    const item = {
                        image,
                        w: 0,
                        h: 0,
                    };
                    image.onload = (ev) => {
                        item.w = image.naturalWidth;
                        item.h = image.naturalHeight;
                    };
                    this.__setDragTarget(
                        {
                            ref: ev.target as HTMLDivElement,
                            target: DataOperationTarget.MAX, //@TODO
                            item,
                        },
                        null,
                        ev,
                    );
                }
            }
        }
    }.bind(this);
    private onPointerUp = function (this: CanvasHostBase, ev: PointerEvent) {
        const target = ev.target;
        const C = this.state.const;
        if (this._pointer.hRule) {
            const fh = this.state.footer?.canvasState?.chart?.height || 0;
            const px = window?.devicePixelRatio || 1;
            const dy = (this._pointer.clientY - ev.screenY) / px;
            this._setFooterHeight(fh + dy);
            this._cancelPointerIfNeeded();
        } else if (C) {
            const isCanvasTarget = target === this._div;
            this._setLastPointer(isCanvasTarget ? ev : null);
            const drag = this.state.canvasState.drag;
            let drop: CanvasHostBaseTarget;
            if (isCanvasTarget && (drop = drag?.sel as CanvasHostBaseTarget)?.ref) {
                // droppped a target
                const rs = this.state.reactState;
                const cs = this.state.canvasState;
                const p = this._createPointerEvent(drag);
                const x = p.x - (drag.left || 0) - (true ? C.gpa.padding : 0);
                const y = p.y - (drag.top || 0) - (true ? C.gpa.padding + C.gpa.header.height : 0);
                const w = drag.width;
                const h = drag.height;
                let trade = null;
                let lib = null;
                if (DataOperationTarget.TRADE === drop.target) {
                    const i_trade = this.props.worker.canvas.trades.findIndex((trade) => trade.id === drop.id);
                    const _trade = i_trade >= 0 ? this.props.worker.canvas.trades[i_trade] : null;
                    trade =
                        _trade && "number" === typeof _trade.id
                            ? {
                                  ref: this.props.worker.canvas.meta.session.sandbox,
                                  target: DataOperationTarget.TRADE,
                                  id: _trade.id,
                                  name: _trade.name,
                                  color: _trade.color,
                              }
                            : null;
                } else if (DataOperationTarget.MAX === drop.target) {
                    // library item: TODO
                    const i_lib = this.props.worker.wb.stripes.findIndex((stripe) => stripe.t === drop.id);
                    const _lib = i_lib >= 0 ? this.props.worker.wb.stripes[i_lib] : null;
                    lib =
                        _lib && "number" === typeof _lib.t
                            ? {
                                  ref: this.props.worker.canvas.wb.active,
                                  target: DataOperationTarget.TASKS,
                                  id: _lib.t,
                              }
                            : null;
                }
                this._renderer.hitTest(p, { ignoreSrcLeft: true });
                const dropInfo: CanvasHostRendererDropInfo = {
                    trade: trade,
                    lib: lib,
                    x: this._renderer.hit.hitStripe ? this._renderer.hit.hitStripeGridDate : x,
                    y: this._renderer.hit.hitStripe ? this._renderer.hit.hitStripeGridY : y,
                    w: this._renderer.hit.hitStripe ? 1 : w,
                    h: this._renderer.hit.hitStripe ? 1 : h,
                    pid: this._renderer.hit.hitStripe ? this._renderer.hit.hitStripe.t : undefined,
                };
                this.onDropEnd(this._renderer as any as CanvasHostRendererCtx, p, dropInfo, drop.ref);
            } else if (drag) {
                if (this._renderer.eventHitTest) {
                    const p = this._createPointerEvent(drag);
                    this._renderer.hitTest(p, { ignoreSelected: true, ignoreSrcLeft: true });
                }
                const dragInfo = this._renderer.dragEnd(true);
                this._invalidateCanvas();
                this.onDragEnd(this._renderer as any as CanvasHostRendererCtx, dragInfo);
            } else if (this.state.canvasState.marquee) {
                this._renderer.marqueeSelect(this.state.canvasState.marquee);
                this._invalidateCanvas();
                this._pointer.clear = false;
            }
            const clear = this._pointer.clear;
            this._cancelPointerIfNeeded();
            if (clear) {
                const mouse = {
                    x: ev.clientX / this.state.reactState.scale - this.state.reactState.left,
                    y: ev.clientY / this.state.reactState.scale - this.state.reactState.top,
                    screenX: ev.clientX,
                    screenY: ev.clientY,
                    offsetX: this.state.reactState.left,
                    offsetY: this.state.reactState.top,
                    scale: this.state.reactState.scale,
                };
                this.__setDragTarget(null);
                this._renderer.hitTest(mouse, { ignoreSrcLeft: true });
                this.onClick(mouse);
                this._updateSelection(ev);
            }
            if (clear || isCanvasTarget) {
                const selected = this._renderer.getSel().map((sel) => sel.id);
                this.props.worker.dispatchMessage(["taskex", { selected, isWhiteboard: "wb" === this.props.mode }]);
            }
            this._updateMouseCursor();

            // check if over deps lines
            if (
                (useCanvasStore.getState().showDependencies || this.props.mode === "wb") &&
                this.state.reactState.scale > 0.1
            ) {
                let stop = false;
                const rs = this.state.reactState;
                for (let i = 0; i < this._renderer.tasks.length; i++) {
                    if (stop) {
                        break;
                    }
                    const t = this._renderer.tasks[i];
                    if (
                        !this.isTaskVisible(t) || // skip hit detection if task not visible
                        (useCanvasStore.getState().showConflictMode &&
                            !useCanvasStore.getState().dependencyChain.includes(t.id)) // skip hit detection if in conflict mode and task is not part of dep chain
                    ) {
                        continue;
                    }

                    const succTasks = this._renderer.getSuccessor(t);

                    for (let j = 0; j < succTasks.length; j++) {
                        if (stop) {
                            break;
                        }
                        const [targetTask, dependencyType] = succTasks[j];
                        const x1Offset =
                            dependencyType === LCMDContextDependencyType.AA
                                ? 0
                                : dependencyType === LCMDContextDependencyType.AE
                                  ? 0
                                  : t.__w;
                        const x2Offset =
                            dependencyType === LCMDContextDependencyType.AE
                                ? targetTask.__w
                                : dependencyType === LCMDContextDependencyType.EE
                                  ? targetTask.__w
                                  : 0;

                        const x1 = (t.__x + rs.left + x1Offset) * rs.scale;
                        const y1 = (t.__y + t.__h / 2 + rs.top) * rs.scale;
                        const x2 = (targetTask.__x + rs.left + x2Offset) * rs.scale;
                        const y2 = (targetTask.__y + targetTask.__h / 2 + rs.top) * rs.scale;

                        //used for tracking if the pointerup was near the dependency line
                        if (isPointNearLine(ev.clientX, ev.clientY, x1, y1, x2, y2, 5)) {
                            this._renderer.hit.hitDep = [t.id, targetTask.id];
                            stop = true;
                            this._renderer.clearSelect();
                            this._invalidateCanvas();
                        }
                    }

                    const predTasks = this._renderer.getPredecessor(t);
                    for (let j = 0; j < predTasks.length; j++) {
                        if (stop) {
                            break;
                        }
                        const [targetTask, dependencyType] = predTasks[j];
                        const x1Offset =
                            dependencyType === LCMDContextDependencyType.AA
                                ? 0
                                : dependencyType === LCMDContextDependencyType.EA
                                  ? 0
                                  : t.__w;

                        const x2Offset =
                            dependencyType === LCMDContextDependencyType.EA
                                ? targetTask.__w
                                : dependencyType === LCMDContextDependencyType.EE
                                  ? targetTask.__w
                                  : 0;

                        const x1 = (t.__x + rs.left + x1Offset) * rs.scale;
                        const y1 = (t.__y + t.__h / 2 + rs.top) * rs.scale;
                        const x2 = (targetTask.__x + rs.left + x2Offset) * rs.scale;
                        const y2 = (targetTask.__y + targetTask.__h / 2 + rs.top) * rs.scale;

                        if (isPointNearLine(ev.clientX, ev.clientY, x1, y1, x2, y2, 5)) {
                            this._renderer.hit.hitDep = [targetTask.id, t.id];
                            stop = true;
                            this._renderer.clearSelect();
                            this._invalidateCanvas();
                        }
                    }
                }
            }
        }
    }.bind(this);
    private onPointerCancel = function (this: CanvasHostBase, ev: PointerEvent) {
        this._cancelPointerIfNeeded();
    }.bind(this);
    private onPointerMove = function (this: CanvasHostBase, ev: PointerEvent) {
        if (this._pivotTouchPoint) {
            return;
        }
        const target = ev.target;
        if (this._pointer.hRule) {
            const fh = this.state.footer?.canvasState?.chart?.height || 0;
            const px = window?.devicePixelRatio || 1;
            const dy = (this._pointer.clientY - ev.screenY) / px;
            this._pointer.hRule.style.bottom = fh + dy + "px";
        } else {
            const isCanvasTarget = target === this._div;
            this._setLastPointer(isCanvasTarget ? ev : null);
            /*
            if (true || "wb"===this.props.mode) { // only broadcast position in whiteboard...
                this.props.worker.postMessage(["rt", {
                    mode: this.props.mode,
                    x: this._pointer.last?.x||null,
                    y: this._pointer.last?.y||null,
                    n: (this.props.worker.auth as any)?.auth_result?.email||""
                }]);
            }
            */
            if (this._pointer.drag) {
                const C = this.state.const;
                if (target === this._div && this._pointer.drag.overlay && C) {
                    // dragged over canvas
                    this._hideOverlay();
                    this._pointer.drag.overlay = false;
                    const width = this.state.const.colPx;
                    const height = this.state.const.rowPx;

                    this._pointer.drag.left = (this._pointer.drag.left / this._pointer.drag.width) * width;
                    this._pointer.drag.top = (this._pointer.drag.top / this._pointer.drag.height) * height;
                    this._pointer.drag.width = width;
                    this._pointer.drag.height = height;

                    /*
                    let width=0;
                    let height=0;
                    switch(this._pointer.drag.info.target) {
                        case DataOperationTarget.MAX: //@TODO
                            if (this._pointer.drag.info.item) {
                                width=this._pointer.drag.info.item.w;
                                height=this._pointer.drag.info.item.h;
                            } else {
                                width=800;
                                height=200;
                            }
                        break;
                        case DataOperationTarget.TRADE: //@TODO
                            width=C.rowPx-C.gpa.padding;
                            height=C.colPx-C.gpa.padding;
                        break;
                        default:
                            assert(false); //@TODO
                        break;
                    }
                    this._pointer.drag.left=(this._pointer.drag.left/this._pointer.drag.width)*width;
                    this._pointer.drag.top=(this._pointer.drag.top/this._pointer.drag.height)*height;
                    this._pointer.drag.width=width;
                    this._pointer.drag.height=height;
                    */
                }
                if (this._pointer.drag.overlay) {
                    if (this._overlay) {
                        this._overlay.style.left = ev.clientX - this._pointer.drag.left + "px";
                        this._overlay.style.top = ev.clientY - this._pointer.drag.top + "px";
                    }
                } else {
                    const dx = ev.clientX - this._pointer.clientX;
                    const dy = ev.clientY - this._pointer.clientY;
                    const rs = this.state.reactState;
                    this._pointer.drag.clientX = ev.clientX;
                    this._pointer.drag.clientY = ev.clientY;
                    const left = ev.clientX / rs.scale - rs.left;
                    const top = ev.clientY / rs.scale - rs.top;
                    if (this._renderer.eventHitTest) {
                        this._renderer.hitTest(
                            {
                                x: left,
                                y: top,
                                screenX: ev.clientX,
                                screenY: ev.clientY,
                                offsetX: this.state.reactState.left,
                                offsetY: this.state.reactState.top,
                                scale: this.state.reactState.scale,
                            },
                            { ignoreSrcLeft: true },
                        );
                        //console.log(JSON.stringify({x: this._renderer.hit.hitStripeX, y: this._renderer.hit.hitStripeY}));
                        if (null === this.state.canvasState.drag) {
                            this.onDragStart(this._renderer as any as CanvasHostRendererCtx, this._pointer.drag.hit);
                        }
                        this.onDragging(
                            this._renderer as any as CanvasHostRendererCtx,
                            this._renderer.hit,
                            this._pointer.drag.hit,
                        );
                    } else {
                        if (null === this.state.canvasState.drag) {
                            this.onDragStart(this._renderer as any as CanvasHostRendererCtx, null);
                        }
                    }
                    const _dx = left - this._pointer.drag.left;
                    const _dy = top - this._pointer.drag.top;
                    this._renderer.dragSel(
                        this._pointer.drag.handle,
                        _dx,
                        _dy,
                        this._pointer.drag.hit,
                        null === this.state.canvasState.drag,
                    );
                    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
                        this._pointer.clear = false; // we moved the canvas.. keep the selection alive on pointerup
                    }
                    this.state.canvasState.drag = this._pointer.drag;
                    this._invalidateCanvas();
                }
            } else if (target === this._div) {
                if (1 === this._pointer.down && ev.buttons === this._pointer.down) {
                    assert(
                        null !== this._pointer.left &&
                            null !== this._pointer.top &&
                            null !== this._pointer.clientX &&
                            null !== this._pointer.clientY,
                    );
                    const dx = ev.clientX - this._pointer.clientX;
                    const dy = ev.clientY - this._pointer.clientY;
                    if (Math.abs(dx) > 1 || Math.abs(dy) > 1) {
                        this._pointer.clear = false; // we moved the canvas.. keep the selection alive on pointerup
                    }

                    if (this._pointer.marquee) {
                        const rs = this.state.reactState;
                        const x1 = this._pointer.clientX / rs.scale - rs.left;
                        const y1 = this._pointer.clientY / rs.scale - rs.top;
                        const x2 = ev.clientX / rs.scale - rs.left;
                        const y2 = ev.clientY / rs.scale - rs.top;
                        this.state.canvasState.marquee = {
                            x: x1,
                            y: y1,
                            w: x2 - x1,
                            h: y2 - y1,
                        };
                        this._invalidateCanvas();
                    } else {
                        this.setState(
                            {
                                ...this.state,
                                reactState: {
                                    ...this.state.reactState,
                                    left: this._pointer.left + dx / this.state.reactState.scale,
                                    top: this._pointer.top + dy / this.state.reactState.scale,
                                },
                            },
                            this._invalidateCanvas,
                        );
                        this._savePositionSessionState();
                    }
                } else if (0 === this._pointer.down) {
                    const handle = this._renderer.isBBox(
                        {
                            x: ev.clientX / this.state.reactState.scale - this.state.reactState.left,
                            y: ev.clientY / this.state.reactState.scale - this.state.reactState.top,
                        },
                        this.state.reactState.scale,
                    );

                    if (this.state.const) {
                        //Don't know if that's smart, because it will spam a lot of CPU usage if many tasks in process
                        const prevHitTest = Boolean(this._renderer.hit.hitTask);
                        this._renderer.hitTest(
                            {
                                x: ev.clientX / this.state.reactState.scale - this.state.reactState.left,
                                y: ev.clientY / this.state.reactState.scale - this.state.reactState.top,
                                screenX: ev.clientX,
                                screenY: ev.clientY,
                                offsetX: this.state.reactState.left,
                                offsetY: this.state.reactState.top,
                                scale: this.state.reactState.scale,
                            },
                            { keepDepSelection: true, ignoreSrcLeft: true },
                        );

                        if (this._renderer.hit.hitTask || prevHitTest) {
                            // i dont think thats a good idea!!!! Will break a lot of stuff?
                            this.onRenderCanvas();
                        }
                        // trigger on hover process for UI
                        if (this._renderer.hit.hitTask) {
                            const scale = this.state.reactState.scale;
                            this.onHoverProcess(
                                this._renderer.hit.hitTask,
                                {
                                    mouse: {
                                        x: ev.clientX,
                                        y: ev.clientY,
                                    },
                                    task: {
                                        x: (this.state.reactState.left + this._renderer.hit.hitTask.__x) * scale,
                                        y: (this.state.reactState.top + this._renderer.hit.hitTask.__y) * scale,
                                    },
                                },
                                this.state.reactState.scale,
                                this.state.fs < 0,
                            );
                        } else if (prevHitTest && !this._renderer.hit.hitTask) {
                            this.onLeaveProcess();
                        }
                    }

                    this._updateMouseCursor(handle);
                }
            }
        }
    }.bind(this);

    private onViewChange = function (
        this: CanvasHostBase,
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption<any>,
        index?: number,
    ) {
        const trackingNames = ["weekly-new", "daily-new"];
        if ("number" === typeof index) {
            WebAppTelemetryFactory.trackEvent("processplan-view-change", {
                view: trackingNames[index],
            });
            this.props.worker.postMessage(["view", "select", index]);
            useUserJourneyStore.getState().actions.setScaleSettings(index);
        }
    }.bind(this);
    private onGotoToday = function (this: CanvasHostBase) {
        this.props.worker.dispatchMessage(["goto", "today"]);
    }.bind(this);

    private _updatingView = false;
    private _updatingViewTimer = null;
    public stupidBox = function (this: CanvasHostBase) {
        // const selectedScale = useUserJourneyStore.getState().scaleSettings;
        // console.log('selectedScale', selectedScale);
        // let index;
        // if (selectedScale === viewNames[0]) {
        //     index = 0;
        // } else {
        //     index = 1;
        // }
        // FIXME: this cause problems
        // this.props.worker.postMessage(["view", "select", index]);

        const VIEWS = React.useMemo(
            () =>
                CONST.views.map((view) => ({
                    key: view.label,
                    text: intl.get(["canvas", "views", view.label].join(".")),
                })),
            [],
        );
        const C = this.state.const;
        if (C) {
            const rs = this.state.reactState;
            const sidebarWidth =
                (rs.l_max - rs.l_min) * CONST.sidebarColWidth +
                CONST.menuCondensedWidth +
                CONST.sidebarColExtra +
                C.sidebarColImage;
            return (
                <div
                    className={classNames.stupidBox}
                    style={{
                        width: sidebarWidth,
                        height: C.colHeaderHeight,
                    }}
                >
                    <div className={classNames.stupidBoxItem}>
                        <Dropdown
                            // selectedKey={VIEWS[index].key}
                            selectedKey={C?.label || null}
                            options={VIEWS}
                            onChange={this.onViewChange}
                            styles={{
                                root: {
                                    width: "100px",
                                    maxWidth: CONST.legacyCanvasSidebarWidth,
                                },
                            }}
                            data-userflow-id="pp-zeitachse"
                        />
                        <IconButton
                            iconProps={{
                                iconName: "GotoToday",
                                title: intl.get("fw.tooltip.today"),
                            }}
                            styles={{
                                root: {
                                    color: "black",
                                },
                            }}
                            onClick={this.onGotoToday}
                            data-userflow-id="pp-heutiges-datum"
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }.bind(this);
    private alertBox = function (this: CanvasHostBase, props: {}) {
        return (
            <MessageBar
                styles={alertStyle}
                delayedRender={false}
                messageBarType={MessageBarType.warning}
                isMultiline={false}
                onDismiss={() => {
                    this.setState({
                        gpaDirty: false,
                    });
                }}
                dismissButtonAriaLabel="Close"
                actions={
                    <div>
                        <MessageBarButton
                            onClick={() => {
                                this.setState({
                                    gpaDirty: false,
                                });
                            }}
                        >
                            Sync
                        </MessageBarButton>
                    </div>
                }
            >
                Sync Whiteboard changes into PP?
            </MessageBar>
        );
    }.bind(this);
    private onReconnect = function (this: CanvasHostBase) {
        this.setState(
            {
                connectionState: null,
            },
            () => {
                this.props.worker.postMessage(["reconnect", {}]);
            },
        );
    }.bind(this);
    private legendBox = function (
        this: CanvasHostBase,
        props: { mode: string; viewConst: CanvasViewConst; bottom: number },
    ) {
        const CustomLegend = this.props.worker.config.Legend;
        const details: any = props.viewConst?.details || null;
        const customLegend = React.useMemo(
            () => <CustomLegend mode={props.mode as any} details={details} />,
            [CustomLegend, props.mode, details],
        );
        return customLegend ? (
            <div
                className={classNames.legend}
                style={{
                    bottom: props.bottom,
                }}
            >
                {customLegend}
            </div>
        ) : null;
    }.bind(this);
    private _refHRule = function (this: CanvasHostBase, r: HTMLDivElement) {
        this._hRule = r;
    }.bind(this);
    private hRule = function (this: CanvasHostBase, props: { bottom: number }) {
        return (
            <div
                ref={this._refHRule}
                className={classNames.hRule}
                style={{
                    bottom: props.bottom,
                }}
            />
        );
    }.bind(this);

    /*
    private _hitRectXYWH(m:{x, y}, {x, y, w, h}) {
        return this._hitRect(m, {
            x1: x,
            y1: y,
            x2: x+w,
            y2: y+h
        });
    }

    private _hitRect({x, y}, {x1, y1, x2, y2}) {
        return x1<=x && x<x2 && y1<=y && y<=y2;
    }

    private _hitHandles(mouse:{x:number, y:number}, {x, y, w, h}) {
        const C=this.state.const;
        const d=C.gpa.selected.handle.width;
        if (this._hitRect(mouse, { x1: x+w-d, y1: y+h-d, x2: x+w+d, y2: y+h+d })) { // right/bottom
            return 4;
        } else if (this._hitRect(mouse, { x1: x+w-d, y1: y-d, x2: x+w+d, y2: y+d })) { // right/top
            return 2;
        } else if (this._hitRect(mouse, { x1: x-d, y1: y+h-d, x2: x+d, y2: y+h+d })) { // left/bottom
            return 3;
        } else if (this._hitRect(mouse, { x1: x-d, y1: y-d, x2: x+d, y2: y+d })) { // left/top
            return 1;
        } else {
            return 0;
        }
    }

    private _hitStripes(pos:{ x , y}, full?:boolean):any {
        const C=this.state.const;
        const stripes=this.state.reactState.stripes;
        const n_stripes=stripes.length;
        const helper={x1:0, y1:0, x2:0,  y2:0};
        for(let i_stripe=n_stripes;i_stripe>0;) { i_stripe--;
            const stripe=stripes[i_stripe];
            helper.x1=stripe.x;
            helper.y1=stripe.y;
            helper.x2=stripe.x+stripe.w;
            helper.y2=stripe.y+C.gpa.header.height+(full?stripe.h:0);
            let hit=this._hitRect(pos, helper);
            if (hit) {
                return stripe;
            }
        }
        return null;
    }


    private _hitTasks(pos:{ x , y}, rect?:{x:number, y:number, w:number, h:number, ref:any}):number|null {
        const C=this.state.const;
        const tasks=this.state.reactState.tasks;
        const n_tasks=tasks.length;
        const stripes=this.state.reactState.stripes;
        const n_stripes=stripes.length;
        const helper={x1:0, y1:0, x2:0,  y2:0};
        for(let i_task=n_tasks;i_task>0;) { i_task--; //@TODO speed up be skipping stripes...
            const task:CanvasTaskData=tasks[i_task];
            assert(task && 0<=task.stripe && task.stripe<n_stripes);
            const stripe:CanvasStripeData=stripes[task.stripe];
            const e0=(task.stripe>0?stripes[task.stripe-1].e:0);
            helper.x1=stripe.x+task.left+C.gpa.padding;
            helper.x2=stripe.x+task.right-C.gpa.padding;
            const y=(stripe.y-(e0*C.rowPx))+task.top+C.gpa.padding+C.gpa.header.height;
            const h=C.rowPx-C.gpa.padding-C.gpa.padding;
            helper.y1=y;
            helper.y2=y+h;
            let hit=this._hitRect(pos, helper);
            if (hit) {
                if (rect) {
                    rect.x=helper.x1;
                    rect.y=y;
                    rect.w=helper.x2-helper.x1;
                    rect.h=h;
                    rect.ref=task;
                }
                return task.id;
            }
        }
        return null;
    }

    private _hitTest(this:CanvasHostBase, pos:{ x , y}):CanvasHostBaseSelection {
        let ret:CanvasHostBaseSelection=null;
        const helper={x:null, y:null, w:null, h:null, ref:null};
        const stripe:CanvasStripeData=this._hitStripes(pos);
        if (stripe) {
            ret={
                group: [-stripe.t],
                sentinel: stripe.t,
                target: DataOperationTarget.TASKS,
                stripes: this.state.reactState.stripes,
                info: {
                    x: stripe.x,
                    y: stripe.y,
                    w: stripe.w,
                    h: stripe.h,
                    ref: {
                        stripe:stripe
                    }
                }
            }
       }
       const tid:number=this._hitTasks(pos, helper);
       if (null!==tid) {
            ret={
                group: [tid],
                sentinel: tid,
                target: DataOperationTarget.TASKS,
                stripes: null,
                info: {
                    x: helper.x,
                    y: helper.y,
                    w: helper.w,
                    h: helper.h,
                    ref: {
                        task:helper.ref
                    }
                }
            }
       }
       if ("development"===process.env.NODE_ENV && null!==ret) {
          const info=this._getBoundingRect(ret.target, ret.sentinel);
          assert(info.x===ret.info.x &&
                 info.y===ret.info.y &&
                 info.w===ret.info.w &&
                 info.h===ret.info.h &&
                 (info.ref as CanvasHostBaseStripeRef).stripe===(ret.info.ref as CanvasHostBaseStripeRef).stripe &&
                 (info.ref as CanvasHostBaseTaskRef).task===(ret.info.ref as CanvasHostBaseTaskRef).task &&
                 (info.ref as CanvasHostBaseShapeRef).shape===(ret.info.ref as CanvasHostBaseShapeRef).shape);
       }
       return ret;
    }
    */
    private onFtrScroll = function (ev) {
        this._invalidateCanvas();
    }.bind(this);
    private _cache = {};

    /*
     */
    protected constructor(
        props: T,
        opt: {
            zOrderStripes: boolean;
            xGroupTasks: boolean;
            eventHitTest: boolean;
        },
    ) {
        super(props);
        this._renderer = new CanvasHostRenderer(opt);
    }

    private isTaskVisible(task: CanvasTaskData & any): boolean {
        const rs = this.state.reactState;
        const h = this.state.canvas.style.height / rs.scale;
        const w = this.state.canvas.style.width / rs.scale;
        const dx = -1 === task.__z ? 0 : 0;
        const dy = -1 === task.__z ? 0 : 0;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;
        const tw = task.__w - p - p;
        const th = task.__h - p - p;
        return l > -rs.left && l + tw < -rs.left + w && t > -rs.top && t + th < -rs.top + h;
        // this._renderer.render(ctx, rs.scale, -rs.left, -rs.top, w / rs.scale, h / rs.scale);
    }

    private _positionState = null;
    private _skipZoomToAll = false;
    private _restoreSessionPositionAfterZoomToAll = false;

    private _savePositionState(): void {
        this._positionState = {
            scale: this.state.reactState.scale,
            left: this.state.reactState.left,
            top: this.state.reactState.top,
        };
    }

    private _restorePositionState(): void {
        this.setState({
            ...this.state,
            reactState: {
                ...this.state.reactState,
                scale: this._positionState.scale,
                left: this._positionState.left,
                top: this._positionState.top,
            },
        });

        this._positionState = null;
    }

    private _checkFocusMode(state: CanvasStore, prevState: CanvasStore): void {
        if (state.isFocusMode && !prevState.isFocusMode) {
            this._savePositionState();
        } else if (!state.isFocusMode && prevState.isFocusMode) {
            this._skipZoomToAll = true;
            this._restorePositionState();
        }
    }

    private _restoreSessionPosition(): void {
        const { position, splitView, contentType } = useUserJourneyStore.getState();

        if (position) {
            this.setState({
                ...this.state,
                reactState: {
                    ...this.state.reactState,
                    ...position,
                },
            });
            this._invalidateCanvas();
        }
    }

    private _restoreSessionSplitViewCalls = 0;
    protected _restoreSessionSplitView(): void {
        const { splitView, contentType } = useUserJourneyStore.getState();
        this._restoreSessionSplitViewCalls++;
        if (splitView) {
            this._setFooter(contentTypeMap[contentType]);

            if (this._restoreSessionSplitViewCalls > 3) {
                this._restoreSessionSplitView = () => {};
            }
        }
    }

    private _savePositionTimer = null;
    private _savePositionSessionState(): void {
        if (this._savePositionTimer !== null) {
            return;
        }

        clearTimeout(this._savePositionTimer);
        this._savePositionTimer = setTimeout(() => {
            useUserJourneyStore.getState().actions.setPosition({
                left: this.state.reactState.left,
                top: this.state.reactState.top,
                scale: this.state.reactState.scale,
            });
            this._savePositionTimer = null;
        }, 300);
    }

    componentDidMount(this: CanvasHostBase) {
        this._canvasStoreSubscription = useCanvasStore.subscribe((state, prevState) => {
            if (
                state.showConflictMode !== prevState.showConflictMode ||
                state.showDependencies !== prevState.showDependencies
            ) {
                this._invalidateCanvas();
            }

            this._checkFocusMode(state, prevState);
        });
        this.props.worker.registerHandler(this.onWorkerMsg);
        window.addEventListener("wheel", this._onWheel);
        document.addEventListener("wheel", this._onWheel, detectIt.supportsPassiveEvents ? { passive: false } : false);
        document.body.addEventListener(
            "wheel",
            this._onWheel,
            detectIt.supportsPassiveEvents ? { passive: false } : false,
        );
        this._div.addEventListener("wheel", this.onWheel);
        window.addEventListener("pointerdown", this.onPointerDown);
        window.addEventListener("pointerup", this.onPointerUp);
        window.addEventListener("pointermove", this.onPointerMove);
        window.addEventListener("pointercancel", this.onPointerCancel);
        window.addEventListener("dblclick", this.onDblClick);
        window.addEventListener("touchstart", this.onTouchStart);
        window.addEventListener("touchmove", this.onTouchMove);
        window.addEventListener("touchend", this.onTouchEnd);
        window.addEventListener("touchcancel", this.onTouchEnd);
        document.body.addEventListener("keydown", this.onKeyDown);
        document.body.addEventListener("paste", this.onClipBoardPaste);
        if ("tz" === this.props.mode) {
            assert(null === this.wbs && null === this._wbsCtx); // already set? why?
            this._wbsCtx = {
                onInit: (msg) => {
                    this.setState(
                        (state) => {
                            const ret = { ...state, reactState: { ...state.reactState, scale: 1 } };
                            if (msg.view) {
                                ret.reactState.left = msg.view.left;
                                ret.reactState.top = msg.view.top;
                                ret.reactState.scale = msg.view.scale;
                            } else {
                                console.log("zoomToAll");
                                this._zoomToAll(ret, ret.reactState.tasks);
                            }
                            return ret;
                        },
                        () => {
                            this._invalidateCanvas();
                        },
                    );
                },
                onUpdate: this._onProcessViewUpdate.bind(this),
            };
            this.props.worker.postMessage([
                "processview",
                "init",
                { stripes: true, intl: { Project: intl.get("fw.Project") } },
            ]);
        } else if ("wb" === this.props.mode) {
            this.props.worker.postMessage(["wb", "canvas", { mode: this.props.mode, storageName: this.props.active }]);
        } else if ("canvas" === this.props.mode) {
            this.props.worker.postMessage([
                "wb",
                "canvas",
                { mode: this.props.mode, storageName: this.props.worker.canvas.meta.session.sandbox },
            ]);
            this._restoreSessionPositionAfterZoomToAll = true;
            //this.props.worker.postMessage(["canvas", {}]);
        } else {
            assert(false); //handle me
        }
        assert(null === this._timerId);
        this._timerCtx.rt.mode = this.props.mode;
        this._timerCtx.rt.n = (this.props.worker.auth as any)?.auth_result?.email || "";
        this._timerId = setInterval(this._timerCB, 200);
        this._pointer.rt.mode = this.props.mode;
        assert(null !== this._timerId);
    }

    componentWillUnmount(this: CanvasHostBase) {
        if (this._canvasStoreSubscription) {
            this._canvasStoreSubscription();
            this._canvasStoreSubscription = null;
        }
        if (null !== this._timerId) {
            clearInterval(this._timerId);
            this._timerId = null;
        }
        if ("tz" === this.props.mode) {
            this.props.worker.postMessage([
                "processview",
                "cleanup",
                {
                    id: this.wbs?.id,
                },
            ]);
            this.wbs = null;
            this._wbsCtx = null;
        } else if ("wb" == this.props.mode || "canvas" === this.props.mode) {
            this.props.worker.postMessage([
                "wb",
                "unmount",
                {
                    mode: this.props.mode,
                    storageName:
                        "wb" === this.props.mode ? this.props.active : this.props.worker.canvas.meta.session.sandbox,
                    view: {
                        left: this.state.reactState.left,
                        top: this.state.reactState.top,
                        scale: this.state.reactState.scale,
                    },
                    footer: this.state.footer?.name
                        ? {
                              name: this.state.footer.name,
                              options: this._pendingView.options,
                          }
                        : undefined,
                    footerH: this.state.footerH,
                },
            ]);

            if (this.props.mode === "canvas") {
                // this._savePositionSessionState();
                clearTimeout(this._savePositionTimer);
            }
        } else {
            assert(false); // handle me!
        }
        this._setFooter(null);
        document.body.removeEventListener("paste", this.onClipBoardPaste);
        document.body.removeEventListener("keyup", this.onKeyDown);
        window.removeEventListener("dblclick", this.onDblClick);
        window.removeEventListener("pointercancel", this.onPointerCancel);
        window.removeEventListener("pointermove", this.onPointerMove);
        window.removeEventListener("pointerup", this.onPointerUp);
        window.removeEventListener("pointerdown", this.onPointerDown);
        window.removeEventListener("touchstart", this.onTouchStart);
        window.removeEventListener("touchmove", this.onTouchMove);
        window.removeEventListener("touchend", this.onTouchEnd);
        window.removeEventListener("touchcancel", this.onTouchEnd);

        this._div.removeEventListener("wheel", this.onWheel);
        document.body.removeEventListener("wheel", this._onWheel);
        document.removeEventListener("wheel", this._onWheel);
        window.removeEventListener("wheel", this._onWheel);
        this.props.worker.unregisterHandler(this.onWorkerMsg);
        if (this._overlay) {
            this._overlay.remove();
        }
    }

    // componentDidUpdate(prevProps): void {
    //     console.log('componentDidUpdate', this.state.loaded, prevProps.loaded, this.props.loaded);
    //     // if (!this.state.loaded && this.props.loaded) {
    //         // this.setState({loaded: this.props.loaded})
    //     // } else if (this.state.loaded) {
    //         this._restoreSessionState();
    //     // }
    // }

    /** @internal */
    onViewChangeEvent(state, { event, tasks }) {
        if (0 === state.reactState.scale) {
            // no nothing
        } else if (event.filter || event.filter0) {
            this._zoomToAll(state, tasks, event.filter?.date?.startEnd);
        } else if (event.view && event.view0) {
            const headerHeight = CONST.titleHeight + CONST.subtitleHeight + state.const.colHeaderHeight;
            const sidebarWidth0 =
                (state.reactState.l_max - state.reactState.l_min) * CONST.sidebarColWidth +
                CONST.menuCondensedWidth +
                CONST.sidebarColExtra +
                event.view0.sidebarColImage;
            const sidebarWidth =
                (state.reactState.l_max - state.reactState.l_min) * CONST.sidebarColWidth +
                CONST.menuCondensedWidth +
                CONST.sidebarColExtra +
                event.view.sidebarColImage;
            const top0 = state.reactState.top * state.reactState.scale;
            state.reactState.left =
                ((state.reactState.left - sidebarWidth0 / state.reactState.scale) * event.view.colPx) /
                    event.view0.colPx +
                sidebarWidth / state.reactState.scale;
            state.reactState.top = (state.reactState.top * event.view.rowPx) / event.view0.rowPx;
            if (
                "canvas" === this.props.mode &&
                Array.isArray(event.stripes) &&
                Array.isArray(event.stripes0) &&
                top0 < headerHeight
            ) {
                const rowOfs0 = -(top0 - headerHeight) / (event.view0.rowPx * state.reactState.scale);
                let stripeOfs = 0;
                while (
                    stripeOfs < event.stripes0.length &&
                    stripeOfs < event.stripes.length &&
                    event.stripes0[stripeOfs].t === event.stripes0[stripeOfs].t &&
                    event.stripes0[stripeOfs].e <= rowOfs0
                )
                    stripeOfs++;
                const rows0 = stripeOfs > 0 ? event.stripes0[stripeOfs - 1].e : 0;
                const rows1 = stripeOfs > 0 ? event.stripes[stripeOfs - 1].e : 0;
                const delta = rows0 - rows1;
                state.reactState.top += delta * event.view.rowPx;
                if (
                    stripeOfs < event.stripes0.length &&
                    stripeOfs < event.stripes.length &&
                    rowOfs0 < event.stripes0[stripeOfs].e
                ) {
                    const _e0 = stripeOfs > 0 ? event.stripes0[stripeOfs - 1].e : 0;
                    const e0 = stripeOfs > 0 ? event.stripes[stripeOfs - 1].e : 0;
                    const _row0 = rowOfs0 - _e0;
                    const _row1 = (_row0 * (event.stripes[stripeOfs].e - e0)) / (event.stripes0[stripeOfs].e - _e0);
                    const _delta = _row0 - _row1;
                    state.reactState.top += _delta * event.view.rowPx;
                }
            }
        } else if (event.grid && event.grid0) {
            const sidebarWidth =
                (state.reactState.l_max - state.reactState.l_min) * CONST.sidebarColWidth +
                CONST.menuCondensedWidth +
                CONST.sidebarColExtra +
                state.const.sidebarColImage;
            const date0 = event.grid0.gridToDate(
                Math.floor(
                    Math.min(
                        event.grid0.grid[event.grid0.grid.length - 1].g1 - 1,
                        Math.max(0, -(state.reactState.left * state.reactState.scale - sidebarWidth)) /
                            (state.const.colPx * state.reactState.scale),
                    ),
                ),
            );
            const left =
                sidebarWidth -
                event.grid.dateToGrid(
                    Math.min(date0, EpochDaystoEpochMS(event.grid.grid[event.grid.grid.length - 1].d1 - 1)),
                ) *
                    state.const.colPx *
                    state.reactState.scale;
            state.reactState.left = left / state.reactState.scale;
        }
    }

    /** @internal */
    _cancelTextArea(focus?: boolean) {
        this._textarea.style.visibility = null; // style default
        this._textarea.dataset.selTarget = null;
        this._textarea.dataset.selId = null;
        this._textarea.dataset.text = null;
        if (false !== focus) {
            this._div.focus();
        }
    }

    public getCONST(): {
        titleHeight: number;
        subtitleHeight: number;
    } {
        return CONST;
    }

    render(this: CanvasHostBase) {
        const transform =
            "scale(" +
            this.state.reactState.scale.toString(10) +
            ") translate(" +
            this.state.reactState.left +
            "px, " +
            this.state.reactState.top +
            "px)";
        const frs = this.state.footer?.data ? this._getFooterReactState() : null;
        const fh = this.state.footer?.canvasState?.chart?.height || 0;
        // this._restoreSessionSplitView();
        return (
            <div ref={this._onDivRef} className={classNames.host} tabIndex={-1}>
                {this.state.canvas && this.state.reactState.scale ? (
                    <canvas
                        className={classNames.canvas}
                        {...this.state.canvas.attr}
                        style={this.state.canvas.style}
                        ref={this._onCanvasRef}
                    />
                ) : null}
                {this.state.canvas && this.state.reactState.scale ? (
                    <div
                        className={classNames.overlay}
                        style={{
                            transform: transform,
                        }}
                    >
                        <textarea
                            ref={this._onTextareaRef}
                            onBlur={this._onTextareaBlur}
                            onChange={this._onTextareaChange}
                            onFocus={this._onTextareaFocus}
                            className={classNames.textarea}
                            onKeyDown={this._onInputKeyDown}
                            onCopy={this._onInputCopy}
                            onPaste={this._onInputPaste}
                        />
                    </div>
                ) : null}
                {this._overlayHack && this.state.canvas && this.state.reactState.scale ? (
                    <this._overlayHack {...this.state} />
                ) : null}
                {frs && this.state.reactState.scale ? (
                    <div
                        className={classNames.footer}
                        ref={this._onFtrDivRef}
                        style={{
                            left: this.calcLeft(frs),
                            height: fh,
                        }}
                        onPointerDown={this.state.footer.chartInstance.onPointerDown}
                        onPointerUp={this.state.footer.chartInstance.onPointerUp}
                        onPointerMove={this.state.footer.chartInstance.onPointerMove}
                        onPointerEnter={this.state.footer.chartInstance.onPointerEnter}
                        onPointerLeave={this.state.footer.chartInstance.onPointerLeave}
                        onPointerCancel={this.state.footer.chartInstance.onPointerCanceö}
                        onClick={this.state.footer.chartInstance.onClick}
                    >
                        {this.state.footer.chartInstance?.Overlay ? (
                            <this.state.footer.chartInstance.Overlay {...frs} />
                        ) : null}
                    </div>
                ) : null}
                {frs && this.state.reactState.scale ? (
                    <div
                        className={classNames.footerComponent}
                        ref={this._onFtrCompRef}
                        onScroll={this.onFtrScroll}
                        data-is-scrollable={true}
                        style={{
                            width: CanvasSizeHelper.getHeaderWidth(
                                this.state.const,
                                this.state.reactState.l_min,
                                this.state.reactState.l_max,
                            ),
                            height: this.state.footer.canvasState.chart.height || 0,
                        }}
                    >
                        {this.state.footer.chartInstance?.Component ? (
                            <this.state.footer.chartInstance.Component {...frs} />
                        ) : null}
                    </div>
                ) : null}
                {"canvas" === this.props.mode && this.state.reactState.scale ? <this.stupidBox /> : null}
                <this.legendBox mode={this.props.mode} viewConst={this.props.viewConst} bottom={fh} />
                {fh ? <this.hRule bottom={fh} /> : null}
                {"canvas" === this.props.mode && this.state.reactState.scale && this.state.gpaDirty ? (
                    <this.alertBox />
                ) : null}
                {this.state.connectionState && this.props.worker.config.ConnectionErrorAlertBox ? (
                    <this.props.worker.config.ConnectionErrorAlertBox
                        onReconnect={this.onReconnect}
                        connectionState={this.state.connectionState}
                        defaultStyle={alertStyle}
                    />
                ) : null}
                {false && "development" === process.env.NODE_ENV ? (
                    <div
                        ref={this._onDebugRef}
                        style={{
                            display: "none", // remove me!!!
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 120,
                            height: 40,
                            pointerEvents: "none",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    public getImage(image: string, w: number, h: number): CanvasImageSource {
        const hit = this._cache[image];
        if (undefined !== hit) {
            return hit;
        } else {
            this._cache[image] = null;
            const ret = new Image();
            ret.src = image;
            ret.onload = (ev) => {
                assert(null === this._cache[image]);
                const sx = w / ret.naturalWidth;
                const sy = h / ret.naturalHeight;
                const s = Math.min(sx, sy);
                const sw = Math.floor(ret.naturalWidth * s);
                const sh = Math.floor(ret.naturalHeight * s);
                createImageBitmap(ret, {
                    resizeWidth: sw,
                    resizeHeight: sh,
                })
                    .then((bmp) => {
                        this._cache[image] = bmp;
                        this._invalidateCanvas();
                    })
                    .catch((e) => {
                        //this._cache[image]=bmp;
                    });
            };
            //document.body.appendChild(ret);
            return null;
        }
    }

    renderDependency(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        const p = 2;
        const l = task.__x + dx + p;
        const t = task.__y + dy + p;
        const w = task.__w - p - p;
        const h = task.__h - p - p;
        const nv = l + w < rc.view.x1 || l > rc.view.x2 || t + h < rc.view.y1 || t > rc.view.y2;

        if (nv) {
            return;
        }

        const successorTasks = this._renderer.getSuccessor(task);
        const predecessorTasks = this._renderer.getPredecessor(task);
        if (successorTasks.length === 0 && predecessorTasks.length === 0) {
            return;
        }
        const _task = this.transformAndCopyTaskForDepReposition(task);
        for (const [succTask, type] of successorTasks) {
            this.drawSuccessorLine(ctx, _task, this.transformAndCopyTaskForDepReposition(succTask), type);
        }
        for (const [preTask, type] of predecessorTasks) {
            this.drawPredecessorLine(ctx, _task, this.transformAndCopyTaskForDepReposition(preTask), type);
        }
    }

    private drawSuccessorLine(
        ctx,
        task1: CanvasTaskData & CanvasHostRenderNode,
        task2: CanvasTaskData & CanvasHostRenderNode,
        type: LCMDContextDependencyType,
    ) {
        const x =
            task1.__x +
            (type === LCMDContextDependencyType.AA ? 0 : type === LCMDContextDependencyType.AE ? 0 : task1.__w);
        const x2 =
            task2.__x +
            (type === LCMDContextDependencyType.AE ? task2.__w : type === LCMDContextDependencyType.EE ? task2.__w : 0);

        if (task1.__y === task2.__y && x === x2) {
            return;
        }

        const y = task1.__y + task1.__h / 2;
        const y2 = task2.__y + task2.__h / 2;
        const rotation = Math.atan2(y2 - y, x2 - x);
        const lineOffsetX = 5 * Math.cos(rotation);
        const lineOffsetY = 5 * Math.sin(rotation);

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x2 - lineOffsetX, y2 - lineOffsetY);
        ctx.closePath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "black";

        // comparission is over referenze !!! Dont know if that's smart
        if (this._renderer.hit.hitDep[0] === task1.id && this._renderer.hit.hitDep[1] === task2.id) {
            ctx.strokeStyle = "#3762c9";
        }
        ctx.stroke();

        this.drawDependencyLineStart(
            ctx,
            task1,
            type === LCMDContextDependencyType.AA || type === LCMDContextDependencyType.AE,
        );
    }

    private drawPredecessorLine(ctx: CanvasRenderingContext2D, task1, task2, type: LCMDContextDependencyType) {
        const x =
            task1.__x +
            (type === LCMDContextDependencyType.AA ? 0 : type === LCMDContextDependencyType.EA ? 0 : task1.__w);
        const x2 =
            task2.__x +
            (type === LCMDContextDependencyType.EA ? task2.__w : type === LCMDContextDependencyType.EE ? task2.__w : 0);

        if (task1.__y === task2.__y && x === x2) {
            ctx.translate(5, 0);
            this.drawDependencyLineTip(ctx, task2, task1, type, task1.__x < task2.__x);
            ctx.translate(-5, 0);
            return;
        }

        const y = task1.__y + task1.__h / 2;
        const y2 = task2.__y + task2.__h / 2;
        const rotation = Math.atan2(y2 - y, x2 - x);
        const lineOffsetX = 5 * Math.cos(rotation);
        const lineOffsetY = 5 * Math.sin(rotation);

        ctx.beginPath();
        ctx.moveTo(x + lineOffsetX, y + lineOffsetY);
        ctx.lineTo(x2, y2);
        ctx.closePath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "black";

        // comparission is over referenze !!! Dont know if that's smart
        if (this._renderer.hit.hitDep[0] === task2.id && this._renderer.hit.hitDep[1] === task1.id) {
            ctx.strokeStyle = "#3762c9";
        }
        ctx.stroke();

        this.drawDependencyLineTip(ctx, task2, task1, type);
    }

    drawTaskSelectedSide(ctx: CanvasRenderingContext2D, task: CanvasTaskData & CanvasHostRenderNode) {
        if (this._renderer.hit.hitSrcTaskId === task.id && this._renderer.hit.hitSrcTaskLeft !== null) {
            this.drawDependencyLineStart(
                ctx,
                this.transformAndCopyTaskForDepReposition(task),
                this._renderer.hit.hitSrcTaskLeft,
                "blue",
            );
        }
    }

    protected drawPlusSing(ctx: CanvasRenderingContext2D, x: number, y: number): void {
        ctx.fillStyle = "black";
        ctx.strokeStyle = "white";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.arc(x, y, 8, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();
        ctx.fillStyle = "white";
        ctx.beginPath();
        ctx.roundRect(x - 4, y - 0.75, 8, 1.5, 8);
        ctx.fill();
        ctx.beginPath();
        ctx.roundRect(x - 0.75, y - 4, 1.5, 8, 8);
        ctx.fill();
    }

    private drawDependencyLineStart(
        ctx: CanvasRenderingContext2D,
        startTask: CanvasTaskData & CanvasHostRenderNode,
        start: boolean,
        color?: string,
    ) {
        const x = startTask.__x + (start ? 0 : startTask.__w);
        const y = startTask.__y + startTask.__h / 2;

        ctx.fillStyle = color ? "blue" : "black";
        ctx.strokeStyle = "white";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.arc(x, y, 4, 0, Math.PI * 2);
        ctx.fill();
        ctx.stroke();
    }

    private drawDependencyLineTip(
        ctx: CanvasRenderingContext2D,
        startTask: CanvasTaskData & CanvasHostRenderNode,
        endTask: CanvasTaskData & CanvasHostRenderNode,
        type: LCMDContextDependencyType,
        reverse?: boolean,
    ) {
        const endTaskX =
            endTask.__x +
            (type === LCMDContextDependencyType.AA || type === LCMDContextDependencyType.EA ? 0 : endTask.__w);
        const startTaskX =
            startTask.__x +
            (type === LCMDContextDependencyType.AA || type === LCMDContextDependencyType.AE ? 0 : startTask.__w);
        ctx.save();
        ctx.translate(endTaskX, endTask.__y + endTask.__h / 2);
        ctx.rotate(
            Math.atan2(endTask.__y + endTask.__h / 2 - (startTask.__y + startTask.__h / 2), endTaskX - startTaskX),
        );
        ctx.translate(-endTaskX, -endTask.__y - endTask.__h / 2);
        ctx.beginPath();
        ctx.moveTo(endTaskX - (reverse ? 0 : 10), endTask.__y + endTask.__h / 2 - 5.5);
        ctx.lineTo(endTaskX - (reverse ? 10 : 0), endTask.__y + endTask.__h / 2);
        ctx.lineTo(endTaskX - (reverse ? 0 : 10), endTask.__y + endTask.__h / 2 + 5.5);
        ctx.closePath();
        ctx.fillStyle = "black";
        ctx.fill();
        ctx.restore();
    }

    private addDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType) {
        this._renderer.addAddDependency(srcPid, tgtPid, dependencyType);
        this._renderer.hit.hitSrcTaskLeft = null;
        this._renderer.hit.hitSrcTaskId = null;
    }

    // creates a copy of the task and transforms the __x and __y values if needed for rendering deplines while dragging length of process
    private transformAndCopyTaskForDepReposition(
        task: CanvasTaskData & CanvasHostRenderNode,
    ): CanvasTaskData & CanvasHostRenderNode {
        const handle = this._pointer.drag?.handle;
        const { sx: scaleWidthFactor, tx: transformXValue, dx, dy } = this._renderer.getBBox() ?? {};
        const transformedTask = { ...task };

        if (this._renderer.isTaskSelected(task) && handle === 6) {
            transformedTask.__x += transformXValue;
            transformedTask.__w -= transformXValue;
        } else if (this._renderer.isTaskSelected(task) && handle === 5) {
            transformedTask.__w *= scaleWidthFactor;
        } else if (task.__z >= 0) {
            transformedTask.__x = task.__x + dx;
            transformedTask.__y = task.__y + dy;
        }

        return transformedTask;
    }

    protected abstract onCopyCut(cmd: "copy" | "cut");

    protected abstract onPaste(ev: CanvasHostBasePointerEvent);

    protected abstract onDeleteKey();

    protected abstract onHoverProcess(
        task: CanvasTaskData & CanvasHostRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
        scale: number,
        dynamicFontSize: boolean,
    );

    protected abstract onLeaveProcess();

    protected onDragStart(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult) {}

    protected abstract onDragEnd(rc: CanvasHostRendererCtx, dragInfo: CanvasHostRendererDragInfo | null);

    protected onDragging(rc: CanvasHostRendererCtx, hit: CanvasHostHitResult, hitStart: CanvasHostHitResult) {}

    protected abstract onDropEnd(
        rc: CanvasHostRendererCtx,
        pointer: CanvasHostBasePointerEvent,
        dropInfo: CanvasHostRendererDropInfo | null,
        draggedRef: HTMLElement,
    );

    protected abstract onClick(mouse: { x: number; y: number });

    protected abstract onTextEdited(target: number, id: number, value: string);

    private _postView(this: CanvasHostBase) {
        assert(this.state.footer.name);
        if (this._pendingView.col0 < this._pendingView.col1) {
            this._pendingView.state = 1;
            this.props.worker.postMessage([
                "stats",
                "view",
                {
                    name: this.state.footer.name,
                    grid_ts: this._pendingView.grid_ts,
                    col0: this._pendingView.col0,
                    col1: this._pendingView.col1,
                    scale: this._pendingView.scale,
                    options: this._pendingView.options,
                },
            ]);
        } else {
            this._pendingView.state = 0;
        }
    }

    private _setPendingView(this: CanvasHostBase, C: CanvasViewConst, grid: CalendarGrid, options?: any) {
        if (this.state.footer?.name) {
            const ftrOfs = this._ftrComp ? this._ftrComp.getBoundingClientRect().width : 0;
            //const ftrOfs=this._ftrDiv?this._ftrDiv.getBoundingClientRect().left:0; //@TODO move to _pendingView
            const rs = this.state.reactState;
            const scale = rs.scale;
            const colPx = C.colPx * scale;
            const __left = -(rs.left * scale) + ftrOfs;
            const _left = Math.max(0, __left);
            const _width = Math.max(
                0,
                this.state.canvas.style.width -
                    /*ftrOfs-*/ Math.max(0, rs.left * scale - ftrOfs) -
                    (ftrOfs + CONST.newSidebarWidth),
            );
            //const _right=_left+Math.min(rs.width*rs.scale, this.state.canvas.width-ftrOfs);
            //const left=Math.max(0, -_left);
            //const right=Math.max(0, -Math.min(_left+this.state.canvas.width-ftrOfs, _right));
            //const width=Math.min((rs.width-rs.left)*rs.scale, this.state.canvas.width-ftrOfs);
            const _col0 = Math.max(C.colPx > 0 ? Math.floor(_left / colPx) : 0, 0);
            //const _col1=Math.max(_col0, Math.min(C.colPx>0?Math.ceil(_right/colPx):0, (grid?.view.cols||0)-_col0)-1);
            const _col1 = Math.max(
                _col0,
                Math.min(C.colPx > 0 ? Math.ceil((_left + _width) / colPx) : 0, grid?.view.cols || 0),
            );
            //console.log(JSON.stringify({_left, __left, _width, _col0, _col1}));
            const grid_ts = grid?.ts || 0;
            if (
                _col0 !== this._pendingView.col0 ||
                _col1 !== this._pendingView.col1 ||
                scale !== this._pendingView.scale ||
                grid_ts !== this._pendingView.grid_ts ||
                options
            ) {
                this._pendingView.col0 = _col0;
                this._pendingView.col1 = _col1;
                this._pendingView.scale = scale;
                this._pendingView.grid_ts = grid_ts;
                this._pendingView.options = options || this._pendingView.options || undefined;
                //console.log("SET"+JSON.stringify(this.pending));
                if (0 === this._pendingView.state) {
                    this._postView();
                } else {
                    assert(1 === this._pendingView.state || 2 === this._pendingView.state);
                    this._pendingView.state = 2;
                }
            }
        }
    }

    private transformCanvasPointer(
        this: CanvasHostBase,
        handler: (canvas, props, ev) => void,
        _ev: React.PointerEvent<HTMLCanvasElement>,
    ) {
        const target: HTMLCanvasElement = _ev.currentTarget;
        const rect = target.getBoundingClientRect();
        const data = this.state.footer.data;
        const C = this.state.const;
        const rs = this.state.reactState;
        // (rs.left+(data.view.col0*C.colPx))*rs.scale
        // +this.state.footer.data.view.col0*this.state.const.colPx
        //+this.state.reactState.left;
        const canvasLeft = (rs.left + data.view.col0 * C.colPx) * rs.scale;
        (_ev as any).canvasX = _ev.clientX /*-rect.left*/ - canvasLeft;
        (_ev as any).canvasY = _ev.clientY - rect.top;
        const ev = _ev as any as React.PointerEvent<HTMLCanvasElement> & { canvasX: number; canvasY: number };
        //console.log({canvasX: (_ev as any).canvasX, canvasY: (_ev as any).canvasY});
        handler(this.state.footer.canvasState, this._getFooterReactState(), ev);
    }

    private _setFooter(this: CanvasHostBase, name: string, options?: any) {
        if (this.state.footer?.name !== name) {
            if (this.state.footer) {
                this._pendingView.col0 = null; // invalid state
                this._pendingView.col1 = null; // ----- " -----
                this._pendingView.state = 2; // ----- " -----
                this._pendingView.scale = 0; // ----- " -----
                this._pendingView.grid_ts = -1; // ----- " -----
                this._pendingView.options = null; // release ref
                // debugger;
                this.props.worker.postMessage([
                    "stats",
                    "view",
                    {
                        name: this.state.footer.name,
                        scale: null, // unregister
                    },
                ]);
            }
            let _instance: CanvasChartCallbacks = null;
            switch (name) {
                case "canvas.kappa":
                    _instance = this.props.worker.config?.resourceChart;
                    break;
                case "canvas.milestones":
                    _instance = this.props.worker.config?.milestonesChart;
                    break;
            }
            if (name && _instance) {
                /*
                if (_instance) {
                    this.chartInstance.onRenderCanvas=_instance.onRenderCanvas;
                    this.chartInstance.Overlay=_instance.Overlay.bind(this.canvasState);
                    this.chartInstance.Component=_instance.Component.bind(this.canvasState);
                    this.chartInstance.onPointerDown=this.transformCanvasPointer.bind(this, _instance.onPointerDown);
                    this.chartInstance.onPointerUp=this.transformCanvasPointer.bind(this, _instance.onPointerUp);
                    this.chartInstance.onPointerMove=this.transformCanvasPointer.bind(this, _instance.onPointerMove);
                    this.chartInstance.onPointerEnter=this.transformCanvasPointer.bind(this, _instance.onPointerEnter);
                    this.chartInstance.onPointerLeave=this.transformCanvasPointer.bind(this, _instance.onPointerLeave);
                    this.chartInstance.onClick=this.transformCanvasPointer.bind(this, _instance.onClick);
                }
                */
                const _canvasState = {};
                const canvasState = {
                    // HACK.. will be modified by side-effects...
                    canvasState: _canvasState, // custom state
                    setCanvasState: (state) => {
                        Object.assign(_canvasState, state);
                    },
                    setOverlayState: (_state) => {
                        this.setState(
                            (state) => ({
                                ...state,
                                footer: { ...state.footer, overlayState: { ...state.footer.overlayState, ..._state } },
                            }),
                            this._invalidateCanvas,
                        );
                    },
                    setOptions: (options: any) => {
                        const _options = { ...(this._pendingView.options || {}), ...options };
                        this._setPendingView(this.state.const, this.state.grid, _options);
                    },
                    chart: {
                        getContext: (type) => this._canvas.getContext(type),
                        width: this.state.canvas.style.width,
                        height: this.state.footerH,
                    },
                    legend: null as HTMLCanvasElement,
                };
                const footer: CanvasHostBaseFooter & any = {
                    name: name,
                    data: null,
                    chartInstance: {
                        onRenderCanvas: _instance.onRenderCanvas,
                        Overlay: _instance.Overlay.bind(canvasState),
                        Component: _instance.Component.bind(canvasState),
                        onPointerDown: this.transformCanvasPointer.bind(this, _instance.onPointerDown),
                        onPointerUp: this.transformCanvasPointer.bind(this, _instance.onPointerUp),
                        onPointerMove: this.transformCanvasPointer.bind(this, _instance.onPointerMove),
                        onPointerEnter: this.transformCanvasPointer.bind(this, _instance.onPointerEnter),
                        onPointerLeave: this.transformCanvasPointer.bind(this, _instance.onPointerLeave),
                        onPointerCancel: this.transformCanvasPointer.bind(this, _instance.onPointerCancel),
                        onClick: this.transformCanvasPointer.bind(this, _instance.onClick),
                    },
                    canvasState: canvasState,
                    overlayState: {},
                };
                this.setState(
                    {
                        footer: footer,
                    },
                    () => {
                        this._pendingView.col0 = null; // valid state
                        this._pendingView.col1 = null; // ----- " -----
                        this._pendingView.state = 0; // ----- " -----
                        this._pendingView.options = options || null;
                        this._invalidateCanvas();
                    },
                );
            } else {
                this.setState(
                    {
                        footer: null,
                    },
                    this._invalidateCanvas,
                );
            }
        }
    }

    private _getFooterReactState(this: CanvasHostBase) {
        //@TODO Cache me
        const rs = this.state.reactState;
        const data = this.state.footer.data;
        const C = this.state.const;
        const scrollTop = this._ftrComp ? this._ftrComp.scrollTop : 0;
        const col0 = data.view.col0;
        const col1 = data.view.col1;
        const left = (rs.left + col0 * C.colPx) * rs.scale;
        const right = (rs.left + col1 * C.colPx) * rs.scale;
        return {
            //@TODO Cache me
            overlayState: this.state.footer.overlayState,
            canvasLeft: left,
            canvasTop: scrollTop,
            canvasRight: right,
            left: left, // state.canvasLeft
            top: 0, // 0
            scale: rs.scale,
            const: C,
            grid: this.state.grid,
            data: data,
            kappa: data, // duplicate for backward compatibility
        };
    }

    /** @internal */
    protected _invalidateCanvasWithEvent(this: CanvasHostBase, event: any) {
        this._invalidateCanvas();
        if (this.state.footer?.chartInstance) {
            this.state.footer.chartInstance.onPointerCancel(event);
        }
    }

    /** @internal */
    protected zoom(
        event: { deltaY: number; clientX: number; clientY: number; currentTarget: HTMLCanvasElement },
        pinchZoom?: boolean,
    ) {
        const delta = event.deltaY < 0 ? 1 : -1; // inspired by getDelta from https://github.com/prc5/react-zoom-pan-pinch/blob/9e20e855c82f52fccd309f25e60b67ddfd5482cb/src/core/wheel/wheel.utils.ts
        //const step=0.2;
        const step = pinchZoom ? 0.05 : Math.abs(event.deltaY) > 50 ? 0.2 : 0.05;
        const s = this.state.reactState.scale;
        const __s = s + delta * (s - s * step) * step;
        const _s = __s;
        //this._previousWheelEvent=event;
        if (_s > 0) {
            console.log("zoom setState");
            this.setState(
                {
                    ...this.state,
                    reactState: {
                        ...this.state.reactState,
                        scale: _s,
                        left: this.state.reactState.left - (event.clientX * (_s - s)) / s / _s,
                        top: this.state.reactState.top - (event.clientY * (_s - s)) / s / _s,
                    },
                },
                this._invalidateCanvasWithEvent.bind(this, event),
            );
        }
    }

    private zoomAbs(scale: number) {
        if (scale || scale === 0) {
            this.setState(
                { ...this.state, reactState: { ...this.state.reactState, scale: scale } },
                this._invalidateCanvas.bind(this),
            );
        }
    }
    private goToProcess(pid: number, scale?: number) {
        if (!pid || isNaN(pid)) {
            console.error("Invalid pid");
            return;
        }

        const task = MainWorkerPipe.findTask(this.state.reactState.tasks, pid);
        if (task) {
            const _scale = scale || scale === 0 ? scale : this.state.reactState.scale;

            const ftrRect = this._ftrComp ? this._ftrComp.getBoundingClientRect() : null;
            const ftrW = ftrRect
                ? ftrRect.width
                : CanvasSizeHelper.getHeaderWidth(
                      this.state.const,
                      this.state.reactState.l_min,
                      this.state.reactState.l_max,
                  );
            const ftrH = ftrRect ? ftrRect.height : 0;
            const hOfs = this.state.const.colHeaderHeight + CONST.titleHeight + CONST.subtitleHeight;
            const x1 = -this.state.reactState.left + ftrW / _scale;
            const y1 = -this.state.reactState.top + hOfs / _scale;
            const x2 = x1 + (this.state.canvas.style.width - ftrW - CONST.newSidebarWidth) / _scale;
            const y2 = y1 + (this.state.canvas.style.height - ftrH - hOfs) / _scale;
            //console.log(JSON.stringify({x1, y1, x2, y2}));
            const milstone = task.left === task.right ? this.state.const.rowPx / 2 : 0;
            const p = 5;
            const tx1 = task.left - milstone - p;
            const tx2 = task.right + milstone + p;
            const ty1 = task.top - p;
            const ty2 = task.top + this.state.const.rowPx + p;
            let left = this.state.reactState.left;
            let top = this.state.reactState.top;

            left = -tx1 + ftrW / _scale + (x2 - x1) / 2;
            top = -ty1 + hOfs / _scale + (y2 - y1) / 2;
            //console.log(JSON.stringify({left, top}));

            this.setState(
                { ...this.state, reactState: { ...this.state.reactState, left: left, top: top, scale: _scale } },
                () => {
                    this._invalidateCanvas();
                    this._renderer.clearSelect(true);
                    this._renderer.select(DataOperationTarget.TASKS, pid);
                },
            );
            // todo: mit produkt klären ob das verhalten gewollt ist (https://lcmexecute.atlassian.net/browse/LCM2-4136?focusedCommentId=13672)
            // vorher war die navigation eine reine navigation und keine selektion
            // eine idee wäre da nur für spezielle funktionen auch eine selektion nach der navigation
            // ausgelöst werden soll
            this.props.worker.dispatchMessage([
                "task",
                {
                    selected: [pid],
                },
            ]);
        }
    }

    private _onProcessViewUpdate(data: LCMDProcessViewData) {
        const C = this.state.const || {
            ...VCONST0,
            colPx: 100,
            rowPx: 20,
            gpa: {
                ...VCONST0.gpa,
                header: {
                    height: 20,
                },
            },
        };
        const M = this.state.meta || null;
        const grid =
            this.state.grid ||
            (() => {
                const g = CalendarGrid.createAllGrid();
                g.view.cols = 1;
                return g;
            })();
        const stripes = data.stripes || this.state.reactState.stripes;
        const tasks = data.data || this.state.reactState.tasks;
        const stripe0 = Array.isArray(stripes) && stripes.length > 0 ? stripes[0] : null;
        const width = 0; // stripe0?stripe0.w:0;
        const height = 0; // stripe0?stripe0.h:0;
        if (
            C !== this.state.const ||
            M !== this.state.meta ||
            grid !== this.state.grid ||
            stripes !== this.state.reactState.stripes ||
            tasks !== this.state.reactState.tasks ||
            width !== this.state.reactState.width ||
            height !== this.state.reactState.height
        ) {
            this.setState((state) => {
                const ret = {
                    const: C,
                    meta: M,
                    grid,
                    reactState: { ...state.reactState, stripes, tasks, width, height },
                };
                this._renderer.updateIfNeeded(
                    ret.const,
                    ret.meta,
                    ret.grid,
                    ret.reactState.stripes,
                    0,
                    0,
                    ret.reactState.tasks,
                    ret.reactState.messages,
                    null,
                );
                return ret;
            }, this._invalidateCanvas);
        }
    }

    private _zoomToAll(state, tasks, startEnd?: { _x1: number; _x2: number }) {
        if (this._skipZoomToAll) {
            this._skipZoomToAll = false;
            return;
        }

        const padding = 3;
        if ("wb" === this.props.mode) {
            const headerHeight = CONST.titleHeight + CONST.subtitleHeight;
            const visibleWidth = this.state.canvas.style.width - CONST.newSidebarWidth;
            const visibleHeight = this.state.canvas.style.height - headerHeight;
            const canvasLeft = 0;
            const canvasTop = 0;
            const canvasWidth = state.reactState.width;
            const canvasHeight = state.reactState.height;
            const scaleX = (visibleWidth - 2 * padding) / canvasWidth;
            const scaleY = (visibleHeight - 2 * padding) / canvasHeight;
            const scale = Math.min(scaleX, scaleY);
            state.reactState.left = -canvasLeft + Math.max(0, visibleWidth - canvasWidth * scale) / scale / 2;
            state.reactState.top =
                headerHeight / scale - canvasTop + Math.max(0, visibleHeight - canvasHeight * scale) / scale / 2;
            state.reactState.scale = scale;
        } else if ("canvas" === this.props.mode) {
            const sidebarWidth =
                (state.reactState.l_max - state.reactState.l_min) * CONST.sidebarColWidth +
                CONST.menuCondensedWidth +
                CONST.sidebarColExtra +
                state.const.sidebarColImage;
            const headerHeight = CONST.titleHeight + CONST.subtitleHeight + state.const.colHeaderHeight;
            const visibleWidth = this.state.canvas.style.width - sidebarWidth - CONST.newSidebarWidth;
            const visibleHeight = this.state.canvas.style.height - headerHeight;
            const helper = (tasks || []).reduce(
                (ret, t, i) => {
                    if (0 === i) {
                        ret.left = t.left;
                        ret.right = t.right;
                    } else {
                        ret.left = Math.min(ret.left, t.left);
                        ret.right = Math.max(ret.right, t.right);
                    }
                    return ret;
                },
                {
                    left: 0,
                    right: state.grid.view.cols * state.const.colPx,
                },
            );
            const startDate = startEnd ? startEnd._x1 : undefined;
            const endDate = startEnd ? startEnd._x2 : undefined;
            const gridStart = startDate
                ? state.grid.dateToGrid(startDate)
                : Math.max(0, Math.floor(helper.left / state.const.colPx));
            const gridEnd = endDate
                ? state.grid.dateToGrid(endDate)
                : Math.min(Math.ceil(helper.right / state.const.colPx), state.grid.view.cols);
            const canvasLeft = state.const.colPx * gridStart;
            const canvasTop = 0;
            const canvasWidth = (gridEnd - gridStart) * state.const.colPx; // state.reactState.width;
            const canvasHeight = state.reactState.height;
            const scaleX = (visibleWidth - 2 * padding) / canvasWidth;
            const scaleY = (visibleHeight - 2 * padding) / canvasHeight;
            const scale = Math.min(scaleX, scaleY);
            state.reactState.left =
                sidebarWidth / scale - canvasLeft + Math.max(0, visibleWidth - canvasWidth * scale) / scale / 2;
            state.reactState.top =
                headerHeight / scale - canvasTop + Math.max(0, visibleHeight - canvasHeight * scale) / scale / 2;
            state.reactState.scale = scale;

            if (this._restoreSessionPositionAfterZoomToAll) {
                this._restoreSessionPositionAfterZoomToAll = false;
                this._restoreSessionPosition();
            } else {
                this._savePositionSessionState();
            }
        } else if ("tz" === this.props.mode) {
            state.reactState.left = 0;
            state.reactState.top = 0;
            state.reactState.scale = 1;
        } else {
            assert(false); // handle me
        }
    }

    private _cancelPointerIfNeeded() {
        if (this._pointer.down || this._pointer.drag) {
            if (this._pointer.drag?.overlay) {
                this._hideOverlay();
            }
            if (this.state.canvasState.drag) {
                this._renderer.dragEnd(false);
                this.state.canvasState.drag = null;
                this._invalidateCanvas();
            }
            if (this.state.canvasState.marquee) {
                this.state.canvasState.marquee = null;
                this._invalidateCanvas();
            }
            if (this._pointer.forceMarquee) {
                this._pointer.forceMarquee = false;
                if (this.props.worker) {
                    this.props.worker.dispatchMessage(["toggle", "forceShiftKey", false]);
                }
            }
            this._pointer.down = 0;
            this._pointer.drag = null;
            this._pointer.clientX = null;
            this._pointer.clientY = null;
            this._pointer.left = null;
            this._pointer.top = null;
            this._pointer.clear = false;
            this._pointer.marquee = false;
            if (this._pointer.hRule) {
                const fh = this.state.footer?.canvasState?.chart?.height || 0;
                this._pointer.hRule.style.bottom = fh + "px";
                this._pointer.hRule = null;
            }
        }
        this._updateMouseCursor();
    }

    private _hideOverlay() {
        this._overlay.style.display = null; // style default
    }

    private _ensureOverlay() {
        if (false && this._overlay && this._overlay.nextElementSibling) {
            // no needed, z-index is set very high
            this._overlay.remove();
            this._overlay = null;
        }
        if (!this._overlay) {
            this._overlay = document.createElement("div");
            this._overlay.id = "DRAGO";
            document.body.appendChild(this._overlay);
            assert(document.body === this._overlay.parentElement && !this._overlay.nextElementSibling);
        }
    }

    private _updateMouseCursor(handle?: number) {
        const _handle = handle || this._pointer.drag?.handle || 0;
        if (_handle !== this._mouseCursorHandle) {
            //console.log("MOUSE POINTER FROM "+this._mouseCursorHandle+" TO "+_handle);
            if (this._div) {
                this._div.style.cursor = 5 <= _handle && _handle <= 6 ? "col-resize" : "";
            }
            this._mouseCursorHandle = _handle;
        }
    }

    private __setDragTarget(
        _target: CanvasHostBaseTarget,
        hit?: CanvasHostHitResult,
        ev?: { clientX: number; clientY: number; ctrlKey: boolean },
        handle?: number,
    ) {
        //console.log("__setDragTarget "+(_target?true:false));
        if (_target) {
            assert(!this._pointer.down && !this._pointer.drag);
            this._pointer.drag = {
                sel: null,
                hit: hit || null,
                overlay: false,
                clientX: null,
                clientY: null,
                left: null,
                top: null,
                width: null,
                height: null,
                handle: handle || 0,
            };
            if ((_target as CanvasHostBaseTarget)?.ref) {
                const target = _target as CanvasHostBaseTarget;
                if (Node.ELEMENT_NODE === (target as CanvasHostBaseTarget).ref.nodeType) {
                    this._ensureOverlay();
                    if (this._overlay) {
                        this._pointer.drag.overlay = true;
                        this._pointer.drag.sel = target;
                        const rect = (target as CanvasHostBaseTarget).ref.getBoundingClientRect();
                        this._overlay.style.left = rect.left + "px";
                        this._overlay.style.top = rect.top + "px";
                        this._overlay.style.width = rect.width + "px";
                        this._overlay.style.height = rect.height + "px";
                        this._overlay.style.display = "block";
                        if (ev) {
                            this._pointer.drag.left = ev.clientX - rect.left;
                            this._pointer.drag.top = ev.clientY - rect.top;
                            this._pointer.drag.width = rect.width;
                            this._pointer.drag.height = rect.height;
                            this._pointer.drag.clientX = ev.clientX;
                            this._pointer.drag.clientY = ev.clientY;
                        }
                    }
                }
                this._renderer.clearSelect();
                this._invalidateCanvas();
            } else if (_target) {
                const rs = this.state.reactState;
                this._pointer.drag.sel = _target;
                this._pointer.drag.left = ev.clientX / rs.scale - rs.left;
                this._pointer.drag.top = ev.clientY / rs.scale - rs.top;
                this._pointer.drag.clientX = ev.clientX;
                this._pointer.drag.clientY = ev.clientY;
                this._invalidateCanvas();
                /*
                const target=_target as CanvasHostBaseSelection;
                if (ev) {
                    this._layoutShapesIfNeeded();
                    const rs=this.state.reactState;
                    const info=this._ensureSelectionInfo(target);
                    if (info) {
                        if (ev.ctrlKey && Array.isArray(target.group) && Array.isArray(this.state.reactState.selection?.group) && this.state.reactState.selection.target===target.target) {
                            if (DataOperationTarget.TASKS===target.target && target.group.length>0 && this.state.reactState.selection.group.length>0 && (target.group[0]<=0)!==(this.state.reactState.selection.group[0]<=0)) {
                                // do nothing, since cannot mix stipes and tasks
                            } else { // merge group
                                target.group=target.group.concat(this.state.reactState.selection.group);
                                this._ensureSelectionSorted(target, true);
                            }
                        }
                        this._pointer.drag.sel=target;
                        this._pointer.drag.left=((ev.clientX/rs.scale)-rs.left)-info.x;
                        this._pointer.drag.top=((ev.clientY/rs.scale)-rs.top)-info.y;
                        this._pointer.drag.width=(info.w);
                        this._pointer.drag.height=(info.h);
                        this._pointer.drag.clientX=ev.clientX;
                        this._pointer.drag.clientY=ev.clientY;
                    }
                }
                this.state.canvasState.drag=this._pointer.drag;
                this._invalidateCanvas();
                this.props.worker.dispatchMessage(["wb", "sel", target]);
                */
            }
        } else {
            assert(!this._pointer.drag);
            this._renderer.clearSelect();
            this._invalidateCanvas();
        }
        this._updateMouseCursor();
    }

    private _updateSelection(ev: PointerEvent) {
        let id = null;
        if (this._renderer.hit.hitTask) {
            id = this._renderer.hit.hitTask.id;
        } else if (this._renderer.hit.hitStripe) {
            const C = this.state.const;
            if (this._renderer.hit.hitStripeY <= C.gpa.header.height) {
                // only header can select
                id = this._renderer.hit.hitStripe.t;
            }
        }
        if (null !== id) {
            if (!ev.ctrlKey && !ev.metaKey && !ev.shiftKey) {
                this._renderer.clearSelect(true);
            }
            this._renderer.select(DataOperationTarget.TASKS, id);
            return true;
        } else {
            return false;
        }
    }

    private _createPointerEvent(ev: { clientX; clientY }): CanvasHostBasePointerEvent {
        const rs = this.state.reactState;
        const ret: CanvasHostBasePointerEvent = {
            x: ev.clientX / rs.scale - rs.left,
            y: ev.clientY / rs.scale - rs.top,
            screenX: ev.clientX,
            screenY: ev.clientY,
            offsetX: rs.left,
            offsetY: rs.top,
            scale: rs.scale,
        };
        return ret;
    }

    private _setLastPointer(ev?: { clientX; clientY }) {
        this._pointer.last = ev ? this._createPointerEvent(ev) : null;
    }

    /** @internal */
    private _postSZMessage(text: string, text0?: string) {
        this.props.worker.postMessage([
            "sz",
            {
                w: Number.parseInt(this._textarea.style.width, 10),
                h: Number.parseInt(this._textarea.style.height, 10),
                text0: undefined !== text0 ? text0 : text,
                text,
            },
        ]);
    }

    private _findTextForId(bbox: { id: number; target: DataOperationTarget }) {
        if (DataOperationTarget.TASKS === bbox.target) {
            const task = MainWorkerPipe.findTask(this.state.reactState.tasks, bbox.id);
            if (task) {
                return task.name;
            } else {
                const stripe = this.state.reactState.stripes.find((stripe) => stripe.t === bbox.id);
                if (stripe) {
                    return stripe.label;
                }
            }
        }
        return null;
    }

    private _showTextArea() {
        if (this._textarea && this._renderer) {
            const bbox = this._renderer.getBBox(this.state.reactState);
            if (bbox?.screen && null !== bbox.target && null !== bbox.id) {
                this._textarea.style.left = bbox.textArea.x + "px";
                this._textarea.style.top = bbox.textArea.y + "px";
                this._textarea.style.width = bbox.textArea.w + "px";
                this._textarea.style.height = bbox.textArea.h + "px";
                const fs = 12;
                this._textarea.style.fontSize = fs.toString(10) + "px";
                this._textarea.dataset.selTarget = (bbox.target + 1).toString();
                this._textarea.dataset.selId = bbox.id.toString();
                const text = this._findTextForId(bbox);
                if ("string" === typeof text) {
                    this._textarea.dataset.text = text;
                    this._textarea.dataset.loading = String(true);
                    this._textarea.value = "";
                    this._textarea.style.paddingTop = ((bbox.textArea.h - fs) / 2).toString(10) + "px";
                    this._textarea.style.lineHeight = fs.toString(10) + "px";
                    this._textarea.focus();
                    this._textarea.select();
                    this._postSZMessage(text, null);
                } else {
                    this._textarea.dataset.text = null;
                    this._textarea.style.paddingTop = null;
                    this._textarea.style.lineHeight = null;
                    this._textarea.value = "";
                }
            }
        }
    }

    private calcLeft(frs) {
        //const rw=CanvasRowHeader.getWidth(this.state.const, this.state.reactState.l_min, this.state.reactState.l_max, this.state.reactState.scale);
        //const canvasLeft=(rs.left+(data.view.col0*C.colPx))*rs.scale;^M
        //const delta=frs.left-rw;
        //console.log("delta="+delta);
        return frs.left;
    }

    private _setFooterHeight(height: number) {
        //const px=(window?.devicePixelRatio||1);
        const p = 10;
        const windowHeight = window.innerHeight;
        const headerHeight = CONST.titleHeight + CONST.subtitleHeight;
        const maxH = windowHeight - (headerHeight + p);
        height = Math.max(p, Math.min(height, maxH));
        this.setState(
            {
                footerH: height,
            },
            () => {
                if (this.state.footer?.canvasState?.chart?.height) {
                    this.state.footer.canvasState.chart.height = height;
                    this.forceUpdate(() => {
                        this._invalidateCanvas();
                    });
                } else {
                    this._invalidateCanvas();
                }
            },
        );
    }
}
