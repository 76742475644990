import * as React from "react";
import { Messages, AuthPropsI, Auth } from "@lcmd/lcmd2logincomponent";
import { intl, FrameworkHttpError, errorToMessage } from "lcmd2framework";
import { Image, ImageFit } from "@fluentui/react";
import ReCAPTCHA from "react-google-recaptcha";

import { validatePassword } from "./utils/auth/validate-password";
import { LCMDContextType, useLCMD } from "../app/LCMContext";
import { LcmdLogin, LcmdLoginProps } from "@/lcmd2loginV2/src/LcmdLogin";
import userflow from "userflow.js";
import { SERVICES } from "@/model/services";
import { getCurrentLocale } from "@/components/utils/date/locale";
// import { Userpilot } from "userpilot";

type LoginCtx = {
    LCMD: LCMDContextType;
    sso?: {
        domain: string;
    };
    pinSecret: string | null;
    status?: {
        hasPassword?: boolean;
    } | null;
    meta?: {
        firstName: string | null;
        lastName: string | null;
    } | null;
    token: string | null;
    recaptcha: string | null;
    sub: string | null;
};

const LOGO_IMAGE_SRC = "/img/lcm_logo.svg";
const BACKGROUND_IMAGE_SRC = "/img/background-image.jpg";

function authErrorToMessage(error) {
    // defaultMessageKey === 'error.409' || 'error.401' ...
    const { message: defaultMessageKey } = error;

    const messageKey = `auth.${defaultMessageKey}`;

    // in a case when the location doesn't contain a translate in auth context
    // return default error
    return intl.get(messageKey).defaultMessage(intl.get(defaultMessageKey));
}

function Captcha(this: LoginCtx, props) {
    const onChange = React.useCallback((token) => {
        this.recaptcha = token;
        props.onChange(Boolean(this.recaptcha));
    }, []);
    return <ReCAPTCHA sitekey="6LcMlvMbAAAAAHdxEVDepmTvfCBqZdCVETr4T3FC" onChange={onChange} />;
}

function BackgroundImage() {
    return <Image imageFit={ImageFit.cover} src={BACKGROUND_IMAGE_SRC} height="100%" />;
}

async function onVerifyEmail(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        resolve(true);
    });
    // return new Promise<boolean>((resolve:(value:boolean)=>void, reject:(reason:Error)=>void)=>{
    //   getDomainInfo(false,  email, (error, resp)=>{
    //     if (error) {
    //       resolve(false); //@TODO better error handling
    //     } else if (resp?.sso?.redirectURL && "string"===typeof(resp?.sso?.redirectURL)) {
    //       window.location.href=resp.sso.redirectURL+"?email="+encodeURIComponent(email)+"&redirectURL="+encodeURIComponent(window.location.href);
    //       resolve(false);
    //     } else {
    //       resolve(true);
    //     }
    //   });
    // });
}

async function onLoginWithSSO(this: LoginCtx, result) {
    const keepMeLoggedIn = true;
    this.LCMD.authLogin(
        result.microsoft.account.username,
        { accessToken: result.microsoft.accessToken },
        (error: FrameworkHttpError, result: any) => {
            if (error) {
                console.error(error); //@TODO: handle error
            } else {
                this.LCMD.natigateToView("files", {}, { keepMeLoggedIn, result });
            }
        },
        null,
        keepMeLoggedIn,
    );
}

async function onLoginViaPassword(
    this: LoginCtx,
    email: string,
    password: string,
    keepMeLoggedIn: boolean,
): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.LCMD.authLogin(
            email,
            password,
            (error: FrameworkHttpError, result: any) => {
                if (error) {
                    reject(new Error(authErrorToMessage(error)));
                } else {
                    this.LCMD.natigateToView(
                        "files",
                        {},
                        {
                            keepMeLoggedIn,
                            result,
                        },
                    );
                    resolve();
                }
            },
            this.recaptcha,
            keepMeLoggedIn,
            {
                sso: this.sso,
            },
        );
    });
}

async function onSignUp(this: LoginCtx, email: string, firstName: string, lastName: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.meta = {
            firstName,
            lastName,
        };
        resolve();
    });
}

async function onRequestSignUpPin(this: LoginCtx, email: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.LCMD.authCreate(
            email,
            (error: FrameworkHttpError) => {
                if (error) {
                    reject(new Error(authErrorToMessage(error)));
                } else {
                    resolve();
                }
            },
            this.recaptcha,
        );
    });
}

async function onVerifySignUp(this: LoginCtx, email: string, pin: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        const pinSecret = this.pinSecret;
        this.LCMD.authVerify(email, pin, pinSecret, null, (error: FrameworkHttpError, result: any) => {
            if (error) {
                reject(new Error(authErrorToMessage(error)));
            } else {
                this.token = result.auth_token;
                this.status = result.extra;
                if (this.status?.hasPassword) {
                    this.LCMD.natigateToView(
                        "files",
                        {},
                        {
                            keepMeLoggedIn: true,
                            result,
                        },
                    );
                    reject(
                        new Error(
                            authErrorToMessage({
                                message: "error.409", // already exists
                            }),
                        ),
                    );
                } else {
                    resolve();
                }
            }
        });
    });
}

async function onCreatePassword(email: string, password: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.LCMD.authVerify(
            email,
            null,
            this.token,
            password,
            (error: FrameworkHttpError, result: any) => {
                if (error) {
                    reject(new Error(authErrorToMessage(error)));
                } else {
                    this.token = result.auth_token;
                    resolve();
                }
            },
            this.meta,
        );
    });
}

function onLogin(this: LoginCtx, sub: string, authToken: string, email: string, registered?: boolean) {
    // remove hash router path from url
    const hashIndex = location.href.indexOf("#");
    if (hashIndex > 0) {
        window.history.pushState(null, "", location.href.substring(0, hashIndex));
    }

    userflow.init(SERVICES.USERFLOW_TOKEN);
    if(registered){
        userflow.identify(sub, {
            email: email,
            signed_up_at: new Date().toISOString(),
            locale_code: getCurrentLocale().code
        })
    }else{
        userflow.identify(sub, {
            email: email,
            locale_code: getCurrentLocale().code
        })
    }

    // login
    this.LCMD.natigateToView(
        "files",
        {},
        {
            keepMeLoggedIn: true,
            result: { auth_token: authToken, sub },
        },
    );
}

async function onRequestRestorePassword(this: LoginCtx, email: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.LCMD.authLogin(
            email,
            null,
            (error: FrameworkHttpError, result: any) => {
                if (error) {
                    reject(new Error(errorToMessage(error)));
                } else {
                    this.pinSecret = result.pin_secret;
                    resolve();
                }
            },
            this.recaptcha,
            false,
        );
    });
}

async function onVerifyRestorePassword(email: string, pin: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        const pinSecret = this.pinSecret;
        this.LCMD.authVerify(email, pin, pinSecret, null, (error: FrameworkHttpError, result: any) => {
            if (error) {
                reject(new Error(authErrorToMessage(error)));
            } else {
                this.token = result.auth_token;
                resolve();
            }
        });
    });
}

async function onRestorePassword(email: string, newPassword: string): Promise<void> {
    return new Promise((resolve: () => void, reject: (e: Error) => void) => {
        this.LCMD.authVerify(email, null, this.token, newPassword, (error: FrameworkHttpError, result: any) => {
            if (error) {
                reject(new Error(authErrorToMessage(error)));
            } else {
                this.token = result.auth_token;
                resolve();
            }
        });
    });
}

function onTermsAndConditionsClick() {
    window.open(intl.get("links.termsURL"), "_blank");
}

function onPrivacyPolicyClick() {
    window.open(intl.get("links.privacyURL"), "_blank");
}

function doIntlHack(messages: any, prefix: string) {
    const ret = {};
    Object.getOwnPropertyNames(messages).reduce((ret, _n) => {
        const n = [prefix, _n].join(".");
        if ("string" === typeof messages[_n]) {
            ret[_n] = intl.get(n);
        } else {
            ret[_n] = doIntlHack(messages[_n], n);
        }
        return ret;
    }, ret);
    return ret;
}

export function Login(props: { nav?: { signUp?: string; login?: string; signIn?: string } }) {
    const LCMD = useLCMD();
    const signIn = React.useMemo(() => {
        let ret = null;
        const signIn = props?.nav?.signIn || "";
        if ("string" === typeof signIn && signIn.startsWith("{") && signIn.endsWith("}")) {
            try {
                ret = JSON.parse(signIn);
            } catch (e) {
                ret = null;
            }
        }
        return ret;
    }, [props?.nav?.signIn]);
    const loginCtx = React.useMemo(() => {
        const config = LCMD.getConfig();
        return {
            LCMD,
            pinSecret: null,
            meta: null,
            token: null,
            sso: config.sso,
        };
    }, [LCMD]);

    const loginCB = React.useMemo<LcmdLoginProps & AuthPropsI>(
        () => ({
            Captcha: undefined, // implement here a captcha react component if needed
            keepMeLoggedInDisabled: Boolean(loginCtx.sso),
            keepMeLoggedIn: !loginCtx.sso,
            initialState: (props.nav?.signUp && "SIGN_UP") as any,
            messages: doIntlHack(Messages, "login") as any,
            email: props.nav?.signUp || props.nav?.login || "",
            onVerifyEmail: onVerifyEmail.bind(loginCtx),
            onLoginViaPassword: onLoginViaPassword.bind(loginCtx),
            onLoginViaPin: onVerifyRestorePassword.bind(loginCtx),
            onRequestLoginPin: null, // onRequestLoginPin.bind(loginCtx),
            onRequestRestorePassword: onRequestRestorePassword.bind(loginCtx),
            onVerifyRestorePassword: onVerifyRestorePassword.bind(loginCtx),
            onRestorePassword: loginCtx.sso ? null : onRestorePassword.bind(loginCtx),
            onSignUp: loginCtx.sso ? null : onSignUp.bind(loginCtx),
            onRequestSignUpPin: onRequestSignUpPin.bind(loginCtx),
            onVerifySignUp: onVerifySignUp.bind(loginCtx),
            onCreatePassword: onCreatePassword.bind(loginCtx),
            onTermsAndConditionsClick,
            onPrivacyPolicyClick,
            validatePassword,
            onLoginWithSSO: onLoginWithSSO.bind(loginCtx),
            onLogin: onLogin.bind(loginCtx),
        }),
        [loginCtx],
    );
    React.useEffect(() => {
        if (signIn?.email && signIn?.secret) {
            const keepMeLoggedIn = true;
            LCMD.authLogin(
                signIn?.email,
                signIn?.secret,
                (error: FrameworkHttpError, result: any) => {
                    if (error) {
                        console.error(error); //@TODO: handle error
                    } else {
                        LCMD.natigateToView(
                            "files",
                            {},
                            {
                                keepMeLoggedIn,
                                result,
                            },
                        );
                    }
                },
                null,
                keepMeLoggedIn,
            );
        }
    }, [LCMD, signIn]);

    const newLogin = true;

    return signIn ? (
        <div></div>
    ) : newLogin ? (
        <LcmdLogin {...loginCB} />
    ) : (
        <Auth fixedLayout rightPane={<BackgroundImage />} logoSrc={LOGO_IMAGE_SRC} {...loginCB}></Auth>
    );
}
