import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { WebAppTelemetryFactory } from "@/app/services/WebAppTelemetry.service";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { Label } from "@/components/ui/label";

type EditNameInput = {
    name: string;
    saveName?: (name: string) => void;
    darkBg?: boolean;
    userflowId?: string;
    trackingEvent?: string;
    inputClassName?: string;
    className?: string;
    triggerValue?: string;
    showCancel?: boolean;
    onResetText?: () => void;
    label?: string;
    placeholder?: string;
    allowEmptyString?: boolean;
};

export function EditNameInput({
    name: nameField,
    saveName,
    darkBg,
    userflowId,
    trackingEvent,
    inputClassName,
    className,
    triggerValue,
    onResetText,
    label,
    placeholder,
    allowEmptyString = false,
}: EditNameInput) {
    const [isEditMode, setEditMode] = useState<boolean>(false);
    const name = nameField?.trim();
    const editNameInputValue = name || placeholder;

    const handleSaveName = (value: string) => {
        if (name == value) {
            setEditMode(false);
            return;
        }

        saveName(value);

        if (trackingEvent) {
            WebAppTelemetryFactory.trackEvent(trackingEvent, {
                place: "header",
            });
        }

        setEditMode(false);
    };

    function resetText() {
        handleSaveName("");
        onResetText();
    }

    return (
        <div className="flex flex-col gap-2">
            {label && (
                <Label htmlFor="editInput" className="font-semibold">
                    {label}
                </Label>
            )}
            <div
                onClick={() => !isEditMode && saveName && setEditMode(true)}
                className={cn("relative max-w-max", className)}
                data-userflow-id={userflowId}
            >
                {!isEditMode ? (
                    <div className={"cursor-text truncate text-ellipsis leading-5"}>
                        {triggerValue || editNameInputValue}
                    </div>
                ) : (
                    <Input
                        id="editInput"
                        placeholder={editNameInputValue}
                        autoFocus={true}
                        defaultValue={name}
                        onKeyUp={(event) => {
                            const value = (event.target as HTMLInputElement).value;
                            if (event.key === "Escape") {
                                setEditMode(false);
                                return;
                            }
                            if (event.key === "Enter") {
                                if (value === "" && !allowEmptyString) {
                                    setEditMode(false);
                                    return;
                                }
                                handleSaveName(value);
                            }
                        }}
                        onBlur={(event) => {
                            const value = (event.target as HTMLInputElement).value;

                            if (value === "" && !allowEmptyString) {
                                setEditMode(false);
                                return;
                            }
                            handleSaveName(value);
                        }}
                        className={cn(darkBg && "bg-black", inputClassName)}
                    />
                )}
            </div>
            {onResetText && isEditMode && (
                <Button
                    size={"icon"}
                    variant={"secondary"}
                    className={"absolute right-1 top-1/2 my-auto h-6 w-6 -translate-y-1/2 transform cursor-pointer"}
                    onClick={resetText}
                >
                    <X size={20} />
                </Button>
            )}
        </div>
    );
}
