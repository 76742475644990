import {
    assert,
    DataModelFilter,
    DataModelFilterCollection,
    DataModelFilterSavedAs,
    DateToMonday,
    END_OF_DAY_MS,
    EpochDaystoEpochMS,
    EpochMStoEpochDays,
    FILTER_SPECIAL_UNIT_MASK,
    FILTER_SPECIAL_UNIT_MONTH,
    FILTER_SPECIAL_UNIT_WEEKS,
    FILTER_SPECIAL_VALUE,
    FILTER_SPECIAL_VALUE_MASK,
    FrameworkError,
    FrameworkErrorDataModelNeedsSync,
    FrameworkHttpError,
    HelperDateTo24TimeStr,
    HelperEpochMSToLocalDate,
    LCMDContextCardStatus,
    MAX_EPOCH_DAYS,
    TableExportHelper,
    OnParticleCoreUpdateEvent,
    OnParticleWorkerMsgEvent,
    ParticleCore,
    stringToHex,
    weekNumber,
} from "../model/api/particlecore";
import { CanvasCardData, CanvasTaskData, DependencyList, EpochDate } from "../model/DataModel";
import { DataModel, isWorkDayAll, ParticleCardData } from "../model/DataModel";
import { dataViewReducer } from "../app/DataView";
import { getTaktingFluxState } from "../app/TaktingWorker";
import { XlsxExport } from "./XlsxExport";
import { calcCriticalPath, checkCircular, checkDependencies } from "./Deps";
import {
    LCMDCOntextAddPredecessorState,
    LCMDContextCardDetailsState,
    LCMDContextDependencyDetailsState,
    LCMDContextDependencyType,
    LCMDContextProcessDetailsState,
    LCMDContextTaskDetailsResult,
    LCMDContextTaskDetailsResultCardDetails,
    LCMDContextTaskDetailsResultDependencyDetails,
    LCMDContextTaskDetailsResultTradeDetails,
    LCMDContextTodoItem,
    LCMDContextTodoItemProcessDetails,
    LCMDContextTodoItemTarget,
    LCMDContextTodoResult,
    LCMDContextUnitType,
    LCMDUploadToken,
} from "../app/LCMDContextTypes";
import { CoreEventNames, CoreEventsCallbackData } from "../app/types/CoreEvents";
import { ProcessGetter } from "../model/api/processGetter";
import { TradesGetter } from "../model/api/tradesGetter";
import { HiveGetter } from "../model/api/hiveGetter";
import { ParticleGetter } from "../model/api/particleGetter";
import { ComplexProp } from "../model/api/complexProp";
import {
    ComplexDocumentTransaction,
    DocumentTransaction,
    IDocumentTransaction,
} from "../model/api/documentTransaction";
import { InitCoreOptions, IWorkerCanvas, ParticleCoreMeta, ParticleCoreUserMeta } from "../model/api/coreTypes";
import { initServices } from "../model/services";
import { ParticleProjectBuilder } from "../model/api/particleProjectBuilder";
import { _ensureCoreFor } from "../model/WorkerCore";
import { getMilestoneStatus, getStatusForCards } from "@/components/common/ProcessTooltip/helper";

import { AttachedReasonCode, AttachedReasonCodeId, ReasonCodesService } from "./services/ReasonCode.service";
import { ItemPriority, ItemState, ItemType, ToDoProps } from "../components/ToDo/interface";
import { generateTodosFromRawDate } from "../components/ToDo/ActionItem.factory";
import { dateToEpochDay, isToDoOverdue } from "../components/Utils";
import * as ApiHelper from "../model/ApiHelper";
import { Persona } from "../legacy/SubTypes";
import { UserMap } from "../components/hooks/UserMapTypes";
import { SubCache } from "@/legacy/SubCache";
import "../app/prepare";
import { AttachedChecklist, AttachedChecklistId, Checklist, ChecklistService } from "@/core/services/Checklist.service";
import { CONST } from "@/legacy/settings";
import { NotificationService } from "@/core/Notification/NotificationService";
import { DefaultNotificationService } from "@/core/Notification/DefaultNotificationService";
import { LCMDNotificationService } from "@/core/Notification/LCMDNotificationService";
import { convertMillisecondsToDays } from "@/utils/DateUtils";
import posthog from "posthog-js";

export * from "../model/api/particlecore";

export type ProcessId = number;

function EpochMSGetTimeOfsMS(d: number) {
    return d - EpochDaystoEpochMS(EpochMStoEpochDays(d));
}

interface LCMDContextTodoItemProcessDetailsAREAPATH extends LCMDContextTodoItemProcessDetails {
    areaPath: string[];
}

function processHasTrade(pGt: ProcessGetter, subTrades: { [tid: number]: true }) {
    const _rw = pGt.aux<any>("_rw") || {};
    const ret = Object.getOwnPropertyNames(subTrades || {}).reduce((ret, tid) => {
        ret = ret || "number" === typeof _rw[tid];
        return ret;
    }, false);
    return ret;
}

export class Core extends ParticleCore {
    /**
     * This is set from outside in various code parts. Should be refactored.
     */
    public _canvas: IWorkerCanvas | undefined = undefined;
    private filter: DataModelFilter | number | null = null;
    protected _reasonCodes: ReasonCodesService | undefined;
    protected _checkLists: ChecklistService | undefined; //@nikhil checklist_api
    public notificationService: NotificationService;

    constructor(opt: InitCoreOptions, canvas?: IWorkerCanvas) {
        super(opt);
        this._canvas = canvas;
        this.notificationService = opt?.notificationService || new DefaultNotificationService();
    }

    public get meta(): ParticleCoreMeta {
        return this._canvas?.meta!;
    }

    public get userMeta(): ParticleCoreUserMeta {
        return this._canvas?.userMeta!;
    }

    public get projectId(): string | undefined {
        return this.meta?.pid;
    }

    public get reasonCodes() {
        if (!this._reasonCodes) {
            this._reasonCodes = new ReasonCodesService(this);
        }
        return this._reasonCodes;
    }

    //@nikhil checklist_api

    public get checkLists() {
        if (!this._checkLists) {
            this._checkLists = new ChecklistService(this);
        }
        return this._checkLists;
    }

    public get auth_token() {
        return this._auth_token;
    }

    public static init(opt: InitCoreOptions) {
        global.XMLHttpRequest = require("xhr2");
        initServices(opt);
        Core.initFactory();
    }

    public static initFactory() {
        ParticleCore.dataViewReducer = dataViewReducer;
        Core.factory = (opt: InitCoreOptions, canvas?: IWorkerCanvas) => {
            opt.notificationService = new LCMDNotificationService();
            return new Core(opt, canvas);
        };
    }

    public static newInstance(opt: { model: DataModel; auth_token: string }, canvas?: IWorkerCanvas): Core {
        assert(Core.factory);
        return Core.factory({ ...opt, initEmitter: true }, canvas);
    }

    public static async load(
        projectId: string,
        credentials: { auth_token: string; readonly?: boolean },
        options?: {
            maxCommit?: number;
        },
    ): Promise<ParticleCore | null> {
        try {
            if (Array.isArray((credentials as any)?._ops)) {
                const model: DataModel = DataModel.loadOps((credentials as any)?._ops, true, options);
                if (model) {
                    model.setAllGrid();
                    const ret = Core.newInstance({ model, auth_token: credentials.auth_token });
                    return ret;
                } else {
                    return null;
                }
            } else {
                const project = await ParticleCore._getProject(credentials.auth_token, projectId);
                if (project?.sid) {
                    const auth_ret = await DataModel.authenticateMasterUser(credentials.auth_token, project.sid);
                    if (auth_ret?.sid && auth_ret?.token) {
                        assert(auth_ret.master === project.sid);
                        const sandbox = auth_ret?.sid;
                        const model: DataModel = await DataModel.loadStream(
                            sandbox,
                            auth_ret.token, //master_token,
                            0,
                            auth_ret.ofs,
                            true,
                        );
                        model.setStorageName(sandbox);

                        await model.doInitialSyncFromCache(DataModel.cache, auth_ret.token, undefined);

                        if (model) {
                            model.setAllGrid();
                            const ret = Core.newInstance({ model, auth_token: auth_ret.token });
                            return ret;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }
        } catch (e) {
            return null;
        }
    }

    public on(
        event: CoreEventNames,
        fn: (data: CoreEventsCallbackData, triggeredEvent: CoreEventNames) => void,
        context?: any,
    ) {
        if (!this._model?.eventEmitter) {
            console.warn("using eventEmitter before init");
            return;
        }
        return this._model.eventEmitter.on(event, fn, context);
    }

    protected override onPPCanvasInit(evt: { core: Core }) {
        assert(this === evt.core);

        if (!this._model?.eventEmitter) {
            console.warn("using eventEmitter before init");
            return;
        }
        this._model.eventEmitter.on("processes::durationChanged::positiveEffect", async (args) => {
            const projectColaborators = await this.getProjectCollaborators(this.projectId!);

            // @todo: total hack to resolve issue for one specific user, remove asap when "card magic" is resolved
            const shouldExit = projectColaborators.some((colaborator) => {
                return colaborator.data.email == "bnbtji@gmail.com";
            });

            if (
                this.projectId === "d6f6372b-9073-4a4e-8711-986d3c897581" ||
                this.projectId === "549cd102-13c8-4128-91af-0d48733519d0" ||
                this.projectId === "6e6ac20b-a62c-4e28-bf63-eac883fa9173" ||
                shouldExit
            ) {
                return;
            }

            assert(args[0].target.type === "process");
            const processGetter = new ProcessGetter(this, args[0].target.id);

            assert(processGetter.id === args[0].target.id, `Process with id ${args[0].target.id} does not exist`);
            const cardsOfCreatedProcess = this.gatherActivitiesForTask(processGetter, {
                resolve: {
                    wf: true,
                    name: true,
                },
            });

            const documentTransaction = new DocumentTransaction(this);
            const _meta = this.meta;
            const projectInfos = this.getProjectInfo();
            if (
                processGetter.id >= 0 &&
                (_meta.role === "admin" || (_meta.role === "user" && !projectInfos.rbac[projectInfos.sub]))
            ) {
                // fix only for real tasks
                this._fixCardOps(documentTransaction, cardsOfCreatedProcess);
                documentTransaction.commit();
            }
        });
    }

    protected onPPCanvasUserMeta(evt: { core: Core }): any {
        const fs = Number.parseInt(evt.core.userMeta?.prefs?.fs, 10);
        if (fs) {
            evt.core.patchLegacyFluxState("fs", fs);
        }
    }

    protected override onPPCanvasWillUpdate(evt: { core: Core; taskIds: string[] }): any {
        assert(this === evt.core);
        const C = this.getViewConst();
        let M_chain;
        if ("conflict" === C?.details) {
            checkDependencies(this); // calc dependency chain is needed...
            {
                // calc meta chain
                const taskIds = evt.taskIds;
                const viewMeta = this.getLegacyFluxState("view.meta");
                const _M_chain = viewMeta.model.chain;
                if (Array.isArray(_M_chain)) {
                    M_chain = _M_chain.filter((tid) => taskIds.indexOf(tid.toString(10)) >= 0);
                    this.sortInCanvasOrder(M_chain);
                }
            }
        } else if ("criticalpath" === C?.details) {
            calcCriticalPath(this);
        }
        return {
            chain: M_chain,
            n_chain: Array.isArray(M_chain) ? M_chain.length : 0,
            i_chain: 0,
        };
    }

    protected override onPPCanvasDidUpdate(evt: { core: Core; userCtx?: any }): void {
        assert(this === evt.core);
        assert(evt.userCtx.i_chain === evt.userCtx.n_chain);
    }

    protected onPPProcessUpdateCtx(evt: OnCoreUpdateEvent, pGt: ProcessGetter, userCtx: any): void {
        assert(userCtx.i_chain <= userCtx.n_chain);
        userCtx.m_chain =
            userCtx.i_chain < userCtx.n_chain && userCtx.chain[userCtx.i_chain] === pGt.id && ++userCtx.i_chain
                ? 1
                : undefined;
    }

    protected onPPProcessUnchanged(
        evt: OnCoreUpdateEvent,
        ct: CanvasTaskData,
        pGt: ProcessGetter,
        userCtx: any,
    ): void | boolean {
        const name = pGt.value<string>("name", pGt.aux<string>("_name") || "");
        const color = evt.core.getColorForProcess(pGt, evt.trGt);
        assert(color === ct.color && name === ct.name);
        const task_cps = pGt.aux<number>("_cps");
        const task_cpl = pGt.aux<number>("_cpl");
        return userCtx.m_chain === ct.m && (ct as any)._cps === task_cps && (ct as any)._cpl === task_cpl;
    }

    protected onPPProcessChanged(
        evt: OnCoreUpdateEvent,
        canvasTask: CanvasTaskData,
        pGt: ProcessGetter,
        userCtx: any,
    ): void {
        const C = evt.core.getViewConst();
        const _cards = evt.core.gatherActivitiesForTask(pGt);
        const cards = _cards.cards;
        const g1 = pGt.aux<number>("_x2");
        const { p, s, atm } = pGt.isMilestone()
            ? getMilestoneStatus(pGt.getMilestoneState(), C.today, convertMillisecondsToDays(g1))
            : getStatusForCards(C.today, g1, cards);
        canvasTask.name = pGt.value<string>("name", pGt.aux<string>("_name") || "");
        const trade = evt.core.getTradeForProcess(pGt, evt.trGt);
        canvasTask.trade = trade?.name || "-";
        canvasTask.color = evt.core.getColorForProcess(pGt, evt.trGt);
        canvasTask.tradeIcon = trade?.icon;
        canvasTask.m = userCtx.m_chain;
        canvasTask.status = s;
        canvasTask.atm = atm;
        canvasTask.isVirtual = pGt.isVirtual;
        canvasTask.hasComments = pGt.getComments().size > 0;

        if ("stability" === C.details) {
            if (false && posthog.getFeatureFlag("ab-checklist") === "test") {
                const attachedChecklists = evt.core.checkLists.getAttachedChecklists(pGt.id);
                const doneChecklists = attachedChecklists.filter((checklist) => checklist.status === "done");
                const isOverdue = attachedChecklists.some((checklist) => {
                    if (checklist.status == "done") {
                        return false;
                    }
                    return dateToEpochDay(checklist.endDate) + 1 < CONST.views[0].today;
                });

                const isAllOpen = attachedChecklists.every((checklist) => checklist.status == "open");
                const isAllDone = doneChecklists.length == attachedChecklists.length;
                const isInProgress = !isAllDone && !isAllOpen && !isOverdue;

                if (attachedChecklists.length == 0) {
                    canvasTask.c = 0xa1a1aa; //gray
                    canvasTask.l = "0";
                } else {
                    if (isAllOpen) {
                        canvasTask.c = 0x742fad; //purple
                    }

                    if (isOverdue) {
                        canvasTask.c = 0xd83b01; // red
                    }

                    if (isAllDone) {
                        canvasTask.c = 0x107c10; //green
                    }

                    if (isInProgress) {
                        canvasTask.c = 0x6790f2; //blue
                    }

                    canvasTask.l = `${doneChecklists.length} / ${attachedChecklists.length}`;
                }

                canvasTask.v = C.details;
            } else {
                const ctx = {
                    stabs: {},
                    done: 0,
                    overdue: 0,
                    todos: [] as LCMDContextTodoItem[],
                };
                const todos = evt.core.getTaskTodos(pGt, ctx, evt.trGt, evt._Gt);
                assert(todos === ctx.todos);
                canvasTask.v = C.details;
                canvasTask.p = todos.length > 0 ? (ctx.done * 100) / todos.length : 100;
                if (ctx.done === todos.length) {
                    // all done
                    canvasTask.c = 0x107c10;
                } else if (0 === ctx.overdue) {
                    canvasTask.c = 0xa1a1aa; // white
                } else {
                    canvasTask.c = 0xd83b01; // red
                }
                canvasTask.l = (ctx.done > 99 ? 99 : ctx.done) + "/" + (todos.length > 99 ? 99 : todos.length);
            }
        } else if ("status" === C.details) {
            const STATUS_BACKGROUND_COLORS = [
                0xc1c5c7, // 0===OPEN
                0x107c10, // 1===DONE
                0x009bd4, // 2===IN_PROGRESS,
                0xe47829, // 3===LATE
                0xd83b01, // 4===OVERDUE
            ];
            canvasTask.v = C.details;
            canvasTask.p = p;
            canvasTask.c = STATUS_BACKGROUND_COLORS[s];
            canvasTask.l = p < 100 ? p.toString(10) + "%" : "100%";
        } else if ("conflict" === C.details) {
            canvasTask.v = C.details;
            const task_dc = pGt.aux<number>("_dc");
            if (undefined === task_dc) {
                canvasTask.c = 0x107c10; // green
            } else if (task_dc < 0) {
                canvasTask.c = 0xffc000; // yellow
                canvasTask.l = "+" + (-task_dc).toString(10);
            } else {
                assert("number" === typeof task_dc && task_dc >= 0);
                canvasTask.c = 0xd83b01; // red
                canvasTask.l = "+" + task_dc.toString(10);
            }
        } else if ("criticalpath" === C.details) {
            canvasTask.v = C.details;
            const task_cps = pGt.aux<number>("_cps");
            const task_cpl = pGt.aux<number>("_cpl");
            if (task_cps && task_cpl && task_cps === task_cpl) {
                canvasTask.c = 0xff0000;
            } else {
                canvasTask.c = 0xcccccc;
            }
            canvasTask.l =
                (task_cps ? new Date(task_cps).toISOString().substring(0, 10) : "?") +
                " " +
                (task_cpl ? new Date(task_cpl).toISOString().substring(0, 10) : "?");
            (canvasTask as any)._cps = task_cps;
            (canvasTask as any)._cpl = task_cpl;
        } else {
            const train = pGt.value<string | number>("train", pGt.aux<string | number>("_train"));
            if (undefined !== train) {
                canvasTask.v = "train";
                canvasTask.l = train.toString() || "";
            }
            canvasTask.p = p;
        }
    }

    protected onWBProcessUpdateCtx(evt: OnCoreUpdateEvent, wbPGt: ProcessGetter, userCtx: any): void {}

    protected onWBProcessUnchanged(
        evt: OnCoreUpdateEvent,
        ct: CanvasTaskData,
        wbPGt: ProcessGetter,
        userCtx: any,
    ): void | boolean {
        const name = wbPGt.value<string>("name", wbPGt.aux<string>("_name") || "");
        const color = evt.core.getColorForProcess(wbPGt, evt.wbTrGt);
        assert(color === ct.color && name === ct.name);
    }

    protected onWBProcessChanged(
        evt: OnCoreUpdateEvent,
        canvasTask: CanvasTaskData,
        wbPGt: ProcessGetter,
        userCtx: any,
    ): void {
        canvasTask.name = wbPGt.value<string>("name", wbPGt.aux<string>("_name") || "");
        canvasTask.color = evt.core.getColorForProcess(wbPGt, evt.wbTrGt);
        const train = wbPGt.value<string | number>("train", wbPGt.aux<string | number>("_train"));
        if (undefined !== train) {
            canvasTask.v = "train";
            canvasTask.l = train.toString() || "";
        }
    }

    private static syncProps: {
        [key: string]: string | number;
    } = {
        // specified which property are synced from the PP trade sentinels to their wb trades counterparts
        name: "",
        color: 0xb4c85d,
    };
    protected onWBSync(ctx: OnCoreUpdateEvent, init?: boolean): void {
        const core = ctx.core;
        assert(core);
        const wb = ctx.wb;
        assert(wb);
        const wbTrGt = ctx.wbTrGt;
        assert(wbTrGt, "TradeGetter for WBSync is missing.");
        if (init) {
            // initially loaded, so some extra sync
            const t = new DocumentTransaction(wb);
            //const wbPGt=ctx.wbPGt;
            const trGt = ctx.trGt;
            const trades = wb.getAllTradeIds();
            trades.forEach((trId) => {
                wbTrGt.reset(trId);
                assert(wbTrGt.id === trId);
                Object.getOwnPropertyNames(Core.syncProps).forEach((prop) => {
                    if (wbTrGt._(prop, -1) < 0) {
                        // create default property for sync
                        console.info("fix missing prop " + prop);
                        t.setTradeProp<string | number>(wbTrGt.id, prop, Core.syncProps[prop]);
                    }
                });
                wbTrGt.getRef(trGt);
                if (trGt.id < 0) {
                    console.warn(
                        "wb trade " + wbTrGt.value<string | null>("name", null) + "[" + wbTrGt.id + "] has no sentinel",
                    );
                } else {
                    //console.debug("wb trade "+wbTrGt.value<string>("name", null)+"["+wbTrGt.id+"] has sentinel "+trGt.value<string>("name", null)+"["+trGt.id+"]");
                }
            });
            t.commit();
        }
        wb.getTrades(true).forEach((trade) => {
            wbTrGt.reset(trade.id);
            assert(wbTrGt.id === trade.id);
            wbTrGt.syncPropsFrom(core, Object.getOwnPropertyNames(Core.syncProps));
        });
    }

    protected _coreUpdateActivities(
        ctx: { model: DataModel },
        tid: number,
    ): {
        cards: ParticleCardData[];
        _: number;
        _y_max: number;
    } {
        assert(ctx.model === this._model);
        const _ctx = ctx as any;
        if (!_ctx.pIt) {
            _ctx.pIt = new ProcessGetter(this);
        }
        _ctx.pIt.reset(tid);
        return this.onCreateCardsForProcess(_ctx.pIt);
    }

    public isProcessFixed(pGt: ProcessGetter): number {
        // returns bitmask, if the task has an activity card with a "fixed" date (E.g. on saturday) associated with it..
        let ret = 1 << 0;
        if (pGt.id >= 0) {
            if (pGt.isVirtual) {
                // task is "virtual"
                ret = 1 << 2;
            } else {
                const it = new ComplexProp();
                const props = pGt.getAllProps();
                ret = 0;
                for (props.first(it); props.next(it); ) {
                    const cardProp = it.isCARDPROP();
                    if (cardProp && "day" === cardProp.prop) {
                        const day = pGt.value<number>(it);
                        if (day >= MAX_EPOCH_DAYS) {
                            ret = 1 << 1;
                        }
                    }
                }
            }
        }
        return ret;
    }

    public isAnyProcessFixed(tids: number[]) {
        let ret: boolean = false;
        if (Array.isArray(tids) && tids.length > 0) {
            const pGt = new ProcessGetter(this);
            for (let i = 0; i < tids.length; i++) {
                pGt.reset(tids[i]);
                const _ret = 0 !== this.isProcessFixed(pGt);
                ret = ret || _ret;
            }
        }
        return ret;
    }

    private cmpActivityCards(c1, c2) {
        let ret = c1.tid - c2.tid;
        if (0 === ret) {
            ret = c1.date - c2.date;
            if (0 === ret) {
                ret = c1.y - c2.y;
                if (0 === ret) {
                    ret = c2.yTS - c1.yTS;
                    if (0 === ret) {
                        ret = c1.aid - c2.aid;
                        if (0 === ret) {
                            ret = c1.i - c2.i;
                        }
                    }
                }
            }
        }
        return ret;
    }

    private sortInCanvasOrder(tids: number[]) {
        const pIt = new ProcessGetter(this);
        tids.sort((tid1, tid2) => {
            const t1_stripe1 = pIt.reset(tid1).aux<number>("_stripe1");
            const t2_stripe1 = pIt.reset(tid2).aux<number>("_stripe1");
            let ret = t1_stripe1 - t2_stripe1;
            if (0 === ret) {
                ret = tid1 - tid2;
            }
            return ret;
        });
        return tids;
    }

    private _getAttachmentsForAcitvity(pGt: ProcessGetter, a) {
        const prop = new ComplexProp();
        const atms = Object.getOwnPropertyNames(a).reduce((ret, n) => {
            prop._reset(n);
            const blobId = prop.isATTACHMENTID();
            if (blobId) {
                const _Gt = new ParticleGetter(pGt.core, a[n]);
                const propValue = _Gt.value<any>();
                if (propValue) {
                    const contentType = propValue?.contentType || "application/octet-stream";
                    ret.push({
                        ...propValue,
                        blobId,
                        url: Core.createAttachmentUrl({ blobId, contentType }),
                    });
                }
            }
            return ret;
        }, []);
        return atms.length > 0 ? atms : undefined;
    }

    private _resolveActivityOp = function (ret, it: ComplexProp, _Gt: ParticleGetter) {
        const prop = it.isCARDPROP();
        if (prop) {
            const aids = ret.aids;
            if (true) {
                const aid = prop.aid;
                const day = prop.cid;
                assert(aid >= 0);
                aids[aid] = aids[aid] || {};
                if (day < MAX_EPOCH_DAYS) {
                    ret.i_max = Math.max(ret.i_max, day);
                }
                if (null === day) {
                    aids[aid][prop.prop] = _Gt._;
                } else {
                    ret.dMax = Math.max(day, ret.dMax);
                    aids[aid][day] = aids[aid][day] || {};
                    if ("y" === prop.prop) {
                        aids[aid][day]._y = _Gt._;
                    } else if (prop.prop.startsWith("lcmx.")) {
                        aids[aid][day].lcmx = aids[aid][day].lcmx || {};
                        const key = prop.prop.substring(5);
                        aids[aid][day].lcmx[key] = _Gt.value();
                    } else {
                        aids[aid][day][prop.prop] = _Gt._;
                        if ("day" === prop.prop) {
                            const v_day: number = _Gt.value(0);
                            if (v_day >= 0) {
                                if (v_day < MAX_EPOCH_DAYS) {
                                    const days = ret.days;
                                    days[aid] = days[aid] || {};
                                    days[aid][v_day] = days[aid][v_day] || [];
                                    days[aid][v_day].push(day);
                                } else {
                                    ret.fixedDays.push({
                                        aid: aid,
                                        i: day,
                                    });
                                }
                            } else {
                                // card is deleted...
                            }
                        }
                    }
                }
            }
        }
        return ret;
    }.bind(this);

    public gatherActivitiesForTask(
        pGt: ProcessGetter,
        opt?: {
            resolve?: {
                wf: boolean;
                name: boolean;
            };
        },
    ) {
        const ret = {
            _: 0,
            tid: pGt.id,
            cards: [],
            grid_wd: 0,
            grid_cd: 0,
            _y_max: 0,
            _i_max: -1,
            aids: {} as any,
        };
        if (pGt.id >= 0) {
            ret._ = pGt.aux("_", pGt.aux("__"));
            const x1 = pGt.aux<number>("_x1");
            const x2 = pGt.aux<number>("_x2");
            const _x1 = EpochMStoEpochDays(x1);
            const _x2 = EpochMStoEpochDays(x2);
            const fix_lcmx_defaults = (lcmx) => lcmx;
            const res = {
                aids: {},
                days: {},
                fixedDays: [],
                dMax: _x2 - _x1,
                i_max: -1,
            };
            const templateId = pGt.value<number>("#P#atid", 0);
            if (templateId >= 0) {
                if (0 === templateId) {
                    // default template
                    res.aids[0] = {};
                } else {
                    assert(false); // not used
                }
            }
            const it = pGt.getAllProps();
            const prop = new ComplexProp();
            const _Gt = new ParticleGetter(this);
            for (it.first(prop); it.next(prop); ) {
                this._resolveActivityOp(res, prop, _Gt.reset(pGt._(prop._value)));
            }
            const aids = Object.getOwnPropertyNames(res.aids).map((_aid) => Number.parseInt(_aid));
            const n_aids = aids.length;
            ret.aids = res.aids;
            ret._i_max = res.i_max;
            const isDate = x1 > MAX_EPOCH_DAYS;

            for (
                let cd = 0, _date = isDate ? new Date(x1) : x1;
                (isDate ? (_date as Date).getTime() : _date) < x2;
                isDate ? Core.moveNextDay(_date as Date) : (_date as number)++, cd++
            ) {
                const isWd = isDate ? this.isWorkingDay(_date as Date) : true;
                const ed: number = isDate ? EpochMStoEpochDays((_date as Date).getTime()) : (_date as number);
                for (let i_aids = 0; i_aids < n_aids; i_aids++) {
                    const aid = aids[i_aids];
                    const days = res.days[aid] || {};
                    const a = res.aids[aid] as {
                        name?: number;
                    };
                    const a_name = _Gt.reset(a.name).value<string>(pGt.value<string>("name", "nn"));
                    const wd = ret.grid_wd;
                    const idsForDay = []; // ids for days
                    if (isWd) {
                        idsForDay.push(...(days[wd] || [])); // ids for work days
                        if (_Gt.reset(a[wd]?.day)._ < 0) {
                            // needs template for the day, since it has not been touched...
                            idsForDay.push(wd);
                        }
                    }
                    const n = idsForDay.length;
                    if (n > 0) {
                        for (let i = 0; i < n; i++) {
                            const cid = idsForDay[i];
                            const n = _Gt.reset(a[cid]?.n).value<string>(null) || a_name;
                            const name = n;
                            const desc = _Gt.reset(a[cid]?.m).value<string>();
                            const lcmx = fix_lcmx_defaults(a[cid]?.lcmx);
                            const yTS = a[cid]?._y || 0;
                            const y = yTS ? _Gt.reset(yTS).value<number>(0) : 0;
                            const s = _Gt.reset(a[cid]?.s).value<number>(0 /* DailyBoardAPICardStatus.OPEN */);
                            const statusChangedTimestamp = _Gt.reset(a[cid]?.s).date;
                            const wf = _Gt.reset(a[cid]?.wf).value<number>();
                            const _card = {
                                tid: pGt.id,
                                aid: aid,
                                i: cid,
                                y: y,
                                yTS: yTS,
                                date: ed,
                                name: false !== opt?.resolve?.name ? name : _Gt.reset(a[cid]?.n).value<string>(null),
                                desc: desc,
                                lcmx: lcmx,
                                s: s,
                                statusChangedTimestamp,
                                wf: opt?.resolve?.wf && "number" !== typeof wf ? pGt.value<number>("wf", 0) : wf,
                                atm: a[cid] ? this._getAttachmentsForAcitvity(pGt, a[cid]) : undefined,
                            };
                            ret.cards.push(_card);
                        }
                    } else {
                        // no card on this day...
                        // do nothing...
                    }
                }
                if (isWd) {
                    ret.grid_wd++;
                }
                ret.grid_cd++;
            }
            const fixedCards = res.fixedDays;
            const n_fixedCards = fixedCards.length;
            for (let i_fixedCard = 0; i_fixedCard < n_fixedCards; i_fixedCard++) {
                const fixedDay = fixedCards[i_fixedCard];
                const aid = fixedDay.aid;
                const cid = fixedDay.i;
                const a = res.aids[aid] as {
                    name?: number;
                };
                const card = a[cid];
                const day = _Gt.reset(card.day).value<number>();
                assert(day >= MAX_EPOCH_DAYS);
                const date = EpochMStoEpochDays(day);
                const a_name = _Gt.reset(a.name).value<string>(pGt.value<string>("name", "nn"));
                const n = _Gt.reset(a[cid]?.n).value<string>(null) || a_name;
                const name = n;
                const desc = _Gt.reset(a[cid]?.m).value<string>();
                const lcmx = fix_lcmx_defaults(a[cid]?.lcmx);
                const yTS = a[cid]?._y || 0;
                const y = yTS ? _Gt.reset(yTS).value<number>(0) : 0;
                const s = _Gt.reset(a[cid]?.s).value<number>(0 /* DailyBoardAPICardStatus.OPEN */);
                const wf = _Gt.reset(a[cid]?.wf).value<number>();
                const _card = {
                    tid: pGt.id,
                    aid: aid,
                    i: cid,
                    y: y,
                    yTS: yTS,
                    date: date,
                    name: false !== opt?.resolve?.name ? name : _Gt.reset(a[cid]?.n).value<string>(null),
                    desc: desc,
                    lcmx: lcmx,
                    s: s,
                    wf: opt?.resolve?.wf && "number" !== typeof wf ? pGt.value<number>("wf", 0) : wf,
                    atm: a[cid] ? this._getAttachmentsForAcitvity(pGt, a[cid]) : undefined,
                };
                ret.cards.push(_card);
            }
            ret.cards.sort(this.cmpActivityCards);
            const n_cards = ret.cards.length;
            for (let i_card = 0; i_card < n_cards; ) {
                const i_card0 = i_card++;
                ret.cards[i_card0]._y = 0;
                for (; i_card < n_cards && ret.cards[i_card0].date === ret.cards[i_card].date; i_card++) {
                    ret.cards[i_card]._y = ret.cards[i_card - 1]._y + 1;
                    ret._y_max = Math.max(ret._y_max, ret.cards[i_card]._y);
                }
            }
        }
        return ret;
    }

    public getCardName(processId: number, taskId: { aid: number; cid: number }) {
        const pGt = new ProcessGetter(this, processId);
        const _Gt = new ParticleGetter(this);

        const taskNameRef = ComplexProp.CARDPROP(taskId.aid, taskId.cid, "n");
        const opId = pGt._(taskNameRef);
        _Gt.reset(opId);
        return _Gt.value<string>();
    }

    public getActionItemName(processId: number, id: number) {
        const pGt = new ProcessGetter(this, processId);
        const _Gt = new ParticleGetter(this);
        const name = ComplexProp.ACTIONITEM(id, "issue");
        const opId = pGt._(name);
        _Gt.reset(opId);
        return _Gt.value<string>();
    }

    public getProcessDetails(
        tids: number[] | number,
        opt?: {
            cards?: {
                resolve?: {
                    wf: boolean;
                    name: boolean;
                };
            };
        },
    ) {
        if ("number" === typeof tids) tids = [tids];
        const ret: LCMDContextTaskDetailsResult = {
            _: this.getUncommitedTS(),
            projectId: this.getSID(),
            project: {},
            pid: null, // process id */
            ppid: null /* parent id */,
            ppaths: null /* parent paths */,
            isAllDay: null /* the process spans the whole day */,
            isFixed: null /* Process has a fixed date and can only be modified in the DailyBoard. */,
            isMilestone: null,
            milestoneState: null,
            start: null /* start date */,
            end: null /* end date */,
            duration: null /* duration */,
            name: null,
            workforce: null,
            image: null /* image */,
            trades: null /* trades */,
            predecessors: null,
            successors: null,
            cards: null,
            todos: null,
            teams: null,
            train: null,
            isVirtual: null,
        };
        const pGt = new ProcessGetter(this);
        const pGt2 = new ProcessGetter(this);
        const trGt = new TradesGetter(this);
        const _Gt = new ParticleGetter(this);
        const _Gt2 = new ParticleGetter(this);

        const n_tids = tids.length;
        const _readonly = this.isReadOnly;

        const setAsArray = (ret, prop, _, value) => {
            if (_ >= 0) {
                if (null === ret[prop]) {
                    ret[prop] = {
                        _: _,
                        value: value,
                    };
                } else if (Array.isArray(ret[prop].value)) {
                    if (ret[prop].value.indexOf(value) < 0) {
                        ret[prop].value.push(value);
                    } else {
                        // duplicate
                    }
                } else if (ret[prop].value !== value) {
                    ret[prop].value = [ret[prop].value, value];
                    ret[prop].indeterminate = true;
                }
                ret[prop]._ = Math.max(ret[prop]._, _);
            }
        };
        const setAsSingle = (ret, prop, _, value, extra?) => {
            if (_ >= 0) {
                if (null === ret[prop]) {
                    if ("function" === typeof extra) {
                        extra = extra(value);
                    }
                    ret[prop] = { ...(extra || {}), _: _, value: value };
                } else {
                    // indeterminate
                    ret[prop] = {
                        _: ret[prop]._,
                        value: ret[prop].value === value ? ret[prop].value : null,
                        indeterminate: true,
                    };
                }
                ret[prop]._ = Math.max(ret[prop]._, _);
            }
            return value;
        };
        const setAsSingleUnit = (ret, prop, _, value, unit, extra?) => {
            if (_ >= 0) {
                if (null === ret[prop]) {
                    if ("function" === typeof extra) {
                        extra = extra();
                    }
                    ret[prop] = { ...(extra || {}), _: _, value: value, unit: unit };
                } else {
                    // indeterminate
                    ret[prop] = {
                        _: ret[prop]._,
                        value: ret[prop].value === value && ret[prop].unit === unit ? ret[prop].value : null,
                        unit: ret[prop].value === value && ret[prop].unit === unit ? ret[prop].unit : null,
                        indeterminate: true,
                    };
                }
                ret[prop]._ = Math.max(ret[prop]._, _);
            }
        };
        const setAsReduceArray = <ITEM, SRC>(
            ret,
            prop,
            _,
            srcA: SRC[],
            matchCb: (a: SRC, b: ITEM) => boolean,
            addCb: (a: SRC) => ITEM,
            extra: { indeterminate?: boolean; readonly: boolean },
        ) => {
            if (_ >= 0) {
                if (Array.isArray(srcA) && srcA.length > 0) {
                    if (null === ret[prop]) {
                        ret[prop] = {
                            _: _,
                            value: [],
                            indeterminate: extra?.indeterminate ? true : false,
                            readonly: extra?.readonly,
                        };
                    }
                    srcA.reduce((ret, src) => {
                        if (ret.findIndex(matchCb.bind(null, src)) < 0) {
                            ret.push(addCb(src));
                        }
                        return ret;
                    }, ret[prop].value);
                    ret[prop]._ = Math.max(ret[prop]._, _);
                }
            }
        };
        const setAsSingleArray = <ITEM, SRC>(
            ret,
            prop,
            _,
            srcA: SRC[],
            addCb: (a: SRC) => ITEM,
            extra: { indeterminate?: boolean; readonly: boolean },
        ) => {
            if (_ >= 0) {
                if (Array.isArray(srcA) && srcA.length > 0) {
                    if (null === ret[prop]) {
                        ret[prop] = {
                            _: _,
                            value: srcA.reduce((ret, src) => {
                                ret.push(addCb(src));
                                return ret;
                            }, []),
                            indeterminate: extra?.indeterminate ? true : false,
                            readonly: extra?.readonly,
                        };
                    } else {
                        ret[prop] = {
                            _: Math.max(ret[prop]._, _),
                            value: null,
                            indeterminate: true,
                            readonly: extra?.readonly,
                        };
                        ret[prop]._ = Math.max(ret[prop]._, _);
                    }
                }
            }
        };

        const taktingState = getTaktingFluxState();
        if (taktingState?.enabled) {
            ret.project.gpaPreview = {
                trainInfos: taktingState.trainInfos,
            };
        }

        for (let i_tid = 0; i_tid < n_tids; i_tid++) {
            const tid = tids[i_tid];
            pGt.reset("number" === typeof tid ? tid : undefined);
            if (pGt.id === tid) {
                const t__ = pGt.aux<number>("__");
                const t_ = pGt.aux<number>("_", t__);
                const readonly = _readonly || t__ < 0; /* virtual tasks */
                if (t__ >= 0) {
                    setAsArray(ret, "pid", t__, pGt.id);
                } else {
                    // virtual task for takting
                    assert(t__ < 0);
                    ret.pid = {
                        _: -1, // virtual task
                        value: null,
                    };
                    const sid = this._model?.canvasWhiteboards?.model.storageName;
                    const _rid = pGt.aux("_rid");
                    const _rsid = pGt.aux("_rsid");
                    if (sid && "number" === typeof _rid && _rsid === sid) {
                        const p = pGt.aux("_p", -1);
                        assert(p >= 0);
                        (ret.pid as any)._taktId = ["$", _rid, "#", p].join("");
                    }
                }
                const days_ts = _Gt.reset(pGt._("days"))._;
                const days_unit = _Gt.unit(3 /* days */);
                const days = _Gt.value<number>(0) || 0;
                const isFixed = this.isProcessFixed(pGt);
                const isMilestone = 0 === days;
                const isDayUnit = !(1 === days_unit || 2 == days_unit);
                const isVirtual = pGt.isVirtual;

                setAsArray(ret, "ppid", _Gt.reset(pGt._("p"))._, _Gt.value());
                setAsSingle(ret, "isAllDay", t_, isDayUnit ? true : false, { readonly: true });
                setAsSingle(ret, "isFixed", t_, isFixed ? true : false, { readonly: true });
                setAsSingle(ret, "isMilestone", days_ts, isMilestone ? true : false, { readonly: true });
                setAsSingle(ret, "milestoneState", days_ts, isMilestone ? pGt.getMilestoneState() : null, {
                    readonly: true,
                });
                setAsSingle(ret, "isVirtual", 0, isVirtual ? true : false, { readonly: true });
                const start = setAsSingle(
                    ret,
                    "start",
                    _Gt.reset(pGt._("start"))._,
                    isDayUnit ? pGt.aux("_x1") : _Gt.value(),
                    (start) => {
                        if (start < MAX_EPOCH_DAYS) {
                            if (0 < start) {
                                return {
                                    day: start,
                                    readonly: readonly || isFixed ? true : false,
                                };
                            } else {
                                return {
                                    readonly: readonly || isFixed ? true : false,
                                };
                            }
                        } else {
                            const date = start ? HelperEpochMSToLocalDate(start) : null;
                            if (date) {
                                return {
                                    date,
                                    cw: weekNumber(date),
                                    readonly: readonly || isFixed ? true : false,
                                };
                            } else {
                                return {
                                    readonly: readonly || isFixed ? true : false,
                                };
                            }
                        }
                    },
                );
                const end = setAsSingle(
                    ret,
                    "end",
                    Math.max(_Gt.reset(pGt._("start"))._, days_ts),
                    isDayUnit ? pGt.aux("_x2") : null,
                    (end) => {
                        if (end < MAX_EPOCH_DAYS) {
                            if (0 < end) {
                                return {
                                    day: end,
                                    readonly: readonly || isFixed ? true : false,
                                };
                            } else {
                                return {
                                    readonly: readonly || isFixed ? true : false,
                                };
                            }
                        } else {
                            assert(!end || EpochDaystoEpochMS(EpochMStoEpochDays(end)) === end);
                            const date = end ? HelperEpochMSToLocalDate(start < end ? end - END_OF_DAY_MS : end) : null;
                            if (date) {
                                return {
                                    date,
                                    cw: weekNumber(date),
                                    readonly: readonly || isFixed ? true : false,
                                };
                            } else {
                                return {
                                    readonly: readonly || isFixed ? true : false,
                                };
                            }
                        }
                    },
                );
                setAsSingleUnit(ret, "duration", days_ts, days, days_unit, () => {
                    const cd = isDayUnit && start && end ? EpochMStoEpochDays(end) - EpochMStoEpochDays(start) : null;
                    const wd = isDayUnit ? Core.unitToDays(days, days_unit) : null;
                    return {
                        readonly: readonly || isFixed ? true : false,
                        wd: wd,
                        ed: cd,
                    };
                });
                setAsSingle(ret, "name", _Gt.reset(pGt._("name"))._, _Gt.value(), { readonly });
                setAsSingle(ret, "workforce", _Gt.reset(pGt._("wf"))._, _Gt.value(), { readonly });
                if (_Gt.reset(pGt._("image"))._ >= 0 && _Gt.value<any>()?.blobId) {
                    if (null === ret.image) {
                        ret.image = {
                            _: _Gt._,
                            value: _Gt.value(),
                            url: Core.createAttachmentUrl(_Gt.value()),
                            readonly,
                        };
                    } else if (
                        ret.image.value?.blobId !== _Gt.value<any>().blobId ||
                        ret.image.value?.contentType !== _Gt.value<any>().contentType
                    ) {
                        // indetermined
                        ret.image = {
                            _: _Gt._,
                            value: null,
                            readonly: true,
                        };
                    }
                    ret._ = Math.max(ret._, _Gt._);
                }
                const _trades = this.getTradesForProcess(pGt);
                setAsReduceArray<LCMDContextTaskDetailsResultTradeDetails, any>(
                    ret,
                    "trades",
                    t_,
                    _trades,
                    (_trade, trade) => {
                        return trade.trid === _trade.id;
                    },
                    (_trade) => {
                        return {
                            trid: _trade.id,
                            name: _trade.name || "",
                            trade: _trade.trade,
                            color: _trade.color,
                        };
                    },
                    { indeterminate: n_tids > 1, readonly },
                );

                const _rp = pGt.aux<DependencyList>("_rp");
                const _predecessors = Object.getOwnPropertyNames(_rp || {}).reduce((ret, _target) => {
                    pGt2.reset(Number.parseInt(_target));
                    if (!pGt2.isDeleted()) {
                        _Gt2.reset(_rp[_target].depTs);
                        const lag = _Gt2.value(0);
                        const type = _Gt2.type || null;
                        const unit = _Gt2.unit(3 /* days */);
                        const dep = this._checkDedendency(type, pGt2, pGt, lag, unit);
                        ret.push({
                            targetId: pGt2.id,
                            targetName: pGt2.value<string>("name"),
                            lag,
                            type,
                            unit,
                            valid: dep >= 0,
                            conflictOffset: dep < 0 ? Math.abs(dep) : undefined,
                        });
                    }
                    return ret;
                }, []);

                const _rs = pGt.aux<DependencyList>("_rs");
                const _successors = Object.getOwnPropertyNames(_rs || {}).reduce((ret, _target) => {
                    pGt2.reset(Number.parseInt(_target));
                    if (!pGt2.isDeleted()) {
                        _Gt2.reset(_rs[_target].depTs);
                        const lag = _Gt2.value(0);
                        const type = _Gt2.type || null;
                        const unit = _Gt2.unit(3 /* days */);
                        const dep = this._checkDedendency(type, pGt, pGt2, lag, unit);
                        ret.push({
                            targetId: pGt2.id,
                            targetName: pGt2.value<string>("name"),
                            lag,
                            type,
                            unit,
                            valid: dep >= 0,
                            conflictOffset: dep < 0 ? Math.abs(dep) : undefined,
                        });
                    }
                    return ret;
                }, []);

                setAsSingleArray<LCMDContextTaskDetailsResultDependencyDetails, any>(
                    ret,
                    "predecessors",
                    t_,
                    _predecessors,
                    (trade) => ({ ...trade, pid: tid }),
                    { indeterminate: n_tids > 1, readonly },
                );
                setAsSingleArray<LCMDContextTaskDetailsResultDependencyDetails, any>(
                    ret,
                    "successors",
                    t_,
                    _successors,
                    (trade) => ({ ...trade, pid: tid }),
                    { indeterminate: n_tids > 1, readonly },
                );
                const _cards = this.gatherActivitiesForTask(pGt);
                setAsSingleArray<LCMDContextTaskDetailsResultCardDetails, any>(
                    ret,
                    "cards",
                    t_,
                    _cards.cards,
                    (card) => ({
                        _: t_,
                        tid: ["C", card.tid.toString(16), card.aid.toString(16), card.i.toString(16)].join("_"),
                        name: card.name || (false !== opt?.cards?.resolve?.name ? "" : null),
                        s: card.s,
                        atm: card.atm ? true : false,
                        wf: "number" === typeof card.wf ? card.wf : null,
                        date: EpochDaystoEpochMS(card.date),
                    }),
                    { indeterminate: n_tids > 1, readonly },
                );

                setAsSingle(ret, "teams", _Gt.reset(pGt._("teams"))._, _Gt.value(), { readonly });
                setAsSingle(ret, "train", _Gt.reset(pGt._("train"))._, _Gt.value(null), { readonly });
                if (ret.train && null !== ret.train.value && undefined !== ret.train.value) {
                    const p = pGt.value<number>("p", -1);
                    if (p >= 0) {
                        if (pGt2.reset(p)) {
                            const train = stringToHex(ret.train.value as string);
                            const takt = pGt2.value<number>(ComplexProp.TRAINPROP(train, "takt"), 0);
                            ret.train.takt = takt;
                        }
                    }
                }
                const _parents = this._model._getTaskParents(this._model.tasks[pGt.id]);
                setAsReduceArray<any, any>(
                    ret,
                    "ppaths",
                    t_,
                    [_parents],
                    (p1, p2) => {
                        const eq =
                            p1.length === p2.length && p1.reduce((ret, p, i_p) => ret && p.id === p2[i_p].id, true);
                        return eq;
                    },
                    (p) => {
                        return p;
                    },
                    { readonly },
                );

                const t_rsid = pGt.aux<string>("_rsid");
                const t_rid = pGt.aux<number>("_rid");
                if ("string" === typeof t_rsid) {
                    if ("string" == typeof ret.rsid) {
                        if (ret.rsid !== t_rsid) {
                            ret.rsid = [ret.rsid, t_rsid];
                        }
                    } else if (Array.isArray(ret.rsid)) {
                        if (ret.rsid.indexOf(t_rsid) < 0) {
                            ret.rsid.push(t_rsid);
                        }
                    } else {
                        ret.rsid = t_rsid;
                    }
                }
                if ("number" === typeof t_rid) {
                    if ("number" === typeof ret.rid) {
                        ret.rid = [ret.rid, t_rid];
                    } else if (Array.isArray(ret.rid)) {
                        ret.rid.push(t_rid);
                    } else {
                        ret.rid = t_rid;
                    }
                }
                const t_c = pGt.getChildren();
                if (Array.isArray(t_c)) {
                    ret.children = t_c.slice();
                }
            }
        }
        if (ret.trades) {
            ret.trades.value.sort((a, b) => {
                let ret = a.name.localeCompare(b.name);
                if (0 === ret) {
                    ret = (a.trade || "").localeCompare(b.trade || "");
                }
                if (0 === ret) {
                    ret = a.trid - b.trid;
                }
                return ret;
            });
        }
        if (Array.isArray(ret.successors)) {
            ret.successors.sort(
                (
                    a: LCMDContextTaskDetailsResultDependencyDetails,
                    b: LCMDContextTaskDetailsResultDependencyDetails,
                ) => {
                    let ret = pGt.reset(a.targetId).aux<number>("_x1") - pGt2.reset(b.targetId).aux<number>("_x1");
                    if (0 === ret) {
                        ret = (a.targetName || "").localeCompare(b.targetName || "");
                        if (0 === ret) {
                            ret = a.pid - b.pid;
                            if (0 === ret) {
                                ret = a.targetId - b.targetId;
                            }
                        }
                    }
                    return ret;
                },
            );
        }
        if (Array.isArray(ret.predecessors)) {
            ret.predecessors.sort(
                (
                    a: LCMDContextTaskDetailsResultDependencyDetails,
                    b: LCMDContextTaskDetailsResultDependencyDetails,
                ) => {
                    let ret = pGt.reset(a.targetId).aux<number>("_x2") - pGt2.reset(b.targetId).aux<number>("_x2");
                    if (0 === ret) {
                        ret = (a.targetName || "").localeCompare(b.targetName || "");
                        if (0 === ret) {
                            ret = a.pid - b.pid;
                            if (0 === ret) {
                                ret = a.targetId - b.targetId;
                            }
                        }
                    }
                    return ret;
                },
            );
        }
        if (true) {
            // add todos
            const todos = this._getApiTodos(tids, {});
            ret.todos = todos;
        }
        return ret;
    }

    public getTradesForProcess(pGt: ProcessGetter, trGtHelper?: TradesGetter) {
        const _Gt = new ParticleGetter(this);
        const trGt = trGtHelper || new TradesGetter(this);
        const _rw = pGt.aux<any>("_rw") || {};
        const _trades = Object.getOwnPropertyNames(_rw).map((_id) => {
            const id = Number.parseInt(_id);
            assert("number" === typeof id);
            trGt.reset(id);
            assert(trGt.id >= 0); // missisng resource?
            _Gt.reset(_rw[id]);
            return {
                id: id,
                name: trGt.value<string>("name", null),
                trade: trGt.value<string>("trade", null),
                color: trGt.value<number>("color", null),
                icon: trGt.icon,
                completed: _Gt.value<number>(0),
                readonly: _Gt._ < 0,
            };
        });
        return _trades;
    }

    public getTradeForProcess(pGt: ProcessGetter, trGtHelper?: TradesGetter) {
        const _Gt = new ParticleGetter(this);
        const trGt = trGtHelper || new TradesGetter(this);
        const _rw = pGt.aux<any>("_rw") || {};
        const _tradeId = Object.getOwnPropertyNames(_rw).at(0);
        const tradeId = typeof _tradeId !== "undefined" ? Number.parseInt(_tradeId) : undefined;
        if (!tradeId) {
            return;
        }

        assert("number" === typeof tradeId);
        trGt.reset(tradeId);
        assert(trGt.id >= 0); // missisng resource?
        _Gt.reset(_rw[tradeId]);
        return {
            id: tradeId,
            name: trGt.value<string>("name", null),
            trade: trGt.value<string>("trade", null),
            color: trGt.value<number>("color", null),
            completed: _Gt.value<number>(0),
            readonly: _Gt._ < 0,
            icon: trGt.icon,
        };
    }

    public _getProcessParents(pGt: ProcessGetter) {
        const _pGt = pGt.clone();
        const p: Array<{ id: number; name: string | null }> = [];
        for (_pGt.reset(_pGt.value<number>("p", -1)); _pGt.id >= 0; _pGt.reset(_pGt.value<number>("p", -1))) {
            p.splice(0, 0, {
                id: _pGt.id,
                name: _pGt.value<string>("name", null),
            });
        }
        return p;
    }

    public getColorForProcess(pGt: ProcessGetter, trGtHelper?: TradesGetter) {
        const trGt = trGtHelper || new TradesGetter(this);
        const _rw = Object.getOwnPropertyNames(pGt.aux<any>("_rw") || {});
        let _c = 0 === pGt.value<number>("days") ? 0x00a2e8 : 0xb4c85d;
        if (_rw.length > 0) {
            trGt.reset(Number.parseInt(_rw[0]));
            if (trGt.value<string>("name") !== null) {
                _c = trGt.value<number>("color", _c);
            }
        }
        return _c;
    }

    private _checkDedendency(type: number, t1: ProcessGetter, t2: ProcessGetter, duration: number, unit: number) {
        let _A: number = null;
        let _E: number = null;
        const t1_x1 = t1.aux<number>("_x1");
        const t1_x2 = t1.aux<number>("_x2");
        const t2_x1 = t2.aux<number>("_x1");
        const t2_x2 = t2.aux<number>("_x2");
        switch (type) {
            case 1:
                {
                    // EE
                    _A = this.CalcEpochStartDayMS(t1_x2, duration, unit);
                    _E = t2_x2;
                }
                break;
            case 2:
                {
                    // EA
                    _A = this.CalcEpochStartDayMS(t1_x2, duration, unit);
                    _E = t2_x1;
                }
                break;
            case 3:
                {
                    // AE
                    _A = this.CalcEpochStartDayMS(t1_x1, duration, unit);
                    _E = t2_x2;
                }
                break;
            case 4:
                {
                    // AA
                    _A = this.CalcEpochStartDayMS(t1_x1, duration, unit);
                    _E = t2_x1;
                }
                break;
            default:
                assert(false); //@TODO
                break;
        }
        let delta = 0;
        if (null !== _A && null !== _E) {
            if (_A <= _E) {
                delta = this.CalcWorkDays(_A, _E);
            } else {
                delta = -this.CalcWorkDays(_E, _A);
            }
        }
        return delta;
    }

    public getProcessLCMX(pGt: ProcessGetter) {
        const t_start = pGt.value<number>("start", 0);
        const t_days = pGt.value<number>("days", 0);
        const t_days_unit = pGt.unit("days", 3 /* days */);
        const props = pGt.getAllProps();
        const p = new ComplexProp();
        const lcmx = {};
        if (t_start && (1 === t_days_unit || 2 === t_days_unit)) {
            // https://lcmexecute.atlassian.net/browse/LCM2-745
            lcmx["lcmd.process.start"] = t_start ? { value: HelperDateTo24TimeStr(t_start) } : undefined;
            lcmx["lcmd.process.end"] =
                t_start && (1 === t_days_unit || 2 === t_days_unit)
                    ? { value: HelperDateTo24TimeStr(t_start + t_days * (2 === t_days_unit ? 60 : 0) * 60 * 1000) }
                    : undefined;
        }
        for (props.first(p); props.next(p); ) {
            const _n = p.isLCMX();
            if (_n) {
                const v = pGt.value<any>(p, undefined);
                lcmx[_n] = v;
            }
        }
        return lcmx;
    }

    private _patchExtFields(container: { fields: any }, patch: any) {
        return {
            ...container,
            fields: Object.getOwnPropertyNames(patch).reduce((fields, id) => {
                fields[id] = { ...(fields[id] || {}), ...patch[id] };
                return fields;
            }, container?.fields || {}),
        };
    }

    private _applyExtPatch(ret: any, prefix: string, patch: any) {
        return Object.getOwnPropertyNames(patch || {}).reduce((ret, p) => {
            const n = [prefix, p].join("#");
            const v = patch[p];
            switch (n) {
                case "#prio":
                    {
                        ret = { ...ret, [p]: v };
                    }
                    break;
                case "#process#fields":
                    {
                        ret = { ...ret, process: this._patchExtFields(ret?.process || {}, v) };
                    }
                    break;
                case "#task#fields":
                    {
                        ret = { ...ret, task: this._patchExtFields(ret?.task || {}, v) };
                    }
                    break;
                case "#process":
                case "#task":
                    ret = this._applyExtPatch(ret, n, v);
                    break;
                default:
                    console.warn("Unhandled patch " + n);
                    break;
            }
            return ret;
        }, ret);
    }

    private _fixOldFormatFields(fields) {
        return fields.reduce((ret, e, i) => {
            if (e?.id) {
                const _e = { ...e };
                delete _e.id;
                if ("number" !== typeof _e["ui.index"]) {
                    _e["ui.index"] = i;
                }
                ret[e.id] = _e;
            }
            return ret;
        }, {});
    }

    protected _resolveExtValue(ext: number | number[], _GtHelper: ParticleGetter): any {
        let ret: any = {};
        if (!Array.isArray(ext)) {
            ext = [ext];
        }
        const n = ext.length;
        for (let i = n; i > 0; i) {
            i--;
            if (_GtHelper.reset(ext[i]).isHiveTarget) {
                const value = _GtHelper.value<any>();
                if (value?.patch) {
                    ret = this._applyExtPatch(ret, "", value.patch);
                } else {
                    ret = value;
                    if (Array.isArray(ret?.process?.fields)) {
                        // fix old format
                        ret = {
                            ...ret,
                            process: { ...ret.process, fields: this._fixOldFormatFields(ret.process.fields) },
                        };
                    }
                    if (Array.isArray(ret?.task?.fields)) {
                        // fix old format
                        ret = { ...ret, task: { ...ret.task, fields: this._fixOldFormatFields(ret.task.fields) } };
                    }
                }
            }
        }
        return ret;
    }

    private _detailsExt = null;
    public getProcessDetailsExt() {
        const hGt = new HiveGetter(this, "lcmd.ext");
        const _GtHelper = new ParticleGetter(this);
        if ((this._detailsExt?._ || 0) !== hGt.aux<number>("_", -1)) {
            this._detailsExt = {
                _: hGt.aux<number>("_", -1),
                task: [],
                process: [],
                CACHE_stabs: [],
            };
            const _extsIds = [];
            {
                const p = new ComplexProp();
                const it = hGt.getAllProps();
                for (it.first(p); it.next(p); ) {
                    const n = p.isNAME();
                    if (n) {
                        _extsIds.push(n);
                    }
                }
            }
            _extsIds.sort((id1, id2) => {
                // sort by first appearance..., thats the prio the fields get
                const _1 = hGt.history(id1);
                const _2 = hGt.history(id2);
                return _1[_1.length - 1] - _2[_2.length - 1];
            });
            const _exts = _extsIds.map((id) => ({ id: id, ext: this._resolveExtValue(hGt.history(id), _GtHelper) }));
            const exts = _exts.reduce((ret, e) => {
                ret.splice(e.ext.prio || 0, 0, e);
                return ret;
            }, []);

            for (let i_ext = 0; i_ext < exts.length; i_ext++) {
                const n = exts[i_ext].id;
                const value: any = exts[i_ext].ext;
                const getFields = (fields) =>
                    Object.getOwnPropertyNames(fields)
                        .map((id) => ({ ...fields[id], id }))
                        .sort((f1, f2) => {
                            let ret = (f1["ui.index"] || 0) - (f2["ui.index"] || 0);
                            if (0 === ret) {
                                ret = f1.id.localeCompare(f2.id);
                            }
                            return ret;
                        });
                const processFields = getFields(value?.process?.fields || {});
                const taskFields = getFields(value?.task?.fields || {});
                const generateKey = (ret, fields) =>
                    (fields || []).reduce((ret, field) => {
                        const _field = { ...field, ext: n, prio: "number" === typeof value.prio ? value.prio : i_ext };
                        if (_field.choice) {
                            _field.choice = generateKey([], _field.choice);
                        }
                        if (_field.id) {
                            _field.key = (n && n.length > 0 ? [n, _field.id].join(".") : _field.id).toLocaleLowerCase();
                        }
                        ret.push(_field);
                        return ret;
                    }, ret);
                this._detailsExt.task = generateKey(this._detailsExt.task, taskFields);
                this._detailsExt.process = generateKey(this._detailsExt.process, processFields);
            }
            //console.log("DETAILS EXT:");
            //console.log(this._detailsExt);

            this._detailsExt.CACHE_stabs = (this._detailsExt?.process || [])
                .filter((f) => Array.isArray(f["stability.stable"]))
                .map((f, i) => ({ ...f, _id: i + 1 }))
                .map((_stab) => {
                    const unsafeTrades = _stab["stability.trades"];
                    if (Array.isArray(unsafeTrades)) {
                        const trades = this.getUnsafeIDs({ trids: unsafeTrades }).trids;
                        const stab = { ..._stab, ["stability.trades"]: trades };
                        return stab;
                    } else {
                        return _stab;
                    }
                }); //TODO use CREATE index to create the ID
        }
        return this._detailsExt;
    }

    public getProcessDetailsVer1(
        taskId: number,
        opt?: {
            cards?: {
                resolve?: {
                    wf: boolean;
                    name: boolean;
                };
            };
        },
    ) {
        const pGt = new ProcessGetter(this);
        const pGtHelper = new ProcessGetter(this);
        const trGtHelper = new TradesGetter(this);
        const _Gt = new ParticleGetter(this);
        let td = null;
        if (pGt.reset(taskId).id >= 0) {
            if (true) {
                const days = pGt.value<number>("days", 0) || 0;
                const unit = pGt.unit("days", 3 /*days*/);
                const wd_unit = unit;
                const isDayUnit = !(1 === wd_unit || 2 == wd_unit);
                const wd = days;
                const name = pGt.value<string>("name", null);
                const name_history = pGt.history("name").map((o) => {
                    _Gt.reset(o);
                    return {
                        _u: _Gt.userId || "nn",
                        _v: _Gt.value<string>(),
                    };
                });
                const duration_history = pGt.history("days").map((o) => {
                    _Gt.reset(o);
                    return {
                        _u: _Gt.userId || "nn",
                        _v: _Gt.value<number>(),
                        _vu: _Gt.unit(),
                    };
                });
                const _trades = this.getTradesForProcess(pGt, trGtHelper);
                const p = this._getProcessParents(pGt);
                const _rp = pGt.aux<DependencyList>("_rp") || {};
                const predecessors = Object.getOwnPropertyNames(_rp).reduce((ret, _target) => {
                    const targetId = Number.parseInt(_target);
                    pGtHelper.reset(targetId);
                    _Gt.reset(_rp[targetId].depTs);
                    if (!pGtHelper.isDeleted()) {
                        const lag = _Gt.value<number>(0);
                        const type = _Gt.type || null;
                        const unit = _Gt.unit(3 /* days */);
                        const dep = this._checkDedendency(type, pGtHelper, pGt, lag, unit);
                        ret.push({
                            targetId: targetId,
                            targetName: pGtHelper.value<string>("name"),
                            lag,
                            type,
                            unit,
                            valid: dep >= 0,
                        });
                    }
                    return ret;
                }, []);
                const _rs = pGt.aux<DependencyList>("_rs") || {};
                const successors = Object.getOwnPropertyNames(_rs).reduce((ret, _target) => {
                    const targetId = Number.parseInt(_target);
                    pGtHelper.reset(targetId);
                    _Gt.reset(_rs[targetId].depTs);
                    if (!pGtHelper.isDeleted()) {
                        const lag = _Gt.value<number>(0);
                        const type = _Gt.type || null;
                        const unit = _Gt.unit(3 /* days */);
                        const dep = this._checkDedendency(type, pGt, pGtHelper, lag, unit);
                        ret.push({
                            targetId: targetId,
                            targetName: pGtHelper.value<string>("name"),
                            lag,
                            type,
                            unit,
                            valid: dep >= 0,
                        });
                    }
                    return ret;
                }, []);
                const milestones = []; //no longer needed
                const _cards = this.gatherActivitiesForTask(pGt, opt?.cards);
                const cards = _cards.cards;
                const doneCards = cards.reduce((ret, card) => {
                    return ret + /*DailyBoardAPICardStatus.DONE*/ (2 === card.s ? 1 : 0);
                }, 0);

                const isFixed = this.isProcessFixed(pGt);
                const isMilestone = pGt.isMilestone();
                let parentId = pGt.value<number>("p", -1);
                const isActive = parentId !== -1;
                if (!isActive) {
                    const parentHistory = pGt.history("p");
                }

                td = {
                    id: taskId,
                    pid: pGt.value<number>("p", -1),
                    active: isActive,
                    isDayUnit,
                    start: isDayUnit ? pGt.aux<number>("_x1") : pGt.value<number>("start"),
                    end: isDayUnit ? pGt.aux<number>("_x2") : null,
                    days: days,
                    unit: unit,
                    wd: wd,
                    wd_unit: wd_unit,
                    wf: pGt.value<number>("wf", 0),
                    name: name,
                    image: pGt.value<any>("image", null),
                    _: pGt.aux("_", pGt.aux("__", 0)),
                    _start: pGt.aux<number>("_x1"),
                    _end: pGt.aux<number>("_x2"),
                    _trades: _trades,
                    _parents: p,
                    _predecessors: predecessors,
                    _successors: successors,
                    _duration_history: duration_history,
                    _name_history: name_history,
                    _cards: cards,
                    task: {
                        _: pGt.aux<number>("_") || -1,
                        id: taskId,
                        name: pGt.value<string>("name"),
                        fs: pGt.value<number>("fs", 10),
                        ...this.getLayoutForProcess(pGt), //@TODO is calculating this here really a good idea? shouldn't this be calculated in the UI and we pass _x1, _x2 only?
                        color: this.getColorForProcess(pGt, trGtHelper),
                    },
                    progress: {
                        done: doneCards,
                        cards: cards.length,
                    },
                    milestones: milestones,
                    isFixed: isFixed,
                    fields: this.getProcessDetailsExt(),
                    lcmx: this.getProcessLCMX(pGt),
                    isMilestone: isMilestone,
                    milestoneState: isMilestone ? pGt.getMilestoneState() : null,
                };
            }
        }
        return td;
    }

    private fixTimeOffsetIfNeeded(pGt: ProcessGetter, date: number) {
        if (date >= 0 && pGt.id >= 0) {
            const unit = pGt.value<number>("unit", 3 /* days */);
            if (1 == unit || 2 == unit) {
                // hours planning, see https://lcmexecute.atlassian.net/browse/LCM2-745
                const timeOfs = EpochMSGetTimeOfsMS(date);
                if (0 === timeOfs) {
                    const start = pGt.value<number>("start", 0);
                    if (start) {
                        const startOfs = EpochMSGetTimeOfsMS(start);
                        date = date + startOfs;
                        assert(EpochMSGetTimeOfsMS(date) === startOfs);
                    }
                }
            }
        }
        return date;
    }

    public moveTask(
        d: DocumentTransaction,
        pGt: ProcessGetter,
        dx: number,
        dy: number,
        sx: number,
        p?: {
            pid: number;
            i_pid: number;
            tz_pid?: string;
        },
    ) {
        if (pGt.id >= 0) {
            const start = this.ensureGridStartDate(pGt.aux("_x1"), dx);
            const y = pGt.aux<number>("__y");
            if (this.isWorkingDay(start)) {
                const _start = this.fixTimeOffsetIfNeeded(pGt, start);
                if (pGt.aux("_x1") != _start) {
                    d.setProcessProp(pGt.id, "start", _start);
                }
                if (sx && 1 !== sx) {
                    const x1 = this.dateToGrid(pGt.aux("_x1"));
                    const x2 = this.dateToGrid(pGt.aux("_x2"));
                    if (x1 >= 0 && x2 >= 0 && x1 < x2) {
                        const end = this.ensureGridStartDate(start, Math.round((x2 - x1) * sx));
                        const wd = this.CalcWorkDays(start, end);
                        d.setProcessProp(pGt.id, "days", wd, {
                            unit: 3,
                        });
                    }
                }
            }
            d.setProcessProp(pGt.id, "stripey", Math.max(0, y + dy));
            if ("number" === typeof p?.pid) {
                d.setProcessProp(pGt.id, "p", p.pid, { i: p.i_pid++, tz: p.tz_pid });
            }
        }
    }

    protected override onCreateCardsForProcess(pIt: ProcessGetter): {
        cards: CanvasCardData[];
        _: number;
        _y_max: number;
    } {
        const tid = pIt.id;
        const trades = this.getTradesForProcess(pIt).map((trade) => trade.id);
        const ret = this.gatherActivitiesForTask(pIt);
        const cards = ret.cards.map((a) => {
            const _x1 = pIt.aux<number>("_x1");
            const _x2 = pIt.aux<number>("_x2");
            const card: any = {
                _: pIt.aux("_", pIt.aux("__")),
                p: a.tid,
                id: ["C", a.tid.toString(16), a.aid.toString(16), a.i.toString(16)].join("_"),
                d: a.date,
                n: a.name,
                m: a.desc,
                t: trades.length > 0 ? (trades.length > 0 ? trades[0] : trades) : undefined,
                s: a.s,
                p_name: pIt.value<string>("name", ""),
                p_start: _x1,
                p_end: _x1 < _x2 ? _x2 - END_OF_DAY_MS : _x2,
                y: a._y,
                atm: a.atm,
                lcmx: a.lcmx,
            };
            let unit;
            if (1 === (unit = pIt.unit("days", 3 /*days*/)) || 2 === unit) {
                //HACK
                const days = pIt.value<number>("days", 0);
                const start = pIt.value<number>("start", 0);
                card.p_start = -start;
                const m = 2 == unit ? days * 60 : days;
                card.p_end = -(start + m * 60 * 1000);
            }
            return card;
        });
        return {
            cards: cards,
            _: ret._,
            _y_max: ret._y_max,
        };
    }

    public setProcessDetails(pids: number[] | ProcessGetter, state: LCMDContextProcessDetailsState) {
        if (Array.isArray(pids)) {
            const pIt = new ProcessGetter(this);
            const ct = new ComplexDocumentTransaction(this);
            for (let i_pid = 0; i_pid < pids.length; i_pid++) {
                const t = new DocumentTransaction(this, ct);
                pIt.reset(pids[i_pid]);
                setProcessImpl(pIt.id, t, state, pIt);
                ct.commit(t);
            }
            ct.commit();
        } else {
            const t = new DocumentTransaction(this);
            setProcessImpl(pids.id, t, state, pids);
            t.commit();
        }
    }

    public createProcess(
        state: LCMDContextProcessDetailsState,
        opt?: {
            c?: 1;
        },
    ) {
        const t = new DocumentTransaction(this);
        const ret = setProcessImpl(
            t.createProcess(state.ppid.value, { i: state.ppid.index, c: opt?.c }),
            t,
            state,
            null,
        );
        t.commit();
        return ret;
    }

    public setDependencyDetails(srcPid: number, dstPid: number, state: LCMDContextDependencyDetailsState | null) {
        let isCircular = false;

        if (state !== null) {
            const stack: Set<string> = new Set();
            isCircular = checkCircular(this, stack, dstPid, {srcPid: dstPid, dstPid: srcPid});
        }

        if (isCircular) {
            const EventData: any = [];
            this._model.eventEmitter.emit(
                "process::dependencyCreated::circularDependency",
                EventData,
                "process::dependencyCreated::circularDependency",
            );
        } else {
            const t = new DocumentTransaction(this);
            setDependencyImpl(t, srcPid, dstPid, state);
            t.commit();
        }
    }

    public magicPredecessor(a_tid: number[]) {
        const t = new DocumentTransaction(this);
        const pGt = new ProcessGetter(this);
        const tasks = a_tid
            .filter((tid) => {
                return pGt.reset(tid).id >= 0 && pGt.aux<number>("_x1", 0) > 0;
            })
            .sort((tid1, tid2) => {
                const tid1_x1 = pGt.reset(tid1).aux<number>("_x1", 0);
                const tid2_x1 = pGt.reset(tid2).aux<number>("_x1", 0);
                return tid1_x1 - tid2_x1;
            });
        for (let i_task = 1; i_task < tasks.length; i_task++) {
            const _rp = pGt.reset(tasks[i_task]).aux<DependencyList>("_rp");
            if (!(_rp || {})[tasks[i_task - 1]]) {
                t.setProcessProp(tasks[i_task], ComplexProp.CREATEREL(tasks[i_task - 1]), 0 /* lag */, {
                    unit: 3, // days
                    type: 2, // EA
                });
            }
        }
        t.commit();
    }

    private _fixCardOps(
        ret_ops: IDocumentTransaction,
        ret: {
            tid: number;
            cards: {
                date: number;
                aid: number;
                i: number;
                _day?: number;
            }[];
            _i_max: number;
            aids: any[];
        },
    ) {
        const tid = ret.tid;
        const pGt = new ProcessGetter(this, tid);
        const _Gt = new ParticleGetter(this);
        const cards = ret.cards;
        const n_cards = cards.length;
        if (n_cards > 0 && pGt.aux<number>("_x1", 0) > 0) {
            // calc new min and max
            let i_card_min = -1;
            let i_card_max = -1;
            let i_id_max = -1;
            for (let i_card = 0; i_card < n_cards; i_card++) {
                const c = cards[i_card];
                if (-1 === i_card_min || c.date < cards[i_card_min].date) {
                    i_card_min = i_card;
                }
                if (-1 === i_card_max || c.date > cards[i_card_max].date) {
                    i_card_max = i_card;
                }
                if (c.i < MAX_EPOCH_DAYS && c.i > i_id_max) {
                    i_id_max = c.i;
                }
            }
            assert(0 <= i_card_min && i_card_min < n_cards && 0 <= i_card_max && i_card_max < n_cards);
            const startDate = cards[i_card_min].date;
            const endDate = cards[i_card_max].date;
            const isDate = pGt.aux<number>("_x1", 0) > MAX_EPOCH_DAYS;
            assert(
                (!isDate && startDate < MAX_EPOCH_DAYS && endDate < MAX_EPOCH_DAYS) ||
                    (isDate &&
                        EpochDaystoEpochMS(startDate) > MAX_EPOCH_DAYS &&
                        EpochDaystoEpochMS(endDate) > MAX_EPOCH_DAYS),
            );
            let cd = 0,
                wd = 0,
                _n_cards = 0,
                ops: {
                    tid: number;
                    aid: number;
                    i: number;
                    value: number;
                    default?: boolean;
                }[] = [],
                touchedI = {};
            for (
                let _date = isDate ? new Date(EpochDaystoEpochMS(startDate)) : startDate;
                (isDate ? EpochMStoEpochDays((_date as Date).getTime()) : _date) <= endDate;
                isDate ? Core.moveNextDay(_date as Date) : (_date as number)++, cd++
            ) {
                const _n_cards0 = _n_cards;
                const isWd = isDate ? this.isWorkingDay(_date) : true;
                // every card: TODO make it linear...
                for (let i_card = 0; i_card < n_cards; i_card++) {
                    const c = cards[i_card];
                    if ((isDate ? EpochMStoEpochDays((_date as Date).getTime()) : _date) === c.date) {
                        const _touchId = [c.aid.toString(16), c.i].join("-");
                        assert(undefined === c._day && c.i >= 0 && true !== touchedI[_touchId]);
                        touchedI[_touchId] = true;
                        _n_cards++; // check card (update number of cards checked)
                        if (isWd) {
                            if (c.i === wd) {
                                // card was planned for this day and has not been moved
                                // no adjustment needed, remove tid.#A#{aid}#{c.i}#day, wd
                                assert(undefined === c._day);
                                ops.push({
                                    tid,
                                    aid: c.aid,
                                    i: c.i,
                                    value: wd,
                                    default: true,
                                });
                            } else {
                                // card was not planned for this day (it has been moved)
                                c._day = wd;
                                ops.push({
                                    tid,
                                    aid: c.aid,
                                    i: c.i,
                                    value: wd,
                                });
                            }
                        } else {
                            // non working day...
                            // fixed day
                            assert(isDate);
                            const day = (_date as Date).getTime();
                            assert(day >= MAX_EPOCH_DAYS);
                            c._day = day;
                            ops.push({
                                tid,
                                aid: c.aid,
                                i: c.i,
                                value: day,
                            });
                        }
                    }
                }
                if (isWd) {
                    if (_n_cards0 === _n_cards) {
                        // empty work day
                    }
                    wd++;
                }
            }
            assert(n_cards === _n_cards); // all cards must have been checked
            // lets deal with untouched cards
            const _i_id_max = Math.max(ret._i_max + 1, wd, i_id_max + 1);
            assert(_i_id_max < MAX_EPOCH_DAYS);
            for (let _i_id = 0; _i_id < _i_id_max; _i_id++) {
                const aid = 0; //@TODO get aid...
                const _touchId = [aid.toString(16), _i_id].join("-");
                if (true !== touchedI[_touchId]) {
                    delete touchedI[_touchId];
                    const a_day = _Gt.reset(ret.aids[aid][_i_id]?.day).value<number>();
                    if (-1 === a_day) {
                        // deleted... keep it that way...
                        ops.push({
                            tid,
                            aid: aid,
                            i: _i_id,
                            value: -1, // -1 deleted, -2 hidden
                        });
                    } else if (_i_id < wd) {
                        // hide it
                        ops.push({
                            tid,
                            aid: aid,
                            i: _i_id,
                            value: -2, // -1 deleted, -2 hidden
                        });
                    } else {
                        // reset
                        const _default = ret.aids[aid][_i_id] ? false : true;
                        ops.push({
                            tid,
                            aid: aid,
                            i: _i_id,
                            value: _i_id,
                            default: _default,
                        });
                    }
                }
            }
            // adjust start start/end if needed
            const t_x1 = pGt.aux<number>("_x1");
            const t_x2 = pGt.aux<number>("_x2");
            if (isDate && (EpochMStoEpochDays(t_x1) !== startDate || endDate + 1 !== EpochMStoEpochDays(t_x2))) {
                const isStartDateWd = this.isWorkingDay(new Date(EpochDaystoEpochMS(startDate)));
                const isEndDateWd = this.isWorkingDay(new Date(EpochDaystoEpochMS(endDate)));
                if (isStartDateWd && isEndDateWd) {
                    assert(this.CalcWorkDays(EpochDaystoEpochMS(startDate), EpochDaystoEpochMS(endDate + 1)) === wd);
                } else {
                    assert(endDate + 1 - startDate === cd);
                }
                if (EpochMStoEpochDays(t_x1) !== startDate) {
                    ret_ops.setProcessProp(pGt.id, "start", EpochDaystoEpochMS(startDate));
                }
                const days = isStartDateWd && isEndDateWd ? wd : cd;
                const unit = isStartDateWd && isEndDateWd ? 3 /* days */ : 10; /* Elapsed Days */
                const t_days = pGt.value<number>("days", -1);
                const t_unit = pGt.unit("days", undefined); //@TODO: this is just for fixing the test: use:  3 /* days */ as default instead of undefined
                if (t_days != days || t_unit !== unit) {
                    ret_ops.setProcessProp(pGt.id, "days", days, {
                        unit: unit,
                    });
                }
            }

            // lets calculate the damage
            const op_cmp_name = (op1, op2) => {
                let ret = op1.tid - op2.tid;
                if (0 === ret) {
                    ret = op1.aid - op2.aid;
                    if (0 === ret) {
                        ret = op1.i - op2.i;
                    }
                }
                return ret;
            };
            const op_cmp_value = (op1, op2) => {
                assert("number" === typeof op1.value && "number" === typeof op2.value);
                const ret = op1.value - op2.value;
                return ret;
            };
            const op_cmp_name_value = (op1, op2) => {
                let ret = op_cmp_name(op1, op2);
                if (0 === ret) {
                    ret = op_cmp_value(op1, op2);
                }
                return ret;
            };
            ops.sort(op_cmp_name_value);
            // gather ops
            const old_ops: {
                tid: number;
                aid: number;
                i: number;
                value: number;
                default?: boolean;
            }[] = [];
            {
                const it_all_ops = pGt.getAllProps();
                const prop = new ComplexProp();
                for (it_all_ops.first(prop); it_all_ops.next(prop); ) {
                    const cardProp = prop.isCARDPROP();
                    if (cardProp && "day" === cardProp.prop) {
                        old_ops.push({
                            tid: pGt.id,
                            aid: cardProp.aid,
                            i: cardProp.cid,
                            value: pGt.value<any>(prop),
                        });
                    }
                }
            }
            old_ops.sort(op_cmp_name_value);

            const n_ops = ops.length,
                n_old_ops = old_ops.length;
            let i_op = 0,
                i_old_op = 0;
            while (i_op < n_ops || i_old_op < n_old_ops) {
                if (i_op < n_ops && i_old_op < n_old_ops && 0 === op_cmp_name_value(ops[i_op], old_ops[i_old_op])) {
                    i_op++;
                    i_old_op++; // same
                } else if (i_old_op < n_old_ops) {
                    if (i_op < n_ops) {
                        // check against default value
                        const cmp = op_cmp_name(ops[i_op], old_ops[i_old_op]);
                        if (0 === cmp) {
                            // we need to overwrite the old value
                            ret_ops.setProcessProp(
                                pGt.id,
                                ComplexProp.CARDPROP(ops[i_op].aid, ops[i_op].i, "day"),
                                ops[i_op].value,
                            );
                            i_op++;
                            i_old_op++; // same
                        } else if (cmp < 0) {
                            if (true !== ops[i_op].default) {
                                ret_ops.setProcessProp(
                                    pGt.id,
                                    ComplexProp.CARDPROP(ops[i_op].aid, ops[i_op].i, "day"),
                                    ops[i_op].value,
                                );
                            }
                            i_op++;
                        } else {
                            assert(cmp > 0);
                            if (true !== old_ops[i_old_op].default) {
                                ret_ops.setProcessProp(
                                    pGt.id,
                                    ComplexProp.CARDPROP(old_ops[i_old_op].aid, old_ops[i_old_op].i, "day"),
                                    -1,
                                ); // deleted
                            }
                            i_old_op++;
                        }
                    } else {
                        assert(n_ops === i_op);
                        if (true !== old_ops[i_old_op].default) {
                            ret_ops.setProcessProp(
                                pGt.id,
                                ComplexProp.CARDPROP(old_ops[i_old_op].aid, old_ops[i_old_op].i, "day"),
                                -1,
                            ); // deleted
                        }
                        i_old_op++;
                    }
                } else if (i_op < n_ops) {
                    assert(i_old_op === n_old_ops);
                    if (true !== ops[i_op].default) {
                        ret_ops.setProcessProp(
                            pGt.id,
                            ComplexProp.CARDPROP(ops[i_op].aid, ops[i_op].i, "day"),
                            ops[i_op].value,
                        );
                    }
                    i_op++;
                }
            }
            assert(n_ops == i_op && n_old_ops === i_old_op);
            assert(ret_ops.length < 10000); // random upper bound, if more than 10000 ops are generated here something seems wrong...
        } else {
            // assert(false); // delete????
        }
    }

    public updateSingleCard(ret_ops: IDocumentTransaction, card: any, ctx: { force?: boolean }) {
        const pGt = new ProcessGetter(this);
        const id = (card?.id || "").split("_");
        const _meta = this.meta;
        if (4 === id.length && "C" === id[0]) {
            const tid = Number.parseInt(id[1], 16);
            const aid = Number.parseInt(id[2], 16);
            const i_day = Number.parseInt(id[3], 16);
            const force = true === ctx?.force;
            const ret = !force
                ? this.gatherActivitiesForTask(pGt.reset(tid))
                : {
                      tid: tid,
                      cards: [],
                      aids: [],
                      _i_max: -1,
                  };
            if ((force || pGt.id >= 0) && -1 === aid && -1 == i_day) {
                const cards = ret.cards;
                for (let i_card = 0; i_card < cards.length; i_card++) {
                    const c = cards[i_card];
                    if ("number" === typeof card.s && c.s !== card.s) {
                        // status
                        ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(c.aid, c.i, "s"), card.s);
                    }
                }
            } else if ((force || pGt.id >= 0) && !Number.isNaN(tid) && !Number.isNaN(aid) && !Number.isNaN(i_day)) {
                if (card.d) {
                    card.date = EpochMStoEpochDays(card.d);
                }
                if (card.d > 0 && pGt.aux<number>("_x1", 0) > 0) {
                    const cards = ret.cards;
                    let i_card = cards.findIndex((c) => c.tid === tid && c.aid === aid && i_day === c.i);
                    if (i_card >= 0) {
                        const c = cards[i_card];
                        if (card.date) {
                            c.date = card.date;
                        }
                    } else {
                        // insert
                        assert(false); // check me
                        ret.cards.push({
                            tid: tid,
                            aid: aid,
                            i: i_day,
                            date: card.date,
                        });
                    }
                }
                if ("number" === typeof card.y) {
                    ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(aid, i_day, "y"), card.y);
                }
                if ("number" === typeof card.s) {
                    // status
                    ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(aid, i_day, "s"), card.s);
                }
                if ("string" === typeof card.n || null === card.n) {
                    // name
                    ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(aid, i_day, "n"), card.n);
                }
                if ("string" === typeof card.m) {
                    // comment
                    ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(aid, i_day, "m"), card.m);
                }
                if ("number" === typeof card.wf || null === card.wf) {
                    // workforce
                    ret_ops.setProcessProp(tid, ComplexProp.CARDPROP(aid, i_day, "wf"), card.wf);
                }
                if (
                    card.lcmx &&
                    card.lcmx.key &&
                    (["string", "number"].indexOf(typeof card.lcmx.value) >= 0 ||
                        ["string", "number"].indexOf(typeof card.lcmx.value?.value) >= 0)
                ) {
                    // custom field
                    ret_ops.setProcessProp(
                        tid,
                        ComplexProp.CARDPROP(
                            aid,
                            i_day,
                            ComplexProp.LCMXPROP((card.lcmx.key as string).toLocaleLowerCase()),
                        ),
                        card.lcmx.value,
                    );
                }
                if ("number" === typeof card.t && card.t >= 0) {
                    const _trades = this.getTradesForProcess(pGt);
                    for (let i_t = 0; i_t < _trades.length; i_t++) {
                        const t_ = _trades[i_t].id;
                        if (card.t !== t_) {
                            // remove existing trades
                            assert(t_ >= 0, "LCM2-725");
                            ret_ops.setProcessProp(ret.tid, ComplexProp.TRADEREF(t_), -1); // -1 removed
                        }
                    }
                    assert(card.t >= 0, "LCM2-725");
                    ret_ops.setProcessProp(ret.tid, ComplexProp.TRADEREF(card.t), 0);
                }
                const projectInfos = this.getProjectInfo();
                if (
                    pGt.id >= 0 &&
                    (_meta.role === "admin" || (_meta.role === "user" && !projectInfos.rbac[projectInfos.sub]))
                ) {
                    // fix only for real tasks
                    this._fixCardOps(ret_ops, ret);
                }
            }
        }
    }

    public updateSingleCardState(
        ret_ops: IDocumentTransaction,
        card: any,
        ctx: {},
        subTrades: { [tid: number]: true },
    ) {
        const pGt = new ProcessGetter(this);
        const id = (card?.id || "").split("_");
        if (4 === id.length && "C" === id[0]) {
            const tid = Number.parseInt(id[1], 16);
            const aid = Number.parseInt(id[2], 16);
            const i_day = Number.parseInt(id[3], 16);
            //const ret=this.gatherActivitiesForTask(pGt.reset(tid));
            pGt.reset(tid);
            if (
                pGt.id >= 0 &&
                !Number.isNaN(tid) &&
                !Number.isNaN(aid) &&
                !Number.isNaN(i_day) &&
                processHasTrade(pGt, subTrades)
            ) {
                if ("number" === typeof card.s) {
                    // status
                    ret_ops.setProcessProp(pGt.id, ComplexProp.CARDPROP(aid, i_day, "s"), card.s);
                }
                if ("string" === typeof card.m) {
                    // comment
                    ret_ops.setProcessProp(pGt.id, ComplexProp.CARDPROP(aid, i_day, "m"), card.m);
                }
            }
        }
    }

    private static RELATIVE_DATE_REGEXP = /^(?<mode>[a-z]+)?(?<duration>[-+][0-9]+)(?<unit>[d])$/;
    public moveSingleCard(ret_ops: DocumentTransaction, card: any, ctx: {}) {
        const id = (card?.id || "").split("_");
        if (4 === id.length && "C" === id[0]) {
            const pGt = new ProcessGetter(this);
            const pGtHelper = new ProcessGetter(this);
            const tid = Number.parseInt(id[1], 16);
            const aid = Number.parseInt(id[2], 16);
            const i_day = Number.parseInt(id[3], 16);
            const ret = this.gatherActivitiesForTask(pGt.reset(tid));
            if (ret.tid >= 0 && !Number.isNaN(tid) && !Number.isNaN(aid) && !Number.isNaN(i_day)) {
                let cardClone = null;
                if (card.d) {
                    if ("string" === typeof card.d) {
                        const m = card.d.match(Core.RELATIVE_DATE_REGEXP);
                        card.d = 0;
                        if (m) {
                            const _duration = m.groups?.duration;
                            const unit = m.groups?.unit;
                            const mode = m.groups?.mode;
                            if (_duration && "d" === unit) {
                                let delta = Number.parseInt(_duration);
                                if (delta) {
                                    let f_card = ret.cards.findIndex(
                                        (c) => c.tid === tid && c.aid === aid && i_day === c.i,
                                    );
                                    if (f_card >= 0) {
                                        if ("clone" === mode) {
                                            cardClone = { ...ret.cards[f_card] };
                                        }
                                        card.d = this.dateAddWorkingDays(
                                            EpochDaystoEpochMS(ret.cards[f_card].date),
                                            delta,
                                        );
                                        card.date = EpochMStoEpochDays(card.d);
                                    }
                                }
                            }
                        }
                    } else {
                        card.date = EpochMStoEpochDays(card.d);
                    }
                }
                if (card.d > 0 && pGt.aux<number>("_x1") > 0) {
                    let f_helper = -1;
                    const cards = ret.cards;
                    let f_card = cards.findIndex((c) => c.tid === tid && c.aid === aid && i_day === c.i);
                    if (f_card >= 0) {
                        const n_cards = cards.length;
                        const helper = [];
                        for (let i_card = 0; i_card < n_cards; i_card++) {
                            while (i_card < n_cards) {
                                const a = [];
                                const i_card0 = i_card;
                                for (; i_card < n_cards && cards[i_card].date === cards[i_card0].date; i_card++) {
                                    if (cards[i_card].date !== cards[f_card].date || i_card === f_card) {
                                        if (i_card === f_card) f_helper = helper.length;
                                        a.push(i_card);
                                    }
                                }
                                assert(i_card0 < i_card);
                                helper.push({
                                    date: cards[i_card0].date,
                                    wdDelta: 0,
                                    cards: a,
                                });
                                assert(
                                    helper.length < 2 ||
                                        helper[helper.length - 2].date < helper[helper.length - 1].date,
                                );
                            }
                        }
                        const moveNextWd = function (
                            this: { core: Core; helper: any[]; n_helper: number; i_helper: number },
                            date: Date,
                        ) {
                            let d;
                            do {
                                Core.moveNextDay(date);
                                d = EpochMStoEpochDays(date.getTime());
                                this.i_helper = 0;
                                while (this.i_helper < this.n_helper && this.helper[this.i_helper].date < d)
                                    this.i_helper++;
                            } while (
                                !(
                                    (this.i_helper < this.n_helper && d === this.helper[this.i_helper].date) ||
                                    this.core.isWorkingDay(date)
                                )
                            );
                        }.bind({
                            core: this,
                            helper: helper,
                            n_helper: helper.length,
                            i_helper: 0,
                        });
                        const n_helper = helper.length;
                        assert(
                            0 <= f_helper &&
                                f_helper < n_helper &&
                                helper[f_helper].date === cards[f_card].date &&
                                1 === helper[f_helper].cards.length &&
                                f_card === helper[f_helper].cards[0],
                        );
                        const date_cmp = helper[f_helper].date - card.date;
                        if (date_cmp < 0) {
                            // set new date
                            helper[f_helper].date = card.date;
                            cards[helper[f_helper].cards[0]].date = card.date;
                            for (
                                let i_helper = f_helper + 1;
                                i_helper < n_helper && helper[i_helper].date <= helper[i_helper - 1].date;
                                i_helper++
                            ) {
                                const date: Date = new Date(EpochDaystoEpochMS(helper[i_helper].date));
                                while (EpochMStoEpochDays(date.getTime()) <= helper[i_helper - 1].date) {
                                    moveNextWd(date);
                                }
                                assert(EpochMStoEpochDays(date.getTime()) > helper[i_helper - 1].date);
                                const newDate = EpochMStoEpochDays(date.getTime());
                                helper[i_helper].date = newDate;
                                for (let i = 0; i < helper[i_helper].cards.length; i++) {
                                    cards[helper[i_helper].cards[i]].date = newDate;
                                }
                            }
                        } else if (date_cmp > 0) {
                            {
                                const date = new Date(EpochDaystoEpochMS(helper[f_helper].date));
                                for (let i_helper = f_helper + 1; i_helper < n_helper; i_helper++) {
                                    assert(EpochMStoEpochDays(date.getTime()) === helper[i_helper - 1].date);
                                    while (EpochMStoEpochDays(date.getTime()) < helper[i_helper].date) {
                                        helper[i_helper].wdDelta++;
                                        moveNextWd(date);
                                    }
                                }
                            }
                            // set new date
                            helper[f_helper].date = card.date;
                            cards[helper[f_helper].cards[0]].date = card.date;
                            for (
                                let i_helper = f_helper + 1;
                                i_helper < n_helper && 1 === helper[i_helper].wdDelta;
                                i_helper++
                            ) {
                                const date = new Date(EpochDaystoEpochMS(helper[i_helper - 1].date));
                                while (helper[i_helper].wdDelta > 0) {
                                    helper[i_helper].wdDelta--;
                                    moveNextWd(date);
                                }
                                const newDate = EpochMStoEpochDays(date.getTime());
                                helper[i_helper].date = newDate;
                                for (let i = 0; i < helper[i_helper].cards.length; i++) {
                                    cards[helper[i_helper].cards[i]].date = newDate;
                                }
                            }
                        }
                        if (0 !== date_cmp) {
                            if (cardClone) {
                                // insert hack: prevent the actual card from beeing moved
                                let f_card = ret.cards.findIndex(
                                    (c) => c.tid === tid && c.aid === aid && i_day === c.i,
                                );
                                assert(f_card >= 0);
                                const cardTemplate = ret.cards[f_card];
                                assert(ret.cards[f_card].i === cardClone.i);
                                ret.cards[f_card] = cardClone;
                                const cid = ret_ops.createCard();
                                cardTemplate.i = cid; // new id
                                ret.cards.push(cardTemplate); // add card
                                if (cardTemplate.name /*&& cardTemplate.name!==cardTemplate.p_name*/) {
                                    // clone name
                                    const p_name = pGtHelper.reset(cardTemplate.tid).value<string>("name", "");
                                    if (p_name !== cardTemplate.name) {
                                        ret_ops.setProcessProp(
                                            cardTemplate.tid,
                                            ComplexProp.CARDPROP(cardTemplate.aid, cardTemplate.i, "n"),
                                            cardTemplate.name,
                                        );
                                    }
                                }
                            }
                            this._fixCardOps(ret_ops, ret);
                        }
                    }
                }
            }
        }
    }

    public insertSingleCard(ops: IDocumentTransaction, card: any, ctx: {}) {
        const tid = card.p;
        const aid = 0;
        const cid = ops.createCard();
        card.id = ["C", tid.toString(16), aid.toString(16), cid.toString(10)].join("_");
        this.updateSingleCard(ops, card, ctx);
    }

    public removeSingleCard(ret_ops: IDocumentTransaction, card: any, ctx: {}) {
        const id = (card?.id || "").split("_");
        if (4 === id.length && "C" === id[0]) {
            const tid = Number.parseInt(id[1], 16);
            const aid = Number.parseInt(id[2], 16);
            const i_day = Number.parseInt(id[3], 16);
            if (!Number.isNaN(tid) && !Number.isNaN(aid) && !Number.isNaN(i_day)) {
                const pGt = new ProcessGetter(this);
                const ret = this.gatherActivitiesForTask(pGt.reset(tid));
                const cards = ret.cards;
                let f_card = cards.findIndex((c) => c.tid === tid && c.aid === aid && i_day === c.i);
                if (f_card >= 0) {
                    ret.aids[aid] = ret.aids[aid] || {};
                    ret.aids[aid][i_day] = ret.aids[aid][i_day] || {};
                    ret.aids[aid][i_day]["day"] = {
                        value: -1,
                    };
                    cards.splice(f_card, 1);
                    if (0 === cards.length) {
                        ret_ops.setProcessProp(tid, "p", -1); // delete process
                    } else {
                        this._fixCardOps(ret_ops, ret);
                    }
                }
            }
        }
    }

    public setTaskDetailsForProcess(
        ids: string[],
        state: LCMDContextCardDetailsState | LCMDContextCardDetailsState[] | null,
    ) {
        const _ops = new ComplexDocumentTransaction(this);
        for (let i_id = 0; i_id < ids.length; i_id++) {
            const cardState = Array.isArray(state) ? state[i_id] : state;
            const id = ids[i_id];
            const ops = new DocumentTransaction(this, _ops);
            setApiCardDetailsImpl(this, ops, id, cardState);
            _ops.commit(ops);
        }
        _ops.commit();
    }

    resolveConflict(
        conflict: {
            tid: number;
            start: number;
            delta: number;
        }[],
    ) {
        const ops = new DocumentTransaction(this);
        const pGt = new ProcessGetter(this);
        const n = conflict.length;
        let concurrentModification = false;
        for (let i = 0; i < n; i++) {
            const c = conflict[i];
            if (pGt.reset(c.tid).id >= 0 && c.start > 0 && c.delta > 0) {
                if (pGt.aux<number>("_x1") === c.start) {
                    const start = this.dateAddWorkingDays(c.start, c.delta);
                    ops.setProcessProp(c.tid, "start", start);
                } else {
                    concurrentModification = true; // value has changed...
                }
            }
        }
        if (!concurrentModification) {
            ops.commit();
        }
    }

    public override getTrades(includeDeleted: boolean = false): any[] {
        const trGt = new TradesGetter(this);
        const tradeIds = this.getAllTradeIds();
        const ret: {
            projectId: string | undefined;
            label: string;
            name: string;
            trade: string;
            color: number;
            id: number;
            subs: { [key: string]: string };
        }[] = tradeIds.reduce((ret, tradeId) => {
            trGt.reset(tradeId);
            const _name = trGt.value<string>("name", "");
            if (null !== _name || includeDeleted) {
                // not deleted
                const _trade = trGt.value<string>("trade", "");
                let label: string;
                if ((_trade || "").length > 0 && _trade !== _name) {
                    label = _trade + " (" + _name + ")";
                } else {
                    label = _name;
                }
                const subs = {};
                {
                    const it = trGt.getAllProps();
                    const p = new ComplexProp();
                    for (it.first(p); it.next(p); ) {
                        const sub = p.isSUB();
                        const value = trGt.value<any>(p);
                        if (sub && value) {
                            subs[sub] = value;
                        }
                    }
                }
                ret.push({
                    projectId: this.getSID(),
                    label: label,
                    name: _name,
                    trade: _trade,
                    color: trGt.value<number>("color", 0xb4c85d),
                    icon: trGt.icon,
                    id: tradeId,
                    subs: subs,
                });
            }
            return ret;
        }, []);
        return ret;
    }

    public gatherTaktzones(collapsedTIDs: number[]) {
        const pGt = new ProcessGetter(this);
        const ret = [];
        const stack: any[] = [
            {
                c: [0],
                i: 0,
                l: 0,
            },
        ];
        while (stack.length > 0) {
            const s = stack[stack.length - 1];
            const n = s.c.length;
            if (s.i < n) {
                const tid = s.c[s.i++];
                pGt.reset(tid);
                assert(pGt.id >= 0);
                const p = pGt.value<number>("p", -1);
                if (0 === tid || p >= 0) {
                    const t_c = pGt.aux<number[]>("_c");
                    const isTZ = Array.isArray(t_c);
                    if (isTZ) {
                        const collapsed = collapsedTIDs.indexOf(tid) >= 0;
                        ret.push({
                            tid: tid,
                            l: s.l,
                            n: pGt.value<string>("name", null),
                            s: isTZ ? (collapsed ? 2 : 1) : 0,
                        });
                        if (isTZ && !collapsed) {
                            stack.push({
                                c: t_c,
                                i: 0,
                                l: s.l + 1,
                            });
                        }
                    }
                } else {
                    // deleted..
                }
            } else {
                stack.pop();
            }
        }
        return ret;
    }

    public setProcessesFilter(processIds: number[]) {
        if (this._model) {
            this.setFilter(this.filter, { forcePids: processIds });
        }
    }

    public resetProcessFilter() {
        if (this._model) {
            this.setFilter(this.filter, { forcePids: null });
        }
    }

    public static filterNormalize(filter: DataModelFilter): DataModelFilter {
        const filterJSON: DataModelFilter = {
            tz: filter.tz,
            trade: filter.trade,
            d: filter.d,
            pids: filter.pids,
        };
        if (Array.isArray(filterJSON.tz) && filterJSON.tz.length > 0) {
            // normalize
            filterJSON.tz.sort();
        } else {
            delete filterJSON.tz;
        }
        if (Array.isArray(filterJSON.trade) && filterJSON.trade.length > 0) {
            // normalize
            filterJSON.trade.sort();
        } else {
            delete filterJSON.trade;
        }
        if (Array.isArray(filterJSON.d) && filterJSON.d.length >= 2 && filterJSON.d[1] > 0) {
            // normalize
            // do nothing...
        } else {
            delete filterJSON.d;
        }

        return filterJSON.tz || filterJSON.trade || filterJSON.d || filterJSON.pids ? filterJSON : null;
    }

    private hivePathToFilter(pathStr) {
        const ret: DataModelFilter = {
            tz: [],
            trade: [],
            d: [] as any,
        };
        this.parseHivePath(pathStr).reduce((ret, item) => {
            switch (item.target) {
                case "T":
                    {
                        ret.tz.push(item.id);
                    }
                    break;
                case "R":
                    {
                        ret.trade.push(item.id);
                    }
                    break;
                case "D":
                    {
                        ret.d.push(item.id);
                    }
                    break;
            }
            return ret;
        }, ret);
        return Core.filterNormalize(ret);
    }

    getFilterCollection(): DataModelFilterCollection {
        const ret = [];
        const hGt = new HiveGetter(this, "lcmd.filter");
        const _Gt = new ParticleGetter(this);
        const p = new ComplexProp();
        {
            const it = hGt.getAllProps();
            for (it.first(p); it.next(p); ) {
                const hid = p.isHIVEID();
                if (hid) {
                    const hGtHelper = new HiveGetter(hGt, p);
                    const name = hGtHelper.value<string>("name");
                    const value = hGtHelper.value<string>("value");
                    if (value && name) {
                        if (_Gt.reset(hGtHelper._("name")).userId === this.getSUB()) {
                            // only show "own" filters at the moment...
                            const pos = hGtHelper.value<number>("pos", 0);
                            const _pos = Math.min(Math.max(0, pos, 0), ret.length);
                            ret.splice(_pos, 0, {
                                _hid: this.getUnsafeIDs({ hids: [hid] }).hids[0],
                                _pos: _pos,
                                _filter: this.hivePathToFilter(value),
                                name: name,
                            });
                        }
                    }
                }
            }
            // fix pos
            for (let i_ret = 0; i_ret < ret.length; i_ret++) {
                ret[i_ret]._pos = i_ret;
            }
        }
        return ret;
    }

    getFilter(fc?: DataModelFilterCollection): DataModelFilterSavedAs {
        const filter = this.filter;
        if ("number" === typeof filter) {
            const hid = this.getSafeIDs({ hids: [filter] }).hids[0];
            const hGt = new HiveGetter(this, ["lcmd.filter", hid].join("."));
            const value = hGt.value<string>("value", null);
            if (value) {
                const filterJSON = this.hivePathToFilter(value);
                if (fc) {
                    const f = fc.find((e) => e._hid === filter);
                    if (f) {
                        Object.assign(filterJSON, {
                            saveAs: {
                                hid: f._hid,
                                name: f.name,
                                pos: f._pos,
                            },
                        });
                    }
                }
                return filterJSON;
            } else {
                return null;
            }
        } else {
            return filter;
        }
    }

    isFilterActive() {
        return this.filter !== null && this.filter !== undefined;
    }

    public static filterToX1X2(d: [number, number]): { _x1: number; _x2: number } {
        let _x1 = d[0];
        let _x2 = d[1];
        if (0 === _x1) {
            const startOfWeek = DateToMonday(new Date(Date.now()), true).getTime();
            _x1 = startOfWeek;
        }
        if (_x2 < FILTER_SPECIAL_VALUE) {
            const unit = _x2 & FILTER_SPECIAL_UNIT_MASK;
            const v = _x2 & FILTER_SPECIAL_VALUE_MASK;
            if (FILTER_SPECIAL_UNIT_MONTH === unit) {
                const helper = new Date(_x1);
                helper.setUTCMonth(helper.getUTCMonth() + v);
                _x2 = helper.getTime();
            } else {
                assert(FILTER_SPECIAL_UNIT_WEEKS === unit);
                _x2 = _x1 + (v > 0 ? EpochDaystoEpochMS(7) * _x2 - 1 : 0);
            }
        }
        return {
            _x1: _x1 <= _x2 ? EpochDaystoEpochMS(EpochMStoEpochDays(_x1)) : undefined,
            _x2: _x1 <= _x2 ? EpochDaystoEpochMS(EpochMStoEpochDays(_x2) + 1) : undefined,
        };
    }

    setFilter(
        filter: DataModelFilter | string | number,
        opt?: {
            modeDailyBoard?: boolean;
            forcePids?: number[];
        },
    ) {
        if ("string" === typeof filter) {
            try {
                filter = JSON.parse(filter) as DataModelFilter;
            } catch (e) {
                filter = null as DataModelFilter;
            }
        }
        let filterJSON: DataModelFilter = null;
        if ("number" === typeof filter) {
            const hid = this.getSafeIDs({ hids: [filter] }).hids[0];
            const hGt = new HiveGetter(this, ["lcmd.filter", hid].join("."));
            const value = hGt.value<number>("value");
            if (value) {
                filterJSON = this.hivePathToFilter(value);
            }
        } else if (filter) {
            filterJSON = Core.filterNormalize(filter);
        }

        // set the forced pids even if the filter is loaded over saved filter. Will overwrite pids passed into the default parameter!!
        if (Array.isArray(opt?.forcePids) || opt?.forcePids === null) {
            if (!filterJSON) {
                // if filterJSON null and forcePids is defined
                filterJSON = {};
            }
            filterJSON.pids = opt.forcePids;
        }

        if (filterJSON) {
            this.filter = filter;
        } else {
            this.filter = null;
        }
        this.setFilterCBs({
            stripesFilter:
                Array.isArray(filterJSON?.tz) && filterJSON.tz.length > 0
                    ? function (this: any, sc: number) {
                          return sc in this;
                      }.bind(
                          filterJSON.tz.reduce((ret, tz) => {
                              ret[tz] = true;
                              return ret;
                          }, {}),
                      )
                    : null,
            stripesTradeFilterCB:
                Array.isArray(filterJSON?.trade) && filterJSON.trade.length > 0
                    ? function (this: any, id: number) {
                          return id in this;
                      }.bind(
                          filterJSON.trade.reduce((ret, t) => {
                              ret[t] = true;
                              return ret;
                          }, {}),
                      )
                    : null,
            filterX1X2: Array.isArray(filterJSON?.d) ? Core.filterToX1X2(filterJSON.d) : null,
            pidsFilter:
                Array.isArray(filterJSON?.pids) && filterJSON?.pids.length > 0
                    ? (pid: number) => {
                          return filterJSON.pids.includes(pid);
                      }
                    : null,
        });
        {
            const filter = this.getFilter();
            const pGt = new ProcessGetter(this);
            const trGt = new TradesGetter(this);
            this.patchLegacyFluxState(
                "filter",
                filter || Array.isArray(opt?.forcePids)
                    ? {
                          saveAs: filter?.saveAs,
                          tz: (filter?.tz || []).map((tid) => {
                              return {
                                  tid: tid,
                                  name: pGt.reset(tid).value<string>("name", "?"),
                              };
                          }),
                          trade: (filter?.trade || []).map((trid) => {
                              return {
                                  trid: trid,
                                  name: trGt.reset(trid).value<string>("name", "?"),
                              };
                          }),
                          date: Array.isArray(filter?.d)
                              ? {
                                    startEnd: Core.filterToX1X2(filter.d),
                                }
                              : null,
                          pids: Array.isArray(opt?.forcePids) ? opt.forcePids : null,
                      }
                    : null,
            );
        }
    }

    async setAndSaveFilterIfNeeded(
        filter: DataModelFilterSavedAs,
        opt?: {
            modeDailyBoard?: boolean;
        },
    ) {
        if (filter?.saveAs) {
            const _filter =
                "number" === typeof filter.saveAs.hid
                    ? this.getFilterCollection().find((e) => filter.saveAs.hid === e._hid)
                    : undefined;
            let a, b;
            if (
                _filter &&
                _filter.name === filter.saveAs.name &&
                _filter._pos === filter.saveAs.pos &&
                (a = JSON.stringify(Core.filterNormalize(_filter._filter))) ===
                    (b = JSON.stringify(Core.filterNormalize(filter)))
            ) {
                // no change
                filter = filter.saveAs.hid as any;
            } else {
                await this.sync();
                const hid = await this.pushFilterToHive(filter);
                if ("number" === typeof filter?.saveAs?.hid && !filter?.saveAs?.name) {
                    // filter is removed
                    filter = null;
                } else {
                    filter = hid as any;
                }
            }
        }
        this.setFilter(filter || null, opt);
    }

    private filterToHivePath(filter: DataModelFilter) {
        const ret = [];
        const tz = filter?.tz;
        const trade = filter?.trade;
        const d = filter?.d;
        if (Array.isArray(tz)) {
            for (let i = 0; i < tz.length; i++) {
                ret.push({
                    target: "T",
                    id: tz[i],
                });
            }
        }
        if (Array.isArray(trade)) {
            for (let i = 0; i < trade.length; i++) {
                ret.push({
                    target: "R",
                    id: trade[i],
                });
            }
        }
        if (Array.isArray(d)) {
            for (let i = 0; i < d.length; i++) {
                ret.push({
                    target: "D",
                    id: d[i],
                });
            }
        }
        return this.createHivePath(ret);
    }

    public pushFilterToHive(filter: DataModelFilterSavedAs) {
        return new Promise((resolve: (hid: number) => void, reject: (e: FrameworkError) => void) => {
            if ("number" === typeof filter?.saveAs?.hid || filter?.saveAs?.name) {
                const t = new DocumentTransaction(this);
                const name = filter?.saveAs?.name;
                let hid;
                if (name) {
                    //@TODO check difference...
                    const filterAsPath = this.filterToHivePath(filter);
                    const _hid = filter.saveAs?.hid > 0 ? filter.saveAs.hid : undefined;
                    hid = t.setHiveProp(
                        _hid,
                        (hid) =>
                            ComplexProp.HIVEPROP(["lcmd", "filter", this.getSafeIDs({ hids: [hid] }).hids[0], "value"]),
                        filterAsPath,
                    );
                    if (filter?.saveAs?.name) {
                        t.setHiveProp(
                            hid,
                            ComplexProp.HIVEPROP(["lcmd", "filter", this.getSafeIDs({ hids: [hid] }).hids[0], "name"]),
                            filter.saveAs.name,
                        );
                    }
                    if ("number" === typeof filter?.saveAs?.pos) {
                        t.setHiveProp(
                            hid,
                            ComplexProp.HIVEPROP(["lcmd", "filter", this.getSafeIDs({ hids: [hid] }).hids[0], "pos"]),
                            filter.saveAs.pos,
                        );
                    }
                } else {
                    assert("number" === typeof filter.saveAs?.hid);
                    hid = filter.saveAs.hid;
                    t.setHiveProp(
                        hid,
                        ComplexProp.HIVEPROP(["lcmd", "filter", this.getSafeIDs({ hids: [hid] }).hids[0], "name"]),
                        null,
                    ); // clear name...
                }
                assert("number" === typeof hid);
                t.commit({
                    ignoreReadonly: true,
                    forceSync: (success: boolean) => {
                        if (success) {
                            resolve(hid);
                        } else {
                            reject(new FrameworkErrorDataModelNeedsSync());
                        }
                    },
                });
            } else {
                resolve(null);
            }
        });
    }

    public selectView(
        view: number | { sidebarColImage?: number; grid?: number; showTaktzones?: boolean; stackProcesses?: boolean },
    ) {
        if (this._model) {
            this._model.selectView(
                "number" === typeof view ? view : undefined,
                undefined,
                "number" === typeof view ? undefined : view,
            );
        }
    }

    public getTaskActionOrExtItems(pGt: ProcessGetter, _gT: ParticleGetter) {
        const it = pGt.getAllProps();
        const ret = {
            ais: {},
            exts: {},
        };
        const p = new ComplexProp();
        for (it.first(p); it.next(p); ) {
            let helper;
            if ((helper = p.isACTIONITEM())) {
                if (helper.prop) {
                    // values for item
                    if (ret.ais[helper.id]) {
                        // deleted if not
                        const v = pGt.value<any>(p, undefined);
                        ret.ais[helper.id] = { ...ret.ais[helper.id], [helper.prop]: v };
                    }
                } else {
                    const va = pGt.history(p);
                    assert(va.length > 0);
                    const v = va.length > 1 ? _gT.reset(va[0]).value() : undefined; // initial value is always CREATE and is always the type of the action item
                    const type = _gT.reset(va[va.length - 1]).value();
                    if (va.length > 1 && !v) {
                        // deleted
                        // do nothing...
                    } else {
                        ret.ais[helper.id] = {
                            id: helper.id,
                            _type: type, // type override for stability criteria
                            _value: v,
                        };
                    }
                }
            } else if ((helper = p.isLCMX())) {
                const path = helper.split("#");
                assert(path.length > 0 && path[0]);
                const _n = path[0];
                if (1 === path?.length) {
                    const v = pGt.value<any>(p, undefined);
                    ret.exts[_n] = {
                        _value: v,
                    };
                } else if (2 === path?.length && path[1]) {
                    // values for item
                    const item = ret.exts[_n];
                    if (item) {
                        // if not  item e.g. deleted
                        const v = pGt.value<any>(p, undefined);
                        ret.exts[_n] = { ...item, [path[1]]: v };
                    }
                }
            }
        }
        return ret;
    }

    public getProcessReasonCodes(
        pGt: ProcessGetter,
        _gT: ParticleGetter,
    ): Map<AttachedReasonCodeId, AttachedReasonCode> | null {
        const it = pGt.getAllProps();
        const p = new ComplexProp();
        const reasonCodesAttachedToProcess = new Map();
        for (it.first(p); it.next(p); ) {
            let helper;
            if ((helper = p.isREASON_CODE())) {
                if (helper.prop) {
                    // values for item
                    if (reasonCodesAttachedToProcess.has(helper.id) && helper.prop !== "createdAt") {
                        const propValue = pGt.value<any>(p, undefined);
                        const existingProps = reasonCodesAttachedToProcess.get(helper.id);
                        reasonCodesAttachedToProcess.set(helper.id, { ...existingProps, [helper.prop]: propValue });
                    }
                } else {
                    const historyOpIds = pGt.history(p);
                    assert(historyOpIds.length > 0);
                    const v = historyOpIds.length > 1 ? _gT.reset(historyOpIds[0]).value() : undefined; // initial value is always CREATE and is always the type of the action item
                    if (historyOpIds.length > 1 && !v) {
                        // deleted
                        // do nothing...
                    } else {
                        reasonCodesAttachedToProcess.set(helper.id, {
                            id: helper.id,
                            createdAt: _gT.createdAt,
                            pid: pGt.id,
                        });
                    }
                }
            }
        }
        return reasonCodesAttachedToProcess.size > 0 ? reasonCodesAttachedToProcess : null;
    }

    private _resolveStability(pGt: ProcessGetter, f) {
        const v = pGt.value<any>(ComplexProp.LCMXPROP(f.key))?.value;
        const lead = f["stability.lead"] || 0;
        const trades = f["stability.trades"];
        const t_rw = pGt.aux("_rw") || {};
        const valid =
            !Array.isArray(trades) ||
            Object.getOwnPropertyNames(t_rw).reduce(
                (ret, _trid) => ret || trades.indexOf(Number.parseInt(_trid)) >= 0,
                false,
            );
        const done = valid
            ? (v &&
                  (((true === f["stability.stable"] || false === f["stability.stable"]) && true === v) ||
                      f["stability.stable"].indexOf(v) >= 0)) ||
              false
            : undefined;
        const t_x1 = pGt.aux<number>("_x1");
        const due = valid && t_x1 > 0 ? this.dateAddWorkingDays(t_x1, -lead) : undefined;
        return { valid, lead, done, due, value: v };
    }

    public getTaskTodos(
        pGt: ProcessGetter,
        ctx: {
            stabs: any;
            done: number;
            overdue: number;
            todos: LCMDContextTodoItem[];
        },
        trGtHelper: TradesGetter,
        _GtHelper: ParticleGetter,
    ) {
        const _Gt = _GtHelper || new ParticleGetter(this);
        const ret: LCMDContextTodoItem[] = Array.isArray(ctx?.todos) ? ctx.todos : [];
        let t_c;
        if (pGt.id >= 0 && !Array.isArray((t_c = pGt.aux<number>("_c"))) && !pGt.isDeleted()) {
            // skip summary tasks

            let i = pGt.value<number>("p", -1);
            let path = [];
            while (i > 0) {
                const processGetter = new ProcessGetter(this, i);
                path.unshift({
                    id: processGetter.id,
                    name: processGetter.value<string>("name", ""),
                });
                i = processGetter.value<number>("p", -1);
            }

            const process: LCMDContextTodoItemProcessDetailsAREAPATH = {
                _: pGt.aux<number>("_", pGt.aux<number>("__")),
                pid: pGt.id,
                name: pGt.value<string>("name", ""),
                areaPath: path,
                trades: this.getTradesForProcess(pGt, trGtHelper).map(
                    (trade) =>
                        ({
                            trid: trade.id,
                            name: trade.name,
                            trade: trade.trade,
                            color: trade.color,
                        }) as LCMDContextTaskDetailsResultTradeDetails,
                ),
                color: this.getColorForProcess(pGt),
            };
            const aiexts = this.getTaskActionOrExtItems(pGt, _Gt);
            const fields = pGt.core.getProcessDetailsExt();
            const stabs = fields?.CACHE_stabs || [];
            const today = pGt.core.getViewConst().today;
            Object.getOwnPropertyNames(aiexts.ais).reduce((ret: LCMDContextTodoItem[], _id) => {
                const id = Number.parseInt(_id);
                const ai = aiexts.ais[_id];
                const deadline = ai.deadline;
                const createdAt = ai.createdAt;
                let done = "done" === ai._value;
                let due = undefined;
                if ("number" === typeof deadline) {
                    if (deadline <= 0) {
                        // lead time in days
                        due = this.dateAddWorkingDays(pGt.aux<number>("_x1"), deadline);
                    } else {
                        due = deadline;
                    }
                }
                if ("string" === typeof ai._type?.stab || "number" === typeof ai._type?.stab) {
                    // stability item, if number then its the task id...
                    const key = "I" + id.toString(16);
                    if (ctx) {
                        if (!(key in ctx.stabs)) {
                            ctx.stabs[key] = {
                                pid: pGt.id,
                                key: key,
                            };
                        }
                        if (done) {
                            ctx.done++;
                        } else if (due && EpochMStoEpochDays(due) < today) {
                            ctx.overdue++;
                        }
                    }
                    ret.push({
                        id: id,
                        process,
                        stab: { ...ai, key: key },
                        done: done,
                        due: due,
                        createdAt: createdAt,
                    });
                } else {
                    if (ctx) {
                        if (done) {
                            ctx.done++;
                        } else if (!due || EpochMStoEpochDays(due) < today) {
                            ctx.overdue++;
                        }
                    }
                    ret.push({
                        id: Number.parseInt(_id),
                        process,
                        ai: ai,
                        done: done,
                        due: due,
                        createdAt: createdAt,
                    });
                }
                return ret;
            }, ret);
            stabs.reduce((ret: LCMDContextTodoItem[], f) => {
                const { valid, lead, done, due, value } = this._resolveStability(pGt, f);
                if (valid) {
                    if (ctx) {
                        if (!(f.key in ctx.stabs)) {
                            ctx.stabs[f.key] = f;
                        }
                        if (done) {
                            ctx.done++;
                        } else if (due && EpochMStoEpochDays(due) < today) {
                            ctx.overdue++;
                        }
                    }

                    const f_id = f["stability.id"];
                    const id = f_id ? [pGt.id, f_id].join("-") : [pGt.id, f._id].join("#");
                    const stab = { ...(aiexts.exts[f.key] || {}), id: id, key: f.key, _value: value };
                    // todo: set createdAt for generated Stabis
                    ret.push({
                        id: id,
                        process,
                        stab,
                        done: done,
                        due: due,
                        createdAt: undefined,
                    });
                }
                return ret;
            }, ret);
            return ret;
        }
        return ret;
    }

    public _getApiTodos(
        tids: (string | number)[],
        options: {
            sort?: string[];
        },
    ): LCMDContextTodoResult {
        const pGt = new ProcessGetter(this);
        const trGt = new TradesGetter(this);
        const _Gt = new ParticleGetter(this);
        const today = EpochDaystoEpochMS(this.getViewConst().today);
        const ctx = {
            stabs: {},
            done: 0,
            overdue: 0,
            todos: [] as LCMDContextTodoItem[],
        };
        tids.reduce((ret, _tid) => {
            const pid = "string" === typeof _tid ? Number.parseInt(_tid) : _tid;
            if ("number" === typeof pid && pGt.reset(pid).id === pid) {
                this.getTaskTodos(pGt, ret, trGt, _Gt);
            }
            return ret;
        }, ctx);
        return {
            today: {
                value: today,
            },
            stabs: ctx.stabs,
            items: ctx.todos,
        };
    }

    public getApiTodos(options: {}) {
        return this._getApiTodos(this.getAllProcessesIds(), options);
    }

    private generatePropertyNameForTodoItem(type: "action" | "stability", id: number | string, propertyName: string) {
        if (type === "action") {
            return ComplexProp.ACTIONITEM(id as number, propertyName);
        } else if (type === "stability") {
            return ComplexProp.LCMXPROP([(id as string).toLocaleLowerCase(), propertyName].join("#"));
        }

        throw new Error("invalid todoItem Type");
    }

    private setMetaDataForTodoItem(
        t: IDocumentTransaction,
        pid: number,
        todoItemId: number | string,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
        type: "action" | "stability",
    ) {
        const createdAtPropertyName = "createdAt";
        // type TodoItemCreateDate = keyof Pick<LCMDContextTodoItem, "createdAt">
        // remove createdAt so it can´t be overwritten
        if (meta && meta.hasOwnProperty(createdAtPropertyName)) {
            delete meta[createdAtPropertyName];
        }

        Object.getOwnPropertyNames(meta || {}).reduce((ret, name) => {
            //@TODO check if already set...
            t.setProcessProp(
                pid,
                this.generatePropertyNameForTodoItem(type, todoItemId, name),
                meta[name].value,
                meta[name] as any,
            );
            return ret;
        }, t);
    }

    public _setApiActionItemForProcess(
        t: IDocumentTransaction,
        pid: number,
        aid: number,
        state: { value: number | string | boolean | null } | null,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
    ) {
        const prefix = ["#I", aid.toString(16)].join("");
        if (state) {
            t.setProcessProp(pid, ComplexProp.ACTIONITEM(aid), state.value);
        }
        this.setMetaDataForTodoItem(t, pid, aid, meta, "action");
    }

    public _setApiStabilityItemForProcess(
        t: IDocumentTransaction,
        pid: number,
        stab: string,
        state: { value: number | string | boolean | null } | null,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
    ) {
        if (stab.startsWith("I")) {
            const target_id = Number.parseInt(stab.substring(1), 16);
            this._setApiActionItemForProcess(t, pid, target_id, state, meta);
        } else {
            if (state) {
                t.setProcessProp(pid, ComplexProp.LCMXPROP((stab as string).toLocaleLowerCase()), state);
            }
            this.setMetaDataForTodoItem(t, pid, stab, meta, "stability");
        }
    }

    public setOrCreateApiTodoItemForProcess(
        target: LCMDContextTodoItemTarget[],
        state: { value: number | string | boolean | null } | null,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
    ) {
        const t = new DocumentTransaction(this);
        setOrCreateApiTodoItemForProcessImpl(this, t, target, state, meta);
        t.commit();
    }

    public setOrCreateReasonCode(commentId: number | null, pid: number, reasonCodeId: string, opId: number) {
        const documentTransaction = new DocumentTransaction(this);
        setOrCreateReasonCodeImpl(this, documentTransaction, commentId, pid, reasonCodeId, opId);
        documentTransaction.commit();
    }

    public deleteAttachedReasonCode(commentId: number, pid: number) {
        const documentTransaction = new DocumentTransaction(this);
        deleteAttachedReasonCode(this, documentTransaction, commentId, pid);
        documentTransaction.commit();
    }

    //@nikhil checklist_api
    public attachCheckList(pid: number, checklistTemplateId: string) {
        const documentTransaction = new DocumentTransaction(this);
        attachChecklistImpl(this, documentTransaction, pid, checklistTemplateId);
        documentTransaction.commit();
    }

    public updateAttachedChecklist(checklistId: number | null, pid: number, states: number[]) {
        const documentTransaction = new DocumentTransaction(this);
        updateAttachedChecklistImpl(this, documentTransaction, checklistId, states, pid);
        documentTransaction.commit();
    }

    public deleteAttachedChecklist(pid: number, commentId: number) {
        const documentTransaction = new DocumentTransaction(this);
        deleteAttachedChecklistImpl(this, documentTransaction, commentId, pid);
        documentTransaction.commit();
    }

    public decodeAttachedChecklistStates(states: string, checkpointsLength: number): number[] {
        return decodeHexToData(states, checkpointsLength);
    }

    public _createApiActionItem(
        t: IDocumentTransaction,
        target: {
            pid?: number;
            type: "action" | "stability";
        },
    ) {
        if (target?.pid) {
            const commentId = t.createComment();
            const name = "createdAt";
            const createdAtProperty = {
                value: new Date().getTime(),
            };

            t.setProcessProp(
                target.pid,
                ComplexProp.ACTIONITEM(commentId),
                "stability" === target.type ? { stab: 1 } : 1,
            );
            t.setProcessProp(
                target.pid,
                ComplexProp.ACTIONITEM(commentId, name),
                createdAtProperty.value,
                createdAtProperty as any,
            );
            return commentId;
        } else {
            return null;
        }
    }

    public async exportAsXLSX(opt?: { cards?: boolean; json?: boolean }) {
        return new Promise((resolve: (buffer: Buffer) => void, reject: (e: FrameworkError) => void) => {
            if (this._model) {
                const writer = new XlsxExport(this).exportAsXLSX(opt);
                writer
                    .fetchXSLX(this._auth_token, opt)
                    .then((buffer) => {
                        resolve(buffer);
                    })
                    .catch((e: FrameworkHttpError) => {
                        reject(e);
                    });
            } else {
                reject(new FrameworkHttpError(0));
            }
        });
    }

    // @todo: area or taktzone ?!?
    public getMaxAreaDepth(): number {
        const wbs = this.gatherTaktzones([]);
        return wbs?.reduce((ret, row) => Math.max(ret, row.l), 0);
    }

    public async exportTodoItemsAsXlsx(
        projectId: string,
        todoItems?: ToDoProps[],
        maxAreaLevel?: number,
        opt?: { fetchTodos?: boolean; json?: boolean },
    ) {
        if (!todoItems && opt?.fetchTodos === true) {
            todoItems = generateTodosFromRawDate(this.getApiTodos({}));
        }

        if (!todoItems) {
            return;
        }

        const today = EpochDaystoEpochMS(this.getViewConst().today);
        maxAreaLevel = (
            maxAreaLevel !== null && typeof maxAreaLevel !== "undefined" && Number.isInteger(maxAreaLevel)
                ? maxAreaLevel
                : this.getMaxAreaDepth()
        ) as number;

        assert<number>(maxAreaLevel, "maxAreaLevel needs to be defined and a number");
        if (!maxAreaLevel) {
            return;
        }

        const helper: TableExportHelper = new TableExportHelper();
        helper.pushHeader("ID");
        helper.pushHeader("Prio");
        helper.pushHeader("Issue");
        helper.pushHeader("Action");
        helper.pushHeader("Trade");
        helper.pushHeader("Process");

        for (let i = 0; i < maxAreaLevel; i++) {
            helper.pushHeader("Takt Zone Level" + ` ${i}`);
        }

        helper.pushHeader("Raised by");
        //helper.pushHeader("Raised Date");
        helper.pushHeader("Responsible");
        helper.pushHeader("Action by");
        helper.pushHeader("Type");
        helper.pushHeader("Status");
        helper.pushHeader("Deadline");

        todoItems.forEach((item) => {
            let status: string = item.status;
            if (item.status == ItemState.OPEN && isToDoOverdue(item, today)) {
                status = "Overdue";
            }
            if (item.status == ItemState.OPEN && !isToDoOverdue(item, today)) {
                status = "Open";
            }
            if (item.status == ItemState.DONE) {
                status = "Done";
            }

            let priority: string = item.priority.toString();
            if (item.priority == ItemPriority.LOW) {
                priority = "low";
            }
            if (item.priority == ItemPriority.MEDIUM) {
                priority = "medium";
            }
            if (item.priority == ItemPriority.HIGH || item.priority == ItemPriority.HIGHER) {
                priority = "high";
            }

            let type: string = item.type;
            if (item.type === ItemType.ACTION_ITEM) {
                type = "Action item";
            }
            if (item.type === ItemType.STABILITY_CRITERIA) {
                type = "Stability criteria";
            }

            const areaPathTemp = item?.process?.areaPath;
            const areaPath = [];
            if (!maxAreaLevel) {
                return;
            }
            for (let i = 0; i < maxAreaLevel - areaPathTemp.length; i++) {
                areaPath.push("");
            }

            let row = [
                `${item.id}`,
                priority,
                item.issue,
                item.action,
                item.trade.map((t) => t.name).join(","),
                item.process.name,
                "",
                "",
                // item.raisedBy.map(userId => createUserName(allUsersMap[userId])).join(","),
                // //new Date(0),
                // item.responsible.map(userId => createUserName(allUsersMap[userId])).join(","),
                item.actionBy,
                type,
                status,
                new Date(item.deadline),
            ];

            row = row.slice(0, 6).concat(areaPathTemp.map((a) => a.name).concat(areaPath), row.slice(6));
            helper.pushRow(row);
        });

        try {
            if (!this.auth_token) {
                console.error("Authtoken is missing");
                return;
            }
            const response = await helper.fetchXSLX(this.auth_token);
            return response;
        } catch (e) {}
    }

    public async getProjectCollaborators(projectId: string): Promise<Persona[]> {
        return new Promise((resolve, reject) => {
            if (this.auth_token && projectId) {
                ApiHelper.getProject(this.auth_token, projectId, (error, result) => {
                    if (error) {
                        reject(error as FrameworkError);
                        return;
                    }

                    const subs = Object.getOwnPropertyNames(result.project.shared);
                    SubCache.getPersonas(this.auth_token, subs, (personas: Persona[]) => {
                        resolve(personas);
                    });
                });
            }
        });
    }

    public async getUserMap(projectId: string): Promise<UserMap> {
        const projectCollaborators = await this.getProjectCollaborators(projectId);

        const tmpUsers: UserMap = {};
        projectCollaborators.forEach((d) => {
            tmpUsers[d.data.sub] = {
                email: d.personaName,
                meta: {
                    firstName: d.data?.meta?.firstName,
                    lastName: d.data?.meta?.lastName,
                },
            };
        });

        return tmpUsers;
    }

    public ensureTrade(t: DocumentTransaction, trGt: TradesGetter) {
        const trId = t.createTrade(trGt);
        if (trId && t.isNewTradeId(trId)) {
            const color = trGt.value<number | null>("color", null);
            if ("number" === typeof color) {
                t.setTradeProp(trId, "color", color);
            }
        } else {
            // trade exists
        }
        return trId;
    }

    getUIExtensions() {
        if (this._model) {
            const ret = this.getProcessDetailsExt();
            return {
                processExt: ret.process,
                cardExt: ret.task,
            };
        } else {
            return null;
        }
    }

    private _baselineCoreCache: Core | undefined = undefined;
    public getBaseline(): Core {
        let ret: Core = null;
        const m = this._model;
        if (m?.baseline) {
            if (this._canvas) {
                ret = _ensureCoreFor(m?.baseline);
            } else {
                if (!this._baselineCoreCache) {
                    this._baselineCoreCache = Core.newInstance({ model: m.baseline, auth_token: this._auth_token });
                }
                ret = this._baselineCoreCache;
            }
        }
        return ret;
    }

    public patchLegacyFluxState(name: string, value: any) {
        const m = this._model;
        if (m) {
            switch (name) {
                case "view.meta":
                    m.setCanvasViewMeta(value.model, value.view);
                    break;
                case "fs":
                    if (this._canvas && m.legacyFLux?.fs !== value) {
                        (this._canvas as any)._force = true;
                    }
                // no break!!!
                default:
                    Object.assign(m.legacyFLux, { [name]: value });
                    break;
            }
        }
    }

    //EOC
}

function setProcessImpl(
    pid: number,
    dst: IDocumentTransaction,
    state: LCMDContextProcessDetailsState,
    pGt: ProcessGetter | null,
): number {
    if ("number" === typeof pid && pid >= 0) {
        if (pGt && "number" === typeof state.ppid?.value) {
            dst.setProcessProp(pid, "p", state.ppid.value, {
                i: state.ppid.index,
            });
        }
        if ("number" === typeof state.start?.value) {
            dst.setProcessProp(pid, "start", state.start.value);
        }
        if ("number" === typeof state.duration?.value) {
            if (state.duration.value > 0 && pGt?.isMilestone() && pGt?.value<number>("milestone-state") >= 0) {
                dst.setProcessProp(pid, "milestone-state", null);
            }
            dst.setProcessProp(pid, "days", state.duration.value, {
                unit: state.duration.unit,
            });
        }
        if ("number" === typeof state.yindex?.value) {
            dst.setProcessProp(pid, "stripey", state.yindex.value);
        }
        if ("string" === typeof state.name?.value) {
            dst.setProcessProp(pid, "name", state.name.value);
        }
        if ("number" === typeof state.workforce?.value) {
            dst.setProcessProp(pid, "wf", state.workforce.value);
        }
        if (Array.isArray(state.trades?.value)) {
            let _trades = [];
            if (pGt && pGt.id >= 0) {
                const trGt = new TradesGetter(pGt.core);
                const _Gt = new ParticleGetter(pGt.core);
                _trades = Object.getOwnPropertyNames(pGt.aux<{ [_resId: string]: number }>("_rw") || {}).reduce(
                    (ret, _resId) => {
                        if (
                            trGt.reset(Number.parseInt(_resId)).id >= 0 &&
                            _Gt.reset(trGt.aux<number>("__", -1))._ >= 0
                        ) {
                            ret.push(trGt.id);
                        }
                        return ret;
                    },
                    [],
                );
            }
            for (let i_trade = 0; i_trade < state.trades.value.length; i_trade++) {
                const _v = state.trades.value[i_trade];
                let completed = 0;
                let resId;
                if ("number" === typeof _v) {
                    resId = _v;
                } else {
                    resId = _v.value;
                    completed = _v.completed || 0;
                }
                const v = pGt ? pGt.value<number>(ComplexProp.TRADEREF(resId), -1) : -1;
                if (v >= 0 && v === completed) {
                    // already there...
                } else {
                    dst.setProcessProp(pid, ComplexProp.TRADEREF(resId), completed);
                }
                const _i = _trades.findIndex((trId) => trId === resId);
                if (_i >= 0) {
                    _trades.splice(_i, 1); // do not delete
                }
            }
            _trades.forEach((trId) => {
                dst.setProcessProp(pid, ComplexProp.TRADEREF(trId), -1);
            });
        }
        if ("number" === typeof state.cards?.s) {
            // @TODO fix me!!! This is a very expensive opration, we need to set thate state at the process and use in for all cards...
            if (pGt?.isMilestone()) {
                dst.setProcessProp(pid, "milestone-state", state.cards?.s);
            } else {
                const a = pGt ? pGt.core.gatherActivitiesForTask(pGt) : null;
                if (a && Array.isArray(a?.cards)) {
                    for (let i_card = 0; i_card < a.cards.length; i_card++) {
                        const card = a.cards[i_card];
                        if (card && card.s !== state.cards.s) {
                            dst.setProcessProp(pid, ComplexProp.CARDPROP(card.aid, card.i, "s"), state.cards.s);
                        }
                    }
                }
            }
        }
        if ("number" === typeof state.teams?.value) {
            dst.setProcessProp(pid, "teams", state.teams.value);
        }
        if ("number" === typeof state.train?.value || "string" === typeof state.train?.value) {
            dst.setProcessProp(pid, "train", state.train.value);
        }
        if (state.trains) {
            const trains = Object.getOwnPropertyNames(state.trains);
            for (let i_train = 0; i_train < trains.length; i_train++) {
                const train = trains[i_train];
                const values = state.trains[train];
                if ("number" === typeof values?.takt?.value) {
                    dst.setProcessProp(pid, ComplexProp.TRAINPROP(train, "takt"), values.takt.value);
                }
            }
        }
        if ("string" === typeof state.image?.value?.blobId) {
            dst.setProcessProp(pid, "image", state.image.value);
        }
        return pid;
    } else {
        return Number.MIN_SAFE_INTEGER;
    }
}

function setDependencyImpl(
    dst: IDocumentTransaction,
    srcPid: number,
    dstPid: number,
    state: LCMDContextDependencyDetailsState | null,
) {
    if (state) {
        dst.setProcessProp(srcPid, ComplexProp.CREATEREL(dstPid), state.lag || 0, {
            unit: state.unit || 3,
            type: state.type,
        });
    } else {
        dst.setProcessProp(srcPid, ComplexProp.CREATEREL(dstPid), 0, {
            unit: 3,
            type: 0 /* del */,
        });
    }
}

function setApiCardDetailsImpl(
    core: Core,
    ops: IDocumentTransaction,
    id: string,
    state: LCMDContextCardDetailsState | null,
    ctx?: { force?: boolean },
) {
    if (state) {
        core.updateSingleCard(
            ops,
            {
                id,
                n: state?.name,
                s: state?.s,
                wf: state?.wf,
                y: state?.y,
                m: state?.comment,
            },
            ctx || {},
        );
    } else if ("string" === typeof id) {
        core.removeSingleCard(ops, { id }, ctx);
    }
}

function setOrCreateApiTodoItemForProcessImpl(
    this: void,
    core: Core,
    dst: IDocumentTransaction,
    target: LCMDContextTodoItemTarget[],
    state: { value: number | string | boolean | null } | null,
    meta: { [name: string]: { value: number | string | boolean } & any } | null,
) {
    const t: IDocumentTransaction = target.reduce((t: IDocumentTransaction, target: LCMDContextTodoItemTarget) => {
        if ("number" === typeof target?.pid && "number" === typeof target?.id) {
            core._setApiActionItemForProcess(t, target.pid, target.id, state, meta);
        } else if ("number" === typeof target?.pid && "string" === typeof target?.stab) {
            core._setApiStabilityItemForProcess(t, target.pid, target.stab, state, meta);
        } else if ("number" === typeof target?.pid && ("action" === target.id || "stability" === target.id)) {
            const commentId = core._createApiActionItem(t, {
                pid: target.pid,
                type: target.id,
            });
            assert(commentId, "CommentId for Action Item is missing.");
            core._setApiActionItemForProcess(t, target.pid, commentId, state, meta);
        }
        return t;
    }, dst);
    return t;
}

function setOrCreateReasonCodeImpl(
    core: Core,
    docTransaction: DocumentTransaction,
    commentId: number | null,
    pid: number,
    reasonCodeId: string,
    opId: number,
) {
    assert(
        Number.isInteger(pid) && typeof reasonCodeId === "string" && reasonCodeId.length > 0 && Number.isInteger(opId),
        "ReasonId or opId is missing. Both are required",
    );
    if (!commentId) {
        // @todo: wo soll createdAt hin ?!
        commentId = docTransaction.createComment();
        // docTransaction.setProcessProp(pid, ComplexProp.REASON_CODE(commentId), null);
        docTransaction.setProcessProp(pid, ComplexProp.REASON_CODE(commentId), 1, { createdAt: new Date().getTime() });
    }
    docTransaction.setProcessProp(pid, ComplexProp.REASON_CODE(commentId, "rcid"), reasonCodeId);
    docTransaction.setProcessProp(pid, ComplexProp.REASON_CODE(commentId, "opid"), opId);
}

function deleteAttachedReasonCode(core: Core, docTransaction: DocumentTransaction, commentId: number, pid: number) {
    assert(Number.isInteger(pid) && Number.isInteger(commentId));
    docTransaction.setProcessProp(pid, ComplexProp.REASON_CODE(commentId), null);
}

//@nikhil checklist_api
function attachChecklistImpl(
    core: Core,
    docTransaction: DocumentTransaction,
    pid: number,
    checklistTemplateId: string,
) {
    let commentId = docTransaction.createComment();
    const checklists = new ChecklistService(this);
    const checklistData: Checklist = checklists.getChecklistTemplate(checklistTemplateId);
    const attachChecklist: AttachedChecklist = { ...checklistData, templateId: checklistData.id, pid, id: commentId };

    // docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(commentId), null);
    // #CL#1
    docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(commentId), 1, {
        createdAt: new Date().getTime(),
    });
    docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(commentId, "checklist"), JSON.stringify(attachChecklist));

    //this is encoded data (checkpoint status) #CL (means related to checklist) #data (status of checkpoints) followed by hex value which is the status
    const hex_data = encodeDataToHex(new Array(attachChecklist.checkpoints.length).fill(0));
    docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(commentId, "data"), hex_data);
}

function updateAttachedChecklistImpl(
    core: Core,
    docTransaction: DocumentTransaction,
    attachCheckListId: number,
    states: number[],
    pid: number,
) {
    // get a processGetter
    // read the checklist bz its id using the processGetter
    // encode the old state
    // endoce the new state
    // compare
    // decide if to store ot not
    //   let processGetter = new ProcessGetter(core, pid);

    // ComplexProp.CHECK_LIST(1);
    // if (condition) {

    // }
    const hex_data = encodeDataToHex(states);
    docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(attachCheckListId, "data"), hex_data);
}

function encodeDataToHex(array: number[]) {
    const enc_data = encodeStates(array);
    return uint8ArrayToHex(enc_data);
}

function encodeStates(states: number[]): Uint8Array {
    // Combine states into a single binary string
    let binaryString = states.map((state) => state.toString(2).padStart(2, "0")).join("");

    // Pad binaryString to ensure its length is a multiple of 8
    while (binaryString.length % 8 !== 0) {
        binaryString += "0";
    }

    // Split binary string into 8-bit chunks and convert to bytes
    let byteArray: number[] = [];
    for (let i = 0; i < binaryString.length; i += 8) {
        let byte = binaryString.slice(i, i + 8);
        byteArray.push(parseInt(byte, 2));
    }

    return new Uint8Array(byteArray);
}

function uint8ArrayToHex(uint8Array: Uint8Array): string {
    return Array.from(uint8Array)
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
}

// Convert hex string to Uint8Array
function hexToUint8Array(hexString: string): Uint8Array {
    const len = hexString.length;
    const bytes = new Uint8Array(len / 2);
    for (let i = 0; i < len; i += 2) {
        bytes[i / 2] = parseInt(hexString.slice(i, i + 2), 16);
    }
    return bytes;
}

// Decode Uint8Array to the original array of states
function decodeStates(uint8Array: Uint8Array, originalLength: number): number[] {
    let binaryString = "";
    for (let byte of uint8Array) {
        binaryString += byte.toString(2).padStart(8, "0");
    }

    let states: number[] = [];
    for (let i = 0; i < originalLength * 2; i += 2) {
        states.push(parseInt(binaryString.slice(i, i + 2), 2));
    }
    return states;
}

// Decode hex string to the original array
function decodeHexToData(hexString: string, originalLength: number): number[] {
    const uint8Array = hexToUint8Array(hexString);
    return decodeStates(uint8Array, originalLength);
}

function deleteAttachedChecklistImpl(core: Core, docTransaction: DocumentTransaction, commentId: number, pid: number) {
    assert(Number.isInteger(pid) && Number.isInteger(commentId));
    docTransaction.setProcessProp(pid, ComplexProp.CHECK_LIST(commentId), null);
}

export function filterToStartEndDate(d: [number, number], convertToUTC?: boolean) {
    const { _x1, _x2 } = Core.filterToX1X2(d);
    assert(
        !_x1 ||
            !_x2 ||
            (EpochDaystoEpochMS(EpochMStoEpochDays(_x1)) === _x1 &&
                EpochDaystoEpochMS(EpochMStoEpochDays(_x2)) === _x2),
    );
    const ret = {
        start: _x1 ? (convertToUTC ? new Date(_x1) : HelperEpochMSToLocalDate(_x1)) : null,
        end: _x2
            ? convertToUTC
                ? new Date(_x2 - END_OF_DAY_MS)
                : new Date(HelperEpochMSToLocalDate(_x2).getTime() - END_OF_DAY_MS)
            : null,
    };
    return ret;
}

export function _getStatusForCards(cards: any, today: any, pGt: ProcessGetter): { p: number; s: number; atm: boolean } {
    if (pGt.hasMilestoneState()) {
        const milestoneState = pGt.getMilestoneState();
        if (milestoneState === LCMDContextCardStatus.DONE) {
            return { p: 100, s: 1, atm: false };
        }

        const _x2 = EpochMStoEpochDays(pGt.aux<number>("_x2"));
        return { p: 0, s: _x2 <= today ? 4 : 0, atm: false };
    }

    const { doneCards, lateCards, atm } = cards.reduce(
        (
            ret: {
                doneCards: number;
                lateCards: number;
                atm: boolean;
            },
            card,
        ) => {
            if (/*DailyBoardAPICardStatus.DONE*/ 2 === card.s) {
                ret.doneCards++;
            }
            if (/*DailyBoardAPICardStatus.DONE*/ 2 !== card.s && card.date < today) {
                ret.lateCards++;
            }
            if (ret.atm === false && card.atm) {
                ret.atm = true;
            }
            return ret;
        },
        {
            doneCards: 0,
            lateCards: 0,
            atm: false,
        },
    );
    //const _x1=EpochMStoEpochDays(pGt.aux<number>("_x1"));
    const _x2 = EpochMStoEpochDays(pGt.aux<number>("_x2"));
    let s = -1;
    if (doneCards === cards.length) {
        // done
        s = 1; // DONE
    } else {
        if (lateCards > 0) {
            if (_x2 <= today) {
                s = 4; // OVERDUE
            } else {
                s = 3; // LATE
            }
        } else {
            if (doneCards > 0) {
                // in progress
                s = 2; // IN_PROGRESS
            } else {
                if (_x2 <= today) {
                    s = 4; // OVERDUE
                } else {
                    s = 0; // OPEN
                }
            }
        }
    }
    assert(-1 !== s);
    const p = cards.length > 0 ? Math.round((doneCards / cards.length) * 100) : 0;
    return { p, s, atm };
}

export function calculateTargetProgressForProcess(
    process: ReturnType<Core["getProcessDetailsVer1"]>,
    today: EpochDate,
): { targetProgress: number; done: number; total: number } {
    if (!process) {
        throw new Error("Process is needed to Calculate target Progress");
    }

    if (EpochMStoEpochDays(process.start) > today) {
        return { targetProgress: 0, done: 0, total: process._cards.length };
    }

    return calculateTargetProgressForCards(process._cards, today);
}

/**
 *
 * @param cards
 * @param today
 */
export function calculateTargetProgressForCards(
    cards: Array<{ date: EpochDate } & any>,
    today: EpochDate,
): ReturnType<typeof calculateTargetProgressForProcess> {
    if (!Array.isArray(cards)) {
        throw new Error("Cards Missing for Calculation of Target");
    }
    const totalCards = cards.length;
    let shouldBeDoneCards = 0;
    if (totalCards === 0) {
        return { targetProgress: 0, done: shouldBeDoneCards, total: totalCards };
    }

    cards.forEach((card) => {
        if (card.date < today) {
            shouldBeDoneCards++;
        }
    });

    return {
        targetProgress: calculateProgressInPercent(shouldBeDoneCards, totalCards),
        done: shouldBeDoneCards,
        total: totalCards,
    };
}

export function calculateProgressInPercent(done: number, total: number): number {
    return done && total ? Math.min(Math.round((done * 100) / total), 100) : 0;
}

export class ProjectBuilder extends ParticleProjectBuilder<
    LCMDContextUnitType,
    LCMDContextDependencyType,
    LCMDContextProcessDetailsState,
    LCMDContextCardDetailsState,
    LCMDContextDependencyDetailsState,
    LCMDUploadToken,
    LCMDCOntextAddPredecessorState
> {
    protected onSetProcessDetails(id: number, state: LCMDContextProcessDetailsState) {
        const ret = setProcessImpl(id, this, state, null);
        return ret;
    }

    protected onSetDependenciesDetails(
        dst: IDocumentTransaction,
        srcPid: number,
        dstPid: number,
        state: LCMDContextDependencyDetailsState,
    ) {
        const ret = setDependencyImpl(dst, srcPid, dstPid, state);
        return ret;
    }

    protected onSetTaskDetailsForProcess(
        dst: IDocumentTransaction,
        id: number,
        day: number,
        state: LCMDContextCardDetailsState,
    ) {
        const ret = setApiCardDetailsImpl(
            this._core,
            dst,
            ["C", id.toString(16), (0).toString(16), day.toString(16)].join("_"),
            state,
            { force: true },
        );
        return ret;
    }

    public setOrCreateTodoItemForProcess(
        target: LCMDContextTodoItemTarget[],
        state: { value: number | string | boolean | null } | null,
        meta: { [name: string]: { value: number | string | boolean } & any } | null,
    ) {
        setOrCreateApiTodoItemForProcessImpl(this._core, this, target, state, meta);
        this._forceGroupNext();
    }
}

export type OnCoreUpdateEvent = OnParticleCoreUpdateEvent;
export type OnWorkerMsgEvent = OnParticleWorkerMsgEvent;
export { ProcessGetter } from "../model/api/processGetter";
export { TradesGetter } from "../model/api/tradesGetter";
export { HiveGetter } from "../model/api/hiveGetter";
export { ParticleGetter } from "../model/api/particleGetter";
export { ComplexProp } from "../model/api/complexProp";
export { ComplexPropIter } from "../model/api/complexPropIter";
export { ComplexDocumentTransaction } from "../model/api/documentTransaction";
export { DocumentTransaction } from "../model/api/documentTransaction";
export type { IDocumentTransaction } from "../model/api/documentTransaction";
export type { InitCoreOptions } from "../model/api/coreTypes";
export type { IWorkerCanvas } from "../model/api/coreTypes";
export type { ParticleCoreFactory } from "../model/api/coreTypes";
export type { ParticleCoreUserMeta } from "../model/api/coreTypes";
export type { ParticleCoreMeta } from "../model/api/coreTypes";
export type { CanvasDataViewReducer } from "../model/api/coreTypes";
export type { CanvasDataViewReducerCtx } from "../model/api/coreTypes";
export { ParticleProjectBuilder } from "../model/api/particleProjectBuilder";
