import { Telemetry } from "../../utils/Telemetry";
import { BUNDLE_HASH, WORKER_HASH } from "../../model/services";

const WebAppTelemetryFactory: Telemetry = new Telemetry(
    "InstrumentationKey=864fcdf4-e4cb-454e-a171-f42483e17287;IngestionEndpoint=https://germanywestcentral-1.in.applicationinsights.azure.com/;LiveEndpoint=https://germanywestcentral.livediagnostics.monitor.azure.com/",
    BUNDLE_HASH,
    WORKER_HASH,
);

export { WebAppTelemetryFactory };
