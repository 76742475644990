import {
    CanvasTaskData,
    CanvasStripeData,
    needsWhiteColor,
    CanvasHostRenderer,
    CanvasHostRendererCtx,
    CanvasHostRendererDropInfo,
    CanvasHostStripeRenderNode,
    ICanvasHostRenderer,
    CanvasHostBase,
    CanvasHostBasePointerEvent,
    CanvasHostBaseProps,
    CanvasHostRendererDragInfo,
    CanvasHostRenderNode,
} from "lcmd2framework";

import { LCMDContextDependencyType, LCMDContextLibraryItemTarget } from "../app/LCMDContextTypes";

const DETAILS_FONT = "400 6pt Roboto";

export class CanvasWhiteboardHost extends CanvasHostBase implements ICanvasHostRenderer {
    constructor(props: CanvasHostBaseProps) {
        super(props, {
            zOrderStripes: true,
            xGroupTasks: false,
            eventHitTest: false,
        });
        this._renderer.setRenderer(this);
    }

    public renderOverlay(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHell(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderHeaven(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D, isVisible: boolean) {}

    public onAddDependency(srcPid: number, tgtPid: number, dependencyType: LCMDContextDependencyType) {
        // src and dst are wrong way around!!!!!
        const props: { src: number; dst: number; type: number; lag: [number, number]; whiteboard?: boolean } = {
            dst: srcPid,
            src: tgtPid,
            lag: [0, 3],
            type: dependencyType,
            whiteboard: true,
        };

        this.props.worker.postMessage(["dependency", "add", props]);
    }

    public renderEnd(rc: CanvasHostRendererCtx, ctx: CanvasRenderingContext2D) {}

    public renderStripeHell(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
        i_stripe: number,
    ) {}

    public renderStripeBegin(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {
        const t = rc.C.gpa.header.height;
        const f = rc.C.fonts[rc.C.gpa.header.font];
        const p = rc.C.gpa.padding;
        ctx.fillStyle = rc.C.gpa.backgroundColor;
        ctx.fillRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        if (1 === rc.C.grid) {
            ctx.strokeStyle = rc.C.gpa.grid.small.style;
            ctx.lineWidth = rc.C.gpa.grid.small.width;
            ctx.beginPath();
            for (let x = 0, i = 0; x < stripe.__w; x += rc.C.colPx, i++) {
                if (x > 0 && 0 !== i % 5) {
                    ctx.moveTo(stripe.__x + x, stripe.__y + t);
                    ctx.lineTo(stripe.__x + x, stripe.__y + stripe.__h);
                }
            }
            ctx.stroke();
        }
        if (1 === rc.C.grid) {
            ctx.strokeStyle = rc.C.gpa.grid.strong.style;
            ctx.lineWidth = rc.C.gpa.grid.strong.width;
            ctx.beginPath();
            for (let x = 0; x < stripe.__w; x += 5 * rc.C.colPx) {
                if (x > 0) {
                    ctx.moveTo(stripe.__x + x, stripe.__y + t);
                    ctx.lineTo(stripe.__x + x, stripe.__y + stripe.__h);
                }
            }
            ctx.stroke();
        }
        if (-1 === stripe.__z || rc.taskICount > 0) {
            ctx.strokeStyle = rc.C.gpa.border.style;
            ctx.lineWidth = rc.C.gpa.border.width;
        } else {
            ctx.strokeStyle = rc.C.gpa.selected.primary.style;
            ctx.lineWidth = rc.C.gpa.selected.primary.width;
        }
        ctx.strokeRect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        ctx.beginPath();
        ctx.moveTo(stripe.__x, stripe.__y + t);
        ctx.lineTo(stripe.__x + stripe.__w, stripe.__y + t);
        ctx.stroke();
        ctx.save();
        ctx.beginPath();
        ctx.rect(stripe.__x, stripe.__y, stripe.__w, stripe.__h);
        ctx.clip();
        ctx.fillStyle = rc.C.gpa.header.textColor;
        CanvasHostRenderer.renderSegments(
            ctx,
            Array.isArray(stripe._segs) ? stripe._segs[1] : null,
            stripe.__x + p,
            stripe.__y + p,
            stripe.__w - p - p,
            t - p - p,
            1,
            false,
            false,
            0,
            1,
            false,
        );
    }

    public renderStripeEnd(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        stripe: CanvasStripeData & CanvasHostStripeRenderNode,
    ) {
        ctx.restore();
    }

    public renderTask(
        rc: CanvasHostRendererCtx,
        ctx: CanvasRenderingContext2D,
        task: CanvasTaskData & CanvasHostStripeRenderNode,
    ) {
        const dx = -1 === task.__z ? 0 : rc.selStripeDx;
        const dy = -1 === task.__z ? 0 : rc.selStripeDy;
        ctx.fillStyle = "#" + task.color.toString(16).padStart(6, "0");
        if (-1 === task.__z) {
            ctx.strokeStyle = rc.C.gpa.task.border.style;
            ctx.lineWidth = rc.C.gpa.task.border.width;
        } else {
            ctx.strokeStyle = rc.C.gpa.selected.primary.style;
            ctx.lineWidth = rc.C.gpa.selected.primary.width;
        }
        const isMilestone = task.left === task.right;
        if (isMilestone) {
            ctx.beginPath();
            ctx.moveTo(task.__x + dx, task.__y + task.__h / 2 + dy);
            ctx.lineTo(task.__x + task.__w / 2 + dx, task.__y + dy);
            ctx.lineTo(task.__x + task.__w + dx, task.__y + task.__h / 2 + dy);
            ctx.lineTo(task.__x + task.__w / 2 + dx, task.__y + task.__h + dy);
            ctx.lineTo(task.__x + dx, task.__y + task.__h / 2 + dy);
            ctx.fill();
            ctx.stroke();
        } else {
            ctx.fillRect(task.__x + dx, task.__y + dy, task.__w, task.__h);
            ctx.strokeRect(task.__x + dx, task.__y + dy, task.__w, task.__h);
        }

        // render text
        const p = rc.C.gpa.task.label.padding;
        const x = task.__x + p + dx;
        const y = task.__y + p + dy;
        const w = task.__w - p - p;
        const h = task.__h - p - p;
        const _w = needsWhiteColor(task.color);
        ctx.fillStyle = _w ? rc.C.gpa.task.label.white : rc.C.gpa.task.label.black;
        CanvasHostRenderer.renderSegments(
            ctx,
            task.segs,
            x,
            y,
            w,
            h,
            1,
            false,
            false,
            isMilestone ? 1 : 1,
            isMilestone ? 1 : 1,
            isMilestone && (_w ? rc.C.gpa.task.label.black : rc.C.gpa.task.label.white),
        );

        if (!isMilestone && task.v && task.l) {
            const p = 2;
            ctx.save();
            ctx.font = DETAILS_FONT;
            const m = ctx.measureText(task.l);
            ctx.fillText(task.l, x + w - m.width - p, y + h - m.actualBoundingBoxDescent - p);
            ctx.restore();
        }

        if (this._renderer.hit.hitTask?.id === task.id) {
            ctx.fillStyle = "rgba(0, 0, 0, 0.33)";

            if (this._renderer.hit.hitTaskLeft === true) {
                // is left
                ctx.fillRect(task.__x, task.__y, task.__w / 4, task.__h);
                this.drawPlusSing(ctx, task.__x + task.__w * 0.125, task.__y + task.__h / 2);
            } else if (this._renderer.hit.hitTaskLeft === false) {
                // is right
                ctx.fillRect(task.__x + task.__w * 0.75, task.__y, task.__w / 4, task.__h);
                this.drawPlusSing(ctx, task.__x + task.__w * 0.875, task.__y + task.__h / 2);
            }
        }
    }

    public selChanged() {}

    protected override onDragEnd(rc: CanvasHostRendererCtx, dragInfo: CanvasHostRendererDragInfo | null) {
        let sel = this._renderer.getSel();
        const sx = dragInfo?.sx || 1;
        const tx = dragInfo?.tx || 0;
        if (this.grid5 > 1 || 1 !== sx || 0 !== tx) {
            sel = sel.map((sel) => {
                if (sel.start > 0) {
                    const start = Math.max(
                        Math.round((sel.start + tx / rc.C.colPx - 1) / this.grid5) * this.grid5 + 1,
                        1,
                    );
                    let days = undefined;
                    if (1 !== sx) {
                        let end = start + Math.round((sel.end - sel.start) * sx);
                        end = Math.round((end - 1) / this.grid5) * this.grid5 + 1; // fix grid
                        days = end - start;
                    }
                    return {
                        ...sel,
                        start: start,
                        days: days,
                    };
                } else {
                    return sel;
                }
            });
        }
        this.props.worker.postMessage(["wb", "update", sel]);
    }

    protected onDropEnd(
        rc: CanvasHostRendererCtx,
        pointer: CanvasHostBasePointerEvent,
        dropInfo: CanvasHostRendererDropInfo | null,
        draggedRef: HTMLElement,
    ) {
        if (dropInfo.trade) {
            if (this.grid5 > 1 && dropInfo.x > 0) {
                dropInfo.x = Math.round((dropInfo.x - 1) / this.grid5) * this.grid5 + 1;
            }
            this.props.worker.postMessage([
                "wb",
                "drop",
                { ...dropInfo, days: Number.parseInt(draggedRef.dataset.dropDays, 10) },
            ]);
        }
    }

    protected override onClick(mouse: { x: number; y: number }) {}

    protected override onTextEdited(target: number, id: number, value: string) {
        if (CanvasHostBase.TASK_TARGET === target) {
            this.props.worker.postMessage([
                "wb",
                "rename",
                {
                    target: target,
                    id: id,
                    value: value,
                },
            ]);
        }
    }

    protected override onCopyCut(cmd: "copy" | "cut") {
        const sel = this._renderer.getSel();
        if (sel.length > 0) {
            const ids = sel.map((t) => t.id);
            this.props.worker.postMessage(["wb", cmd, ids]);
        }
    }

    protected override onPaste(ev: CanvasHostBasePointerEvent) {
        this._renderer.hitTest(ev);
        if (this._renderer.hit.hitStripe) {
            const pointerGridX = Math.max(0, Math.floor(this._renderer.hit.hitStripeLeft / this._renderer.C.colPx));
            const pointerGridY = Math.max(0, Math.floor(this._renderer.hit.hitStripeTop / this._renderer.C.rowPx));
            const x =
                this.grid5 > 1 && 0 < pointerGridX ? Math.round(pointerGridX / this.grid5) * this.grid5 : pointerGridX;
            this.props.worker.postMessage([
                "wb",
                "paste",
                {
                    x: x,
                    y: pointerGridY,
                    p: {
                        pid: this._renderer.hit.hitStripe.t,
                        i_pid: this._renderer.hit.hitStripe.i,
                    },
                },
            ]);
        }
    }

    protected override onDeleteKey() {
        const sel = this._renderer.getSel();
        if (sel.length > 0) {
            this.props.worker.postMessage([
                "lib",
                "add",
                sel.map(
                    (t) =>
                        ({
                            id: t.id,
                            p: -1,
                        }) as LCMDContextLibraryItemTarget,
                ),
            ]);
        }

        const depSelection = this._renderer.getDependencySel();
        if (depSelection[0] >= 1 && depSelection[1] >= 1) {
            this.props.worker.postMessage([
                "details",
                {
                    isWhiteboard: true,
                    dep: {
                        srcPid: depSelection[1], // targetTaskId,
                        dstPid: depSelection[0], // sourceTaskId,
                        state: null,
                    },
                },
            ]);
            return;
        }
    }

    public onHoverProcess(
        task: CanvasTaskData & CanvasHostRenderNode,
        points: { mouse: { x: number; y: number }; task: { x: number; y: number } },
    ) {}

    public onLeaveProcess() {}
}
