import { getAttachmentUrl, getLog, getProject, getSub, uploadArrayBuffer } from "../ApiHelper";
import {
    CalendarGrid,
    CanvasTaskData,
    DataModel,
    DataOperationTarget,
    MAX_TASK_DAYS,
    ParticleCardData,
} from "../DataModel";
import {
    assert,
    FrameworkError,
    FrameworkHttpError,
    generateId,
    HelperDateToUTCEpochMS,
    LCMDContextCardStatus,
    TableExportHelper,
    unsafeParseJWT,
} from "../GlobalHelper";
import { ProcessGetter } from "./processGetter";
import { ParticleGetter } from "./particleGetter";
import {
    CanvasDataViewReducer,
    InitCoreOptions,
    OnParticleCoreUpdateEvent,
    OnParticleWorkerMsgEvent,
    ParticleCoreFactory,
} from "./coreTypes";

export { FrameworkError, FrameworkHttpError, assert, LCMDContextCardStatus, generateId, HelperDateToUTCEpochMS };
export {
    stringToHex,
    topSort,
    gfu2,
    HelperEpochMSToLocalDate,
    EpochDaystoEpochMS,
    EpochMStoEpochDays,
    HelperDateTo24TimeStr,
    DateToMonday,
    unsafeParseJWT,
    UNIT_TYPES,
    REL_TYPES,
    TableExportHelper,
    errorToJSON,
    EpochMSToLocaleDateStr,
    HelperDaysUnitToDurationStr,
} from "../GlobalHelper";
export { weekNumber } from "weeknumber";
export { END_OF_DAY_MS, FrameworkErrorDataModelNeedsSync } from "../DataModel";
export type { CanvasCardData, CanvasTaskData, CanvasCalendarIntl } from "../DataModel";
export * from "../DataModelTypes";
export { SERVICES } from "../services";
export type { CanvasMeta } from "../../legacy/components/Canvas/Canvas";
export { createProject, uploadAttachment } from "../ApiHelper";

/**
 * @todo Documentation
 * @public
 */
export abstract class ParticleCore {
    protected readonly _auth_token: string | null = null;
    public readonly _model: DataModel | null = null;

    /**
     *
     * @public
     */
    public constructor(opt: InitCoreOptions) {
        this._model = opt.model;
        this._auth_token = opt.auth_token;
        assert(undefined === this._model.callbacks._coreUpdateActivities); // already set? why?
        this._model.callbacks._coreUpdateActivities = this._coreUpdateActivities.bind(this);
        this._model.callbacks._coreGetTrades = this._coreGetTrades.bind(this);
        this._model.callbacks._dataViewReducer = ParticleCore.dataViewReducer;
        this._model.callbacks._PPPUnchanged = this.onPPProcessUnchanged.bind(this);
        this._model.callbacks._PPPChanged = this.onPPProcessChanged.bind(this);
        this._model.callbacks._PPPUpdateCtx = this.onPPProcessUpdateCtx.bind(this);
        this._model.callbacks._WBPUnchanged = this.onWBProcessUnchanged.bind(this);
        this._model.callbacks._WBPChanged = this.onWBProcessChanged.bind(this);
        this._model.callbacks._WBPUpdateCtx = this.onWBProcessUpdateCtx.bind(this);
        this._model.callbacks._coreResolveExtValue = this._resolveExtValue.bind(this);
        this._model.callbacks._onWBSync = this.onWBSync.bind(this);
        this._model.callbacks.onUpdateCanvas = this._onUpdateCanvas.bind(this);
        this._model.callbacks.onInitCanvas = this._onInitCanvas.bind(this);
        this._model.callbacks.onMetaCanvas = this._onMetaCanvas.bind(this);
    }

    /** @internal */
    private _onUpdateCanvas(model: DataModel, userCtx: any, taskIds?: string[]): any {
        assert(model === this._model);
        if (taskIds) {
            return this.onPPCanvasWillUpdate({
                core: this,
                taskIds,
            });
        } else {
            this.onPPCanvasDidUpdate({ core: this, userCtx });
            return undefined;
        }
    }
    protected abstract onPPCanvasInit(evt: { core: ParticleCore }): any;
    /** @internal */
    private _onInitCanvas(model: DataModel): any {
        assert(model === this._model);
        this.onPPCanvasInit({ core: this });
    }
    protected abstract onPPCanvasUserMeta(evt: { core: ParticleCore }): any;
    /** @internal */
    private _onMetaCanvas(model: DataModel): any {
        assert(model === this._model);
        this.onPPCanvasUserMeta({ core: this });
    }
    protected abstract onPPCanvasWillUpdate(evt: { core: ParticleCore; taskIds: string[] }): any;
    protected abstract onPPCanvasDidUpdate(evt: { core: ParticleCore; userCtx?: any });

    protected abstract _resolveExtValue(ext: number | number[], _GtHelper: ParticleGetter): any;

    //static create=function<C, T extends ParticleCore<C>>(dbclass: NoParamConstructor<T>) { Const db = new dbclass(); await db.connect(); return db; }
    public static factory: ParticleCoreFactory = null;
    public static dataViewReducer: CanvasDataViewReducer<any, any> = null;

    protected static _getProject(auth_token: string, pid: string) {
        return new Promise(async (resolve: (data: any) => void, reject: (error: Error) => void) => {
            getProject(auth_token, pid, (error, project_result) => {
                if (error) {
                    resolve(null);
                } else {
                    const log = project_result?.project?.log || [];
                    if (log.length > 0) {
                        getLog(project_result.project_token, pid, log[log.length - 1], (log_error, log_result) => {
                            if (log_error) {
                                resolve(null);
                            } else {
                                let master;
                                try {
                                    master = unsafeParseJWT(log_result?.master);
                                } catch (e) {
                                    master = null;
                                }
                                const ret = {
                                    details: log_result.commit,
                                    pid: log_result.pid,
                                    sid: master?.sid || null,
                                    role: project_result.role,
                                };
                                resolve(ret);
                            }
                        });
                    } else {
                        resolve(null);
                    }
                }
            });
        });
    }

    protected static async _loadModel(
        auth: { sid: string; token: string; ofs: number; master: string },
        readonly: boolean,
        options?: {
            readonly?: boolean;
            maxCommit?: number;
        },
    ) {
        const model = await DataModel.loadStream(auth.sid, auth.token, 0, auth.ofs, undefined, options);
        assert(model.storageName === auth.master);
        model.setStorageName(auth.sid);
        if (false === readonly) {
            model.viewConst = { ...model.viewConst, readonly: false };
        }
        model.updateModel();
        const syncRet = model.getSync(0, true);
        if (0 === syncRet.sync.ops.length) {
            const ret = await DataModel.pushStreamOps(auth.sid, syncRet.sync);
            if (options?.maxCommit > 0) {
                const _commit = Math.min(ret.ofs + ret.ops.length, options.maxCommit);
                if (_commit < ret.ofs) {
                    return null; // in theory we need to "uncommit"....
                } else {
                    const maxOps = _commit - ret.ofs;
                    if (maxOps < ret.ops.length) {
                        ret.ops = ret.ops.slice(0, maxOps);
                    }
                }
            }
            const commitRet = model.commitSync(ret, syncRet.token);
            if (commitRet >= 0) {
                model.updateModel();
                if (options?.maxCommit > 0) {
                    if (model._snap().commited !== options.maxCommit) {
                        return null; // error;
                    }
                }
                return model;
            } else {
                return null; // commitSync error
            }
        } else {
            return null;
        }
    }

    public async exportLogAsXLSX(opt?: { startOfs?: number; json?: boolean }) {
        return new Promise((resolve: (buffer: ArrayBuffer) => void, reject: (e: FrameworkError) => void) => {
            if (this._model) {
                this._model.exportLog({
                    ...opt,
                    resolveSubs: (writer, sub_index, name_index, done: () => TableExportHelper) => {
                        const needed_subs = writer._data.reduce((ret, line, i_line) => {
                            if (i_line > 0 && line[sub_index]) {
                                ret[line[sub_index]] = sub_index;
                            }
                            return ret;
                        }, {});
                        Promise.all(Object.getOwnPropertyNames(needed_subs).map((sub) => this.fetchSub(sub)))
                            .then((ret) => {
                                ret.forEach((resp) => (needed_subs[resp.sub] = resp.result));
                                const n = writer._data.length;
                                for (let i = 1; i < n; i++) {
                                    if (writer._data[i][sub_index]) {
                                        writer._data[i][name_index] =
                                            needed_subs[writer._data[i][sub_index]]?.email ||
                                            writer._data[i][sub_index] ||
                                            null;
                                    }
                                }
                                done()
                                    .fetchXSLX(this._auth_token, opt)
                                    .then((buffer) => {
                                        resolve(buffer);
                                    })
                                    .catch((e: FrameworkHttpError) => {
                                        reject(e);
                                    });
                            })
                            .catch((e) => {
                                reject(e);
                            });
                    },
                });
            } else {
                reject(new FrameworkHttpError(0));
            }
        });
    }

    private _subCache = {};
    public fetchSub(sub: string, force?: boolean) {
        return new Promise((resolve: (data: any) => void, reject: (e: FrameworkError) => void) => {
            if (this._subCache[sub] && !force) {
                resolve(this._subCache[sub]);
            } else if (this._auth_token) {
                getSub(this._auth_token, sub, (error, result) => {
                    if (error) {
                        //reject(error);
                        result = {
                            sub: sub,
                            error: error,
                        };
                        this._subCache[sub] = result;
                        resolve(result);
                    } else {
                        this._subCache[sub] = result;
                        resolve(result);
                    }
                });
            } else {
                reject(new FrameworkHttpError(403));
            }
        });
    }

    getRootProcessIds(): number[] {
        return this._model && this._model.tasks[0] ? [0] : [];
    }

    getAllProcessesIds(opt?: { processesOnly?: boolean; includeAll?: boolean }): number[] {
        if (this._model) {
            const tasks: number[] = [];
            for(const taskId  of Object.getOwnPropertyNames(this._model.tasks)){
                const id = +taskId;
                if((opt?.includeAll || this._model.VALUE<number>(this._model.tasks[id]?.p, -1) >= 0) &&
                    (!opt?.processesOnly || !Array.isArray(this._model.tasks[id]._c))){
                    tasks.push(id)
                }
            }
            return tasks;
        } else {
            return null;
        }
    }

    getAllTradeIds() {
        const tradeIds = Object.getOwnPropertyNames(this._model?.trades || {}).map((_trid) => Number.parseInt(_trid));
        return tradeIds;
    }

    getProjectInfo() {
        return this._model ? this._model.getProjectInfo() : null;
    }

    gatherUsedTradeParticles(): { [tradeId: number]: number[] } {
        return this._model ? this._model.gatherUsedIds()[DataOperationTarget.TRADE] : [];
    }

    public addCommentToProcess(id: number, comment: string) {
        this._model.addTaskComment(id, comment);
        this._model.updateModel();
    }

    public async sync(opt?: { group?: boolean }) {
        this._model.updateModel();
        if (true === opt?.group) {
            const ts0 = this._model.syncCommitedTS().ofs;
            const ts1 = this._model.commitTS();
            if (ts0 < ts1) {
                assert(false === this._model.ops[ts1 - 1].group);
                for (let ts = ts0; ts < ts1 - 1; ts++) {
                    this._model.ops[ts].group = true;
                }
                assert(false === this._model.ops[ts1 - 1].group);
            }
            this._model._clearUndoStack(); // clear undo stack...
        }
        const syncRet = this._model.getSync(0);
        const pushRet = await DataModel.pushStreamOps(this._model.storageName, syncRet.sync);
        let commitRet = -1;
        while (0 === (commitRet = this._model.commitSync(pushRet, syncRet.token))) {
            // progress?
        }
        if (commitRet < 0) {
            throw new FrameworkError("fw.syncFailed");
        } else {
            return true;
        }
    }

    public adjustToNextWorkingDay(date: number) {
        return DataModel.adjustToWorkingDay(this._model.taskCalendarHack, date, +1);
    }

    public adjustToWorkingDay(date: number, dx: number) {
        return DataModel.adjustToWorkingDay(this._model.taskCalendarHack, date, dx);
    }

    public dateAddWorkingDays(date: number, delta: number) {
        return DataModel.dateAddWorkingDays(this._model.taskCalendarHack, date, delta);
    }

    public getSafeIDs(ids: { pids?: number[]; trids?: number[]; hids?: number[] }): {
        pids?: string[];
        trids?: string[];
        hids?: string[];
    } {
        return this._model.getSafeIDs(ids);
    }

    public getUnsafeIDs(safeIDs: { pids?: string[]; trids?: string[]; hids?: string[] }): {
        pids?: number[];
        trids: number[];
        hids?: number[];
    } {
        return this._model.getUnsafeIDs(safeIDs);
    }

    public getSID() {
        return this._model?.storageName;
    }

    public getSUB() {
        return this._model?.userName;
    }

    /** @beta */
    static async uploadProject(buffer: ArrayBuffer) {
        return new Promise((resolve: (resource: { resource_token: string }) => void, reject: (err) => void) => {
            uploadArrayBuffer(buffer, (error, done) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(done);
                }
            });
        });
    }

    public getCommitedTS() {
        return this._model ? this._model.syncCommitedTS().ofs : 0;
    }

    public getUncommitedTS() {
        return this._model ? this._model.commitTS() : 0;
    }

    public getImportedTS() {
        return this._model ? this._model._snap().imported : 0;
    }

    public hasProcess(id: number, sid?: string): boolean {
        return this._model.hasTaskId(id, sid);
    }

    public hasTrade(id: number, sid?: string): boolean {
        return this._model.hasTradeId(id, sid);
    }

    public calcEpochStartDate(start: number, duration: number, unit: number) {
        const m = this._model;
        return DataModel.CalcEpochStartDayMS(m.taskCalendarHack, start, duration, unit);
    }

    public removeVirtualProcess(pid: number) {
        const m = this._model;
        assert(m.tasks[pid] && null === m.VALUE<number | null>(m.tasks[pid]?.p, null)); // no parent => virtual child
        delete m.tasks[pid];
    }

    public createVirtualProcess(
        props: {
            _x1: number;
            _x2: number;
            _rw: { [id: number]: number }; // resources "work"/trades
            _name: string;
            _days: number;
            _rsid: string; // Referenced Storage ID
            _rid: number; // Referenced ID
            _train: string | number;
            _stripey: number;
            _p: number;
            _wf: number;
        },
        cachedId?: number,
    ): number {
        const m = this._model;
        if ("number" === typeof cachedId) {
            const cachedTask = m.tasks[cachedId];
            if (cachedTask) {
                assert(cachedTask.__ < 0);
                Object.assign(cachedTask, props);
                cachedTask.__--; // mark as "dirty"
                return cachedId;
            }
        }
        return m._createTask(props, cachedId);
    }

    public getLegacyFluxState(name) {
        const m = this._model;
        switch (name) {
            case "startDate":
                return m.canvasStartDate || m.startDate;
            case "endDate":
                return m.canvasEndDate || m.endDate;
            case "tz.maxLevels": {
                const ctx = m.stackTasksIfNeeded();
                return ctx.l - ctx.l_min;
            }
            case "calendar.meta": {
                return m.getWorkingDayMeta();
            }
            case "view.meta": {
                return m.viewMeta;
            }
            default:
                return m.legacyFLux[name];
        }
    }

    public patchFluxState(name: string, value: any) {
        assert(false); //@TOOD
    }

    public getViewConst() {
        return this._model.viewConst;
    }

    public get isReadOnly() {
        return this._model.viewConst.readonly ? true : false;
    }

    public static unitToDays(days: number, unit: number) {
        return DataModel.unitToDays(days, unit);
    }

    public static createAttachmentUrl(image) {
        return getAttachmentUrl(image);
    }

    public CalcEpochStartDayMS(start: number, duration: number, unit: number) {
        if (this._model.whiteboard) {
            // ignore the calculation of start date, because wb dosen't have a concept of calendar
            return start;
        }
        return DataModel.CalcEpochStartDayMS(this._model.taskCalendarHack, start, duration, unit);
    }

    public CalcWorkDays(start: number, end: number) {
        return DataModel.CalcWorkDays(this._model.taskCalendarHack, start, end);
    }

    public isWorkingDay(date: number | Date) {
        return date < MAX_EPOCH_DAYS
            ? true
            : this._model.taskCalendarHack("number" === typeof date ? new Date(date) : date);
    }

    public dateToGrid(date: number): number {
        return CalendarGrid._dateToGrid(this._model.grid.grid, date);
    }

    public gridToDate(x: number) {
        const g = this._model.grid;
        return 0 <= x && x < g.grid[g.grid.length - 1].g1 ? g.gridToDate(x) : null;
    }

    public static moveNextDay(d: Date) {
        return DataModel.moveNextDay(d);
    }

    // will enhance the dynamically enhance the grid if needed to calc a start pos
    public ensureGridStartDate(start: number, dx: number): number {
        const m = this._model;
        const isWorkingDay = m.taskCalendarHack;
        const _start = DataModel.calcGridStart(start, m.grid, m.gridIsWorkDay, dx, isWorkingDay);
        return _start;
    }

    public getViewCols(): number {
        return this._model.grid.view.cols;
    }

    protected abstract onCreateCardsForProcess(pIt: ProcessGetter): {
        cards: ParticleCardData[];
        _: number;
        _y_max: number;
    };

    protected abstract _coreUpdateActivities(
        ctx: { model: DataModel },
        tid: number,
    ): {
        cards: ParticleCardData[];
        _: number;
        _y_max: number;
    };

    public abstract getTrades(): any[];

    private _coreGetTrades() {
        return this.getTrades();
    }

    public updateCore(opt?: { updateLayout: boolean }) {
        if (this._model) {
            this._model.updateModel();
            if (opt?.updateLayout) {
                this._model.stackTasksIfNeeded();
            }
        }
    }

    public getLayoutForProcess(pGt: ProcessGetter) {
        const m = this._model;
        const C = m.viewConst;
        const stripe = pGt.aux<number>("_stripe1");
        const isTaktzone = !!pGt.getChildren();
        // can happen in the dailyboard API, where there are no stripes...
        const top =
            0 <= stripe && stripe < m.canvasStripes.length
                ? ((stripe > 0 ? m.canvasStripes[stripe - 1].e : 0) + pGt.aux<number>("_y")) * m.viewConst.rowPx
                : undefined;
        const left =
            !isTaktzone && m.canvasStartDate
                ? m.epochDateToProjectGrid(m.canvasStartDate, pGt.aux<number>("_x1")) * C.colPx
                : null; //@TODO is calculating this here really a good idea?
        const right =
            !isTaktzone && m.canvasStartDate
                ? m.epochDateToProjectGrid(m.canvasStartDate, pGt.aux<number>("_x2")) * C.colPx
                : null; //@TODO shouldn't this be calculated in the UI and we pass _x1, _x2 only?
        return {
            left,
            right,
            top,
            stripe,
        };
    }

    public parseHivePath(path: string): { target: string; id?: number; name?: string }[] {
        const ret = [];
        const _path = DataModel.parseHivePath(path);
        for (let i_path = 1; i_path < _path.length; i_path++) {
            switch (_path[i_path].target || null) {
                case "T":
                    {
                        ret.push({
                            target: _path[i_path].target,
                            id: Number.parseInt(_path[i_path].hexId, 16),
                        });
                    }
                    break;
                case "R":
                    {
                        ret.push({
                            target: _path[i_path].target,
                            id: Number.parseInt(_path[i_path].hexId, 16),
                        });
                    }
                    break;
                case "D":
                    {
                        ret.push({
                            target: _path[i_path].target,
                            id: Number.parseInt(_path[i_path].hexId, 16),
                        });
                    }
                    break;
                case null:
                    {
                        ret.push({
                            target: null,
                            id: -1,
                            name: _path[i_path].name,
                        });
                    }
                    break;
                default:
                    break;
            }
        }
        return ret;
    }

    public createHivePath(path: { target: string; id?: number; name?: string }[]) {
        const ret = [];
        for (let i_path = 0; i_path < path.length; i_path++) {
            switch (path[i_path].target || null) {
                case "T":
                    {
                        ret.push(["#", path[i_path].target, path[i_path].id.toString(16)].join(""));
                    }
                    break;
                case "R":
                    {
                        ret.push(["#", path[i_path].target, path[i_path].id.toString(16)].join(""));
                    }
                    break;
                case "D":
                    {
                        ret.push(["#", path[i_path].target, path[i_path].id.toString(16)].join(""));
                    }
                    break;
                case null:
                    {
                        ret.push(["#", path[i_path].name].join(""));
                    }
                    break;
                default:
                    {
                    }
                    break;
            }
        }
        return ret.join("");
    }

    protected setFilterCBs(ctx: {
        stripesFilter?: ((sc: number) => boolean) | null;
        stripesTradeFilterCB?: (id: number) => boolean;
        filterX1X2: { _x1: number; _x2: number } | null;
        pidsFilter: (id: number) => boolean;
    }) {
        if (this._model) {
            this._model.setFilterCB(ctx);
        }
    }

    protected abstract onWBSync(ctx: OnParticleCoreUpdateEvent, init?: boolean): void;
    protected abstract onPPProcessUnchanged(
        ctx: OnParticleCoreUpdateEvent,
        ct: CanvasTaskData,
        pGt: ProcessGetter,
        userCtx: any,
    ): void | boolean;
    protected abstract onPPProcessChanged(
        ctx: OnParticleCoreUpdateEvent,
        ct: CanvasTaskData,
        pGt: ProcessGetter,
        userCtx: any,
    ): void;
    protected abstract onPPProcessUpdateCtx(ctx: OnParticleCoreUpdateEvent, pGt: ProcessGetter, userCtx: any): void;
    protected abstract onWBProcessUnchanged(
        ctx: OnParticleCoreUpdateEvent,
        ct: CanvasTaskData,
        wbPGt: ProcessGetter,
        userCtx: any,
    ): void | boolean;
    protected abstract onWBProcessChanged(
        ctx: OnParticleCoreUpdateEvent,
        ct: CanvasTaskData,
        wbPGt: ProcessGetter,
        userCtx: any,
    ): void;
    protected abstract onWBProcessUpdateCtx(ctx: OnParticleCoreUpdateEvent, wbPGt: ProcessGetter, userCtx: any): void;

    public get compat(): {
        gpaFix: number; // fix for the gpa "start" value, default: 1. Needed because in the first design untits where in weeks not days.
    } & any {
        return this._model.compat || {};
    }

    // EOC
}

export type ParticleCoreContextProcessDetailsState = {
    ppid?: { value: number; index?: number };
};
export type ParticleCoreContextCardDetailsState = {};
export type ParticleCoreContextDependencyDetailsState = {};
export type ParticleCoreUploadToken = {
    token: string;
    blobId: string;
};
export type ParticleCoreContextUnitType = number;
export type ParticleCoreContextAddPredecessorState<LCMDContextUnitType, LCMDContextDependencyType> = {
    /** dependency source process pid */
    src: number;
    /** dependency target process pid */
    dst: number;
    /** dependency type */
    type: LCMDContextDependencyType;
    lag: [number /** dependency lag value */, LCMDContextUnitType /** dependency type */];
};

export type OnWorkerMsgType = [string, any] | [string, any, any] | ["processFilter", "set", {processIds: number[]}] | ["resolve-conflict", {processId: number, callback?: () => void}];
export type IParticleWorker = {
    postMessage: (message: any, transfer?: Transferable[]) => void;
};

export type OnImportOptions = { resource: any; type: string };

export const MAX_EPOCH_DAYS = MAX_TASK_DAYS;

export type { OnParticleWorkerMsgEvent, OnParticleCoreUpdateEvent };
