import { IconButton } from "@/components/common/IconButton/IconButton";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { CanvasViewConst } from "../../../model/DataModel";
import { CanvasMeta } from "./Canvas";
import { MainWorkerPipe } from "../../MainWorkerPipe";
import { getMoreButtonOptions } from "./Shared/CanvasMoreButtonOptions";
import { getDropdownOptions } from "./Shared/CanvasDropdownOptions";
import { useLCMD } from "@/app/LCMContext";
import { useMemo, useState } from "react";
import { BaselinesDialog } from "@/components/baselines-dialog-v2";
import { DialogType } from "@fluentui/react";
import { intl } from "@/legacy/GlobalHelperReact";

const dropdownClassName = "focus:bg-stone-700 focus:text-white focus:cursor-pointer data-[state=open]:bg-stone-700";

const getOptionsBySelectedTab = (selectedTab: string) => {
    const defualtMenus = ["clone", "share", "props", "unlink"];
    switch (selectedTab) {
        case "workshop":
        case "todo":
        case "dashboard":
        case "dailyboard":
        case "settings":
            return defualtMenus;
        case "project":
            return ["baseline", "export", ...defualtMenus];
    }
};

export function CanvasStatusDropdown({
    const: { lcmx },
    meta,
    worker,
    selected,
    userflowId,
}: {
    const: CanvasViewConst;
    worker: MainWorkerPipe;
    meta: CanvasMeta;
    selected: string;
    userflowId?: string;
}) {
    const LCMD = useLCMD();
    const [showBaselineDialog, setShowBaselineDialog] = useState(false);
    const moreButtonOptions = getMoreButtonOptions({ lcmx, worker, meta });
    const optionsBySelectedTab = useMemo(() => getOptionsBySelectedTab(selected), [selected]);
    const dropdownOptions = getDropdownOptions({
        selected,
        moreButtonOptions,
        handleShowBaselineDialog: () => setShowBaselineDialog(true),
        handleShowChanges: () => worker.dispatchMessage(["toggle", "changes", {}]),
        handleCloneProject: () => LCMD.showDialog("fw.project.clone"),
        showSettings: () => LCMD.showDialog("dialog.project.properties", true),
        shareProject: () => LCMD.showDialog("fw.project.share"),
        handleLeaveProject: () => {
            LCMD.showDialog("fw.alert", {
                dialogContentProps: {
                    type: DialogType.normal,
                    title: intl.get("overview.cmd.unlink.alert.title"),
                    subText: intl.get("overview.cmd.unlink.alert.subText"),
                },
                onOK: () => {
                    LCMD.showDialog("fw.alert", null);
                    LCMD.showDialog("fw.project.unlink"); // fix me...
                },
                onCancel: () => {
                    LCMD.showDialog("fw.alert", null);
                },
            });
        },
    });

    const extendedDropdownOptions = useMemo(
        () =>
            dropdownOptions
                .filter((el) => optionsBySelectedTab.includes(el.key))
                .map((el) => ({
                    ...el,
                    text: intl.get(["overview.cmd", el.key, "text"].join(".")),
                })),
        [dropdownOptions, optionsBySelectedTab],
    );

    const renderDropdownOptions = (options) => {
        return options.map(({ onClick, text, icon, options: subOptions, key, separate }) => (
            <div key={key}>
                {subOptions ? (
                    <DropdownMenuSub key={key}>
                        <DropdownMenuSubTrigger className={dropdownClassName}>
                            {icon}
                            {text}
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent className="border-0 bg-neutral-800 text-white">
                                {renderDropdownOptions(subOptions)}
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                ) : (
                    <DropdownMenuItem key={key} className={dropdownClassName} onClick={onClick}>
                        {icon}
                        {text}
                    </DropdownMenuItem>
                )}
                {separate && <DropdownMenuSeparator />}
            </div>
        ));
    };

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <div className="ml-1" style={{ height: "32px" }}>
                        <IconButton icon={ChevronDown} userflowId={userflowId} />
                    </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56 border-0 bg-neutral-800 text-white">
                    <DropdownMenuGroup>{renderDropdownOptions(extendedDropdownOptions)}</DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
            {showBaselineDialog && <BaselinesDialog onShow={setShowBaselineDialog} />}
        </>
    );
}
