export enum RouteNames {
    HOME = "/",
    TRADES = "trades-filter",
    PRIORITY = "priority-filter",
    AREA = "areas-filter",
    RAISEDBY = "raised-by-filter",
    RESPONSIBLE = "responsible-filter",
    TYPE = "todo-type-filter",
    STATUS = "status-filter",
    DEADLINE = "date-filter",
}

export type FilterData = {
    typeFilterData: any;
    responsibleFilterData: any;
    dateFilterData: ProjectDateRange[];
    raisedOnFilterData: ProjectDateRange[];
    tradesFilterData: any;
    areasFilterData: any;
    priorityFilterData: any;
    statusFilterData: any;
    raisedByFilterData: any;
};

export enum FilterType {
    TYPE = "typeFilterData",
    RESPONSIBLE = "responsibleFilterData",
    DATE = "dateFilterData",
    RAISEDON = "raisedOnFilterData",
    TRADES = "tradesFilterData",
    AREAS = "areasFilterData",
    PRIORITY = "priorityFilterData",
    STATUS = "statusFilterData",
    RAISEDBY = "raisedByFilterData",
}

export type ProjectDateRange = {
    selectedMode: string;
    startDate: number;
    endDate: number;
};
