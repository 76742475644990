﻿import * as React from "react";
import { CanvasViewConst } from "../../../model/DataModel";
import { DataStorage } from "../../../model/DataStorage";
import { assert } from "../../../model/GlobalHelper";
import * as fluentui from "@fluentui/react";
import { Icon, Link, Spinner } from "@fluentui/react";
import { intl } from "../../GlobalHelperReact";
import { CanvasCommonProps, CanvasViewProps } from "./Canvas";
import DigitalPlanningBoard from "../DigitalPlanningBoard";
import { MainWorkerMessage } from "../../MainWorkerPipe";
import { Progress } from "@/components/ui/progress";

type CanvasPopupProps = CanvasCommonProps &
    CanvasViewProps & {
        divRefs: { CO: HTMLDivElement };
    };

type CanvasPopupState = {
    const: CanvasViewConst;
    visible: boolean;
    login: boolean;
    loaded: boolean | (() => void);
    status: string;
    download: { type: string; url: string; name: string } | null;
    upload: boolean;
    timer0: number;
    timer: number;
    error: null | {
        message: string;
    };
    workerBusy: boolean;
    procore: any;
};

export class CanvasPopup extends React.Component<CanvasPopupProps, CanvasPopupState> {
    state = {
        const: this.props.worker.canvas.viewConst,
        visible: true,
        login: false,
        loaded: false,
        status: null,
        download: null,
        upload: false,
        timer0: 0,
        timer: 0,
        error: null,
        workerBusy: false,
        procore: null,
    };
    onWorkerMsg = function (this: CanvasPopup, msg: MainWorkerMessage) {
        switch (msg[0]) {
            case "canvas":
                {
                    const canvas = msg[1];
                    const C = canvas?.viewConst || this.state.const;
                    if (false && "development" === process.env.NODE_ENV) {
                        // no login screen...
                        this.setState({
                            visible: false,
                            const: C,
                        });
                    }
                    if (false === this.state.loaded) {
                        this.setState({
                            loaded: true,
                            const: C,
                        });
                    } else {
                        if (true !== this.state.loaded) {
                            const loaded = this.state.loaded;
                            this.setState(
                                {
                                    loaded: true,
                                    const: C,
                                },
                                loaded,
                            );
                        } else if (C !== this.state.const) {
                            this.setState({
                                const: C,
                            });
                        }
                    }
                }
                break;
            case "import":
                {
                    const todos = msg[1];
                    if (todos?.error) {
                        console.warn(todos.error);
                        this._ensureTimer(false);
                        this.setState({
                            error: todos.error,
                        });
                        //@ts-ignore
                        if (!window.stopReload) {
                            DataStorage.dropAllDatabase().finally(() => {
                                alert("Critical error, application will reload!");
                                location.reload();
                            });
                        }
                    } else if (todos?.procore) {
                        this._ensureTimer(false);
                        this.setState({
                            procore: todos.procore,
                        });
                    } else {
                        const C = this.state.const;
                        console.log("Import Postprocess...");
                        const todo_tasks = todos.tasks;
                        const ret = {};
                        for (let i = 0; i < todo_tasks.length; i++) {
                            const t = todo_tasks[i];
                            const fs = DigitalPlanningBoard.calcCardFontSize(t.right - t.left, C.rowPx, t.name);
                            ret[t.id] = fs;
                        }
                        this.props.worker.postMessage(["import", { todos: ret }]);
                        this.setState({
                            visible: false,
                        });
                    }
                }
                break;
            case "http":
                {
                    this.setState({
                        status: msg[1] + " " + msg[2],
                    });
                }
                break;
            case "export":
                {
                    const data = msg[1];
                    //const view=new DataView(data);
                    if (data.procore) {
                        this.setState({
                            visible: true,
                            procore: data.procore,
                        });
                    } else {
                        const blob = data.data
                            ? new Blob([data.data], {
                                  type: data.mime,
                              })
                            : null;
                        const url = blob ? URL.createObjectURL(blob) : null;
                        this.setState({
                            visible: true,
                            download: {
                                type: data.type,
                                name: data.name,
                                url: url,
                            },
                        });
                        /*
        const file=new File([data.data], "sample.mpp.xml", {
            type: "application/vnd.ms-project",
        })
        window.open(url);
        //URL.revokeObjectURL(url);
        */
                    }
                }
                break;
            case "worker":
                {
                    if ("busy" === msg[1]) {
                        this.setState({
                            workerBusy: msg[2],
                        });
                    } else if ("progress" === msg[1]) {
                        const opt = msg[2];
                        const show = opt.show ? true : false;
                        this._ensureTimer(show);
                        if (opt.error) {
                            console.error(opt.error);
                            if (this.state.visible !== show) {
                                this.setState({
                                    visible: true,
                                    error: opt.error,
                                });
                            }
                        } else {
                            if (this.state.visible !== show) {
                                this.setState({
                                    visible: show,
                                });
                            }
                        }
                    }
                }
                break;
            case "framework":
                {
                    switch (msg[1]) {
                        case "uploadExtension":
                            {
                                this.setState({
                                    upload: true,
                                    visible: true,
                                });
                            }
                            break;
                    }
                }
                break;
        }
    }.bind(this);
    onDragover = function (this: CanvasPopup, event: any) {
        //event.dataTransfer.effectAllowed = "copy";
        event.preventDefault();
    }.bind(this);
    onDrop = function (this: CanvasPopup, event: any) {
        try {
            if (false) {
                const dt = event.dataTransfer;
                if (dt && dt.items && dt.items.length > 0) {
                    if (dt.files && dt.files.length > 0) {
                        /*
                        this.setState({
                            session:null,
                            sessionLoaded:null
                        }, ()=>{
                            if (this.state.appConfig.renderRoot) {
                                this._renderedRoot=this.state.appConfig.renderRoot(this._renderedRoot, dt.files);
                                this.forceUpdate();
                            }
                        });
                        */
                        this.setState({
                            status: "Uploading. Please wait...",
                        });
                        //DataModel.compressAndUploadProjectFile(dt.files[0]);
                        const file: File = dt.files[0];
                        file.arrayBuffer().then((b) => {
                            const isCSV = file?.name?.endsWith(".csv");
                            const isJSON = file?.name?.endsWith(".json");
                            this.props.worker.postMessage(
                                [
                                    "import",
                                    {
                                        projectFile: isCSV || isJSON ? undefined : b,
                                        csvFile: isCSV ? b : undefined,
                                        jsonFile: isJSON ? b : undefined,
                                        fileName: file.name,
                                        fileSize: file.size,
                                    },
                                ],
                                [b],
                            );
                            /*
                            this.setState({
                                visible: false
                            });
                            */
                        });
                    }
                }
            }
        } finally {
            event.preventDefault();
        }
    }.bind(this);
    private onTimerRef: any | null = null;
    private onTimer = function (this: CanvasPopup) {
        const now = new Date().getTime();
        this.setState({
            timer: now,
        });
    }.bind(this);
    private _onSubmit = function (this: CanvasPopup, event) {
        const value = event.target[0].value;
        if (value.indexOf("@") >= 0) {
            const email = value;
            setTimeout(() => {
                this.loginWithToken("TODOAUTHTOKEN");
            }, 1000);
        } else {
            //@TODO error
        }
        event.preventDefault();
    }.bind(this);
    private _onDismiss = function (this: CanvasPopup, event) {
        event.preventDefault();
        if (this.state.error?.meta) {
            this.setState({
                visible: false,
            });
        } else {
            this.props.worker.dispatchMessage(["framework", "toggle", "folder"]);
        }
    }.bind(this);

    componentDidMount(this: CanvasPopup) {
        this.props.worker.registerHandler(this.onWorkerMsg);
        window.addEventListener("dragover", this.onDragover);
        window.addEventListener("drop", this.onDrop);
        this._ensureTimer(true);
        this.setState({
            loaded: () => {
                //HACK: refactor me!!!
                this._ensureTimer(false);
                this.setState({
                    visible: false,
                });
            },
        });
    }

    componentWillUnmount(this: CanvasPopup) {
        this._ensureTimer(false);
        window.removeEventListener("drop", this.onDrop);
        window.removeEventListener("dragover", this.onDragover);
        this.props.worker.unregisterHandler(this.onWorkerMsg);
    }

    render(this: CanvasPopup) {
        if (this.state.visible) {
            const cp_style: React.CSSProperties = {};
            let popup = null;
            if (this.state.error) {
                const message = this.state.error?.message || "Error";
                popup = (
                    <form
                        onSubmit={this._onDismiss}
                        style={{
                            opacity: 1,
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            color: "black",
                            backgroundColor: "white",
                            width: 640,
                            minHeight: 300,
                            padding: 50,
                            fontSize: 20,
                            zIndex: 999,
                        }}
                    >
                        <div
                            style={{
                                color: "black",
                                textAlign: "center",
                                marginBottom: 40,
                                fontWeight: "bold",
                            }}
                        >
                            Error
                        </div>
                        <div>{message}</div>
                        <div
                            style={{
                                marginTop: 40,
                                textAlign: "center",
                            }}
                        >
                            <button
                                tabIndex={2}
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                Dismiss
                            </button>
                        </div>
                    </form>
                );
            } else if (this.state.procore) {
                const procore = this.state.procore;
                const modelProps = {
                    isBlocking: false,
                    styles: { main: { maxWidth: 450 } },
                };
                const dialogContentProps = {
                    type: fluentui.DialogType.largeHeader,
                    title: "ProCore Integration",
                    //                    subText: 'Please login into Procore',
                };
                popup = (
                    <fluentui.Dialog hidden={false} dialogContentProps={dialogContentProps} modalProps={modelProps}>
                        {this.state.procore.href ? (
                            <Link
                                underline={true}
                                href={this.state.procore.href}
                                target={this.state.procore.target}
                                onClick={() => {
                                    this._ensureTimer(false);
                                    this.setState({ procore: null, visible: false });
                                }}
                            >
                                Edit in LCMDigital...
                                <Icon iconName="NavigateExternalInline" />
                            </Link>
                        ) : (
                            <Link
                                underline={true}
                                onClick={() => {
                                    assert(false);
                                    /*
                            proCoreLoginAsync((error, result)=>{
                                if (error) {
                                    console.error(error);
                                } else {
                                    this._ensureTimer(true);
                                    this.setState({
                                        procore: null
                                    }, ()=>{
                                        switch(procore[0]) {
                                            case "init":
                                                Object.assign(procore[1]?.key?.procore||{}, {
                                                    auth: result
                                                });
                                            break;
                                            case "export":
                                                Object.assign(procore[1]||{}, {
                                                    auth: result
                                                });
                                            break;
                                        }
                                        this.props.worker.postMessage(procore);
                                    })
                                }
                            });
                            */
                                }}
                            >
                                Please login into Procore...
                            </Link>
                        )}
                    </fluentui.Dialog>
                );
            } else if (this.state.download) {
                popup = (
                    <div
                        style={{
                            opacity: 1,
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            color: "black",
                            backgroundColor: "white",
                            width: 640,
                            height: 200,
                            padding: 50,
                            fontSize: 20,
                            zIndex: 999,
                        }}
                    >
                        <div
                            style={{
                                color: "black",
                                textAlign: "center",
                                marginBottom: 40,
                                fontWeight: "bold",
                            }}
                        >
                            {intl.get(["export", this.state.download.type, "success"].join("."))}
                        </div>
                        <div
                            style={{
                                color: "black",
                                textAlign: "center",
                                fontSize: 20,
                                marginBottom: 40,
                            }}
                        >
                            <a
                                href={this.state.download.url}
                                download={this.state.download.name}
                                onClick={() => {
                                    setImmediate(() => {
                                        URL.revokeObjectURL(this.state.download.url);
                                        this.setState({
                                            visible: false,
                                            download: null,
                                        });
                                    });
                                }}
                            >
                                {intl.get("export.download")}
                            </a>
                        </div>
                    </div>
                );
                cp_style.opacity = 1;
                cp_style.backgroundColor = "darkgray";
            } else if (this.state.upload) {
                popup = (
                    <form
                        style={{
                            opacity: 1,
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            color: "black",
                            backgroundColor: "white",
                            width: 640,
                            height: 200,
                            padding: 50,
                            fontSize: 20,
                            zIndex: 999,
                        }}
                        onSubmit={(ev) => {
                            ev.preventDefault();
                            return true;
                        }}
                    >
                        <label>
                            {intl.get("extension.upload")}{" "}
                            <input
                                type="file"
                                accept=".lcmdx.json"
                                onChange={(ev) => {
                                    if (ev && ev.target && ev.target.files && ev.target.files.length > 0) {
                                        const file = ev.target.files[0];
                                        const reader = new FileReader();
                                        reader.onload = (ev) => {
                                            try {
                                                const ext = JSON.parse(ev.target.result as string);
                                                this.props.worker.postMessage(["lcmdx", ext]);
                                                this.setState({
                                                    upload: false,
                                                    visible: false,
                                                });
                                            } catch (e) {
                                                console.error(e);
                                            }
                                        };
                                        reader.onerror = (ev) => {
                                            console.error(ev);
                                        };
                                        reader.readAsText(file, "UTF-8");
                                    }
                                }}
                            />
                        </label>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <fluentui.DefaultButton
                                text={intl.get("fw.cancel")}
                                onClick={() => {
                                    this.setState({
                                        upload: false,
                                        visible: false,
                                    });
                                }}
                            />
                        </div>
                    </form>
                );
                cp_style.opacity = 1;
                cp_style.backgroundColor = "darkgray";
            } else if (this.props.projectId) {
                if (this.state.login && (false === this.state.loaded || true === this.state.loaded)) {
                    popup = (
                        <form
                            onSubmit={this._onSubmit}
                            style={{
                                opacity: 1,
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                transform: "translateX(-50%) translateY(-50%)",
                                color: "black",
                                backgroundColor: "white",
                                width: 640,
                                height: 300,
                                padding: 50,
                                fontSize: 20,
                                zIndex: 999,
                            }}
                        >
                            <div
                                style={{
                                    color: "black",
                                    textAlign: "center",
                                    marginBottom: 40,
                                    fontWeight: "bold",
                                }}
                            >
                                Login
                            </div>
                            <input
                                id="login_email"
                                type="email"
                                placeholder="Please enter your email address..."
                                tabIndex={1}
                                style={{
                                    color: "black",
                                    width: "100%",
                                    fontSize: 20,
                                    marginBottom: 40,
                                }}
                            />
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <button
                                    tabIndex={2}
                                    style={{
                                        fontSize: 20,
                                    }}
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    );
                } else {
                    const t_max = 5 * 60 * 1000;
                    const t = Math.min(Math.max(0, this.state.timer - this.state.timer0), t_max);
                    let variant = undefined;
                    let p = 0;
                    let text = null;
                    if (t > 2 * 60 * 1000) {
                        // more than 2 min
                        variant = "red";
                        p = Math.min(Math.max(Math.ceil((t / t_max) * 100), 0), 100);
                        text = "This operation takes longer than usual...";
                    } else if (t > 1 * 60 * 1000) {
                        // more than 1 min
                        variant = "yellow";
                        p = Math.min(Math.max(Math.ceil((t / (2 * 60 * 1000)) * 100), 0), 100);
                        text = "This operation takes longer than usual...";
                    } else {
                        p = Math.min(Math.max(Math.ceil((t / (1 * 60 * 1000)) * 100), 0), 100);
                    }
                    const m = Math.floor(t / (60 * 1000));
                    const s = Math.ceil((t - m * (60 * 1000)) / 1000);
                    const f = m + "m:" + s + "s";
                    popup = (
                        <div
                            style={{
                                opacity: 1,
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                transform: "translateX(-50%) translateY(-50%)",
                                color: "black",
                                backgroundColor: "white",
                                width: 640,
                                height: 200,
                                padding: 50,
                                fontSize: 20,
                                zIndex: 999,
                                borderRadius: "8px"
                            }}
                        >
                            <div className="mb-5">
                                {intl.get("fw.loading")} ({f})
                            </div>
                            <Progress value={p} indicatorColor={variant} className="h-1" />
                            {text && <div className="text-center">{text}</div>}
                        </div>
                    );
                }
            } else {
                popup = (
                    <div
                        style={{
                            opacity: 1,
                            position: "fixed",
                            left: "50%",
                            top: "50%",
                            transform: "translateX(-50%) translateY(-50%)",
                            color: "black",
                            backgroundColor: "white",
                            width: 640,
                            height: 200,
                            padding: 50,
                            fontSize: 20,
                            zIndex: 999,
                        }}
                    >
                        <div
                            style={{
                                color: "black",
                                textAlign: "center",
                                marginBottom: 40,
                                fontWeight: "bold",
                            }}
                        >
                            Please Drag &amp; Drop a project to start
                        </div>
                        <div
                            style={{
                                color: "black",
                                textAlign: "center",
                                fontSize: 20,
                                marginBottom: 40,
                            }}
                        >
                            {this.state.status || ""}
                        </div>
                    </div>
                );
                cp_style.opacity = 1;
                cp_style.backgroundColor = "darkgray";
            }
            return (
                <div>
                    <div id="CP" style={cp_style}></div>
                    {popup}
                </div>
            );
        } else if (this.state.workerBusy) {
            return (
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "gray",
                        opacity: 0.5,
                        zIndex: 1000,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                >
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Spinner />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    private _ensureTimer(ensure: boolean) {
        if (ensure) {
            if (null === this.onTimerRef) {
                const now = new Date().getTime();
                this.setState(
                    {
                        timer0: now,
                        timer: now,
                    },
                    () => {
                        this.onTimerRef = setInterval(this.onTimer, 1000);
                    },
                );
            }
        } else {
            if (null !== this.onTimerRef) {
                clearInterval(this.onTimerRef);
                this.onTimerRef = null;
                this.setState({
                    timer0: 0,
                    timer: 0,
                });
            }
        }
    }

    private loginWithToken(auth_token: string) {
        const login = function (
            this: CanvasPopup,
            login: {
                auth_token: string;
            },
        ) {
            /*
            this.props.worker.postMessage(["login", {
                projectId: this.props.projectId,
                name: login.email
            }]);
            this.setState({
                visible: false
            });
            */
            console.log("auth_token");
        }.bind(this, {
            auth_token: auth_token,
        });
        if (false === this.state.loaded) {
            this.setState({
                loaded: login,
            });
        } else {
            assert(true === this.state.loaded);
            login();
        }
    }
}
