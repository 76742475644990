export const FILTER_SPECIAL_VALUE = 1 << 10;
export const FILTER_SPECIAL_VALUE_MASK = (1 << 8) - 1;
export const FILTER_SPECIAL_UNIT_MASK = ((1 << 3) - 1) << 8;
export const FILTER_SPECIAL_UNIT_WEEKS = 0 << 8;
export const FILTER_SPECIAL_UNIT_MONTH = 1 << 8;

export type DataModelFilter = {
    tz?: number[];
    trade?: number[];
    d?: [number, number];
    pids?: number[];
};

export type DataModelFilterSavedAs = DataModelFilter & {
    saveAs?: {
        hid?: number;
        name?: string;
        pos?: number;
    };
};

export type DataModelFilterCollection = {
    _hid: number;
    _pos: number;
    _filter: DataModelFilter;
    name?: string;
    pos?: number;
}[];

export type CanvasDataView = {
    commit_ts: number;
    grid_ts: number;
    baseline_ts: number;
    gpa_ts: number;
    filter: DataModelFilterSavedAs;
    view: {
        grid_ts: number;
        col0: number;
        col1: number;
        options?: any;
    };
    options: any;
};
