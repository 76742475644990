import { FrameworkError, getServices } from "lcmd2framework";

function _proCoreLoginPollCallback(ctx: {
    redirect_uri: string;
    procore_login: Window;
    timerRef: number;
    cb: (error: FrameworkError, result: any) => void;
}) {
    try {
        console.log(ctx.procore_login.location.href);
        if (ctx.procore_login.location.href.startsWith(ctx.redirect_uri)) {
            let ret = null;
            const hash = ctx.procore_login.location.hash;
            if (hash && "#" === hash[0]) {
                ret = hash
                    .substring(1)
                    .split("&")
                    .reduce((ret, nv) => {
                        const h = decodeURIComponent(nv).split("=");
                        ret[h[0]] = h[1] || null;
                        return ret;
                    }, {});
            }
            if (ctx.cb) {
                ctx.cb(null, ret);
                ctx.cb = null;
            }
            ctx.procore_login.close();
        }
    } catch (e) {
        console.error(e);
    } finally {
        if (ctx.procore_login.closed) {
            if (ctx.timerRef) {
                clearInterval(ctx.timerRef);
                ctx.timerRef = 0;
            }
            if (ctx.cb) {
                ctx.cb(null, null);
                ctx.cb = null;
            }
        }
    }
}

export function proCoreLoginAsync(cb: (error: FrameworkError, result: any) => void) {
    if (false) {
        cb(null, { access_token: "!23" });
        return;
    }
    const redirect_uri = getServices().OAUTH_REDIRECT_URL;
    const url = getServices().PROCORE_LOGIN + "&redirect_uri=" + encodeURIComponent(redirect_uri);
    const screen_width = screen.availWidth;
    const screen_height = screen.availHeight;
    const width = 480;
    const height = 640;
    const left = (screen_width - width) / 2;
    const top = (screen_height - height) / 2;
    const procore_login = window.open(
        url,
        "procore-login",
        [
            ["left", left].join("="),
            ["top", top].join("="),
            ["width", width].join("="),
            ["height", height].join("="),
        ].join(","),
    );
    const ctx = {
        cb: cb,
        redirect_uri: redirect_uri,
        procore_login: procore_login,
        timerRef: 0,
    };
    ctx.timerRef = setInterval(_proCoreLoginPollCallback.bind(this, ctx), 1000) as any;
}

const contentHeight = 400;

type ProCoreUploadProps = {
    initialData?: any;
    onDone: (data: any) => void;
};

export function ProCoreUploadPopup(props: ProCoreUploadProps) {
    return null; //@TODO
}
