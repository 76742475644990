﻿import { DataModel } from "../DataModel";
import { UUID5 } from "../UUID5";
import { stringToHex } from "../GlobalHelper";

export class ComplexProp {
    public _value = null;
    private _ref = undefined;

    /**
     * get ref object / set instance _ref or return propName and set as ref
     * @private
     */
    private _split() {
        if (undefined === this._ref) {
            if (this._value.startsWith("_")) {
                this._ref = null;
            } else {
                const ref = DataModel.parseRefPath(this._value);
                if (Array.isArray(ref)) {
                    this._ref = ref;
                } else {
                    this._ref = this._value;
                }
            }
        }
        return this._ref;
    }

    public static TRADEREF(tradeId: number) {
        const ret = new ComplexProp();
        ret._reset(["#RW", tradeId.toString(16)].join(""));
        return ret;
    }

    public static CARDPROP(aid: number, cid: number | undefined | null, prop: string) {
        const ret = new ComplexProp();
        if (null !== cid && undefined !== cid) {
            ret._reset(["#A", aid.toString(16), "#", cid.toString(10), "#", prop].join(""));
        } else {
            ret._reset(["#A", aid.toString(16), "#", prop].join(""));
        }
        return ret;
    }

    public static TRAINPROP(train: string | number, prop: string) {
        const ret = new ComplexProp();
        ret._reset(["#t#", stringToHex(train as string), "#", prop].join(""));
        return ret;
    }

    public static CREATEREL(dstPid: number) {
        const ret = new ComplexProp();
        ret._reset(["#TR", dstPid.toString(16)].join(""));
        return ret;
    }

    public static LCMXPROP(n: string[] | string) {
        const _n = Array.isArray(n) ? n.join(".") : n;
        return ["lcmx", _n].join(".");
    }

    public static HIVEPROP(n: string[]) {
        return ["#", n.join("#")].join("");
    }

    private static generateIdWithProp(complexPropType: "#I" | "#RC" | "#CL", id: number, prop?: string) {
        const _id = [complexPropType, id.toString(16)].join("");
        return prop ? [_id, prop].join("#") : _id;
    }

    public static ACTIONITEM(id: number, prop?: string) {
        return ComplexProp.generateIdWithProp("#I", id, prop);
    }

    public static REASON_CODE(id: number, prop?: string) {
        return ComplexProp.generateIdWithProp("#RC", id, prop);
    }

    //@nikhil checklist_api
    public static CHECK_LIST(id: number, prop?: string) {
        return ComplexProp.generateIdWithProp("#CL", id, prop);
    }

    public static UUIDPROP(uuid: string): string {
        const uuid5 = UUID5.fromUUID(uuid).toUUID5();
        return ["U", uuid5.toLocaleLowerCase()].join("");
    }

    public _reset(value?: string) {
        this._value = value || null;
        this._ref = undefined;
    }

    public isTRADEREF() {
        const ref = this._split();
        if (Array.isArray(ref) && 1 === ref.length && "R" === ref[0]?.target && "W" === ref[0]?.shortname) {
            return {
                tradeId: ref[0].id,
            };
        } else {
            return null;
        }
    }

    public isCARDPROP() {
        const ref = this._split();
        if (
            Array.isArray(ref) &&
            ref.length >= 2 &&
            "A" === ref[0]?.target &&
            undefined === ref[0]?.shortname &&
            ref[1]?.name
        ) {
            if (3 === ref.length && ref[2]?.name) {
                return {
                    aid: ref[0].id,
                    cid: Number.parseInt(ref[1].name, 10),
                    prop: ref[2].name,
                };
            } else if (2 === ref.length) {
                return {
                    aid: ref[0].id,
                    cid: null,
                    prop: ref[2].name,
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public isNAME(): string | null {
        const ref = this._split();
        return Array.isArray(ref) ? null : ref;
    }

    public isAUX() {
        const ref = this._split();
        return null === ref ? this._value : null;
    }

    public isATTACHMENTID() {
        const n = this._value;
        if (27 === n.length && "a" === n[0]) {
            const blobId = UUID5.fromUUID5(n.substring(1)).toUUID();
            return blobId;
        } else {
            return null;
        }
    }

    public isLCMX() {
        const n = this._value;
        if (n.startsWith("lcmx.")) {
            const _n = n.substr(5);
            return _n;
        } else {
            return null;
        }
    }

    public isSUB() {
        const n = this._value;
        if (n.length >= 28 && n.startsWith("S_")) {
            const sub5 = UUID5.fromUUID5(n.substring(2));
            return sub5 ? sub5.toUUID() : null;
        } else {
            return null;
        }
    }

    public isHIVEID() {
        const n = this._value;
        if (n.length > 1 && n.startsWith("H")) {
            return n;
        } else {
            return null;
        }
    }

    public isCOMMENTID() {
        const ref = this._split();
        if (Array.isArray(ref) && 1 === ref.length && "C" === ref[0]?.target) {
            return {
                commentId: ref[0].id,
            };
        } else {
            return null;
        }
    }

    private createPropertyRef(propName: string) {
        const n = this._value;
        if (n.length > 1 && n.startsWith(propName)) {
            const ref = this._split();
            if (1 === ref.length && ref[0].id >= 0) {
                return {
                    id: ref[0].id,
                };
            } else if (2 === ref.length && ref[0].id >= 0 && ref[1].name) {
                return {
                    id: ref[0].id,
                    prop: ref[1].name,
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public isACTIONITEM(): { id: number; prop?: string } {
        return this.createPropertyRef("#I");
    }

    public isREASON_CODE(): { id: number; prop?: string } {
        return this.createPropertyRef("#RC");
    }

    //@nikhil checklist_api
    public isCHECKLIST(): { id: number; prop?: string } {
        return this.createPropertyRef("#CL");
    }
}
