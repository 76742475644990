import * as React from "react";
import {
    assert,
    intl,
    NonProgressBar,
    createProject,
    errorToMessage,
    uploadFileBlocks,
    jsonToError,
    getServices,
} from "lcmd2framework";
import { getParsedProject } from "../../core/RestHelper";
import { ProCoreUploadPopup } from "../ProCoreUploadPopup";
import { getLCMD } from "../../app/LCMContext";
import { LcmdModal2 } from "../common/LcmModal2";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";
import { Alert } from "../ui/alert";
import { XCircle } from "lucide-react";
import { LegacyStartEndDateControl } from "@/legacy/GlobalHelperFluentUI";

function Form(props: { formRef: any; onSubmit: any; children: any }) {
    return (
        <form ref={props.formRef} onSubmit={props.onSubmit}>
            {props.children}
        </form>
    );
}

function Form_Group(props: { controlId: string; children: [JSX.Element, JSX.Element] | JSX.Element; style?: any }) {
    const _props = {
        controlId: props.controlId,
    };
    return (
        <div style={props.style}>
            {Array.isArray(props.children)
                ? [
                      <div key={"1"}>{React.cloneElement(props.children[0], _props)}</div>,
                      <div key={"2"}>{React.cloneElement(props.children[1], _props)}</div>,
                  ]
                : React.cloneElement(props.children, _props)}
        </div>
    );
}

function Form_Label(props: { controlId?: string; children: string }) {
    return <Label htmlFor={props.controlId}>{props.children}</Label>;
}

function Form_Select(props: { controlId?: string; defaultValue?: any; children?: any }) {
    return (
        <select className="w-full px-2 py-0.5" id={props.controlId}>
            {props.children}
        </select>
    );
}

type FileUploadProps = {
    view: "upload" | "login" | "welcome" | "details";
    viewOptions?: any & {
        files?: FileList | File[];
    };
    auth_token: string | null;
    auth_warmup: {
        req: string;
    } | null;
    project: string;
    onDone: (data: any) => void;
    resource_token?: string;
};

type FileUploadPopupState = {
    view: {
        name: string;
        title: string;
        error?: string;
        status?: any;
        busy?: boolean;
        pin_secret?: string;
        license?: string;
        lic_updated?: boolean;
        nonVisualProps?: any;
        canSkip?: boolean;
        login?: string;
        usePassword?: boolean;
        email?: string;
        message?: string;
    };
    data: {
        sub: string;
        auth_token: string;
        upload_result: any;
        file: File;
        details: any;
        clone: boolean;
        ops: any[];
    };
    timer0: number;
    timer: number;
};

export class FileUploadPopup extends React.Component<FileUploadProps, FileUploadPopupState> {
    static initialState: FileUploadPopupState = {
        view: null,
        data: {
            sub: null,
            auth_token: null,
            upload_result: null,
            file: null,
            details: null,
            clone: false,
            ops: [],
        },
        timer0: 0,
        timer: 0,
    };
    state: FileUploadPopupState = FileUploadPopup.initialState;

    onDragover = function (this: FileUploadPopup, event: any) {
        //event.dataTransfer.effectAllowed = "copy";
        event.preventDefault();
    }.bind(this);

    onDrop = function (this: FileUploadPopup, event: any) {
        try {
            const dt = event.dataTransfer;
            if (dt && dt.items && dt.items.length > 0) {
                if (dt.files && dt.files.length > 0) {
                    const input: HTMLInputElement = (this.activeForm?.elements || [])["file"];
                    if (input) {
                        input.files = dt.files;
                        this.onNext();
                    }
                }
            }
        } catch (e) {
        } finally {
            event.preventDefault();
        }
    }.bind(this);

    componentDidMount(this: FileUploadPopup) {
        if (this.props.viewOptions?.files || this.props.viewOptions?.clone) {
            this.onNext();
        }
    }

    componentWillUnmount(this: FileUploadPopup) {}

    static getDerivedStateFromProps(props: FileUploadProps, state: FileUploadPopupState): FileUploadPopupState {
        const LCMD = getLCMD();
        if (state?.view) {
            return state;
        } else {
            const initialView = {
                view: null,
            };
            switch (props.view) {
                case "welcome":
                case "upload":
                    initialView.view = {
                        name: "start",
                        //name: "details", // for debug
                        title: intl.get(["legacyProjectDialog", props.view, "title"].join(".")),
                        canSkip: "welcome" === props.view,
                    };
                    break;
                case "login":
                    initialView.view = {
                        name: "login",
                        title: intl.get(["legacyProjectDialog", "login", "title"].join(".")),
                        login: LCMD.worker.nav?.login,
                        license: LCMD.worker.nav?.license,
                    };
                    break;
                case "details":
                    initialView.view = {
                        name: "details",
                        title: intl.get(["legacyProjectDialog", "details", "title"].join(".")),
                        canSkip: false,
                    };
                    if (props?.resource_token) {
                        let token = null;
                        try {
                            token = JSON.parse(atob(props.resource_token.split(".")[1]));
                        } catch (e) {
                            token = null;
                        }
                        if (token) {
                            (initialView as any).data = {
                                ...(state.data || {}),
                                upload_result: {
                                    token: token,
                                    resource_token: props?.resource_token,
                                },
                            };
                        }
                    }
                    break;
            }
            return Object.assign({}, FileUploadPopup.initialState, initialView);
        }
    }

    onFileUploadAsync(
        this: FileUploadPopup,
        file: File,
        cb: (error: string, project: any) => void,
        status?: (status: { parsing?: boolean; ofs: number; length: number }) => void,
    ): any {
        if (true) {
            uploadFileBlocks(
                file,
                (error, result) => {
                    if (error) {
                        cb(error ? errorToMessage(error) : null, null);
                    } else {
                        if (status) {
                            status({
                                parsing: true,
                                ofs: file.size,
                                length: file.size,
                            });
                        }
                        getParsedProject(
                            getServices(),
                            {
                                token: result.resource_token,
                            },
                            (error, project: any) => {
                                if (error) {
                                    cb(error ? errorToMessage(error) : null, null);
                                } else {
                                    Object.assign(project, {
                                        file: {
                                            resource_token: result.resource_token,
                                            fileName: file.name,
                                            customTextFields: project?.props?.customTextFields || [],
                                        },
                                    });
                                    cb(null, project);
                                }
                            },
                        );
                    }
                },
                status,
            );
        }
    }

    onHide = function (this: FileUploadPopup) {
        this._ensureTimer(false);
        this.setState(FileUploadPopup.initialState, () => {
            this.props.onDone(null);
        });
    }.bind(this);

    onDone = function (this: FileUploadPopup, data: any) {
        this._ensureTimer(false);
        this.setState(FileUploadPopup.initialState, () => {
            this.props.onDone(data);
        });
    }.bind(this);

    onSubmit = function (this: FileUploadPopup, e: any) {
        e.preventDefault();
        this.onNext();
        return false;
    }.bind(this);

    onCreateProject = function (this: FileUploadPopup) {
        const LCMD = getLCMD();
        const auth_token = this.state.data?.auth_token;
        const resource_id = this.state.data?.upload_result?.token?.resource_id;
        const file = this.state.data?.file;
        const details = this.state.data?.details || {};
        if (auth_token && resource_id) {
            const customFields = {
                ...(this.state.data?.upload_result?.token?.resource_meta || {}),
                ...(details?.customFields || {}),
            };
            const _file = {
                id: resource_id,
                lastModified: file?.lastModified,
                name: file?.name,
                size: file?.size,
                type: file?.type,
                customFields: customFields,
            };
            const _commit = {
                name: details?.name || undefined,
                message: details?.message || undefined,
            };
            if (this.props.project) {
                assert(false); // no longer needed
            } else {
                createProject(
                    auth_token,
                    (error, result) => {
                        if (error) {
                            this.state.view.nonVisualProps = {
                                error: errorToMessage(error),
                            };
                            this.onNext();
                        } else {
                            this.state.view.nonVisualProps = {
                                done: result,
                            };
                            this.onNext();
                        }
                    },
                    _file,
                    _commit,
                );
            }
        } else if (auth_token && this.props.viewOptions?.clone) {
            // clone project
            assert(this.state.data?.clone);
            LCMD.worker.postMessage([
                "project",
                "clone",
                {
                    auth_token,
                    details,
                    clone: this.state.data.clone,
                    ops: this.state.data.ops,
                    cb: LCMD.worker.registerCallback((data: any) => {
                        if (!data?.result || data.error) {
                            const error = jsonToError(data?.error || undefined);
                            this.state.view.nonVisualProps = {
                                error: errorToMessage(error),
                            };
                            this.onNext();
                        } else {
                            assert(data.result);
                            this.state.view.nonVisualProps = {
                                done: data.result,
                            };
                            this.onNext();
                        }
                    }),
                },
            ]);
        } else if (auth_token && details?.startDate && details?.endDate) {
            // create a new/empty project template
            const _file = {
                id: "00000000-0000-0000-0000-000000000000",
                type: "application/vnd.lcmdigital.new",
                customFields: {
                    ...(details?.customFields || {}),
                    startDate: (details?.startDate).getTime(),
                    endDate: (details?.endDate).getTime(),
                },
            };
            const _commit = {
                name: details?.name || undefined,
                message: details?.message || undefined,
            };
            createProject(
                auth_token,
                (error, result) => {
                    if (error) {
                        this.state.view.nonVisualProps = {
                            error: errorToMessage(error),
                        };
                        this.onNext();
                    } else {
                        this.state.view.nonVisualProps = {
                            done: result,
                        };
                        this.onNext();
                    }
                },
                _file,
                _commit,
            );
        } else if (auth_token) {
            this.state.view.nonVisualProps = {
                done: {
                    auth_token: auth_token,
                },
            };
            this.onNext();
        } else {
            this.state.view.nonVisualProps = {
                error: "Internal Error",
            };
            this.onNext();
        }
    }.bind(this);

    onNext = function (this: FileUploadPopup) {
        const LCMD = getLCMD();
        let next = null;
        let data = undefined;
        let done: any = null;
        let cb: () => void = null;
        const view = this.state.view;
        switch (view.name) {
            case "start":
                if (view.nonVisualProps?.error) {
                    this._ensureTimer(false);
                    next = Object.assign({}, view, {
                        error: view.nonVisualProps?.error,
                        busy: false,
                        nonVisualProps: null,
                    });
                } else if (view.nonVisualProps?.upload_result) {
                    this._ensureTimer(false);
                    next = {
                        name: "details",
                        title: this.props.viewOptions?.clone ? "Cloning successful" : "Upload Successful",
                    };
                    data = {
                        upload_result: view.nonVisualProps?.upload_result,
                    };
                } else if (view.nonVisualProps?.upload_status) {
                    if (view.busy) {
                        this._ensureTimer(true);
                    }
                    next = Object.assign({}, view, {
                        status: view.nonVisualProps?.upload_status,
                        nonVisualProps: null,
                    });
                } else if (!view.busy) {
                    const input: HTMLInputElement = (this.activeForm?.elements || [])["file"];
                    const files = this.props.viewOptions?.files || input?.files;
                    if (files && files.length > 0) {
                        const file = files[0];
                        next = Object.assign({}, view, {
                            busy: true,
                        });
                        data = {
                            file: file,
                        };
                        cb = this.onFileUploadAsync.bind(
                            this,
                            file,
                            (error, result) => {
                                if (error) {
                                    this.state.view.nonVisualProps = {
                                        error: error,
                                    };
                                } else {
                                    this.state.view.nonVisualProps = {
                                        upload_result: result,
                                    };
                                }
                                this.onNext();
                            },
                            (status) => {
                                this.state.view.nonVisualProps = {
                                    upload_status: status,
                                };
                                this.onNext();
                            },
                        );
                    } else {
                        // no project => login
                        this._ensureTimer(false);
                        if (view.canSkip) {
                            if (this.props.auth_token) {
                                next = {
                                    name: "done",
                                    title: intl.get("legacyProjectDialog.done.title"),
                                    busy: true,
                                };
                                cb = this.onCreateProject;
                                data = {
                                    auth_token: this.props.auth_token,
                                };
                            } else {
                                data = {};
                                next = {
                                    name: "login",
                                    title: intl.get("legacyProjectDialog.login.title"),
                                    login: LCMD.worker.nav?.login,
                                };
                            }
                        } else {
                            if (this.props.auth_token) {
                                const clone = this.props.viewOptions?.clone ? true : false;
                                if (clone) {
                                    next = {
                                        name: view.name,
                                        title: intl.get("legacyProjectDialog.clone.title"),
                                        busy: true,
                                    };
                                    cb = () => {
                                        LCMD.worker.postMessage([
                                            "project",
                                            "clone_pre",
                                            {
                                                ...this.props.viewOptions.clone,
                                                auth_token: this.props.auth_token,
                                                cb: LCMD.worker.registerCallback((data: any) => {
                                                    if (!data?.result || data.error) {
                                                        const error = errorToMessage(
                                                            jsonToError(data?.error || undefined),
                                                        );
                                                        this.state.view.nonVisualProps = {
                                                            error: error,
                                                        };
                                                        this.setState(
                                                            {
                                                                view: {
                                                                    ...this.state.view,
                                                                    busy: false,
                                                                    name: "done",
                                                                    error: error,
                                                                    title: intl.get("legacyProjectDialog.clone.title"),
                                                                },
                                                                data: null,
                                                            },
                                                            this.onNext,
                                                        );
                                                    } else {
                                                        assert(data.result);
                                                        this.setState(
                                                            {
                                                                view: {
                                                                    ...this.state.view,
                                                                    busy: true,
                                                                    name: "details",
                                                                    title: clone
                                                                        ? intl.get("legacyProjectDialog.clone.title")
                                                                        : intl.get("legacyProjectDialog.empty.title"),
                                                                },
                                                                data: data.result,
                                                            },
                                                            this.onNext,
                                                        );
                                                    }
                                                }),
                                            },
                                        ]);
                                        this.state.view.nonVisualProps = {
                                            upload_status: {
                                                clone,
                                                parsing: true,
                                                ofs: 0,
                                                length: 0,
                                            },
                                        };
                                        this.onNext();
                                    };
                                    data = {
                                        auth_token: this.props.auth_token,
                                        clone,
                                    };
                                } else {
                                    next = {
                                        name: "details",
                                        title: intl.get("legacyProjectDialog.empty.title"),
                                    };
                                    data = {
                                        auth_token: this.props.auth_token,
                                    };
                                    this.onNext();
                                }
                            }
                        }
                    }
                }
                break;
            case "details":
                {
                    const input: HTMLInputElement = (this.activeForm?.elements || [])["formProjectName"];
                    const projectName = input?.value || "";
                    const textArea: HTMLTextAreaElement = (this.activeForm?.elements || [])["formCommitMessage"];
                    const formSelectTaktZone: HTMLSelectElement = (this.activeForm?.elements || [])[
                        "formSelectTaktZone"
                    ];
                    const formDefaultCalendar: HTMLSelectElement = (this.activeForm?.elements || [])[
                        "formDefaultCalendar"
                    ];
                    const commitMessage = textArea?.value || "";
                    const details = {
                        name: projectName,
                        message: commitMessage,
                        customFields: {
                            taktZone: formSelectTaktZone.value,
                            calendar: formDefaultCalendar.value,
                        },
                    };
                    if (this.props.viewOptions?.clone) {
                        // clone project
                        if (this.state.data.clone) {
                            if (view.busy) {
                                // do nothing...
                                next = { ...view, busy: false };
                            } else {
                                next = {
                                    name: "done",
                                    title: intl.get("legacyProjectDialog.done.title"),
                                    busy: true,
                                };
                                data = {
                                    details,
                                };
                                cb = this.onCreateProject;
                            }
                        } else {
                            // (old JSON-based clone)
                            data = {
                                auth_token: this.props.auth_token,
                                details: details,
                            };
                            next = {
                                name: "done",
                                title: intl.get("legacyProjectDialog.done.title"),
                                busy: true,
                            };
                            cb = this.onCreateProject;
                        }
                    } else if (!this.state.data?.upload_result) {
                        // create project form scatch => ask for more details
                        data = {
                            auth_token: this.props.auth_token,
                            details: details,
                        };
                        next = {
                            name: "details2",
                            title: intl.get("legacyProjectDialog.details2.title"),
                        };
                    } else {
                        if (this.props.auth_token) {
                            next = {
                                name: "done",
                                title: intl.get("legacyProjectDialog.done.title"),
                                busy: true,
                            };
                            cb = this.onCreateProject;
                            data = {
                                auth_token: this.props.auth_token,
                                details: details,
                            };
                        } else {
                            data = {
                                details: details,
                            };
                            next = {
                                name: "login",
                                title: intl.get("legacyProjectDialog.login.title"),
                                login: LCMD.worker.nav?.login,
                            };
                        }
                    }
                }
                break;
            case "details2":
                {
                    const _startDate: HTMLInputElement = (this.activeForm?.elements || [])["startDate"];
                    const _endDate: HTMLInputElement = (this.activeForm?.elements || [])["endDate"];
                    const startDate = _startDate ? new Date(_startDate.value) : null;
                    const endDate = _endDate ? new Date(_endDate.value) : null;
                    if (startDate && endDate && startDate.getTime() < endDate.getTime()) {
                        data = Object.assign({}, this.state.data);
                        data.details = { ...data.details, startDate, endDate };
                        if (this.props.auth_token) {
                            next = {
                                name: "done",
                                title: intl.get("legacyProjectDialog.done.title"),
                                busy: true,
                            };
                            cb = this.onCreateProject;
                        } else {
                            next = {
                                name: "login",
                                title: intl.get("legacyProjectDialog.login.title"),
                                login: LCMD.worker.nav?.login,
                            };
                        }
                    } else {
                        //invalid date...
                    }
                }
                break;
            case "login":
                assert(false); // no longer used
                break;
            case "pin":
                assert(false); // no longer used
                break;
            case "legal":
                assert(false); // no longer used
                break;
            case "done":
                if (view.nonVisualProps?.error) {
                    next = Object.assign({}, view, {
                        error: view.nonVisualProps?.error,
                        busy: false,
                        nonVisualProps: null,
                    });
                } else if (view.nonVisualProps?.done) {
                    next = {};
                    done = view.nonVisualProps?.done;
                }
                break;
        }
        if (next) {
            if (data) {
                this.setState(
                    {
                        view: next,
                        data: Object.assign({}, this.state.data || {}, data),
                    },
                    cb,
                );
            } else {
                this.setState(
                    {
                        view: next,
                    },
                    cb,
                );
            }
        }
        if (done) {
            this.onDone(done);
        }
    }.bind(this);

    private activeForm: HTMLFormElement = null;
    private _activeFormRef = function (this: FileUploadPopup, ref: HTMLFormElement) {
        this.activeForm = ref;
    }.bind(this);

    private onTimerRef: any | null = null;
    private onTimer = function (this: FileUploadPopup) {
        const now = new Date().getTime();
        this.setState({
            timer: now,
        });
    }.bind(this);

    private _ensureTimer(ensure: boolean) {
        if (ensure) {
            if (null === this.onTimerRef) {
                const now = new Date().getTime();
                this.setState(
                    {
                        timer0: now,
                        timer: now,
                    },
                    () => {
                        this.onTimerRef = setInterval(this.onTimer, 1000);
                    },
                );
            }
        } else {
            if (null !== this.onTimerRef) {
                clearInterval(this.onTimerRef);
                this.onTimerRef = null;
                this.setState({
                    timer0: 0,
                    timer: 0,
                });
            }
        }
    }

    render(this: FileUploadPopup) {
        const defaultStartDate = new Date();
        const defaultEndDate = new Date(new Date().setMonth(defaultStartDate.getMonth() + 12));
        const INTL_HELPER = (uploadButton) =>
            intl
                .get("legacyProjectDialog.upload.content")
                .split("<uploadButton/>")
                .reduce((ret, s, i_s, a_s) => {
                    ret.push(<span key={i_s}>{s}</span>);
                    if (i_s + 1 < a_s.length) {
                        ret.push(React.cloneElement(uploadButton, { key: -(i_s + 1) }));
                    }
                    return ret;
                }, []);
        const onDismiss = this.state.view?.message ? this.onNext : this.onHide;
        return "procore" === this.state.view?.name && null !== this.props.onDone && !this.props.project ? (
            <ProCoreUploadPopup onDone={this.props.onDone} />
        ) : (
            <LcmdModal2
                size="m"
                open={!!this.props.onDone}
                onOpenChange={onDismiss}
                className="pb-1"
                header={{
                    title: this.state.view?.message ? "Message" : this.state.view?.title || "",
                }}
            >
                <div className="flex flex-auto flex-col p-0" onDragOver={this.onDragover} onDrop={this.onDrop}>
                    {this.state.view?.error && (
                        <div className="relative mb-2 flex items-center">
                            <XCircle
                                color="#ef4444"
                                className="absolute inset-y-0 left-3 my-auto h-4 w-4 text-gray-500"
                            />
                            <Alert variant="destructive" className="py-1 pl-8 pr-4 text-sm">
                                {this.state.view.error}
                            </Alert>
                        </div>
                    )}
                    {null === this.state.view ? null : this.state.view?.message ? (
                        <div>{this.state.view.message}</div>
                    ) : "start" === this.state.view.name ? (
                        <div className="flex min-h-[400px] w-full flex-col justify-center bg-[#C4C4C7]">
                            <form ref={this._activeFormRef} onSubmit={this.onSubmit} className="text-center">
                                {this.state.view.status?.parsing ? (
                                    <span>
                                        {this.state.view.status?.clone
                                            ? "TODO CLONE"
                                            : intl.get("legacyProjectDialog.upload.processing", {
                                                  totalMB: Math.ceil(this.state.view.status.length / 1024 / 1024),
                                              })}
                                    </span>
                                ) : this.state.view.status ? (
                                    this.state.view.status.ofs > 0 ? (
                                        <span>
                                            {intl.get("legacyProjectDialog.upload.uploading", {
                                                ofs: this.state.view.status.ofs,
                                                length: this.state.view.status.length,
                                                percent: Math.ceil(
                                                    (this.state.view.status.ofs * 100) / this.state.view.status.length,
                                                ),
                                            })}
                                        </span>
                                    ) : (
                                        <span>
                                            {intl.get("legacyProjectDialog.upload.uploading2", {
                                                totalMB: Math.ceil(this.state.view.status.length / 1024 / 1024),
                                            })}
                                        </span>
                                    )
                                ) : (
                                    <span>
                                        {INTL_HELPER(
                                            <input
                                                onChange={this.onNext}
                                                type="file"
                                                name="file"
                                                data-userpilot-id="fileUploadPopup-mppFile"
                                                className="w-[220px]"
                                            />,
                                        )}
                                    </span>
                                )}
                                {this.state.view.status?.parsing || this.state.view.status
                                    ? null
                                    : intl.getHTML("legacyProjectDialog.upload.content2", {
                                          link: intl.get("links.uploadHelpURL"),
                                      })}
                            </form>
                            {this.state.view.status?.parsing || this.state.view.status ? (
                                <NonProgressBar
                                    {...this.state}
                                    timerLabel={this.state.view.status.clone ? "TODO CLONE TIME" : undefined}
                                />
                            ) : this.props.project || true !== this.state.view?.canSkip ? (
                                <div className="text-center">{intl.getHTML("legacyProjectDialog.upload.content3")}</div>
                            ) : (
                                <div className="text-center">{intl.getHTML("legacyProjectDialog.upload.content4")}</div>
                            )}
                        </div>
                    ) : "details" === this.state.view.name ? (
                        <div className="w-full">
                            <div
                                style={{
                                    display: this.state.data?.upload_result?.file ? undefined : "none",
                                    paddingBottom: 10,
                                }}
                            >
                                {this.props.viewOptions?.clone ? (
                                    <span>{intl.getHTML("legacyProjectDialog.details.successClone")}</span>
                                ) : (
                                    <span>
                                        {intl.getHTML("legacyProjectDialog.details.successUpload", {
                                            file: this.state.data?.upload_result?.file?.fileName || "?",
                                        })}
                                    </span>
                                )}
                            </div>
                            <Form formRef={this._activeFormRef} onSubmit={this.onSubmit}>
                                <Label className="text-[#323130]" htmlFor="formProjectName">
                                    {intl.get("legacyProjectDialog.details.formProjectName.label")}
                                </Label>
                                <Input
                                    id="formProjectName"
                                    placeholder={intl.get("legacyProjectDialog.details.formProjectName.placeholder")}
                                    defaultValue={
                                        this.state.data?.upload_result?.props?.projectTitle ||
                                        this.state.data?.upload_result?.file?.fileName ||
                                        ""
                                    }
                                    className="mb-2 mt-1"
                                    data-userpilot-id="fileUploadPopup-projectName"
                                />
                                <Label className="text-[#323130]" htmlFor="formCommitMessage">
                                    {intl.get("legacyProjectDialog.details.formCommitMessage.label")}
                                </Label>
                                <Textarea
                                    id="formCommitMessage"
                                    data-userpilot-id="fileUploadPopup-projectDescription"
                                    className="mb-2 mt-1"
                                    rows={6}
                                />
                                <Form_Group controlId="formSelectTaktZone" style={{ display: "none" }}>
                                    <Form_Label>
                                        {intl.get("legacyProjectDialog.details.formSelectTaktZone.label")}
                                    </Form_Label>
                                    <Form_Select defaultValue={22 - 1}>
                                        <option key={-1} value={-1}>
                                            None
                                        </option>
                                        {(this.state.data?.upload_result?.props?.customTextFields || []).map(
                                            (e, i_e) => {
                                                return (
                                                    <option key={i_e} value={i_e}>
                                                        {e}
                                                    </option>
                                                );
                                            },
                                        )}
                                    </Form_Select>
                                </Form_Group>
                                <Form_Group
                                    controlId="formDefaultCalendar"
                                    style={{ display: this.state.data?.clone ? "none" : undefined }}
                                >
                                    <Form_Label>
                                        {intl.get("legacyProjectDialog.details.formDefaultCalendar.label")}
                                    </Form_Label>
                                    <Form_Select
                                        defaultValue={5}
                                        data-userpilot-id="fileUploadPopup-projectDefaultCalendar"
                                    >
                                        <option value={5}>
                                            {intl.get("legacyProjectDialog.details.formDefaultCalendar.5")}
                                        </option>
                                        <option value={6}>
                                            {intl.get("legacyProjectDialog.details.formDefaultCalendar.6")}
                                        </option>
                                        <option value={7}>
                                            {intl.get("legacyProjectDialog.details.formDefaultCalendar.7")}
                                        </option>
                                    </Form_Select>
                                </Form_Group>
                            </Form>
                        </div>
                    ) : "details2" === this.state.view.name ? (
                        <div className="w-full">
                            <form ref={this._activeFormRef} onSubmit={this.onSubmit}>
                                <input name="startDate" type="hidden" value={defaultStartDate.toISOString()} />
                                <input name="endDate" type="hidden" value={defaultEndDate.toISOString()} />
                                <Form_Group controlId="formStartEnd">
                                    <Form_Label>{intl.get("legacyProjectDialog.details2.label")}</Form_Label>
                                    <LegacyStartEndDateControl
                                        utc={false}
                                        defaultStart={defaultStartDate}
                                        defaultEnd={defaultEndDate}
                                        onChange={(start: Date, end: Date) => {
                                            const startDate: HTMLInputElement = (this.activeForm?.elements || [])[
                                                "startDate"
                                            ];
                                            const endDate: HTMLInputElement = (this.activeForm?.elements || [])[
                                                "endDate"
                                            ];
                                            if (startDate) {
                                                startDate.value = start ? start.toISOString() : "";
                                            }
                                            if (endDate) {
                                                endDate.value = end ? end.toISOString() : "";
                                            }
                                        }}
                                        data-userpilot-id="fileUploadPopup-projectStartDate"
                                    />
                                </Form_Group>
                            </form>
                        </div>
                    ) : "login" === this.state.view.name ? null /*no longer used*/ : "pin" ===
                      this.state.view.name ? null /* no longer used*/ : "legal" ===
                      this.state.view.name ? null /* no longer used*/ : null}
                </div>
                <div className="flex flex-none items-end justify-end gap-2 pt-0">
                    <div
                        style={{
                            flexGrow: 1,
                            textAlign: "left",
                            display:
                                false && "start" === this.state.view?.name && !this.props.project ? undefined : "none",
                        }}
                    >
                        <Button
                            disabled={false}
                            onClick={() => this.setState({ view: { name: "procore", title: null } })}
                        >
                            ProCore
                        </Button>
                    </div>
                    <Button
                        variant="outline"
                        disabled={!this.state.view?.message && (null === this.state.view || this.state.view.busy)}
                        onClick={this.state.view?.message ? this.onNext : this.onHide}
                    >
                        {intl.get("legacyProjectDialog.cancel")}
                    </Button>
                    <Button
                        disabled={
                            !this.state.view?.message &&
                            (null === this.state.view || this.state.view.busy || "start" === this.state.view.name)
                        }
                        onClick={this.onNext}
                        data-userpilot-id="fileUploadPopup-step1ContinueButton"
                    >
                        {intl.get("legacyProjectDialog.next")}
                    </Button>
                </div>
            </LcmdModal2>
        );
    }
}
